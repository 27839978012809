import React from 'react';
import { history } from '../../helper/history.js';
import { routes } from '../../helper/routes.js';
import ContentWrapper from '../../components/common/AssessmentContentWrapper.js';
import AdminTemplate from '../../components/admin/layouts/Template.js';
import Card from '../../components/common/Card.js';
import CardHeader from '../../components/common/CardHeader.js';
import CardBody from '../../components/common/CardBody.js';
import CardTitle from '../../components/common/AssessmentCardTitle.js';
import CardToolbar from '../../components/common/QuestionCardToolBar.js';
import Breadcrumb from '../../components/common/Breadcrumb.js';
import BreadcrumbItem from '../../components/common/BreadcrumbItem.js';
import { questionTypeConstants } from "../../helper/constants";
import Form from '../../components/common/form/Form.js';
import Button from '../../components/common/form/Button.js';
import FormGroup from '../../components/common/form/FormGroup.js';
import Input from '../../components/common/form/Input.js';
import Select from '../../components/common/form/AssessmentSelect.js';
import Option from '../../components/common/form/Option.js';
import CustomEditor from "../../components/common/editor/CustomEditor";
import GuidanceEditor from '../../components/common/editor/GuidanceEditor.js';
import FileUpload from '../../components/common/form/FileUpload.js';
import { Redirect } from 'react-router-dom';
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { connect } from 'react-redux';
import { libraryActions } from '../../actions/question/libraryActions.js';
import { createActions } from '../../actions/question/createActions.js';
import { AzureStorage } from "../../helper/azureStorage";
import { namespace } from "../../helper/namespace"
import Spinner from "../../components/common/Spinner";
import { questionActions } from '../../actions/assessment/questionActions.js';
import { questionDifficultyConstants } from "../../helper/constants";
import DataHandler from '../../handlers/DataHandler.js';
import config from '../../helper/config.js';
import {customerConstants} from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, confirmClass, cancelClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class EditQuestion extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showSummerNoteOrFileUpload: this.props.question.qtype,
      mcqAnswerList: [],
      title: '',
      sourceOptions: [],
      subjectOptions: [],
      authorOptions: [],
      yearOptions: [],
      termOptions: [],
      privilegeOptions: [],
      tags: '',
      allGrades: [],
      allChapters: [],
      selectedGrade: "",
      selectedChapter: "",
      guidance: null,

      answer_content: '',
      editorQuestionState: EditorState.createEmpty(),
      editorGuidanceState: EditorState.createEmpty(),
      editorAnswerStates: [],
      selectedChoices: [],
      delete: false,
      refresh: false,
      edit: false,
      updateState: false,
      editAttachment: '',
      category: '',
      difficulty: '',
      language: '',
      subject: '',
      source: '',
      author: '',
      year: '',
      privilege: '',
      term: '',
      setQuestion: false,
      uploaded: false,
      categoryOptions: [],
      difficultyOptions: [],
      languageOptions: [],
      deletedFiles: [],
      isSubQuestion: false,
      reset: false
    }

    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleAuthorChange = this.handleAuthorChange.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleTermChange = this.handleTermChange.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.onContentEditorChange = this.onContentEditorChange.bind(this);
    this.onGuidanceEditorChange = this.onGuidanceEditorChange.bind(this);
    this.onChoicesEditorChange = this.onChoicesEditorChange.bind(this);
    this.onChoiceEdit = this.onChoiceEdit.bind(this);
    this.onChoicesEditorChange = this.onChoicesEditorChange.bind(this);
    this.addOption = this.addOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.onUpdateQuestion = this.onUpdateQuestion.bind(this);
    this.onCancelQuestion = this.onCancelQuestion.bind(this);
    this.onResetQuestion = this.onResetQuestion.bind(this);
    this.onDeleteQuestion = this.onDeleteQuestion.bind(this);
    this.getSubmissionContent = this.getSubmissionContent.bind(this);
    this.deletSubmissionContent = this.deletSubmissionContent.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleDifficultyChange = this.handleDifficultyChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.uploadAttachment = this.uploadAttachment.bind(this);
  }

  onUnload = e => { // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = '';
  }

  async componentDidMount() {

    window.onpopstate = function (event) {
      history.push(routes.questions_library)
      //window.location.reload()
    };

    if (window.KTSelect2) window.KTSelect2.init();
    if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
    if (window.KTIONRangeSlider) window.KTIONRangeSlider.init();
    if (window.KTSummernoteDemo) window.KTSummernoteDemo.init();
    if (window.$) window.$('.selectpicker').selectpicker();

    this.props.getSetQuestion(this.props.question?.question_id);
    if (this.props.assessmentSetQuestions?.length > 0) {
      this.setState({
        setQuestion: true
      })
    }

    await this.props.getQuestionMetaData()

    let questionCategories = this.props.allQuestionMetaData.find(item => 'categories' in item).categories;
    if (questionCategories.length > 0) {
      let tempCategories = []
      let categoryOptions = []
      questionCategories.map((el, i) => {
        tempCategories[el.category_id] = el.name
        categoryOptions.push(<Option key={el.category_id} optionName={el.name} value={el.category_id} />)
      });
      this.setState({
        categoryOptions: categoryOptions
      })

      if (categoryOptions)
        window.$('#question-category').selectpicker('refresh');

    }

    let questionDifficulties = this.props.allQuestionMetaData.find(item => 'difficulty_types' in item).difficulty_types;
    if (questionDifficulties.length > 0) {
      let tempDifficultyTypes = [];
      let difficultyOptions = [];
      questionDifficulties.map((el, i) => {
        tempDifficultyTypes[el.difficulty_id] = el.difficulty_type
        difficultyOptions.push(<Option key={el.difficulty_id} optionName={el.difficulty_type} value={el.difficulty_id} />)
      });

      this.setState({
        difficultyOptions: difficultyOptions
      })

      if (difficultyOptions)
        window.$('#question-difficulty').selectpicker('refresh');
    }

    let questionLanguages = this.props.allQuestionMetaData.find(item => 'languages' in item).languages;
    if (questionLanguages.length > 0) {
      let tempLanguages = [];
      let languageOptions = [];
      languageOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionLanguages.map((el, i) => {
        tempLanguages[el.language_id] = el.language
        languageOptions.push(<Option key={el.language_id} optionName={el.language} value={el.language_id} />)
      });

      this.setState({
        languageOptions: languageOptions,
      })

      if (languageOptions)
        window.$('#question-language').selectpicker('refresh');
    }

    let questionSources = this.props.allQuestionMetaData.find(item => 'sources' in item).sources;
    if (questionSources.length > 0) {
      let tempSources = [];
      let sourceOptions = [];
      sourceOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionSources.map((el, i) => {
        tempSources[el.source_id] = el.source
        sourceOptions.push(<Option key={el.source_id} optionName={el.source} value={el.source_id} />)
      });
      this.setState({
        sourceOptions: sourceOptions
      })
      if (sourceOptions)
        window.$('#question-source').selectpicker('refresh');
    }

    let questionGrades = this.props.allQuestionMetaData.find(item => 'grades' in item).grades;
    if (questionGrades.length > 0) {
      let allGrades = [];
      allGrades.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionGrades.map((el, i) => {
        allGrades.push(<Option key={el.grade_id} optionName={el.grade} value={el.grade_id} />)
      });

      this.setState({
        allGrades: allGrades,
      })

      if (allGrades)
        window.$('#question-grade').selectpicker('refresh');
    }

    let questionChapters = this.props.allQuestionMetaData.find(item => 'chapters' in item).chapters;
    if (questionChapters.length > 0) {
      let allChapters = [];
      allChapters.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionChapters.map((el, i) => {
        allChapters.push(<Option key={el.chapter_id} optionName={el.chapter} value={el.chapter_id} />)
      });

      this.setState({
        allChapters: allChapters,
      })

      if (allChapters)
        window.$('#question-chapter').selectpicker('refresh');
    }

    let questionSubjects = this.props.allQuestionMetaData.find(item => 'subjects' in item).subjects;
    if (questionSubjects.length > 0) {
      let tempSubjects = [];
      let subjectOptions = [];
      subjectOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionSubjects.map((el, i) => {
        tempSubjects[el.subject_id] = el.subject
        subjectOptions.push(<Option key={el.subject_id} optionName={el.subject} value={el.subject_id} />)
      });

      this.setState({
        subjectOptions: subjectOptions
      })

      if (subjectOptions)
        window.$('#question-subject').selectpicker('refresh');
    }

    let questionAuthors = this.props.allQuestionMetaData.find(item => 'authors' in item).authors;
    if (questionAuthors.length > 0) {
      let tempAuthors = [];
      let authorOptions = [];
      authorOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionAuthors.map((el, i) => {
        tempAuthors[el.author_id] = el.author
        authorOptions.push(<Option key={el.author_id} optionName={el.author} value={el.author_id} />)
      });

      this.setState({
        authorOptions: authorOptions
      })

      if (authorOptions)
        window.$('#question-author').selectpicker('refresh');
    }

    let questionYears = this.props.allQuestionMetaData.find(item => 'years' in item).years;
    if (questionYears.length > 0) {
      let tempYears = [];
      let yearOptions = [];
      yearOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionYears.map((el, i) => {
        tempYears[el.year_id] = el.year
        yearOptions.push(<Option key={el.year_id} optionName={el.year} value={el.year_id} />)
      });

      this.setState({
        yearOptions: yearOptions
      })

      if (yearOptions)
        window.$('#question-year').selectpicker('refresh');
    }

    let questionPrivileges = this.props.allQuestionMetaData.find(item => 'privileges' in item).privileges;
    if (questionPrivileges.length > 0) {
      let privilegeOptions = [];

      questionPrivileges.map((el, i) => {
        privilegeOptions.push(<Option key={el.privilege_id} optionName={el.privilege} value={el.privilege_id} />)
      });

      this.setState({
        privilegeOptions: privilegeOptions
      })

      if (privilegeOptions)
        window.$('#question-privilege').selectpicker('refresh');
    }

    let questionTerms = this.props.allQuestionMetaData.find(item => 'terms' in item).terms;
    if (questionTerms.length > 0) {
      let tempTerms = [];
      let termOptions = [];
      termOptions.push(
        <Option key="" optionName="Nothing selected" value={null} />
      );
      questionTerms.map((el, i) => {
        tempTerms[el.term_id] = el.term
        termOptions.push(<Option key={el.term_id} optionName={el.term} value={el.term_id} />)
      });

      this.setState({
        termOptions: termOptions
      })

      if (termOptions)
        window.$('#question-term').selectpicker('refresh');
    }

    if (this.props.question) {
      let type = this.props.question.qtype
      this.setState({ title: this.props.question.title })
      this.setState({ subject: this.props.question.subject_id })
      this.setState({ source: this.props.question.source_id })
      this.setState({ author: this.props.question.author_id })
      this.setState({ year: this.props.question.year_id })
      this.setState({ term: this.props.question.term_id })
      this.setState({ tags: this.props.question.tags })
      this.setState({ category: this.props.question.category_id })
      this.setState({ difficulty: this.props.question.difficulty_id })
      this.setState({ language: this.props.question.language_id })
      this.setState({ selectedChapter: this.props.question.chapter_id })
      this.setState({ selectedGrade: parseInt(this.props.question.grade_id) })
      this.setState({ privilege: this.props.question.privilege_id })
      // this.setState({ guidance: this.props.question.guidence })
      if (this.props.question?.is_subquestion === "true") {
        this.setState({
          isSubQuestion: true
        })
      }
      if (type === questionTypeConstants.MCQ.id) {
        this.setState({ showSummerNoteOrFileUpload: questionTypeConstants.MCQ.name, uploaded: true })

      } else if (type === questionTypeConstants.DESCRIPTIVE.id) {
        this.setState({
          showSummerNoteOrFileUpload: questionTypeConstants.DESCRIPTIVE.name,
          editorAnswerStates: EditorState.createEmpty(),
          uploaded: true
        })
      }
      else if (type === questionTypeConstants.SUBQUESTION.id) {
        this.setState({
          showSummerNoteOrFileUpload: questionTypeConstants.SUBQUESTION.name,
          editorAnswerStates: EditorState.createEmpty(),
          uploaded: true
        })
      }
      else if (type === questionTypeConstants.SUBMISSION.id) {
        this.setState({ showSummerNoteOrFileUpload: questionTypeConstants.SUBMISSION.name, uploaded: true })
      } else {
        this.setState({ showSummerNoteOrFileUpload: null })
      }

    }
    try {
      let tempAnswerStates = []
      let states = JSON.parse(this.props.questionContent)
      let guidanceStates = JSON.parse(this.props.guidanceContent)
      let tempQus = EditorState.createWithContent(convertFromRaw(states[0]))
      let tempGuidance = EditorState.createWithContent(convertFromRaw(guidanceStates[0]))
      this.setState({ editorQuestionState: tempQus })
      this.setState({ editorGuidanceState: tempGuidance })
      let tempAnswer = []
      if (this.props.question.qtype === questionTypeConstants.MCQ.id) {
        states.shift();
        states.forEach(state => {
          tempAnswerStates.push(EditorState.createWithContent(convertFromRaw(state)))
        });

        this.props.question.answer.split(',').map((answer) => {
          tempAnswer.push(parseInt(answer));
        });
        this.setState({
          editorAnswerStates: tempAnswerStates,
          selectedChoices: tempAnswer
        })
      } else if (this.props.question.qtype === questionTypeConstants.DESCRIPTIVE.id) {
        let ans_states = JSON.parse(this.props.answerContent)
        let tempAns = EditorState.createWithContent(convertFromRaw(ans_states[0]))
        this.setState({ editorAnswerStates: tempAns })
      } else if (this.props.question.qtype === questionTypeConstants.SUBQUESTION.id && this.state.isSubQuestion) {
        let ans_states = JSON.parse(this.props.answerContent)
        let tempAns = EditorState.createWithContent(convertFromRaw(ans_states[0]))
        this.setState({ editorAnswerStates: tempAns })
      }
      else if (this.props.question.qtype === questionTypeConstants.SUBMISSION.id) {
        this.setState({ editAttachment: this.props.answerContent.replace(/^\[(.+)\]$/, "$1").replace(/"\"$/, "$1").split(',') })
      }
    } catch {
      this.setState({ refresh: true });
    }
    let self = this;
    window.$('#kt_select2_11').on('select2:select', function (e) {
      var data = e.params.data;
      self.handleCategoryChange(data.id)
    });

    window.$('#kt_select2_11').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handleCategoryChange(data.id)
    });

    window.$('#kt_select2_13').on('select2:select', function (e) {
      var data = e.params.data;
      self.handleDifficultyChange(data.id)
    });

    window.$('#kt_select2_13').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handleDifficultyChange(data.id)
    });

    window.$('#kt_select2_15').on('select2:select', function (e) {
      var data = e.params.data;
      self.handleLanguageChange(data.id)
    });

    window.$('#kt_select2_15').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handleLanguageChange(data.id)
    });

    window.$('#kt_select2_17').on('select2:select', function (e) {
      var data = e.params.data;
      self.handleSubjectChange(data.id)
    });

    window.$('#kt_select2_17').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handleSubjectChange(data.id)
    });

    window.$('#kt_select2_19').on('select2:select', function (e) {
      var data = e.params.data;
      self.handleSourceChange(data.id)
    });

    window.$('#kt_select2_19').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handleSourceChange(data.id)
    });

    window.$('#kt_select2_27').on('select2:select', function (e) {
      var data = e.params.data;
      self.handleGradeChange(data.id)
    });

    window.$('#kt_select2_27').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handleGradeChange(data.id)
    });

    window.$('#kt_select2_28').on('select2:select', function (e) {
      var data = e.params.data;
      self.handleChapterChange(data.id)
    });

    window.$('#kt_select2_28').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handleChapterChange(data.id)
    });

    window.$('#kt_select2_21').on('select2:select', function (e) {
      var data = e.params.data;
      self.handleAuthorChange(data.id)
    });

    window.$('#kt_select2_21').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handleAuthorChange(data.id)
    });

    window.$('#kt_select2_29').on('select2:select', function (e) {
      var data = e.params.data;
      self.handlePrivilegeChange(data.id)
    });

    window.$('#kt_select2_29').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handlePrivilegeChange(data.id)
    });

    window.$('#kt_select2_23').on('select2:select', function (e) {
      var data = e.params.data;
      self.handleYearChange(data.id)
    });

    window.$('#kt_select2_23').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handleYearChange(data.id)
    });

    window.$('#kt_select2_25').on('select2:select', function (e) {
      var data = e.params.data;
      self.handleTermChange(data.id)
    });

    window.$('#kt_select2_25').on('select2:unselect', function (e) {
      var data = e.params.data;
      self.handleTermChange(data.id)
    });

    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  componentDidUpdate() {
    if (this.props.successupdate && this.state.updateState) {
      if (!this.props.loadingupdate && this.props.errorupdate) {
        window.Swal.fire({
          title: namespace.FAILED,
          text: namespace.QUESTION_UPDATED_FAIL,
          icon: 'error',
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
          }
        });
      } else {
        window.removeEventListener("beforeunload", this.onUnload);
        window.Swal.fire({
          title: 'Success!',
          text: namespace.QUESTION_UPDATED_SUCCESSFULL,
          icon: 'success',
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
        })
          .then(function () {
            history.push(routes.questions_library)
            //window.location.reload()
          });
      }
      this.setState({ updateState: false })
    }
  }

  onDeleteQuestion() {
    window.Swal.fire({
      title: 'Do you want to remove the question?',
      showCancelButton: true,
      confirmButtonColor: addBtnColor,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (this.props.question.share) {
          window.Swal.fire({
            title: namespace.FAILED,
            text: namespace.CANT_REMOVE_SHARED_QUESTION,
            icon: 'error',
            customClass: {
              confirmButton: `btn font-weight-bold ${cancelClass}`,
              cancelButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
              }
            }
          });
        } else if (this.props.assessmentSetQuestions?.length > 0) {
          window.Swal.fire({
            title: namespace.FAILED,
            text: namespace.CANT_REMOVE_SET_QUESTION,
            icon: 'error',
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
          });
        }
        else {
          const user_id = DataHandler.getFromSession("user_id")
          const containerName = "".concat(user_id, "-a-", this.props?.question.answer)

          if (this.props.question.qtype === questionTypeConstants.SUBMISSION.id) {
            await AzureStorage.removeBlobContent(containerName, this.props?.question.answer)
            //         this.state.editAttachment.map(async function (attach) {
            //         //await AzureStorage.deleteContainerImmediately(containerName)
            // })
          }
          await this.props.deleteQuestion(this.props.question);
          window.removeEventListener("beforeunload", this.onUnload);
          window.Swal.fire({
            title: 'Success!',
            text: namespace.QUESTION_DELETED_SUCCESSFULL,
            icon: 'success',
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
          })
            .then(function () {
              history.push(routes.questions_library)
              //window.location.reload()
            });
          //    this.setState({ delete: true }); 
        }
      }
    })
  }


  handleTitleChange(e) {
    this.setState({
      title: e.target.value,
      updatedTitle: e.target.value,
      reset: false
    });
  }

  handleGuidanceChange = (e) => {
    this.setState({
      guidance: e.target.value,
      reset: false
    });
  }

  handleSubjectChange(data) {
    if (data === "Nothing selected") {
      this.setState({
        subject: null,
        reset: false
      });
    }
    else {
      this.setState({
        subject: data,
        reset: false
      });
    }
  }

  handleSourceChange(data) {
    if (data === "Nothing selected") {
      this.setState({
        source: null,
        reset: false
      });
    }
    else {
      this.setState({
        source: data,
        reset: false
      });
    }
  }

  handleAuthorChange(data) {
    if (data === "Nothing selected") {
      this.setState({
        author: null,
        reset: false
      });
    }
    else {
      this.setState({
        author: data,
        reset: false
      });
    }
  }

  handlePrivilegeChange = (data) => {
    this.setState({
      privilege: data,
      reset: false
    });

  }

  handleChapterChange = (data) => {
    if (data === "Nothing selected") {
      this.setState({
        selectedChapter: null,
        reset: false
      });
    }
    else {
      this.setState({
        selectedChapter: data,
        reset: false
      });
    }
  }

  handleGradeChange = (data) => {
    if (data === "Nothing selected") {
      this.setState({
        selectedGrade: null,
        reset: false
      });
    }
    else {
      this.setState({
        selectedGrade: data,
        reset: false
      });
    }
  }

  handleYearChange(data) {
    if (data === "Nothing selected") {
      this.setState({
        year: null,
        reset: false
      });
    }
    else {
      this.setState({
        year: data,
        reset: false
      });
    }
  }

  handleTermChange(data) {
    if (data === "Nothing selected") {
      this.setState({
        term: null,
        reset: false
      });
    }
    else {
      this.setState({
        term: data,
        reset: false
      });
    }
  }

  handleTagsChange(e) {
    this.setState({
      tags: e.target.value,
      reset: false
    });
  }

  handleCategoryChange(data) {
    this.setState({
      category: data,
      reset: false
    })

  }

  handleDifficultyChange(data) {
    this.setState({
      difficulty: data,
      reset: false
    })

  }

  handleLanguageChange(data) {
    if (data === "Nothing selected") {
      this.setState({
        language: null,
        reset: false
      });
    }
    else {
      this.setState({
        language: data,
        reset: false
      });
    }

  }

  onContentEditorChange(editorState) {
    this.setState({
      editorQuestionState: editorState,
      reset: false
    })
  }

  onGuidanceEditorChange(guidanceEditorState) {
    this.setState({
      editorGuidanceState: guidanceEditorState,
      reset: false
    })
  }

  addOption() {
    let choiceAnswers = [...this.state.selectedChoices]

    let pre = [...this.state.editorAnswerStates]
    pre.push(EditorState.createEmpty())
    choiceAnswers.push(false);

    this.setState({
      editorAnswerStates: pre,
      selectedChoices: choiceAnswers
    })
  }

  removeOption() {
    let pre = [...this.state.editorAnswerStates]
    let choiceAnswers = [...this.state.selectedChoices]
    pre.pop()
    choiceAnswers.pop()
    this.setState({
      editorAnswerStates: pre,
      selectedChoices: choiceAnswers
    })
  }

  optionOnAnimationEnd(e) {
    const event = e.target

    function removeClass(event) {
      if (event) event.classList.remove('new-item');
      if (window.KTSummernoteDemo) window.KTSummernoteDemo.init();
    }

    setTimeout(() => removeClass(event), 500)
  }


  getValue(el) {
    if (el === questionTypeConstants.MCQ.id) {
      return questionTypeConstants.MCQ.name
    } else if (el === questionTypeConstants.DESCRIPTIVE.id) {
      return questionTypeConstants.DESCRIPTIVE.name
    } else if (el === questionTypeConstants.SUBMISSION.id) {
      return questionTypeConstants.SUBMISSION.name
    } else if (el === questionTypeConstants.SUBQUESTION.id) {
      return questionTypeConstants.SUBQUESTION.name
    }
  }

  onChoiceEdit(e, i) {
    let selectedChoices = this.state.selectedChoices;
    selectedChoices[i] = parseInt(e.target.value)

    this.setState({
      selectedChoices: selectedChoices
    });

  }

  // onChoiceChecked(e, i) {
  //     let selectedChoices = this.state.selectedChoices;
  //     selectedChoices[i] = !this.state.selectedChoices[i]

  //     this.setState({
  //         selectedChoices: selectedChoices
  //     });
  // }

  onChoicesEditorChange(editorState, value) {
    let newEditorStates = this.state.editorAnswerStates;

    let typeName = this.props.question.qtype;

    if (typeName === 1) {
      newEditorStates[value] = editorState
    } else if (typeName === 2 || typeName === 5) {
      newEditorStates = editorState
    }

    this.setState({
      editorAnswerStates: newEditorStates
    });
  }

  validate() {
    const FORM = document.getElementById("edit-questions")
    const FORM_VALIDATION = window.FormValidation;
    const submitButton = document.querySelector('[cardLabel="Edit question"], [type="submit"]');
    let form = FORM_VALIDATION.formValidation(FORM, {
      fields: {
        title: {
          validators: {
            // notEmpty: {
            //     // message: 'Title is required'
            // }
          }
        }
      },
      plugins: {
        trigger: new FORM_VALIDATION.plugins.Trigger,
        bootstrap: new FORM_VALIDATION.plugins.Bootstrap({
          eleValidClass: ""
        }),
        fieldStatus: new FORM_VALIDATION.plugins.FieldStatus({
          onStatusChanged: function (areFieldsValid) {
            areFieldsValid
              ? submitButton.removeAttribute('disabled')
              : submitButton.setAttribute('disabled', 'disabled');
          }
        }),
      }
    })
    return form.validate()
  }

  onCancelQuestion(e) {
    window.removeEventListener("beforeunload", this.onUnload);
    e.preventDefault();
    window.Swal.fire({
      title: 'Do you want to discard and close?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: addBtnColor,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        history.push(routes.questions_library);
        //window.location.reload();
      }
    })
  }

  async onUpdateQuestion() {
    window.removeEventListener("beforeunload", this.onUnload);
    let question_id = this.props.question.question_id;
    let title = this.state.title;
    let subject_id = this.state.subject;
    let author_id = this.state.author;
    let source_id = this.state.source;
    let year_id = this.state.year;
    let term_id = this.state.term;
    let tags = this.state.tags;
    let qtype = this.props.question.qtype;
    let chapter_id = this.state.selectedChapter;
    let grade_id = this.state.selectedGrade;
    // let guidence = this.state.guidance;
    let category_id = this.state.category;
    let question_key = this.props.question.content;
    let created_by = this.props.question.created_by;
    let answer_key = this.props.question.answer;
    let difficulty_id = this.state.difficulty;
    let privilege_id = this.state.privilege;
    let language_id = this.state.language;
    let is_subquestion = this.state.isSubQuestion
    let question_content = [];
    let guidence = [];
    question_content.push(convertToRaw(this.state.editorQuestionState.getCurrentContent()));
    guidence.push(convertToRaw(this.state.editorGuidanceState.getCurrentContent()));
    window.removeEventListener("beforeunload", this.onUnload);
    var qblock = Object.keys(question_content[0].blocks).length;
    var err = 0;

    for (var i = 0; i < qblock; i++) {
      if (!question_content[0].blocks[i].text.trim() && !question_content[0].entityMap[i]) {
        err = err + 1;
      }
    }

    if (!title.trim()) {
      this.validate();
      window.Swal.fire({
        title: 'Failed!',
        text: namespace.QUESTION_OR_TITLE_NOT_GIVEN,
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    } else if (err == qblock) {
      window.Swal.fire({
        title: 'Failed!',
        text: namespace.QUESTION_OR_TITLE_NOT_GIVEN,
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    } else if (question_content[0].blocks[0].text == " " && question_content[0].blocks[1].text == "" && question_content[0].entityMap[0]) {
      window.Swal.fire({
        title: 'Failed!',
        text: namespace.QUESTION_OR_TITLE_NOT_GIVEN,
        icon: 'error',
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
    else {
      if (this.props.question.qtype === questionTypeConstants.MCQ.id) {
        for (let answerState of this.state.editorAnswerStates) {
          question_content.push(convertToRaw(answerState.getCurrentContent()))
        }
        var err = 0;
        for (var i = 1; i < Object.keys(question_content).length; i++) {
          var mcqblock = Object.keys(question_content[i].blocks).length;
          var errmcq = 0;
          for (var x = 0; x < mcqblock; x++) {
            if (!question_content[i].blocks[x].text.trim() && !question_content[i].entityMap[x]) {
              errmcq = errmcq + 1;
            }
          }

          if (errmcq == mcqblock) {
            err = err + 1;
          }
        }
        var value = this.state.selectedChoices.reduce(function (r, a) {
          return a > 0 ? r + a : r;
        }, 0);
        if (this.state.selectedChoices.length == 0) {
          window.Swal.fire({
            title: 'Failed!',
            text: 'Please select at least one option',
            icon: 'error'
          });
        } else if (err != 0) {
          window.Swal.fire({
            title: 'Failed!',
            text: namespace.ANSWER_NOT_COMPLETED,
            icon: 'error',
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
          });
        }
        else if (value !== 100) {
          window.Swal.fire({
            title: 'Failed!',
            text: 'Mark allocations should sum up to 100%',
            icon: 'error',
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
          });
        }
        else {
          const answer = this.state.selectedChoices.toString();
          await this.props.updateQuestion({
            question_id,
            title,
            subject_id,
            source_id,
            author_id,
            year_id,
            term_id,
            tags,
            qtype,
            category_id,
            chapter_id,
            grade_id,
            guidence,
            question_key,
            question_content,
            created_by,
            answer,
            difficulty_id,
            privilege_id,
            language_id
          })
          this.setState({ updateState: true })
        }


      } else if (this.props.question.qtype === questionTypeConstants.DESCRIPTIVE.id) {
        let answer_content = [];
        answer_content.push(convertToRaw(this.state.editorAnswerStates.getCurrentContent()));

        var ansblock = Object.keys(answer_content[0].blocks).length;
        var errans = 0;
        for (var x = 0; x < ansblock; x++) {
          if (!answer_content[0].blocks[x].text.trim() && !answer_content[0].entityMap[x]) {
            errans = errans + 1;
          }
        }
        if (ansblock == errans) {
          window.Swal.fire({
            title: 'Failed!',
            text: namespace.ANSWER_NOT_GIVEN,
            icon: 'error',
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
            }
          });
        } else if (answer_content[0].blocks[0].text == " " && answer_content[0].blocks[1].text == "" && answer_content[0].entityMap[0]) {
          window.Swal.fire({
            title: 'Failed!',
            text: namespace.ANSWER_NOT_GIVEN,
            icon: 'error',
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
          });
        }
        else {
          await this.props.updateQuestion({
            question_id,
            title,
            subject_id,
            source_id,
            author_id,
            year_id,
            term_id,
            tags,
            qtype,
            category_id,
            chapter_id,
            grade_id,
            guidence,
            question_key,
            question_content,
            created_by,
            answer_key,
            answer_content,
            difficulty_id,
            privilege_id,
            language_id
          });
          this.setState({ updateState: true })
        }

      } else if (this.props.question.qtype === questionTypeConstants.SUBQUESTION.id) {
        let answer_content = [];
        answer_content.push(convertToRaw(this.state.editorAnswerStates.getCurrentContent()));

        var ansblock = Object.keys(answer_content[0].blocks).length;
        var errans = 0;
        for (var x = 0; x < ansblock; x++) {
          if (!answer_content[0].blocks[x].text.trim() && !answer_content[0].entityMap[x]) {
            errans = errans + 1;
          }
        }
        if (ansblock == errans && is_subquestion) {
          window.Swal.fire({
            title: 'Failed!',
            text: namespace.ANSWER_NOT_GIVEN,
            icon: 'error',
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
          });
        } else if (answer_content[0].blocks[0].text == " " && answer_content[0].blocks[1].text == "" && answer_content[0].entityMap[0] && is_subquestion) {
          window.Swal.fire({
            title: 'Failed!',
            text: namespace.ANSWER_NOT_GIVEN,
            icon: 'error',
            customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                  },
                  onOpen: (modalElement) => {
                      if (customer === customerConstants.EXO_CUSTOMER2) {
                          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                      }
                  }
          });
        } else {
          await this.props.updateQuestion({
            question_id,
            title,
            subject_id,
            source_id,
            author_id,
            year_id,
            term_id,
            tags,
            qtype,
            category_id,
            chapter_id,
            grade_id,
            guidence,
            question_key,
            answer_content,
            is_subquestion,
            question_content,
            created_by,
            answer_key,
            difficulty_id,
            privilege_id,
            language_id
          });
          this.setState({ updateState: true })
        }


      }
      else if (this.props.question.qtype === questionTypeConstants.SUBMISSION.id) {
        if (this.state.deletedFiles.length > 0) {
          const user_id = DataHandler.getFromSession("user_id")
          //const containerName = "".concat(user_id, "-sa-", this.props.question.answer)
          const containerName= config.storageBlobConfigs.containerName;
          for (var i = 0; i < this.state.deletedFiles.length; i++) {
            AzureStorage.deleteblob(containerName, this.state.deletedFiles[i].replace(/['"]+/g, ''))
          }
        }
        if (this.state.editAttachment) {
          try {
            // let answer_content = await this.uploadAttachment(this.state.editAttachment);
            let answer_content = this.state.answer_content;
            if (answer_content == '') {
              answer_content = await this.uploadAttachment(this.state.editAttachment);
            }
            await this.props.updateQuestion({
              question_id,
              title,
              subject_id,
              source_id,
              author_id,
              year_id,
              term_id,
              tags,
              qtype,
              category_id,
              question_key,
              question_content,
              chapter_id,
              grade_id,
              guidence,
              created_by,
              answer_key,
              answer_content,
              difficulty_id,
              privilege_id,
              language_id
            });
            this.setState({ updateState: true })


            this.setState({
              editAttachment: answer_content
            });
          } catch (e) {

            throw { 'error': 'Saving answer attachment failed' };
          }
        }
      }
    }

  }

  async uploadAttachment(files) {
    this.setState({
      uploaded: false
    })
    let submissionName = []
    files.map((attach) => {
      if (attach.name) {
        const user_id = DataHandler.getFromSession("user_id")
        //const containerName = "".concat(user_id, "-sa-", this.props.question.answer)
        const containerName= config.storageBlobConfigs.containerName;

        let name = attach.name;
        submissionName.push(name)
        let reader = new FileReader();
        let text;
        var self = this;
        reader.onload = async function (e) {
          text = e.target.result;
          await AzureStorage.uploadBlob(containerName, name, text).then(() => {
            window.Swal.fire({
              title: 'Success!',
              text: namespace.FILE_UPLOAD_SUCCESS,
              icon: 'success',
              customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                      modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
              }
            })
            self.setState({
              uploaded: true
            })
            // })
          })
        }
        reader.readAsArrayBuffer(attach);
      } else {
        submissionName.push(attach.replace(/['"]+/g, ''))
      }
    })
    return submissionName

  }

  async getSubmissionContent(file) {
    this.setState({ reset: false })
    let files = []
    if (this.state.editAttachment != "") {
      files = this.state.editAttachment
    }
    else {
      this.setState({
        editAttachment: file.name.replace(/^\[(.+)\]$/, "$1").replace(/"\"$/, "$1").split(',')
      });
    }
    files.push(file)
    this.setState({
      editAttachment: files
    });
    this.setState({
      answer_content: await this.uploadAttachment(this.state.editAttachment)
    })

  }

  async deletSubmissionContent(file) {
    let files = [...this.state.editAttachment]
    let fileIndex = files.indexOf(file.name)
    files.splice(fileIndex, 1);
    let deletedFiles = [...this.state.deletedFiles]
    deletedFiles.push(file.name)
    this.setState({
      deletedFiles: deletedFiles
    })
    this.setState({
      editAttachment: files
    })
    if (this.state.answer_content != "") {
      this.state.answer_content.splice(fileIndex, 1)
    }
  }

  onResetQuestion() {
    let tempAnswerStates = []
    let tempAnswer = []
    let states = JSON.parse(this.props.questionContent)
    let guidanceStates = JSON.parse(this.props.guidanceContent)
    let tempQus = EditorState.createWithContent(convertFromRaw(states[0]))
    let tempGuidance = EditorState.createWithContent(convertFromRaw(guidanceStates[0]))
    this.setState({
      editorQuestionState: tempQus,
      editorGuidanceState: tempGuidance,
      title: this.props.question.title,
      subject: this.props.question.subject_id,
      source: this.props.question.source_id,
      author: this.props.question.author_id,
      year: this.props.question.year_id,
      term: this.props.question.term_id,
      tags: this.props.question.tags,
      category: this.props.question.category_id,
      difficulty: this.props.question.difficulty_id,
      language: this.props.question.language_id
    })
    this.validate()
    window.$("#kt_select2_11").select2('val', 'All');
    window.$("#kt_select2_13").select2('val', 'All');
    window.$("#kt_select2_15").select2('val', 'All');
    window.$("#kt_select2_17").select2('val', 'All');
    window.$("#kt_select2_19").select2('val', 'All');
    window.$("#kt_select2_21").select2('val', 'All');
    window.$("#kt_select2_23").select2('val', 'All');
    window.$("#kt_select2_25").select2('val', 'All');
    window.$("#kt_select2_27").select2('val', 'All');
    window.$("#kt_select2_28").select2('val', 'All');
    window.$("#kt_select2_29").select2('val', 'All');
    if (this.props.question.qtype == questionTypeConstants.MCQ.id) {
      states.shift();
      states.forEach(state => {
        tempAnswerStates.push(EditorState.createWithContent(convertFromRaw(state)))
      });

      this.props.question.answer.split(',').map((answer) => {
        tempAnswer.push(parseInt(answer));
      });
      this.setState({
        editorAnswerStates: tempAnswerStates,
        selectedChoices: tempAnswer
      })

    } else if (this.props.question.qtype == questionTypeConstants.DESCRIPTIVE.id) {
      let ans_states = JSON.parse(this.props.answerContent)
      let tempAns = EditorState.createWithContent(convertFromRaw(ans_states[0]))
      this.setState({ editorAnswerStates: tempAns })

    } else if (this.props.question.qtype == questionTypeConstants.SUBQUESTION.id) {
      let ans_states = JSON.parse(this.props.answerContent)
      let tempAns = EditorState.createWithContent(convertFromRaw(ans_states[0]))
      this.setState({ editorAnswerStates: tempAns })

    }
    else if (this.props.question.qtype === questionTypeConstants.SUBMISSION.id) {
      this.setState({ reset: true })
      this.setState({ editAttachment: this.props.answerContent.replace(/^\[(.+)\]$/, "$1").replace(/"\"$/, "$1").split(',') })
      if (this.state.editAttachment == "['[]']") {
        this.setState({
          editAttachment: ''
        })
      }
      this.setState({ deletedFiles: [] })
      this.setState({ answer_content: '' })

    }
  }

  getQuestionCategory(category_id) {
    let categoryname

    let questionCatgories = this.props.allQuestionMetaData.find(item => 'categories' in item).categories;
    questionCatgories.map(category => {

      if (category.category_id === category_id) {
        return categoryname = category.name
      }
    })
    return categoryname
  }

  getQuestionDifficulty(difficulty) {
    switch (difficulty) {
      case questionDifficultyConstants.EASY.id:
        return questionDifficultyConstants.EASY.name;
      case questionDifficultyConstants.MEDIUM.id:
        return questionDifficultyConstants.MEDIUM.name;
      case questionDifficultyConstants.HARD.id:
        return questionDifficultyConstants.HARD.name;
      default:
        return namespace.INVALID_TYPE;
    }
  }

  getLanguage(language_id) {
    let language = '';
    let questionLanguages = this.props.allQuestionMetaData.find(item => 'languages' in item).languages;
    questionLanguages.forEach(lang => {
      if (lang.language_id === language_id) {
        language = lang.language;
      }
    });
    return language;
  }

  getSource(source_id) {
    let source = '';
    let questionSources = this.props.allQuestionMetaData.find(item => 'sources' in item).sources;
    questionSources.forEach(src => {
      if (src.source_id === source_id) {
        source = src.source;
      }
    });
    return source;
  }

  getSubject(subject_id) {
    let subject = '';
    let questionSubjects = this.props.allQuestionMetaData.find(item => 'subjects' in item).subjects;
    questionSubjects.forEach(subj => {
      if (subj.subject_id === subject_id) {
        subject = subj.subject;
      }
    });
    return subject;
  }

  getAuthor(author_id) {
    let author = '';
    let questionAuthors = this.props.allQuestionMetaData.find(item => 'authors' in item).authors;
    questionAuthors.forEach(auth => {
      if (auth.author_id === author_id) {
        author = auth.author;
      }
    });
    return author;
  }

  getPrivilege(privilege_id) {
    let privilege = '';
    let questionPrivileges = this.props.allQuestionMetaData.find(item => 'privileges' in item).privileges;
    questionPrivileges.forEach(auth => {
      if (auth.privilege_id === privilege_id) {
        privilege = auth.privilege;
      }
    });
    return privilege;
  }

  getGrade(grade_id) {
    let grade = '';
    let questionGrades = this.props.allQuestionMetaData.find(item => 'grades' in item).grades;
    questionGrades.forEach(grade => {
      if (grade.grade_id === grade_id) {
        grade = grade.grade;
      }
    });
    return grade;
  }

  getChapter(chapter_id) {
    let chapter = '';
    let questionChapters = this.props.allQuestionMetaData.find(item => 'chapters' in item).chapters;
    questionChapters.forEach(auth => {
      if (auth.chapter_id === chapter_id) {
        chapter = auth.chapter;
      }
    });
    return chapter;
  }

  getYear(year_id) {
    let year = '';
    let questionYears = this.props.allQuestionMetaData.find(item => 'years' in item).years;
    questionYears.forEach(yr => {
      if (yr.year_id === year_id) {
        year = yr.year;
      }
    });
    return year;
  }

  getTerm(term_id) {
    let term = '';
    let questionTerms = this.props.allQuestionMetaData.find(item => 'terms' in item).terms;
    questionTerms.forEach(tm => {
      if (tm.term_id === term_id) {
        term = tm.term;
      }
    });
    return term;
  }

  render() {
    if (this.state.delete) {
      return <Redirect to={routes.questions_library} />;
    }
    if (this.state.refresh) {
      return <Redirect to={routes.questions_library} />;
    }

    // if (this.state.reset)
    // {this.setState({
    //     reset:false
    // })}

    return (
      <AdminTemplate>
        <ContentWrapper>
          {this.props.loadingupdate ||
            this.props.loading ||
            this.state.isDeleteLoading ||
            this.state.loadingContent ? (
            <Spinner id="show" text="Please wait.." />
          ) : (
            <div></div>
          )}
          {/* begin::Card */}
          <Card>
            <CardHeader>
              <CardTitle cardLabel="Edit Question">
                <Breadcrumb>
                  <BreadcrumbItem
                    to={routes.questions_library}
                    breadcrumbItemName="Questions Library"
                  />
                  <BreadcrumbItem
                    to={routes.edit_question}
                    active="active"
                    breadcrumbItemName=""
                  />
                </Breadcrumb>
              </CardTitle>
              <CardToolbar>
                <Button
                  className="btn btn-primary mr-2"
                  btnIconStyle="flaticon-interface-3"
                  btnName="Save"
                  title={!this.state.uploaded && "File upload Onprogress"}
                  type="button"
                  disabled={!this.state.uploaded}
                  onClick={this.onUpdateQuestion}
                />
                <Button
                  className="btn btn-primary mr-2"
                  btnIconStyle="flaticon2-refresh-1"
                  btnName="Reset"
                  onClick={this.onResetQuestion}
                />
                <Button
                  className="btn btn-primary mr-2"
                  btnName="Cancel"
                  onClick={this.onCancelQuestion}
                />
                {/* <Button
                className="btn btn-danger"
                btnIconStyle="flaticon-delete"
                btnName="Remove"
                onClick={this.onDeleteQuestion}
              /> */}
              </CardToolbar>
            </CardHeader>

            <CardBody>
              <Form id="edit-questions">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">

                    {/**Question Type**/}
                    <FormGroup
                      labelName="Type"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <Select
                        selectWrap="col-lg-9"
                        className="form-control selectpicker"
                        disabled="disabled"
                        dataLiveSearch="true"
                        dataSize="5"
                      >
                        <Option
                          optionName={this.getValue(
                            this.state.showSummerNoteOrFileUpload
                          )}
                          value={this.getValue(
                            this.state.showSummerNoteOrFileUpload
                          )}
                        />
                      </Select>
                    </FormGroup>

                    {/**Edit Question Category**/}
                    <FormGroup
                      labelName="Category"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {this.state.categoryOptions && (
                        <Select
                          selectId="kt_select2_11"
                          dataPlaceHolder={
                            this.getQuestionCategory(
                              this.props.question.category_id
                            ) || "Add Category"
                          }
                          id="question-category"
                          selectWrap="col-lg-9"
                          className="form-control selectpicker"
                          dataLiveSearch="true"
                          dataSize="5"
                          defaultValue={this.state.category}
                          value={this.state.category}
                          multiple={false}
                          spinner={
                            this.props.loadingcategory &&
                            this.state.categoryOptions.length <= 0
                          }
                          disabled={
                            this.props.loadingcategory &&
                            this.state.categoryOptions.length <= 0
                          }
                          children={this.state.categoryOptions}
                          onChange={this.handleCategoryChange}
                        />
                      )}
                    </FormGroup>

                    {/**Edit Question Difficulty**/}
                    <FormGroup
                      labelName="Difficulty"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {this.state.difficultyOptions && (
                        <Select
                          selectId="kt_select2_13"
                          dataPlaceHolder={
                            this.getQuestionDifficulty(
                              this.props.question.difficulty_id
                            ) || "Add Difficulty"
                          }
                          id="question-difficulty"
                          selectWrap="col-md-9"
                          className="form-control selectpicker"
                          dataLiveSearch="true"
                          dataSize="5"
                          defaultValue={this.state.difficulty}
                          value={this.state.difficulty}
                          multiple={false}
                          spinner={
                            this.props.loading &&
                            this.state.difficultyOptions.length <= 0
                          }
                          disabled={
                            this.props.loading &&
                            this.state.difficultyOptions.length <= 0
                          }
                          children={this.state.difficultyOptions}
                          onChange={this.handleDifficultyChange}
                        ></Select>
                      )}
                    </FormGroup>

                    {/**Edit Question Medium/Language**/}
                    <FormGroup
                      labelName="Medium/Language"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {this.state.languageOptions && (
                        <Select
                          selectId="kt_select2_15"
                          dataPlaceHolder={
                            this.getLanguage(
                              this.props.question.language_id
                            ) || "Nothing Selected"
                          }
                          id="question-language"
                          selectWrap="col-lg-9"
                          className="form-control selectpicker"
                          dataLiveSearch="true"
                          dataSize="5"
                          defaultValue={this.state.language}
                          value={this.state.language}
                          multiple={false}
                          spinner={
                            this.props.loading &&
                            this.state.languageOptions.length <= 0
                          }
                          disabled={
                            this.props.loading &&
                            this.state.languageOptions.length <= 0
                          }
                          children={this.state.languageOptions}
                          onChange={this.handleLanguageChange}
                        ></Select>
                      )}
                    </FormGroup>

                    {/**Edit Question Subject**/}
                    <FormGroup
                      labelName="Subject"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {this.state.subjectOptions && (
                        <Select
                          selectId="kt_select2_17"
                          dataPlaceHolder={
                            this.getSubject(this.props.question.subject_id) ||
                            "Nothing Selected"
                          }
                          id="question-subject"
                          selectWrap="col-lg-9"
                          className="form-control selectpicker"
                          dataLiveSearch="true"
                          dataSize="5"
                          defaultValue={this.state.subject}
                          value={this.state.subject}
                          multiple={false}
                          spinner={
                            this.props.loading &&
                            this.state.subjectOptions.length <= 0
                          }
                          disabled={
                            this.props.loading &&
                            this.state.subjectOptions.length <= 0
                          }
                          children={this.state.subjectOptions}
                          onChange={this.handleSubjectChange}
                        ></Select>
                      )}
                    </FormGroup>

                    {/**Edit Question Source**/}
                    <FormGroup
                      labelName="Source"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {this.state.sourceOptions && (
                        <Select
                          selectId="kt_select2_19"
                          dataPlaceHolder={
                            this.getSource(this.props.question.source_id) ||
                            "Nothing Selected"
                          }
                          id="question-source"
                          selectWrap="col-lg-9"
                          className="form-control selectpicker"
                          dataLiveSearch="true"
                          dataSize="5"
                          defaultValue={this.state.source}
                          value={this.state.source}
                          multiple={false}
                          spinner={
                            this.props.loading &&
                            this.state.sourceOptions.length <= 0
                          }
                          disabled={
                            this.props.loading &&
                            this.state.sourceOptions.length <= 0
                          }
                          children={this.state.sourceOptions}
                          onChange={this.handleSourceChange}
                        ></Select>
                      )}
                    </FormGroup>

                    {/**Edit Question Author**/}
                    <FormGroup
                      labelName="Author"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {this.state.authorOptions && (
                        <Select
                          selectId="kt_select2_21"
                          dataPlaceHolder={
                            this.getAuthor(this.props.question.author_id) ||
                            "Nothing Selected"
                          }
                          id="question-author"
                          selectWrap="col-lg-9"
                          className="form-control selectpicker"
                          dataLiveSearch="true"
                          dataSize="5"
                          defaultValue={this.state.author}
                          value={this.state.author}
                          multiple={false}
                          spinner={
                            this.props.loading &&
                            this.state.authorOptions.length <= 0
                          }
                          disabled={
                            this.props.loading &&
                            this.state.authorOptions.length <= 0
                          }
                          children={this.state.authorOptions}
                          onChange={this.handleAuthorChange}
                        ></Select>
                      )}
                    </FormGroup>

                    {/* <FormGroup
                    labelName="Privilege"
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  >
                    {this.state.privilegeOptions && (
                      <Select
                        selectId="kt_select2_29"
                        dataPlaceHolder={
                          this.getPrivilege(this.props.question.privilege_id) ||
                          "Nothing Selected"
                        }
                        id="question-privilege"
                        selectWrap="col-lg-9"
                        className="form-control selectpicker"
                        dataLiveSearch="true"
                        dataSize="5"
                        defaultValue={this.state.privilege}
                        value={this.state.privilege}
                        multiple={false}
                        spinner={
                          this.props.loading &&
                          this.state.privilegeOptions.length <= 0
                        }
                        disabled={
                          this.props.loading &&
                          this.state.privilegeOptions.length <= 0
                        }
                        children={this.state.privilegeOptions}
                        onChange={this.handlePrivilegeChange}
                      ></Select>
                    )}
                  </FormGroup> */}
                    {/**Edit Question Grade**/}
                    <FormGroup
                      labelName="Grade"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {this.state.allGrades && (
                        <Select
                          selectWrap="col-lg-9"
                          dataPlaceHolder={
                            this.getGrade(parseInt(this.props.question.grade_id)) ||
                            "Nothing Selected"
                          }
                          selectId="kt_select2_27"
                          id="question-grade"
                          className="form-control selectpicker"
                          dataLiveSearch="true"
                          dataSize="5"
                          value={this.state.selectedGrade}
                          defaultValue={this.state.selectedGrade}
                          multiple={false}
                          spinner={
                            this.props.loading &&
                            this.state.allGrades.length <= 0
                          }
                          disabled={
                            this.props.loading &&
                            this.state.allGrades.length <= 0
                          }
                          children={this.state.allGrades}
                          onChange={this.handleGradeChange}
                        ></Select>
                      )}
                    </FormGroup>

                    {/**Edit Question Chapter/Module/Unit**/}
                    <FormGroup
                      labelName="Chapter/Module/Unit"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {this.state.allChapters && (
                        <Select
                          selectWrap="col-lg-9"
                          selectId="kt_select2_28"
                          dataPlaceHolder={
                            this.getChapter(this.props.question.chapter_id) ||
                            "Nothing Selected"
                          }
                          id="question-chapter"
                          className="form-control selectpicker"
                          dataLiveSearch="true"
                          dataSize="5"
                          value={this.state.selectedChapter}
                          defaultValue={this.state.selectedChapter}
                          multiple={false}
                          spinner={
                            this.props.loading &&
                            this.state.allChapters.length <= 0
                          }
                          disabled={
                            this.props.loading &&
                            this.state.allChapters.length <= 0
                          }
                          children={this.state.allChapters}
                          onChange={this.handleChapterChange}
                        ></Select>
                      )}
                    </FormGroup>

                    {/**Edit Question Year**/}
                    <FormGroup
                      labelName="Year"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {this.state.yearOptions && (
                        <Select
                          selectId="kt_select2_23"
                          dataPlaceHolder={
                            this.getYear(this.props.question.year_id) ||
                            "Nothing Selected"
                          }
                          id="question-year"
                          selectWrap="col-lg-9"
                          className="form-control selectpicker"
                          dataLiveSearch="true"
                          dataSize="5"
                          defaultValue={this.state.year}
                          value={this.state.year}
                          multiple={false}
                          spinner={
                            this.props.loading &&
                            this.state.yearOptions.length <= 0
                          }
                          disabled={
                            this.props.loading &&
                            this.state.yearOptions.length <= 0
                          }
                          children={this.state.yearOptions}
                          onChange={this.handleYearChange}
                        ></Select>
                      )}
                    </FormGroup>

                    {/**Edit Question Term/Semester**/}
                    <FormGroup
                      labelName="Term/Semester"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {this.state.termOptions && (
                        <Select
                          selectId="kt_select2_25"
                          dataPlaceHolder={
                            this.getTerm(this.props.question.term_id) ||
                            "Nothing Selected"
                          }
                          id="question-term"
                          selectWrap="col-lg-9"
                          className="form-control selectpicker"
                          dataLiveSearch="true"
                          dataSize="5"
                          defaultValue={this.state.term}
                          value={this.state.term}
                          multiple={false}
                          spinner={
                            this.props.loading &&
                            this.state.termOptions.length <= 0
                          }
                          disabled={
                            this.props.loading &&
                            this.state.termOptions.length <= 0
                          }
                          children={this.state.termOptions}
                          onChange={this.handleTermChange}
                        ></Select>
                      )}
                    </FormGroup>

                    {/**Edit Question Tags**/}
                    <FormGroup
                      labelName="Tags"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <Input
                        type="text"
                        inputWrap="col-lg-9"
                        className="form-control"
                        id="id-tags"
                        name="tags"
                        value={this.state.tags}
                        onChange={this.handleTagsChange}
                        maxLength={120}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-6 col-md-6 col-12 question-right-container">
                    {/**Edit Question Title**/}
                    <FormGroup
                      labelName="Title"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <Input
                        type="text"
                        inputWrap="col-lg-9"
                        className="form-control"
                        id="id-title"
                        name="title"
                        value={this.state.title}
                        onChange={this.handleTitleChange}
                        maxLength={120}
                      />
                    </FormGroup>

                    {/**Edit Question Guidance**/}
                    {this.props.guidanceContent && (
                      <FormGroup
                        labelName="Marking Guidance"
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      >
                        {/* <Input
                          type="text"
                          inputWrap="col-lg-9"
                          className="form-control"
                          id="id-guidance"
                          name="guidance"
                          value={this.state.guidance}
                          onChange={this.handleGuidanceChange}
                        /> */}
                        <div className="col-lg-12">
                          <div className="RichEditor-root-createquestion">
                            <GuidanceEditor
                              editorState={this.state.editorGuidanceState}
                              onEditorStateChange={this.onGuidanceEditorChange}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    )}

                    {/**Edit Question Content**/}
                    {this.props.questionContent && (
                      <FormGroup
                        labelName="Question"
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      >
                        <div className="col-lg-12">
                          <div className="RichEditor-root-createquestion">
                            <CustomEditor
                              editorState={this.state.editorQuestionState}
                              onEditorStateChange={this.onContentEditorChange}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    )}

                    {/**Edit Question Answer**/}
                    {this.props.answerContent &&
                      this.state.showSummerNoteOrFileUpload ==
                      questionTypeConstants.DESCRIPTIVE.name ? (
                      <>
                        <div id="descriptive" className="colors">
                          <FormGroup
                            labelName="Model answer"
                            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                          >
                            <div className="col-lg-12">
                              <div className="RichEditor-root-createquestion">
                                <CustomEditor
                                  editorState={this.state.editorAnswerStates}
                                  onEditorStateChange={(e) =>
                                    this.onChoicesEditorChange(e, 0)
                                  }
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      </>
                    ) : (
                      <div></div>
                    )}
                    {this.props.answerContent &&
                      this.state.showSummerNoteOrFileUpload ==
                      questionTypeConstants.SUBQUESTION.name && this.state.isSubQuestion ? (
                      <>
                        <div id="descriptive" className="colors">
                          <FormGroup
                            labelName="Model answer"
                            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                          >
                            <div className="col-lg-12">
                              <div className="RichEditor-root-createquestion">
                                <CustomEditor
                                  editorState={this.state.editorAnswerStates}
                                  onEditorStateChange={(e) =>
                                    this.onChoicesEditorChange(e, 0)
                                  }
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      </>
                    ) : (
                      <div></div>
                    )}
                    {this.state.showSummerNoteOrFileUpload ==
                      questionTypeConstants.MCQ.name ? (
                      <>
                        <div id="mcq" className="colors">
                          <div className="row">
                            <h4 className="text-dark font-weight-bold mt-5 mb-5 col-lg-12">
                              Enter the options
                              <span className="form-text text-muted font-size-sm">
                                Enter the percentage of marks to be given when
                                the choice is selected
                              </span>
                            </h4>
                          </div>

                          <div className="option-wrap">
                            <ul className="p-0 row">
                              {this.state.editorAnswerStates.length > 0 &&
                                this.state.editorAnswerStates.map(
                                  (editorState, i) => (
                                    <li
                                      className="new-item new-item-custom col-md-12"
                                      onAnimationEnd={
                                        this.optionOnAnimationEnd
                                      }
                                    >
                                      <FormGroup
                                        key={i}
                                        labelName="Choice"
                                        className="col-lg-12"
                                      >
                                        <div className="col-lg-12">
                                          <Input
                                            type="number"
                                            className="col-md-4 Choice-mark"
                                            name="marks"
                                            value={
                                              this.state.selectedChoices[i]
                                            }
                                            // onChange={this.onChoiceEdit}
                                            onChange={(e) =>
                                              this.onChoiceEdit(e, i)
                                            }
                                          />
                                          <div className="RichEditor-root-createquestion">
                                            <CustomEditor
                                              editorState={
                                                this.state.editorAnswerStates[
                                                i
                                                ]
                                              }
                                              onEditorStateChange={(e) =>
                                                this.onChoicesEditorChange(
                                                  e,
                                                  i
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </FormGroup>
                                    </li>
                                  )
                                )}
                            </ul>
                            <Button
                              type="button"
                              onClick={this.addOption}
                              className="btn btn-primary btn-sm font-weight-bold mr-2 add"
                              btnName="Add options"
                              btnIconStyle="flaticon2-plus icon-sm mr-2"
                            />
                            <Button
                              type="button"
                              onClick={this.removeOption}
                              className="btn btn-danger btn-sm font-weight-bold mr-2 remove"
                              btnName="Remove options"
                              btnIconStyle="flaticon2-plus icon-sm mr-2"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div></div>
                    )}
                    {this.state.showSummerNoteOrFileUpload ===
                      questionTypeConstants.SUBMISSION.name &&
                      this.state.editAttachment &&
                      !this.state.reset &&
                      this.props.answerContent != "[]" &&
                      this.props.answerContent != '["[]"]' && (
                        <div id="file-upload" className="colors">
                          <FormGroup
                            labelName="Model answer"
                            className="col-lg-12"
                          >
                            <FileUpload
                              className="col-lg-12"
                              title={this.state.editAttachment}
                              defaultFile={this.state.editAttachment}
                              callBack={this.getSubmissionContent}
                              deleteback={this.deletSubmissionContent}
                            />
                          </FormGroup>
                        </div>
                      )}
                    {this.state.showSummerNoteOrFileUpload ===
                      questionTypeConstants.SUBMISSION.name &&
                      this.state.editAttachment &&
                      this.state.reset &&
                      this.props.answerContent != "[]" &&
                      this.props.answerContent != '["[]"]' && (
                        <div id="file-upload" className="colors">
                          <FormGroup
                            labelName="Model answer"
                            className="col-lg-12"
                          >
                            <FileUpload
                              className="col-lg-12"
                              title={this.state.editAttachment}
                              defaultFile={this.state.editAttachment}
                              callBack={this.getSubmissionContent}
                              deleteback={this.deletSubmissionContent}
                            />
                          </FormGroup>
                        </div>
                      )}
                    {this.state.showSummerNoteOrFileUpload ===
                      questionTypeConstants.SUBMISSION.name &&
                      this.props.answerContent == "[]" &&
                      !this.state.reset && (
                        <div id="file-upload" className="colors">
                          <FormGroup
                            labelName="Model answer"
                            className="col-lg-12"
                          >
                            <FileUpload
                              className="col-lg-12"
                              title={this.props.editAttachment}
                              defaultFile={this.state.editAttachment}
                              callBack={this.getSubmissionContent}
                              deleteback={this.deletSubmissionContent}
                            />
                          </FormGroup>
                        </div>
                      )}
                    {this.state.showSummerNoteOrFileUpload ===
                      questionTypeConstants.SUBMISSION.name &&
                      this.props.answerContent == "[]" &&
                      this.state.reset && (
                        <div id="file-upload" className="colors">
                          <FormGroup
                            labelName="Model answer"
                            className="col-lg-12"
                          >
                            <FileUpload
                              className="col-lg-12"
                              title={this.props.editAttachment}
                              defaultFile={this.state.editAttachment}
                              callBack={this.getSubmissionContent}
                              deleteback={this.deletSubmissionContent}
                            />
                          </FormGroup>
                        </div>
                      )}
                    {this.state.showSummerNoteOrFileUpload ===
                      questionTypeConstants.SUBMISSION.name &&
                      this.props.answerContent == '["[]"]' &&
                      !this.state.reset && (
                        <div id="file-upload" className="colors">
                          <FormGroup
                            labelName="Model answer"
                            className="col-lg-12"
                          >
                            <FileUpload
                              className="col-lg-12"
                              title={this.props.editAttachment}
                              defaultFile={this.state.editAttachment}
                              callBack={this.getSubmissionContent}
                              deleteback={this.deletSubmissionContent}
                            />
                          </FormGroup>
                        </div>
                      )}
                    {this.state.showSummerNoteOrFileUpload ===
                      questionTypeConstants.SUBMISSION.name &&
                      this.props.answerContent == '["[]"]' &&
                      this.state.reset && (
                        <div id="file-upload" className="colors">
                          <FormGroup
                            labelName="Model answer"
                            className="col-lg-12"
                          >
                            <FileUpload
                              className="col-lg-12"
                              title={this.props.editAttachment}
                              defaultFile={this.state.editAttachment}
                              callBack={this.getSubmissionContent}
                              deleteback={this.deletSubmissionContent}
                            />
                          </FormGroup>
                        </div>
                      )}
                  </div>
                </div>
              </Form>
            </CardBody>
          </Card>
          {/* end::Card */}
        </ContentWrapper>
      </AdminTemplate>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.questionsLibraryReducer.loading,
    error: state.questionsLibraryReducer.error,
    success: state.questionsLibraryReducer.success,

    loadingupdate: state.questionsLibraryReducer.loadingupdate,
    errorupdate: state.questionsLibraryReducer.errorupdate,
    successupdate: state.questionsLibraryReducer.successupdate,

    loadingdelete: state.questionsLibraryReducer.loadingdelete,
    errordelete: state.questionsLibraryReducer.errordelete,
    successdelete: state.questionsLibraryReducer.successdelete,

    loadingContent: state.questionsLibraryReducer.loading,
    questionContent: state.questionsLibraryReducer.questionContent,
    guidanceContent: state.questionsLibraryReducer.guidanceContent,
    answerContent: state.questionsLibraryReducer.answerContent,
    question: state.questionsLibraryReducer.question,

    assessmentSetQuestions: state.questionReducer.assessmentSetQuestions,
    allQuestionMetaData: state.questionsCreateReducer.allQuestionMetaData,
    loadingcategory: state.questionsCreateReducer.loading
  }
}

const mapActionsToProps = {
  getQuestionMetaData: createActions.getQuestionMetaData,
  refreshPage: libraryActions.refreshPage,
  getQuestionContent: libraryActions.getQuestionContent,
  getQuestionGuidanceContent: questionActions.getQuestionGuidanceContent,
  getAnswerContent: libraryActions.getAnswerContent,
  updateQuestion: libraryActions.updateQuestion,
  deleteQuestion: libraryActions.deleteQuestion,
  getSetQuestion: questionActions.getSetQuestion
}

export default connect(mapStateToProps, mapActionsToProps)(EditQuestion);






