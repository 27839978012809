import { libraryTypes } from '../../reducers/types';
import { libraryService } from '../../service/question/libraryService';

export const libraryActions = {
    refreshPage,
    getAllQuestions,
    getAllMyQuestions,
    getQuestionContent,
    getQuestionGuidanceContent,
    getAnswerContent,
    clearQuestionContent,
    updateQuestion,
    deleteQuestion,
    editQuestion,
    selectQuestion,
    getSharedQuestions,
    getAllSharedQuestions,
    deleteCategory,
    updateCategory,
    getAllPublicQuestions
};


function getAllQuestions() {

    return dispatch => {
        dispatch(clearExisting());
        dispatch(request());
        libraryService.getAllQuestions()
            .then(
                data => {
                    dispatch(success(data.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function clearExisting() {
        return { type: libraryTypes.GET_MY_QUESTIONS }
    }

    function request() {
        return { type: libraryTypes.GET_MY_QUESTIONS_REQUEST }
    }

    function success(data) {
        return { type: libraryTypes.GET_MY_QUESTIONS_SUCCESS, data }
    }

    function failure(error) {
        return { type: libraryTypes.GET_MY_QUESTIONS_FAILURE, error }
    }
}

function getAllMyQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources) {

    return async dispatch => {
        dispatch(clearExisting());
        dispatch(request());
        await libraryService.getAllMyQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectCategories, selectedAuthors, selectedChapters,
            selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources)
            .then(
                data => {
                    dispatch(success(data.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };


    function clearExisting() {
        return { type: libraryTypes.GET_ALL_MY_QUESTIONS }
    }

    function request() {
        return { type: libraryTypes.GET_ALL_MY_QUESTIONS_REQUEST }
    }

    function success(data) {
        return { type: libraryTypes.GET_ALL_MY_QUESTIONS_SUCCESS, data }
    }

    function failure(error) {
        return { type: libraryTypes.GET_ALL_MY_QUESTIONS_FAILURE, error }
    }
}

function getAllPublicQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources) {

    return async dispatch => {
        dispatch(clearExisting());
        dispatch(request());
        await libraryService.getAllPublicQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectCategories, selectedAuthors, selectedChapters,
            selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources)
            .then(
                data => {
                    dispatch(success(data.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };


    function clearExisting() {
        return { type: libraryTypes.GET_ALL_PUBLIC_QUESTIONS }
    }

    function request() {
        return { type: libraryTypes.GET_ALL_PUBLIC_QUESTIONS_REQUEST }
    }

    function success(data) {
        return { type: libraryTypes.GET_ALL_PUBLIC_QUESTIONS_SUCCESS, data }
    }

    function failure(error) {
        return { type: libraryTypes.GET_ALL_PUBLIC_QUESTIONS_FAILURE, error }
    }
}

function getSharedQuestions(obj) {

    return dispatch => {
        dispatch(clearExisting());
        dispatch(request());
        libraryService.getSharedQuestions()
            .then(
                data => {
                    dispatch(success(data.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function clearExisting() {
        return { type: libraryTypes.GET_QUESTIONS_SHARED_WITH_ME }
    }

    function request() {
        return { type: libraryTypes.GET_QUESTIONS_SHARED_WITH_ME_REQUEST }
    }

    function success(data) {
        return { type: libraryTypes.GET_QUESTIONS_SHARED_WITH_ME_SUCCESS, data }
    }

    function failure(error) {
        return { type: libraryTypes.GET_QUESTIONS_SHARED_WITH_ME_FAILURE, error }
    }
}

function getAllSharedQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources) {

    return async dispatch => {
        dispatch(clearExisting());
        dispatch(request());
        await libraryService.getAllSharedQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectCategories, selectedAuthors, selectedChapters,
            selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources)
            .then(
                data => {
                    dispatch(success(data.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function clearExisting() {
        return { type: libraryTypes.GET_ALL_QUESTIONS_SHARED_WITH_ME }
    }

    function request() {
        return { type: libraryTypes.GET_ALL_QUESTIONS_SHARED_WITH_ME_REQUEST }
    }

    function success(data) {
        return { type: libraryTypes.GET_ALL_QUESTIONS_SHARED_WITH_ME_SUCCESS, data }
    }

    function failure(error) {
        return { type: libraryTypes.GET_ALL_QUESTIONS_SHARED_WITH_ME_FAILURE, error }
    }
}

function getQuestionContent(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let content = await libraryService.getQuestionContent(obj);
            dispatch(success(content));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(question) {
        return { type: libraryTypes.GET_QUESTION_CONTENT_REQUEST, question }
    }

    function success(question) {
        return { type: libraryTypes.GET_QUESTION_CONTENT_SUCCESS, question }
    }

    function failure(error) {
        return { type: libraryTypes.GET_QUESTION_CONTENT_FAILURE, error }
    }
}

function getQuestionGuidanceContent(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let guidance = await libraryService.getQuestionGuidanceContent(obj);
            dispatch(success(guidance));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(guidance) {
        return { type: libraryTypes.GET_QUESTION_GUIDANCE_CONTENT_REQUEST, guidance }
    }

    function success(guidance) {
        return { type: libraryTypes.GET_QUESTION_GUIDANCE_CONTENT_SUCCESS, guidance }
    }

    function failure(error) {
        return { type: libraryTypes.GET_QUESTION_GUIDANCE_CONTENT_FAILURE, error }
    }
}

function getAnswerContent(obj) {
    return async dispatch => {
        dispatch(request(obj));
        try {
            let content = await libraryService.getAnswerContent(obj);
            dispatch(success(content));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return { type: libraryTypes.GET_ANSWER_CONTENT_REQUEST }
    }

    function success(content) {
        return { type: libraryTypes.GET_ANSWER_CONTENT_SUCCESS, content }
    }

    function failure(error) {
        return { type: libraryTypes.GET_ANSWER_CONTENT_FAILURE, error }
    }
}

function clearQuestionContent() {

    return dispatch => {
        dispatch(request());
    }

    function request() {
        return { type: libraryTypes.CLEAR_QUESTION_CONTENT }
    }
}

function updateQuestion(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let data = await libraryService.updateQuestion(obj);
            dispatch(success(data.data));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return { type: libraryTypes.UPDATE_QUESTION_REQUEST }
    }

    function success(question) {
        return { type: libraryTypes.UPDATE_QUESTION_SUCCESS, question }
    }

    function failure(error) {
        return { type: libraryTypes.UPDATE_QUESTION_FAILURE, error }
    }
}

function deleteQuestion(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let response = await libraryService.deleteQuestion(obj);
            dispatch(success(response.data));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(question) {
        return { type: libraryTypes.DELETE_QUESTION_REQUEST, question }
    }

    function success(success) {
        return { type: libraryTypes.DELETE_QUESTION_SUCCESS, success }
    }

    function failure(error) {
        return { type: libraryTypes.DELETE_QUESTION_FAILURE, error }
    }
}


function selectQuestion(obj) {

    return dispatch => {
        dispatch(select(obj))
    };

    function select(question) {
        return { type: libraryTypes.SELECT_QUESTION, question }
    }
}

function editQuestion(obj) {

    return dispatch => {
        dispatch(request(obj));
        libraryService.editQuestion(obj)
            .then(
                success => {
                    dispatch(success(success.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(question) {
        return { type: libraryTypes.DELETE_QUESTION_REQUEST, question }
    }

    function success(success) {
        return { type: libraryTypes.DELETE_QUESTION_SUCCESS, success }
    }

    function failure(error) {
        return { type: libraryTypes.DELETE_QUESTION_FAILURE, error }
    }
}

function deleteCategory(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let response = await libraryService.deleteCategory(obj);
            dispatch(success(response.data));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(category) {
        return { type: libraryTypes.DELETE_CATEGORY_REQUEST, category }
    }

    function success(success) {
        return { type: libraryTypes.DELETE_CATEGORY_SUCCESS, success }
    }

    function failure(error) {
        return { type: libraryTypes.DELETE_CATEGORY_FAILURE, error }
    }
}

function updateCategory(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let data = await libraryService.updateCategory(obj);
            dispatch(success(data.data));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return { type: libraryTypes.UPDATE_CATEGORY_REQUEST }
    }

    function success(category) {
        return { type: libraryTypes.UPDATE_CATEGORY_SUCCESS, category }
    }

    function failure(error) {
        return { type: libraryTypes.UPDATE_CATEGORY_FAILURE, error }
    }
}

function refreshPage() {

    return dispatch => {
        dispatch(clearExisting());
    };

    function clearExisting() {
        return { type: libraryTypes.LIBRARY_PAGE_REFRESH }
    }
}