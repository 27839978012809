import { assessmentTypes } from "../../helper/constants";


const initialState = {
  assessment: "",
  questions: [],
  assessmentQuestions: [],
  assessmentFilteredQuestions: [],
  totalAssessmentFilteredQuestionsCount: "",
  assessmentSetQuestions: [],
  question: "default",
  questionContent: "",
  questionContents: [],
  questionContentsByType:{},
  answerContents: [],
  selectedQuestions: [],
  loading: false,
  error: "",
  errorAddQuestions: "",
  errorGetQuestions: "",
  success: "",
  text: "",
  assessmentQuestionsByMe: [],
  allQuestionTypes: [],
  qtype: "",
  allDifficulty: [],
  difficulty: "",
  allCategories: [],
  category: "",
  totalAddQuestionsCount: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case assessmentTypes.ADD_QUESTIONS_PAGE_REFRESH:
      return {
        ...state,
        assessment: "",
        questions: [],
        question: "default",
        questionContent: "",
        selectedQuestions: [],
        assessmentQuestionsByMe: [],
        loading: false,
        errorAddQuestions: "",
        success: "",
        text: "",
      };
    case assessmentTypes.REMOVE_QUESTIONS_ADDED_BY_ME:
      return {
        ...state,
        text: "Removing appQuestions!",
        loading: false,
      };
    case assessmentTypes.REMOVE_QUESTIONS_ADDED_BY_ME_REQUEST:
      return {
        ...state,
        text: action.content,
        loading: true,
      };
    case assessmentTypes.REMOVE_QUESTIONS_ADDED_BY_ME_SUCCESS:
      return {
        ...state,
        loading: false,
        text: "Question/s were removed Successfully!",
        success: action.data.success,
      };
    case assessmentTypes.REMOVE_QUESTIONS_ADDED_BY_ME_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        success: "",
        questions: "",
      };
    case assessmentTypes.REMOVE_QUESTIONS_ADDED_BY_ME_REFRESH:
      return {
        ...state,
        selectedQuestions: [],
        question: "default",
        loading: false,
        error: "",
        success: "",
        text: "",
      };
    case assessmentTypes.ADD_QUESTIONS_ASSESSMENT_REFRESH:
      return {
        ...state,
        selectedQuestions: [],
        questionContent: "",
        question: "default",
        loading: false,
        error: "",
        errorAddQuestions: "",
        success: "",
        text: "",
      };
    case assessmentTypes.ADD_QUESTIONS_ASSESSMENT:
      return {
        ...state,
        text: "Adding questions!",
        errorAddQuestions: "",
        assessment: action.assessment,
        loading: false,
      };
    case assessmentTypes.ADD_QUESTIONS_ASSESSMENT_REQUEST:
      return {
        ...state,
        text: action.content,
        loading: true,
      };
    case assessmentTypes.ADD_QUESTIONS_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        text: "Question/s added Successfully!",
        success: action.data.success,
      };
    case assessmentTypes.ADD_QUESTIONS_ASSESSMENT_FAILURE:
      return {
        ...state,
        errorAddQuestions: action.error,
        loading: false,
        success: "",
        questions: "",
      };
    case assessmentTypes.GET_ALL_QUESTIONS:
      return {
        ...state,
        questions: [],
        question: "default",
        text: "Obtain questions!",
        loading: true,
      };
    case assessmentTypes.GET_ALL_QUESTIONS_REQUEST:
      return {
        ...state,
        text: action.id,
        loading: true,
      };
    case assessmentTypes.GET_ALL_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Obtained questions Successfully!",
        questions: action.data.questions,
      };
    case assessmentTypes.GET_ALL_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.GET_ALL_ADD_QUESTIONS:
      return {
        ...state,
        questions: [],
        question: "default",
        text: "Obtain questions!",
        loading: true,
      };
    case assessmentTypes.GET_ALL_ADD_QUESTIONS_REQUEST:
      return {
        ...state,
        text: action.id,
        loading: true,
      };
    case assessmentTypes.GET_ALL_ADD_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Obtained questions Successfully!",
        questions: action.data.questions,
        totalAddQuestionsCount: action.data.total_count
      };
    case assessmentTypes.GET_ALL_ADD_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.GET_QUESTION_CONTENT:
      return {
        ...state,
        question: "selected",
        text: "Obtain questions!",
        loading: true,
      };
    case assessmentTypes.GET_QUESTION_CONTENT_REQUEST:
      return {
        ...state,
        text: action.id,
        loading: true,
      };
    case assessmentTypes.GET_QUESTION_CONTENT_SUCCESS:
   
      return {
        ...state,
        loading: false,
        success: "Obtained questions Successfully!",
        questionContent: action.question,
      };
    case assessmentTypes.GET_QUESTION_CONTENT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.GET_ALL_QUESTION_CONTENTS:
      return {
        ...state,
        text: "Obtain questions!",
        questionContents: [],
        loading: true,
      };
    case assessmentTypes.GET_ALL_QUESTION_CONTENTS_REQUEST:
      return {
        ...state,
        text: action.id,
        loading: true,
      };
    case assessmentTypes.GET_ALL_QUESTION_CONTENTS_SUCCESS:
     
      return {
        ...state,
        loading: false,
        success: "Obtained questions Successfully!",
        questionContents: action.questions,
      };
    case assessmentTypes.GET_ALL_QUESTION_CONTENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.GET_ALL_ANSWER_CONTENTS:
      return {
        ...state,
        text: "Obtain questions!",
        answerContents: [],
        loading: true,
      };
    case assessmentTypes.GET_ALL_ANSWER_CONTENTS_REQUEST:
      return {
        ...state,
        text: action.id,
        loading: true,
      };
    case assessmentTypes.GET_ALL_ANSWER_CONTENTS_SUCCESS:
     
      return {
        ...state,
        loading: false,
        success: "Obtained answers Successfully!",
        answerContents: action.answers,
      };
    case assessmentTypes.GET_ALL_ANSWER_CONTENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.GET_ASSESSMENT_QUESTIONS:
      return {
        ...state,
        assessmentQuestions: [],
        questionContents: [],
        questionContentsbyType:{},
        answerContents: [],
        question: "default",
        questionContent: "",
        text: "Obtaining assessment questions!",
        loading: true,
        success: "",
        error: "",
      };
    case assessmentTypes.GET_ASSESSMENT_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case assessmentTypes.GET_ASSESSMENT_QUESTIONS_SUCCESS:
      var questions;
      
      try {
        questions = JSON.parse(action.data.examQuestions).questions;
      } catch (err) {
        questions = null;
      }
      return {
        ...state,
        loading: false,
        success: "Obtained assessment questions successfully!",
        assessmentQuestions: questions
      };
    case assessmentTypes.GET_ASSESSMENT_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.GET_ALL_ASSESSMENT_QUESTIONS:
      return {
        ...state,
        assessmentFilteredQuestions: [],
        totalAssessmentFilteredQuestionsCount: "",
        questionContents: [],
        questionContentsbyType:{},
        answerContents: [],
        question: "default",
        questionContent: "",
        text: "Obtaining assessment questions!",
        loading: true,
        success: "",
        error: "",
      };
    case assessmentTypes.GET_ALL_ASSESSMENT_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case assessmentTypes.GET_ALL_ASSESSMENT_QUESTIONS_SUCCESS:
      var questions;
      
      try {
        questions = JSON.parse(action.data.examQuestions).questions;
      } catch (err) {
        questions = null;
      }
      return {
        ...state,
        loading: false,
        success: "Obtained assessment questions successfully!",
        assessmentFilteredQuestions: questions,
        totalAssessmentFilteredQuestionsCount: action.data.totalCount
      };
    case assessmentTypes.GET_ALL_ASSESSMENT_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.GET_ALL_QUESTIONS_CONTENTS_BY_TYPE:
      return {
        ...state,
        text: "Obtain questions contents by type!",
        questionContentsByType: {},
        loading: true,
      };
    case assessmentTypes.GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_REQUEST:
      return {
        ...state,
        text: action.id,
        loading: true,
      };
    case assessmentTypes.GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_SUCCESS:
      return {

        ...state,
        loading: false,
        success: "Obtained questions Successfully!",
        questionContentsByType: action.data,
      };
    case assessmentTypes.GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.GET_ALL_ANSWER_CONTENTS:
      return {
        ...state,
        text: "Obtain questions!",
        answerContents: [],
        loading: true,
        };
    case assessmentTypes.GET_SET_QUESTION:
      return {
        ...state,
        assessmentQuestions: [],
        questionContents: [],
        answerContents: [],
        question: "default",
        questionContent: "",
        text: "Obtaining assessment questions!",
        loading: true,
        success: "",
        error: "",
      };
    case assessmentTypes.GET_SET_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case assessmentTypes.GET_SET_QUESTION_SUCCESS:
      var questions;
    
      try {
        questions = JSON.parse(action.data.setQuestion).questions;
      
      } catch (err) {
        questions = null;
      }
      return {
        ...state,
        loading: false,
        success: "Obtained questions added to assessment successfully!",
        assessmentSetQuestions: questions,
      };
    case assessmentTypes.GET_SET_QUESTION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME:
      return {
        ...state,
        assessmentQuestionsByMe: [],
        question: "default",
        questionContent: "",
        text: "Obtaining assessment questions added by me!",
        loading: true,
        success: "",
        error: "",
      };
    case assessmentTypes.GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case assessmentTypes.GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_SUCCESS:
      var questions;
      try {
        questions = JSON.parse(action.data.examQuestions).questions;
      } catch (err) {
        questions = null;
      }
      return {
        ...state,
        loading: false,
        success: "Obtained questions added by you successfully!",
        assessmentQuestionsByMe: questions,
      };
    case assessmentTypes.GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.SAVE_MARK_ALLOCATIONS_REQUEST:
      return {
        ...state,
        error: "",
        text: "Allocating marks!",
        loading: true,
      };
    case assessmentTypes.SAVE_MARK_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        success: action.data.success,
      };
    case assessmentTypes.SAVE_MARK_ALLOCATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.REORDER_QUESTIONS:
      return {
        ...state,
        assessmentQuestions: action.data.list,
        assessmentFilteredQuestions: action.data.list,
        questionContents: action.data.contents,
      };
    case assessmentTypes.FINALIZE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: "Assessment is ready to Launch!",
        assessment: action.assessment.data,
      };
    default:
      return state;
  }
}
