import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import logo from "../../../assets/images/logo.png"
import edsiderlogo from "../../../assets/images/edsiderlogo.png";
import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { routes } from "../../../helper/routes";

class Navigation extends Component {
  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role);
    this.props.history.push(routes.auth_response);
  };

  handleSignUp = () => {
    this.props.history.push(routes.parent_student_signup);
  };

  render() {
    return (
      <>
        <Navbar
          // fixed="top"
          collapseOnSelect
          expand="lg"
          className="d-flex justify-content-between navigation-bg"
        >
          <Navbar.Brand href="/">
            <img
              src={edsiderlogo}
              alt="logo"
              style={{ height: "auto", width: "200px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto navigation-links useful-links">
              <Nav.Link className="navig-links first-link-item" href="#home">
                Home
              </Nav.Link>
              <Nav.Link className="navig-links" href="#about">
                About Us
              </Nav.Link>
              <Nav.Link className="navig-links" href="#support">
                Support
              </Nav.Link>
              <Nav.Link className="navig-links" href="#edsider">
                Why EdSider
              </Nav.Link>
              {/* <Nav.Link className="navig-links" href="#papers">
                Model Papers
              </Nav.Link> */}
              {/* <Nav.Link className="navig-links" href="#scholarship">
                Try EdSider
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse>
            <Nav className="me-auto navigation-links action-links">
              <Nav.Link>
                <button type="button" onClick={() => this.handleRoleSelect("student")} className="signin-btn">
                  Sign In
                </button>
              </Nav.Link>
              {/* <Nav.Link>
                <button
                  type="button"
                  onClick={this.handleSignUp}
                  className="register-btn"
                >
                  Sign Up
                </button>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
export default withRouter(Navigation);
