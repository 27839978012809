import React from "react";
import { history } from "../../helper/history.js";
import { AzureStorage } from "../../helper/azureStorage.js";
import ContentWrapper from "../../components/common/ContentWrapper.js";
import classNames from "classnames";
import Card from "../../components/common/Card.js";
import CardHeader from "../../components/common/CardHeader.js";
import CardBody from "../../components/common/CardBody.js";
import CardToolbar from "../../components/common/CardToolbar.js";
import CardTitle from "../../components/common/QuestionCardTitle.js";
import CardFooter from "../../components/common/CardFooter.js";
import FileUpload from "../../components/common/form/FileUpload.js";
import SubmissionFileUpload from "../../components/common/form/SubmissionFileUpload.js";
import CheckBox from "../../components/common/form/CheckBox.js";
import CheckBoxInline from "../../components/common/form/CheckBoxInline.js";
import { examTimer } from "./ExamTimer.js";
import SubmitModal from "react-bootstrap/Modal";
import Modal from "../../components/common/modal/Modal.js";
import ModalBody from "../../components/common/modal/ModalBody.js";
import ModalFooter from "../../components/common/modal/ModalFooter.js";
import QuestionButton from "../../components/common/form/QuestionButton.js";
import { Button } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import ViewEditor from "../../components/common/ViewEditor.js";
import { connect } from "react-redux";
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  ContentState
} from "draft-js";
import {
  finishTest,
  getAllQuestionContents,
  getAllAnswerContents,
  getTestQuestionContent,
  getCandidateAnswers,
  getAllCandidateAnswerContents,
  loadQuestions,
  loadAssessmentQuestions,
  submitAnswers,
  getAutoShuffleFlag,
  onSetMarks,
  refreshAssessmentStartPage
} from "../../actions/candidate/testActions.js";
import {
  saveMarks,
  finalizeGrade
} from "../../actions/candidate/gradeActions.js";
import { questionTypeConstants } from "../../helper/constants.js";
import { namespace } from "../../helper/namespace.js";
import ExamviewButton from "../../components/common/form/ExamviewButton.js";
import Spinner from "../../components/Spinner3.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getReport } from '../../actions/candidate/examActions.js';
import "./Questions.css"
import PaperAutoGradeReport from "./PaperAutoGradeReport.js";
import PaperGradeReport from "./PaperGradeReport.js";
import config from '../../helper/config.js';
import { customerConstants } from "../../helper/constants.js";
import { store } from "../../helper/redux_store";
import { candidateService } from "../../service/settings/candidate/candidateService.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none"
    }
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
      boxShadow: "none"
    }
    break
  default:
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "Unset",
      fontFamily: "Poppins"
    }
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}


class PaperQuestions extends React.Component {
  intervalID;

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      answers: {},
      getContent: true,
      questions: "",
      assessmentQuestions: "",
      currentQuestion: 0,
      viewAllQuestions: false,
      ans: EditorState.createEmpty(),
      needToRevise: 0,
      answeredNum: 0,
      submissionState: false,
      loadSubmissions: true,
      finishState: false,
      timeUp: false,
      submit: false,
      setAnswers: false,
      fileUpload: false,
      filesUploaded: [],
      answeredQuestions: [],
      subQuestionsIndexes: [],
      subQuestions: [],
      revisedQuestions: [],
      isConnected: window.navigator.onLine,
      auto_shuffle: false,
      isAllMCQs: true,
      renderGradeReport: null,
      examViewLoading: true,
      submitLoading: false,
      isSubmitModalVisible: false,
      remarksEditorState: EditorState.createEmpty(),
      highlightedQuestion: null, 
      candidateGradeScales: []
    };

    this.onClickViewAllQuestions = this.onClickViewAllQuestions.bind(this);
    this.onSelectQus = this.onSelectQus.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.onClickPrevious = this.onClickPrevious.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.handleSubmitTest = this.handleSubmitTest.bind(this);
    this.makeEdit = this.makeEdit.bind(this);
    this.markAnswer = this.markAnswer.bind(this);
    this.getSubmissionContent = this.getSubmissionContent.bind(this);
    this.deleteSubmissionContent = this.deleteSubmissionContent.bind(this);
    this.autosaveTestState = this.autosaveTestState.bind(this);
    this.autoGradeMCQ = this.autoGradeMCQ.bind(this);

    this.questionRefs = [];
    this.containerRef = React.createRef();
  }

  // function to shuffle questions
  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  onUnload = (e) => {
    this.autosaveTestState(true)
    // the method that will be used for both add and remove event
    e.preventDefault();
    //this.saveTestState();
    e.returnValue = "";
  };

  OfflineFunction = (e) => {
    window.alert(" You're offline!");
    this.setState({
      isConnected: false,
    });
  };

  OnlineFunction = (e) => {
    window.alert("Back Online!");
    this.setState({
      isConnected: true,
    });
  };

  async componentDidMount() {
    if (window.KTApp) window.KTApp.initComponents();
    this.setState({ getContent: false });
    // according to autoshuffle shuffles questions
    await this.props.getAutoShuffleFlag().then(async () => {
      let subQuestionsObject = {};
      this.props.questions.forEach((item, index) => {
        if (item.is_subquestion === "true") {
          if (!subQuestionsObject[index]) {
            subQuestionsObject[index] = item;
          }
        }
      });
      let subQuestionsIndexes = this.props.questions.map((item, index) => item.is_subquestion === "true" ? index : null)
        .filter(index => index !== null);

      this.setState({
        subQuestions: subQuestionsObject,
        subQuestionsIndexes: subQuestionsIndexes,
      })
      if (this.props.auto_shuffle) {
        let shuffledQuestions = this.shuffleArray(this.props.questions);
        this.setState({
          questions: shuffledQuestions
        })
        await this.props.getAllQuestionContents({ questions: shuffledQuestions });
      }
      else {
        this.setState({
          questions: this.props.questions.filter(item => item.is_subquestion !== "true")
        })
        await this.props.getAllQuestionContents({ questions: this.props.questions });
      }
    });
    await this.props.loadAssessmentQuestions(this.props.assessment.assessment_id);
    this.setState({ assessmentQuestions: this.props.assessmentQuestions });
    await this.props.getAllAnswerContents({ questions: this.props.assessmentQuestions });//
    this.setState({ examViewLoading: false });
    if (!this.props.candidateAnswerContents?.length || !this.props.candidateAnswers?.length) {
      this.autosaveTestState()
    }
    this.intervalID = setInterval(
      this.autosaveTestState,
      config.examConfigs.autoSubmitInterval * 60 * 1000
    );
    window.addEventListener("beforeunload", this.onUnload);
    window.addEventListener("online", this.OnlineFunction);
    window.addEventListener("offline", this.OfflineFunction);
    await this.getAllCandidateGradeScales();
    this.questionRefs = this.state.questions.map(() => React.createRef());
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.questions !== this.state.questions && this.state.questions.length > 0) {
      this.questionRefs = this.state.questions.map(() => React.createRef());
    }

    if (this.state.submissionState) {
      if (!this.props.submission_uploading && this.props.submission_error) {
        window.Swal.fire({
          title: namespace.FAILED,
          text: namespace.ANSWER_SAVED_FAIL,
          icon: "error",
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
          }
        }
        );
      } else if (this.props.submission_success) {
        window.Swal.fire({
          title: namespace.SUCCESS,
          text: namespace.ANSWER_SAVED_SUCCESSFULL,
          icon: 'success',
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
          }
        }
        );
      }
      this.setState({ submissionState: false });
    }

    // Reload answers if exam is resumed
    if (
      this.state.questions &&
      this.state.questions.length &&
      this.props.questionContents.length &&
      !this.state.setAnswers
    ) {
      var ques = [...this.state.questions];
      let subQuestions = {}
      let answeredQuestions = [...this.state.answeredQuestions]
      let selectedAnswers = this.state.answers;
      var num = 0;
      this.props.questions.map((question, i) => {
        if (this.props.answerMap[this.props.questions[i].question_id]) {
          var answer =
            this.props.answerMap[this.props.questions[i].question_id];
          if (
            this.props.questions[i]?.question_type === questionTypeConstants.MCQ.name
          ) {
            var answers = JSON.parse(answer);
            selectedAnswers[this.props.questions[i].question_id] = answers;
            if (answer != "[]") {
              answeredQuestions = [...answeredQuestions, this.props.questions[i].question_id]
            }
            if (
              localStorage.getItem(this.props.candidate_id) != null &&
              localStorage.getItem(this.props.candidate_id).includes(i)
            ) {
              this.props.questions[i].needToRevise = true;
              num = num + 1;
              this.state.revisedQuestions.push(i);
            }
          } else if (
            this.props.questions[i]?.question_type ===
            questionTypeConstants.DESCRIPTIVE.name
          ) {
            var answerState = EditorState.createWithContent(
              convertFromRaw(JSON.parse(answer))
            );
            const categorizedQuestionIndex = ques.findIndex(item => item.question_id === this.props.questions[i]?.question_id)
            categorizedQuestionIndex && (ques[categorizedQuestionIndex]['answer'] = answerState);

            if (JSON.parse(answer)["blocks"][0]["text"] != "") {
              answeredQuestions = [...answeredQuestions, this.props.questions[i].question_id]
            }
            if (
              localStorage.getItem(this.props.candidate_id) != null &&
              localStorage.getItem(this.props.candidate_id).includes(i)
            ) {
              this.props.questions[i].needToRevise = true;
              num = num + 1;
              this.state.revisedQuestions.push(i);
            }
          } else if (
            (this.props.questions[i]?.question_type ===
              questionTypeConstants.SUBQUESTION.name) && !this.props.questions[i]?.is_subquestion
          ) {
            let subQuestionIdArray = this.props.questions[i]?.sub_questions?.split(',')
            let subQuesAns = subQuestionIdArray.map(id => {
              let answerId = Object.keys(this.props.answerMap).find(answerId => id === answerId.toString())
              var answerState
              if (answerId) {
                answerState = EditorState.createWithContent(
                  convertFromRaw(JSON.parse(this.props.answerMap[answerId]))
                );
              } else {
                answerState = EditorState.createWithContent(
                  ContentState.createFromText('')
                )
              }
              return {
                answerId: parseInt(id),
                answer: answerState
              }
            })
            const categorizedQuestionIndex = ques.findIndex(item => item.question_id === this.props.questions[i]?.question_id)

            if (categorizedQuestionIndex !== -1) {
              ques[categorizedQuestionIndex]['answer'] = subQuesAns
            }
            let isAnswered = false
            subQuestions = { ...this.state.subQuestions }
            subQuesAns.forEach(ans => {
              let subQuesIndex = Object.keys(subQuestions).find(ques => subQuestions[ques].question_id === ans.answerId)
              if (subQuesIndex) {
                subQuestions[subQuesIndex]['answer'] = ans.answer ? ans.answer : ''
              }
              if (!!ans['answer']?.getCurrentContent().getPlainText().length) {
                isAnswered = true
              }
            })
            if (isAnswered) {
              answeredQuestions = [...answeredQuestions, this.props.questions[i].question_id]
            }
            if (
              localStorage.getItem(this.props.candidate_id) != null &&
              localStorage.getItem(this.props.candidate_id).includes(i)
            ) {
              this.props.questions[i].needToRevise = true;
              num = num + 1;
              this.state.revisedQuestions.push(i);
            }
          } else if (
            this.props.questions[i].question_type ===
            questionTypeConstants.SUBMISSION.name
          ) {
            var content = JSON.parse(answer);
            selectedAnswers[this.props.questions[i].question_id] = content;
            if (answer != "[]") {
              answeredQuestions = [...answeredQuestions, this.props.questions[i].question_id]
            }
            if (
              localStorage.getItem(this.props.candidate_id) != null &&
              localStorage.getItem(this.props.candidate_id).includes(i)
            ) {
              this.props.questions[i].needToRevise = true;
              num = num + 1;
              this.state.revisedQuestions.push(i);
            }
          }
          this.setState({
            subQuestions: subQuestions,
          });
        }
      });
      this.setState({
        questions: ques,
        setAnswers: true,
        answers: selectedAnswers,
        needToRevise: num,
        answeredQuestions
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
    clearInterval(this.intervalID);
    this.autosaveTestState()
  }

  //onclick event of view all questions button
  onClickViewAllQuestions() {
    this.setState((preState) => ({
      ...preState,
      viewAllQuestions: !preState.viewAllQuestions,
    }));
  }

  autosaveTestState(onReload = false) {
    this.saveTestState(onReload);
  }

  onSelectQus = (index) => {
    this.setState({ currentQuestion: index, highlightedQuestion: index }, () => {
      const questionElement = this.questionRefs[index]?.current;
      if (this.state.viewAllQuestions && questionElement) {
        const questionRect = questionElement.getBoundingClientRect();
        const containerRect = this.containerRef.current.getBoundingClientRect();
        const scrollTop = this.containerRef.current.scrollTop + (questionRect.top - containerRect.top);
        this.containerRef.current.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
      }
    });
  };

  //onclick event of make revise button
  makeRevise(e, questionIndex) {
    let num = this.state.needToRevise;
    const ques = [...this.state.questions];
    ques[questionIndex].needToRevise = e.target.checked;
    this.setState({ questions: ques });
    if (e.target.checked) {
      this.setState({ needToRevise: num + 1 });
      this.state.revisedQuestions.push(questionIndex);
    } else {
      this.setState({ needToRevise: num - 1 });
      this.state.revisedQuestions.splice(
        this.state.revisedQuestions.indexOf(questionIndex),
        1
      );
    }
    localStorage.setItem(this.props.candidate_id, this.state.revisedQuestions);
  }

  //When Candidate write answers for Descriptive Questions
  makeEdit(editorState, questionIndex, questionId) {
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    const ques = [...this.state.questions];
    ques[questionIndex].answer = editorState;
    this.setState({ questions: ques });
    if (!this.state.answeredQuestions.includes(questionId)) {
      this.setState({
        answeredQuestions: [...this.state.answeredQuestions, questionId]
      }, () => {
        if (!ques[questionIndex].answer.getCurrentContent().getPlainText().length) {
          let removedArray = this.state.answeredQuestions.filter(item => item !== questionId)
          this.setState({
            answeredQuestions: [...removedArray]
          })
        }
      })
    } else {
      if (!ques[questionIndex].answer.getCurrentContent().getPlainText().length) {
        let removedArray = this.state.answeredQuestions.filter(item => item !== questionId)
        this.setState({
          answeredQuestions: [...removedArray]
        })
      }
    }
  }

  makeSubQuestionEdit = (editorState, questionIndex, questionId) => {
    const ques = { ...this.state.subQuestions };
    ques[questionIndex].answer = editorState;
    const setAnswerQuestion = () => {
      let isAnswered = false
      for (const key in ques) {
        if (!!ques[key].answer?.getCurrentContent().getPlainText().length) {
          isAnswered = true
        }
      }
      if (!isAnswered) {
        let removedArray = this.state.answeredQuestions.filter(item => item !== questionId)
        this.setState({
          answeredQuestions: [...removedArray]
        })
      }
    }
    this.setState({ subQuestions: ques });
    if (!this.state.answeredQuestions.includes(questionId)) {
      this.setState({
        answeredQuestions: [...this.state.answeredQuestions, questionId]
      }, () => {
        setAnswerQuestion()
      })
    } else {
      setAnswerQuestion()
    }
  }
  //onclick event of previous button
  onClickPrevious() {
    let previousQus = this.state.currentQuestion;
    if (this.state.currentQuestion > 0) {
      this.setState({ currentQuestion: previousQus - 1 });
    }
  }

  //onClick event of next button
  onClickNext() {
    let previousQus = this.state.currentQuestion;
    if (this.state.questions.length - 1 > this.state.currentQuestion) {
      this.setState({ currentQuestion: previousQus + 1 });
    }
  }

  //when candidate select answer for MCQ question
  markAnswer(e, questionIndex, answerIndex) {
    let selectedAnswers = { ...this.state.answers };
    let current = selectedAnswers[questionIndex];
    let index;

    if (!current) {
      selectedAnswers[questionIndex] = [];
    }
    index = selectedAnswers[questionIndex].indexOf(answerIndex);

    if (index !== -1) {
      selectedAnswers[questionIndex].splice(index, 1);
    } else {
      selectedAnswers[questionIndex].push(answerIndex);
    }

    this.setState({
      answers: selectedAnswers,
    }, () => {
      if (this.state.answeredQuestions.includes(questionIndex) && (selectedAnswers[questionIndex]?.length === 0)) {
        let removedArray = this.state.answeredQuestions.filter(item => item !== questionIndex)
        this.setState({
          answeredQuestions: [...removedArray]
        })
      }
    });

    if (!this.state.answeredQuestions.includes(questionIndex)) {
      this.setState({
        answeredQuestions: [...this.state.answeredQuestions, questionIndex]
      })
    }
  }

  async saveTestState(onReload = false) {
    let candidate_id = this.props?.candidate_id;
    let assessment_id = this.props?.assessment.assessment_id;
    let attempt_no = this.props?.candidateAssessment.attempt_no;
    let sub_questions = this.state.subQuestions

    // let attempt_no = 
    let question_id, contentState, answer_content;
    let answers = {},
      i;
    for (i = 0; i < this.state?.questions.length; i++) {
      question_id = this.state.questions[i].question_id;
      answers[question_id] = [];
      if (this.state.questions[i].question_type === questionTypeConstants.MCQ.name) {
        if (this.state.answers[question_id]) {
          answers[question_id] = this.state.answers[question_id];
        } else {
          answers[question_id] = [];
        }
      }
      else if (
        this.state.questions[i].question_type ===
        questionTypeConstants.DESCRIPTIVE.name
      ) {
        if (this.state.questions[i].answer) {
          contentState = this.state.questions[i].answer.getCurrentContent();
          answer_content = convertToRaw(contentState);
          answers[question_id] = answer_content;
        } else {
          let editorContent = [];
          let block = {
            blocks: [
              {
                key: _randomString(5, "#a"),
                text: "",
                type: "unstyled",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ],
            entityMap: {},
          };
          function _randomString(length, chars) {
            let mask = "";
            if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
            if (chars.indexOf("#") > -1) mask += "0123456789";
            let result = "";
            for (let i = length; i > 0; --i)
              result += mask[Math.floor(Math.random() * mask.length)];
            return result;
          }

          editorContent.push(block);
          answer_content = editorContent[0];
          answers[question_id] = answer_content;
        }
      } else if (
        this.state.questions[i].question_type ===
        questionTypeConstants.SUBQUESTION.name && this.state.questions[i].is_subquestion !== "true"
      ) {

        let editorContent = [];
        let block = {
          blocks: [
            {
              key: _randomString(5, "#a"),
              text: "",
              type: "unstyled",
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {},
            },
          ],
          entityMap: {},
        };
        function _randomString(length, chars) {
          let mask = "";
          if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
          if (chars.indexOf("#") > -1) mask += "0123456789";
          let result = "";
          for (let i = length; i > 0; --i)
            result += mask[Math.floor(Math.random() * mask.length)];
          return result;
        }

        editorContent.push(block);
        answer_content = editorContent[0];
        answers[question_id] = answer_content;

      }
      else if (
        this.state.questions[i].question_type ===
        questionTypeConstants.SUBMISSION.name
      ) {
        // blobs are uploaded/deleted when adding/removing - No action needed for submission
        if (this.state.answers[question_id]) {
          answers[question_id] = this.state.answers[question_id];
        } else {
          answers[question_id] = [];
        }
      }
    }

    if (Object.keys(sub_questions).length > 0) {
      for (let val in sub_questions) {
        if (sub_questions[val]?.answer) {
          let answer_content = sub_questions[val]?.answer.getCurrentContent();
          let sub_answer = convertToRaw(answer_content)
          answers[sub_questions[val]?.question_id] = sub_answer
        } else {
          let editorContent = [];
          let block = {
            blocks: [
              {
                key: _randomString(5, "#a"),
                text: "",
                type: "unstyled",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ],
            entityMap: {},
          };
          function _randomString(length, chars) {
            let mask = "";
            if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
            if (chars.indexOf("#") > -1) mask += "0123456789";
            let result = "";
            for (let i = length; i > 0; --i)
              result += mask[Math.floor(Math.random() * mask.length)];
            return result;
          }

          editorContent.push(block);
          let answer_content = editorContent[0];
          answers[sub_questions[val]?.question_id] = answer_content;
        }
      }
    }
    const examState = { candidate_id, assessment_id, answers, attempt_no, on_reload: onReload }
    if (onReload) {
      localStorage.setItem(namespace.EXAMSTATE, JSON.stringify(examState));
    }

    await this.props?.submitAnswers({ ...examState });
    await this.props?.getCandidateAnswers(this.props?.exam);
    await this.props?.getAllCandidateAnswerContents({ answers: this.props?.candidateAnswers });
    //await this.autoGradeMCQ();
  }

  //onclick event of save button, it save or update answer changes to db
  async onClickSave(e) {
    this.setState({ submitLoading: true });
    e.preventDefault();
    await this.saveTestState();
    //await this.autoGradeMCQ();
    this.setState({ submissionState: true });
    this.setState({ submitLoading: false });
  }

  //finish test, it submit answers to db and change candidate status as finished in db
  async handleSubmitTest() {
    window.removeEventListener("beforeunload", this.onUnload);
    this.setState({ submitLoading: true });
    this.setState({
      submit: true,
      isSubmitModalVisible: false
    });
    await this.saveTestState();
    await this.checkingForAllMcqs();
    if (this.state.isAllMCQs) {
      await this.autoGradeMCQ();
    }
    let candidate_id = this.props.candidate_id;
    let is_all_mcq = this.state.isAllMCQs;
    this.props.finishTest({ candidate_id, is_all_mcq }).then(() => {
      clearInterval(this.intervalID);
      this.setState({ finishState: true }, () => {
        if (!this.props.submission_uploading && this.props.submission_error) {
          window.Swal.fire({
            title: namespace.FAILED,
            text: "Exam Finishing Failed",
            icon: "error",
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
            }
          });
          this.setState({ finishState: false, timeUp: false, submit: false, renderGradeReport: null });
        } else if (
          !this.props.finish_loading && this.props.finish_success
        ) {
          localStorage.removeItem(this.props.candidate_id);
          window.Swal.fire({
            title: "Submission Complete",
            text: "Exam Finished Successfully",
            icon: "success",
            showConfirmButton: true,
            allowOutsideClick: false,
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
            }
          }).then((action) => {
            if (action.isConfirmed) {
              if (this.state.isAllMCQs) {
                this.setState({ renderGradeReport: 'auto', submit: false })
                //history.push("/paper-auto-grade-report");
              }
              else {
                this.setState({ renderGradeReport: 'normal', submit: false })
                // history.push("/paper-grade-report");
              }
              //window.location.reload();
            }
          })

        }
      });
    });
    this.setState({ submitLoading: false })
  }

  async getSubmissionContent(file, questionIndex) {
    let answers = this.state.answers;
    let files = [];
    if (
      Array.isArray(this.state.answers[questionIndex]) &&
      this.state.answers[questionIndex] != "[]"
    ) {
      files = this.state.answers[questionIndex];
    }
    if (!this.state.answeredQuestions.includes(questionIndex)) {
      this.setState({
        answeredQuestions: [...this.state.answeredQuestions, questionIndex]
      })
    }

    let fileReader;
    const container_id = "".concat(this.props.candidate_id, "-", questionIndex);
    const container_name = config.storageBlobConfigs.containerName;
    var name = file.upload.filename;
    var size = file.upload.total;
    var self = this;

    if (size > config.fileUploadConfigs.maxFileSize) {
      window.Swal.fire({
        title: namespace.FAILED,
        text: "File cannot be uploaded. Check the size of the file!",
        icon: "error",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    } else {
      if (!files.includes(name)) {
        files.push(name);
        self.state.filesUploaded.push(name);
        try {
          var reader = new FileReader();
          reader.onload = async function (e) {
            var text = e.target.result;
            await AzureStorage.uploadBlob(container_name, container_id + "-" + name, text).then(() => {
              window.Swal.fire({
                title: "Success!",
                text: "File " + name + " uploaded successfully!",
                icon: "success",
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              });

              const deletedItem = self.state.filesUploaded.splice(
                self.state.filesUploaded.indexOf(name),
                1
              );

              self.saveTestState();
            });
          };
          reader.readAsArrayBuffer(file);
        } catch (e) {
          files.pop();
        } finally {
          answers[questionIndex] = files;
          this.setState({
            answers: answers,
          });
        }
      } else {
        window.Swal.fire({
          title: namespace.FAILED,
          text: 'File names are similar. Delete the previous upload to add a new file with same name!',
          icon: "error",
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
          }
        }
        );
      }
    }
  }

  deleteSubmissionContent(file, questionIndex) {
    let answers = this.state.answers;
    let files = this.state.answers[questionIndex];
    const container_id = "".concat(this.props.candidate_id, "-", questionIndex);
    const container_name = config.storageBlobConfigs.containerName;
    AzureStorage.deleteblob(container_name, container_id + "-" + file.name.replace(/['"]+/g, ""));
    let fileIndex = files.indexOf(file);
    files.splice(fileIndex, 1);
    answers[questionIndex] = files;

    this.saveTestState();
    this.setState({
      answers: answers,
    });
  }

  changeRoute() {
    // window.location = 'http://localhost:3002/infinity/available-assessments'
    // this.props.history.push({
    //   pathname: routes.enrolled_paper_list,
    //   state: { 
    //     "mid": sessionStorage.getItem("enrolled_paper_list_mid"),
    //     "pid": sessionStorage.getItem("pid"),
    //     "lg_user_id": sessionStorage.getItem("lg_user_id"),
    //     "lg_user_table_id": sessionStorage.getItem("lg_user_table_id"),
    //     "flg_list_active": sessionStorage.getItem("flg_list_active"),
    //     "form_display_title": sessionStorage.getItem("form_display_title"),
    //     "form_list_id": sessionStorage.getItem("form_list_id")
    //   },
    // });
    this.props.offStartExam()
    window.location.reload();
  }

  // checking whether all questions are MCQS or not
  async checkingForAllMcqs() {
    var question_list = this.state.assessmentQuestions,
      i;
    for (i = 0; i < this.state?.assessmentQuestions.length; i++) {
      if (question_list[i]["question_type"] !== questionTypeConstants.MCQ.name) {
        this.setState({ isAllMCQs: false });
      }
    }
    return;
  }

  calculateTotalAllocatedMarks() {
    var question_list = this.state.assessmentQuestions, i, sum = 0 ;
    for (i = 0; i < this.state?.assessmentQuestions.length; i++) {
      sum = sum + question_list[i]["mark_allocation"]
    }
    return sum;
  }

  calculateTotalReceivedMarks() {
    var i, total = 0;
    for (i = 0; i < this.state?.assessmentQuestions?.length; i++) {
      var candidate_answers = store.getState()["testReducer"]["candidateAnswerMap"];
      if (candidate_answers[this.state?.assessmentQuestions[i].question_id]["mark_obtained"]) {
        total = total + candidate_answers[this.state?.assessmentQuestions[i].question_id]["mark_obtained"];
      }
    }
    return total;
  }

  getAllCandidateGradeScales = async() => {
    this.setState({ isTableDataLoading: true });
    await candidateService.getAllGradeScales()
        .then((response)=>{
            if(response.success){
                this.setState({ 
                    candidateGradeScales: response.data.candidate_grade_scales,
                    isTableDataLoading: false 
                });
            }
            else{
                this.setState({ isTableDataLoading: false });
            }
            
        })
        .catch(()=>{
          this.setState({ isTableDataLoading: false });
        })
  }

  getRemark(percentage) {
    for (const scale of this.state.candidateGradeScales) {
        if (percentage >= scale.min_marks && percentage <= scale.max_marks) {
            return scale.remarks;
        }
    }
    return "Invalid grade percentage"; 
  }

  getGrade(percentage) {
    for (const scale of this.state.candidateGradeScales) {
          if (percentage >= scale.min_marks && percentage <= scale.max_marks) {
              return scale.grade;
          }
      }
    return "Invalid grade percentage"; 
  }

  async autoGradeMCQ() {
    var i;
    for (i = 0; i < this.props?.assessmentQuestions.length; i++) {
      if (this.props.assessmentQuestions[i]?.question_type == questionTypeConstants.MCQ.name) {
        var candidate_answer =
          this.props?.answerMap[
          this.props.assessmentQuestions[i]?.question_id
          ];
        if (candidate_answer) {
          candidate_answer = JSON.parse(candidate_answer);
        }
        var res = this.props.answerContents[i]?.toString().split(",");
        let numOfCandidateAnswers = candidate_answer?.length;
        let numOfAnswers = 0;
        for (var m in res) {
          if (res[m] != "Nan" && res[m] != 0) {
            numOfAnswers = numOfAnswers + 1;
          }
        }
        if (numOfCandidateAnswers <= numOfAnswers) {
          var marks = 0, j, ans_index;
          for (j in candidate_answer) {
            ans_index = candidate_answer[j];
            marks = marks + (res[ans_index] *
              this.props.assessmentQuestions[i]?.mark_allocation) /
              100;
          }
          const assessor_id = "root";
          var id = this.props.questions[i]?.question_id;
          await this.props?.onSetMarks({ id, marks, assessor_id });
        }
        else {
          var marks = 0;
          const assessor_id = "root";
          var id = this.props?.questions[i]?.question_id;
          await this.props?.onSetMarks({ id, marks, assessor_id });
        }
      }
    }

    var total = 0;
    for (i = 0; i < this.props?.assessmentQuestions?.length; i++) {
      var candidate_answer =
        this.props?.answerMap[
        this.props?.assessmentQuestions[i]?.question_id
        ];
      if (candidate_answer?.mark_obtained) {
        total = total + candidate_answer?.mark_obtained;
      }
    }

    const totalMarksAllocation = this.calculateTotalAllocatedMarks();
    const finalGrade = this.calculateTotalReceivedMarks();
    const percentage = (finalGrade / totalMarksAllocation) * 100;
    const remark = this.getRemark(percentage)
    const grade = this.getGrade(percentage)

    let contentState = convertFromRaw({
      blocks: [
          {
              key: 'initial',
              text: remark,
              type: 'unstyled',
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
              data: {}
          }
      ],
      entityMap: {}
    });
    let remarksState = EditorState.createWithContent(contentState);
    this.setState({ remarksEditorState: remarksState})

    const assessment_id = this.props?.assessment?.assessment_id;
    const user_role_id = "CASS" + this.props?.assessment?.created_by;
    const questions = this.props?.candidateAnswers;
    const candidate_id = this.props?.candidate_id;
    const attempt_no = this.props?.candidateAssessment.attempt_no;
    sessionStorage.setItem("candidate_id", candidate_id);
    sessionStorage.setItem("attempt_no", attempt_no);
    const final_grade = this.props?.candidate.final_grade;
    const total_mark_allocation = totalMarksAllocation;
    const assessor_remarks = convertToRaw(
      this.state.remarksEditorState.getCurrentContent()
    );
    window.Swal.showLoading();

    await this.props?.saveMarks({
      assessment_id,
      questions,
      user_role_id,
      assessor_remarks,
      candidate_id,
      attempt_no
    });

    await this.props?.finalizeGrade({
      candidate_id,
      final_grade,
      user_role_id,
      attempt_no,
      total_mark_allocation,
      grade
    });

    await this.props.getReport({ candidate_id, attempt_no });
    window.Swal.close();

    if (!this.props.gradeLoading && this.props.gradeError) {
      window.Swal.fire({
        title: "Failed!",
        text: "Failed to autograde!",
        icon: "error",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
    // else {
    //   window.Swal.fire("Success!", "Autograded successfully!", "success");
    // }

  }

  handleSubmitModal = () => {
    this.setState({ isSubmitModalVisible: false })
  }

  toggleSubmitModal = () => {
    this.setState((prevState) => ({
      isSubmitModalVisible: !prevState.isSubmitModalVisible,
    }))
  }

  render() {
    let question_content;
    let navPills; //navigate questions button
    let time_difference = null;
    const { isSubmitModalVisible } = this.state

    //start exam timer
    if (this.props.start_time) {
      if(this.props.candidate_exam_duration !== null){
        var start = new Date(Date.parse(this.props.start_time));
        var now = new Date().getTime();
        start.setTime(start.getTime() + this.props.candidate_exam_duration * 60 * 1000);
        if (start >= now) {
          time_difference = start - now;
        } else {
          time_difference = 0;
        }
      }
    }

    if (!this.props.start_time) {
      // return  <Alert className="alert alert-custom alert-danger fade show" alertIcon="flaticon-warning" alertText="Click Here to go to the Available Assessment" onClick={this.changeRoute}/>
      // this.props.history.push({
      //   pathname: routes.enrolled_paper_list,
      //   state: { 
      //     "mid": sessionStorage.getItem("enrolled_paper_list_mid"),
      //     "pid": sessionStorage.getItem("pid"),
      //     "lg_user_id": sessionStorage.getItem("lg_user_id"),
      //     "lg_user_table_id": sessionStorage.getItem("lg_user_table_id"),
      //     "flg_list_active": sessionStorage.getItem("flg_list_active"),
      //     "form_display_title": sessionStorage.getItem("form_display_title"),
      //     "form_list_id": sessionStorage.getItem("form_list_id")
      //   },
      // });
      // window.location.reload();

      this.props.offStartExam()
      //window.location.reload();
    }

    let n = 0; //answered descriptive questions number

    //when candidate enter answer for descriptive question this will add 1 number n=n+1
    if (this.state.questions?.length > 0) {
      this.state.questions.map((qus) => {
        if (qus.answer) {
          return (n = n + 1);
        }
      });
    }

    if (this.state.renderGradeReport === 'auto') {
      return <PaperAutoGradeReport offStartExam={() => this.props.offStartExam()} candidate={this.props.candidate} assessment={this.props.assessment} report={this.props.report} title={this.props.assessment?.title} candidate_id={this.props.candidate_id} allUnits={this.props.allUnits}/>
    } else if (this.state.renderGradeReport === 'normal') {
      return <PaperGradeReport offViewReport={() => this.props.offStartExam()} assessment={this.props.assessment} report={this.props.report} title={this.props.assessment?.title} candidate_id={this.props.candidate_id} allUnits={this.props.allUnits}/>
    }

    //when time difference becomes 0 then this will invoke handlesubmit test function
    if(this.props.candidate_exam_time_limit === true){
      if (
        time_difference === 0 &&
        this.state.timeUp == false &&
        this.state.submit == false &&
        window.navigator.onLine == true
      ) {
        this.setState({
          timeUp: true,
        });
        window.Swal.fire({
          title: namespace.WARNING,
          text: "Exam Time Finished",
          icon: "warning",
          showConfirmButton: true,
          allowOutsideClick: false,
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
          }
        }).then((action) => {
          if (action.isConfirmed) {
            this.handleSubmitTest();
          }
        });
      } else if (
        time_difference === 0 &&
        this.state.timeUp == false &&
        this.state.submit == false &&
        window.navigator.onLine == false
      ) {
        window.Swal.fire({
          title: namespace.WARNING,
          text: "Network disconnected. Please get connected as soon as possible",
          icon: "warning",
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
          }
        }
        );
      }
    }

    //for question and answer part in UI
    if (this.state.questions?.length) {
      let indexes = this.state.subQuestionsIndexes
      let subQuestions = {};
      indexes.forEach(index => {
        if (this.props.questionContents.hasOwnProperty(index)) {
          subQuestions[index] = this.props.questionContents[index];
        }
      });
      let filteredQuestions = this.props.questionContents.filter((item, index) => !indexes.includes(index));
      question_content = filteredQuestions.map((question, i) => {
        let states = JSON.parse(question);
        let tempAnswerStates = [];
        let tempQuestionState = EditorState.createWithContent(
          convertFromRaw(states[0])
        );

        if (
          this.state.questions[i]?.question_type ===
          questionTypeConstants.DESCRIPTIVE.name
        ) {
          return (
            <div className="col-md-12" style={{ overflow: "auto" }}>
              <h3 className="d-flex mb-5 overflow-hidden all-questions-title">
                <div className="number-wrap" style={{ fontSize: "12px", marginTop: "3px" }}>{i + 1}.</div>
                <div className="rdw-editor-question">
                  <ViewEditor
                    editorState={tempQuestionState}
                  />
                </div>
                <div className="pl-2 pr-2 border border-primary rounded d-inline-block push-button">
                  <CheckBoxInline>
                    <CheckBox
                      key={i}
                      onClick={(e) => this.makeRevise(e, i)}
                      checked={
                        this.state.questions[i]
                          ? !!this.state.questions[i].needToRevise
                          : false
                      }
                      checkBoxName="Mark for Review"
                      style={{
                        color: "#5458AF",
                      }}
                    />
                  </CheckBoxInline>
                </div>
              </h3>

              <div
                className="RichEditor-root-descriptive-answer"
                key={i}
                id="editAnswer"
              >
                <Editor
                  placeholder="Add your answer here..."
                  editorStyle={{ height: "200px" }}
                  editorState={this.state.questions[i].answer}
                  key={i}
                  onEditorStateChange={(e) =>
                    this.makeEdit(e, i, this.state.questions[i].question_id)
                  }
                  toolbar={{
                    fontFamily: {
                      options: [
                        "Arial",
                        "Georgia",
                        "Impact",
                        "Tahoma",
                        "Times New Roman",
                        "Verdana",
                        "Kushpoo",
                        "Baamini",
                        "FMAbhaya"
                      ],
                    },
                  }}
                  toolbarClassName="vertical-toolbar"
                />
              </div>
              <div className="separator separator-dashed separator-border-2 mt-5 mb-5" />
            </div>
          );
        } else if (
          this.state.questions[i]?.question_type ===
          questionTypeConstants.SUBQUESTION.name
        ) {
          let subs = this.state.subQuestions
          let arr = []
          for (let val in subs) {
            if (subs[val].title === this.state.questions[i].title) {
              arr.push(val)
            }
          }
          let sub_ques = {}
          arr.forEach(index => {
            if (subQuestions.hasOwnProperty(index)) {
              sub_ques[index] = subQuestions[index];
            }
          });
          return (
            <div className="col-md-12" style={{ overflow: "auto" }}>
              <div className="row">
                <div className="pl-2 pr-2 border border-primary rounded d-inline-block push-button">
                  <CheckBoxInline>
                    <CheckBox
                      key={i}
                      onClick={(e) => this.makeRevise(e, i)}
                      checked={
                        this.state.questions[i]
                          ? !!this.state.questions[i].needToRevise
                          : false
                      }
                      checkBoxName="Mark for Review"
                      style={{
                        color: "#5458AF",
                      }}
                    />
                  </CheckBoxInline>
                </div>
                <div className="col-md-12 pt-3 mb-5">
                  <div className="row">
                    <div className="col-md-1 col-1 number-wrap" style={{ fontWeight: "600", fontSize: "12px", marginTop: "3px" }}>{i + 1}.</div>
                    <div className="col-md-11 col-11 rdw-editor-question overflow-hidden all-questions-title" style={{ fontWeight: "600" }}>
                      <ViewEditor
                        editorState={tempQuestionState}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {Object.entries(sub_ques).map(([key, val], j) => {
                let state = JSON.parse(val);
                let tempQuestion = EditorState.createWithContent(
                  convertFromRaw(state[0])
                );
                return (
                  <div className="col-md-12 ml-5 pl-5">
                    <div className="row d-flex align-items-center">
                      <div className="col-md-1 col-1 number-wrap" style={{ fontWeight: "600", fontSize: "12px", marginTop: "3px" }}>{j + 1}.</div>
                      <div className="col-md-11 col-11 rdw-editor-question" style={{ fontWeight: "500" }}>
                        <ViewEditor
                          editorState={tempQuestion}
                        />
                      </div>
                    </div>

                    <div
                      className="RichEditor-root-descriptive-answer"
                      key={j}
                      id="editAnswer"
                    >
                      <Editor
                        placeholder="Add your answer here..."
                        editorStyle={{ maxHeight: "200px", overflow: "auto" }}
                        editorState={this.state.subQuestions[key].answer}
                        key={i}
                        onEditorStateChange={(e) =>
                          this.makeSubQuestionEdit(e, key, this.state.questions[i].question_id)
                        }
                        toolbar={{
                          fontFamily: {
                            options: [
                              "Arial",
                              "Georgia",
                              "Impact",
                              "Tahoma",
                              "Times New Roman",
                              "Verdana",
                              "Kushpoo",
                              "Baamini",
                              "FMAbhaya"
                            ],
                          },
                        }}
                        toolbarClassName="vertical-toolbar"
                      />
                    </div>
                    <div className="separator separator-dashed separator-border-2 mt-5 mb-5" />
                  </div>
                )
              })}
              <div className="separator separator-dashed separator-border-2 mt-5 mb-5" />
            </div>
          );
        } else if (
          this.state.questions[i]?.question_type === questionTypeConstants.MCQ.name
        ) {
          states.shift();
          states.forEach((state) => {
            tempAnswerStates.push(
              EditorState.createWithContent(convertFromRaw(state))
            );
          });
          return (
            <div className="col-md-12 px-0" style={{ overflow: "auto" }}>
              <h3 className="mb-5 overflow-hidden all-questions-title">
                <div className="d-flex">
                  <div className="number-wrap" style={{ fontSize: "12px", marginTop: "3px" }}>{i + 1}.</div>
                  <div className="rdw-editor-question">
                    <ViewEditor
                      editorState={tempQuestionState}
                    />
                  </div>
                </div>
                <div className="pl-2 pr-2 border border-primary rounded d-inline-block push-button">
                  <CheckBoxInline>
                    <CheckBox
                      key={i}
                      onClick={(e) => this.makeRevise(e, i)}
                      checked={
                        this.state.questions[i]
                          ? !!this.state.questions[i].needToRevise
                          : false
                      }
                      checkBoxName="Mark for Review"
                      style={{
                        color: "#5458AF",
                      }}
                    />
                  </CheckBoxInline>
                </div>
              </h3>
              <div>
                <div
                  className={`RichEditor-root px-0 parent-class ${tempAnswerStates.some(
                    (answer) =>
                      answer.getCurrentContent().getPlainText().length > 100
                  )
                    ? "single-column"
                    : ""
                    }`}
                >
                  {tempAnswerStates.map((answer, j) => {
                    return (
                      <div className="row d-flex align-items-center child-class">
                        <div className="col-md-2 col-2">
                          <CheckBoxInline className="col-md-1">
                            <CheckBox
                              key={j}
                              onClick={(e) => {
                                this.markAnswer(
                                  e,
                                  this.state.questions[i].question_id,
                                  j
                                );
                              }}
                              checked={
                                this.state.answers[
                                  this.state.questions[i].question_id
                                ]
                                  ? this.state.answers[
                                    this.state.questions[i].question_id
                                  ].includes(j)
                                  : false
                              }
                              style={{
                                color: "#5458AF",
                              }}
                            />
                          </CheckBoxInline>
                        </div>
                        <div className="col-md-10 col-10">
                          <Editor
                            editorState={answer}
                            toolbarClassName="hide-toolbar"
                            readOnly={true}
                            toolbar={{}}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="separator separator-dashed separator-border-2 mt-5 mb-5" />
            </div>
          );
        } else if (
          this.state.questions[i]?.question_type ===
          questionTypeConstants.SUBMISSION.name
        ) {
          var submissionList = [];
          var reload = false;
          if (
            this.state.answers[this.state.questions[i].question_id] &&
            this.state.answers[this.state.questions[i].question_id] != "[]"
          ) {
            submissionList = this.state.answers[this.state.questions[i].question_id];
            reload = true;
          }

          var viewSubmissions = <div> No model answer uploaded. </div>;
          viewSubmissions = submissionList?.map((attach) => {
            if (attach.replace(/"/g, "") != "[]") {
              if (!this.state.filesUploaded.includes(attach)) {
                return (
                  <div>
                    <QuestionButton
                      className="btn btn-outline-primary primary-button mr-2"
                      btnIconStyle="flaticon-interface-3"
                      inlineStyle={{ innerWidth: "400px" }}
                      btnName={"Remove file - " + attach.replace(/"/g, "")}
                      onClick={(e) => {
                        this.deleteSubmissionContent(
                          attach,
                          this.state.questions[i].question_id
                        );
                      }}
                    />
                  </div>
                );
              } else {
                return (
                  <div>
                    <QuestionButton
                      className="btn btn-outline-primary primary-button mr-2"
                      btnIconStyle="fa fa-spinner fa-spin"
                      inlineStyle={{ innerWidth: "400px" }}
                      btnName={"Uploading file - " + attach.replace(/"/g, "")}
                    />
                  </div>
                );
              }
            }
          });
          return (
            <div className="col-md-12" style={{ overflow: "auto" }}>
              <h3 className="mb-5 overflow-hidden all-questions-title">
                <div className="d-flex">
                  <div className="number-wrap" style={{ fontSize: "12px", marginTop: "3px" }}>{i + 1}.</div>
                  <ViewEditor
                    editorState={tempQuestionState}
                  />
                </div>
                <div className="pl-2 pr-2 border border-primary rounded d-inline-block push-button">
                  <CheckBoxInline>
                    <CheckBox
                      key={i}
                      onClick={(e) => this.makeRevise(e, i)}
                      checked={
                        this.state.questions[i]
                          ? !!this.state.questions[i].needToRevise
                          : false
                      }
                      checkBoxName="Mark for Review"
                      style={{
                        color: "#5458AF",
                      }}
                    />
                  </CheckBoxInline>
                </div>
              </h3>
              {/* <div className="col-md-10">{viewSubmissions}</div> */}
              <SubmissionFileUpload
                className="col-md-10 col-form-label"
                key={i}
                id={this.state.questions[i].question_id}
                title={submissionList}
                defaultFile={submissionList}
                callBack={this.getSubmissionContent}
                deleteback={this.deleteSubmissionContent}
                reload={reload}
                questionIndex={this.state.questions[i].question_id}
              />

              <div className="separator separator-dashed separator-border-2 mt-5 mb-5" />
            </div>
          );
        }
      });
    }
    //for the navigation button of questions
    if (this.state.questions?.length > 0) {
      navPills = this.state.questions.map((item, i) => {
        let isCurrentQuestion = this.state.currentQuestion === i
        let needToRevise = item.needToRevise
        let isItAnswered = this.state.answeredQuestions.includes(this.state.questions[i]?.question_id)
        return (
          <li className="nav-item" style={{ margin: '0px' }}>
            <QuestionButton
              btnName={i + 1}
              className={`btn btn-icon m-1 btn-${isCurrentQuestion
                ? "primary"
                : needToRevise
                  ? "warning"
                  : isItAnswered
                    ? "success"
                    : "unanswered-question"
                } ${item.isAnswerd ? "btn-circle" : ""}`}
              onClick={(e) => this.onSelectQus(i)}
            />
          </li>
        )
      });
    }

    let timer = <div>{examTimer({ time_int: time_difference })}</div>;
    return (
      <>
        <ContentWrapper>
          {/* {(this.state.timeUp == true ||
          this.state.submit == true ||
          (time_difference == 0 && window.navigator.onLine == false)) && (
          <Spinner id="show" text="Please wait..." />
        )} */}
          {this.props.gradeLoading && <Spinner id="show" text="Please wait.." />}
          {/* {this.state.submit && <Spinner id="show" text="Please wait.." />} */}
          {this.state.submitLoading && <Spinner id="show" text="Please wait.." />}
          {this.state.examViewLoading && <Spinner id="show" text="Please wait.." />}
          {this.props.finish_loading && <Spinner id="show" text="Saving answers.." />}
          <div>
            <Card>
              <CardHeader>
                <CardTitle
                  cardLabel={this.props.assessment?.title}
                // cardBottomLable="Answer all questions"
                />
                <Button
                  variant="contained"
                  style={addBtnStyle}
                  onClick={
                    () => {
                      window.Swal.fire({
                        title: namespace.LEAVE_EXAM_WINDOW,
                        text: namespace.LEAVE_WARNING_MESSAGE,
                        showDenyButton: true,
                        showCancelButton: true,
                        icon: "warning",
                        confirmButtonText: namespace.YES,
                        denyButtonText: namespace.NO,
                        customClass: {
                          confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                          if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                          }
                        }
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          this.props.offStartExam()
                          //     this.props.history.push({
                          //       pathname: routes.enrolled_paper_list,
                          //       state: { 
                          //         "mid": sessionStorage.getItem("enrolled_paper_list_mid"),
                          //         "pid": sessionStorage.getItem("pid"),
                          //         "lg_user_id": sessionStorage.getItem("lg_user_id"),
                          //         "lg_user_table_id": sessionStorage.getItem("lg_user_table_id"),
                          //         "flg_list_active": sessionStorage.getItem("flg_list_active"),
                          //         "form_display_title": sessionStorage.getItem("form_display_title"),
                          //         "form_list_id": sessionStorage.getItem("form_list_id")
                          //       },
                          //  })
                        }
                      });
                    }}
                >
                  Back
                </Button>
              </CardHeader>

              <CardBody className={classNames({ disable_exam: time_difference < 0 })}>
                <div className="table-wrapper-scroll-y">
                  <div className="row">
                    <div className="col-md-9 px-0 border-right">
                      <ul className="list-unstyled test11">
                        {!this.state.viewAllQuestions &&
                          this.state.questions?.length > 0 ? (
                          <li>
                            <div className="mb-5">
                              {" "}
                              {this.props.questions[this.state.currentQuestion]
                                ? question_content[this.state.currentQuestion]
                                : ""}
                              {/* <h3 className="mb-5 overflow-hidden">{i + 1}. {item.question} */}
                            </div>
                          </li>
                        ) : (
                          // displaying all the questions...
                          <div ref={this.containerRef} style={{ overflowY: 'scroll', height: '500px' }}>
                            {question_content?.length > 0 && question_content.map((content, i) => (
                              <div
                                key={i}
                                className={`question-item mb-5 ${this.state.highlightedQuestion === i ? "highlighted" : ""}`}
                                ref={this.questionRefs[i]}
                              >
                                {content}
                              </div>
                            ))}
                            {/* {question_content} */}
                          </div>
                        )}
                      </ul>

                      <div className={`row justify-content-end paper-btn ${this.state.viewAllQuestions ? 'fixed' : ''}`}>
                        <div>
                          {!this.state.viewAllQuestions && this.state.currentQuestion > 0 && (
                            <button
                              type="button"
                              className="btn btn-light-primary font-weight-bolder pr-1 pl-2 py-2 my-3 mr-3 mb-3 mt-3"
                              onClick={this.onClickPrevious}
                            >
                              <span className="svg-icon svg-icon-md mr-2">
                                {/* begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fill-rule="evenodd"
                                  >
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <rect
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)"
                                      x="14"
                                      y="7"
                                      width="2"
                                      height="10"
                                      rx="1"
                                    />
                                    <path
                                      d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                      transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)"
                                    />
                                  </g>
                                </svg>
                                {/* end::Svg Icon */}
                              </span>
                              Previous
                            </button>
                          )}
                          {!this.state.viewAllQuestions &&
                            this.state.questions.length - 1 > this.state.currentQuestion && (
                              <ExamviewButton
                                className="btn btn-primary font-weight-bolder pl-2 pr-1 py-2 mr-3 mb-3 mt-3"
                                type="button"
                                onClick={this.onClickNext}
                                btnName="Next"
                                spanclassName="svg-icon svg-icon-md ml-2"
                                rectFill="#000000"
                                rectOpacity="0.3"
                                rectTransform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                                rectX="7.5"
                                rectY="7.5"
                                rectHeight="9"
                                rectWidth="2"
                                rectRX="1"
                                pathD="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                                pathFilerule="nonzero"
                                pathFill="#000000"
                                pathTransform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                              />
                            )}
                        </div>
                      </div>
                    </div>
                    <div className={`col-md-3 boxIndicators ${this.props.state.path === "dashboard" ? "dashboardEnrolledPath" : ''}`}>
                      {/* <h3>Questions</h3>
                    <p className="text-muted">Navigate to questions</p>

                    <ul className="nav nav-pills">
                      {this.state.questions && navPills}
                    </ul> */}
                      <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
                        <div className={`p-3 questions-section ${this.props.state.path === "dashboard" ? "paperDatas" : ''}`}>
                          <div className="row"><h3>Questions</h3>(Answer all questions)</div>

                          {this.props.candidate_exam_time_limit === true && 
                            <div className="d-flex align-items-center justify-content-start col-lg-12 col-md-12 col-sm-12 timeLeft">
                              <div className="time-remaining-wrap">
                                {" "}
                                {(time_difference && timer) || <h2>Loading Timer</h2>}
                              </div>
                            </div>
                          }

                          <CardToolbar
                            fnViewAllQuestion={this.onClickViewAllQuestions}
                            isViewAllQuestions={this.state.viewAllQuestions}
                          />

                          <p className="">Navigate to questions</p>

                          <ul className="nav nav-pills">
                            {this.state.questions && navPills}
                          </ul>

                          <ExamviewButton
                            className="btn btn-primary font-weight-bolder pl-2 pr-1 py-2 mr-3 mb-2 mt-2"
                            type="button"
                            onClick={this.onClickSave}
                            btnName="Save"
                            spanclassName="svg-icon svg-icon-md ml-2"
                            rectFill="#000000"
                            rectOpacity="0.3"
                            rectX="12"
                            rectY="4"
                            rectHeight="5"
                            rectWidth="3"
                            rectRX="0.5"
                            pathD="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z"
                            pathFilerule="nonzero"
                            pathFill="#000000"
                          />
                          <div className="right-btn-wrap">
                            <ExamviewButton
                              className="btn btn-primary font-weight-bolder pl-2 pr-1 mb-2 mt-2 submit-save-btn"
                              type="button"
                              onClick={this.toggleSubmitModal}
                              btnName="Submit and Finish Test"
                              // dataToggle="modal"
                              dataTarget="#import-questions"
                              spanclassName="svg-icon svg-icon-md ml-2"
                              rectFill="#000000"
                              rectOpacity="0.3"
                              rectTransform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                              rectX="7.5"
                              rectY="7.5"
                              rectHeight="9"
                              rectWidth="2"
                              rectRX="1"
                              pathD="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                              pathFilerule="nonzero"
                              pathFill="#000000"
                              pathTransform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                            />
                          </div>
                        </div>

                        <div className={`p-3 overall-summary mt-10 summary-section ${this.props.state.path === "dashboard" ? "Qsummary" : ''}`}>
                          <h3>Overall Question Summary</h3>
                          <ul className="list-unstyled">
                            <div className="row">
                              <div className="col-md-6">
                                <li>
                                  <QuestionButton
                                    btnName={this.state.currentQuestion + 1}
                                    className="btn btn-icon btn-primary m-2"
                                    to="#"
                                  />
                                  <span>Current</span>
                                </li>
                              </div>
                              <div className="col-md-6">
                                <li>
                                  <QuestionButton
                                    btnName={this.state.answeredQuestions.length}
                                    className="btn btn-icon btn-success m-2"
                                    to="#"
                                  />
                                  <span>Answered</span>
                                </li>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <li>
                                  <QuestionButton
                                    btnName={
                                      this.state.questions.length -
                                      this.state.answeredQuestions.length
                                    }
                                    className="btn btn-icon btn-unanswered-question m-2"
                                    to="#"
                                  />
                                  <span>Unanswered</span>
                                </li>
                              </div>
                              <div className="col-md-6">
                                <li>
                                  <QuestionButton
                                    btnName={this.state.needToRevise}
                                    className="btn btn-icon btn-warning m-2"
                                    to="#"
                                  />
                                  <span>Reviewed</span>
                                </li>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </div>

          {/* Import Questions Modal */}
          <SubmitModal
            // modalTitle="Are you sure you want to finish test?"
            // modalId="import-questions"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => this.setState({ isSubmitModalVisible: false })}
            show={isSubmitModalVisible}
          >
            <SubmitModal.Header>
              <SubmitModal.Title>Are you sure you want to finish the test?</SubmitModal.Title>
            </SubmitModal.Header>
            <SubmitModal.Body>
              <div className="row mt-10 mb-10">
                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <div className="d-flex flex-column flex-center">
                    <div className="symbol symbol-70 symbol-circle symbol-success overflow-hidden">
                      <span className="symbol-label font-size-h1">
                        {this.state.answeredQuestions.length}
                      </span>
                    </div>
                    <div className="font-weight-bold text-dark-75 mt-4 popup-f-btn">
                      Answered Questions
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <div className="d-flex flex-column flex-center">
                    <div className="symbol symbol-70 symbol-circle overflow-hidden">
                      <span className="symbol-label font-size-h1">
                        {this.state.questions.length -
                          (this.state.answeredQuestions.length)}
                      </span>
                    </div>
                    <div className="font-weight-bold text-dark-75 mt-4 popup-f-btn">
                      Unanswered Questions
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                  <div className="d-flex flex-column flex-center">
                    <div className="symbol symbol-70 symbol-circle symbol-warning overflow-hidden">
                      <span className="symbol-label font-size-h1">
                        {this.state.needToRevise}
                      </span>
                    </div>
                    <div className="font-weight-bold text-dark-75 mt-4 popup-f-btn">
                      Reviewed Questions
                    </div>
                  </div>
                </div>
              </div>
            </SubmitModal.Body>

            <SubmitModal.Footer>
              {/* <Button className="btn btn-outline-primary font-weight-bold btn-lg pr-8 mr-4 popup-f-b-btn" btnName="Review"/> */}
              <QuestionButton
                type="button"
                className="btn btn-primary font-weight-bold btn-lg pr-8 mr-4 popup-f-b-btn"
                id="kt_blockui_card_custom_text_2"
                btnName="Submit and Finish Test"
                onClick={this.handleSubmitTest}
              />
              <QuestionButton
                className="btn btn-light-primary font-weight-bold pr-8 btn-lg popup-f-b-btn popup-f-b-close-btn"
                dataDismiss="modal"
                btnName="Close"
                onClick={() => this.setState({ isSubmitModalVisible: false })}
              />
            </SubmitModal.Footer>
          </SubmitModal>
        </ContentWrapper>
      </>
    );
  }
}

const mapStateToActions = {
  getTestQuestionContent: getTestQuestionContent,
  submitAnswers: submitAnswers,
  getAllQuestionContents: getAllQuestionContents,
  getAllAnswerContents: getAllAnswerContents,
  getCandidateAnswers: getCandidateAnswers,
  getAllCandidateAnswerContents: getAllCandidateAnswerContents,
  refreshAssessmentStartPage: refreshAssessmentStartPage,
  getAutoShuffleFlag: getAutoShuffleFlag,
  loadQuestions: loadQuestions,
  loadAssessmentQuestions: loadAssessmentQuestions,
  finishTest: finishTest,
  onSetMarks: onSetMarks,
  saveMarks: saveMarks,
  finalizeGrade: finalizeGrade,
  getReport: getReport
};

function mapStateToProps(state) {
  return {
    test_loading: state.testReducer.loading,
    test_error: state.testReducer.error,
    questions: state.testReducer.questions,
    assessmentQuestions: state.testReducer.assessmentQuestions,
    auto_shuffle: state.testReducer.auto_shuffle,

    content_error: state.testPageReducer.error,
    content_success: state.testPageReducer.success,
    content_loading: state.testPageReducer.loading,
    questionContents: state.testPageReducer.questionContents,

    submission_uploading: state.submissionReducer.loading,
    finish_loading: state.submissionReducer.finish_loading,
    submission_error: state.submissionReducer.error,
    submission_success: state.submissionReducer.success,
    finish_success: state.submissionReducer.finish_success,
    finish_error: state.submissionReducer.finish_error,

    assessment: state.validationReducer.assessment,
    candidateAssessment: state.examReducer.assessment,
    exam: state.examReducer.assessment,
    candidate_id: state.validationReducer.candidate_id,
    start_time: state.validationReducer.start_time,
    exam_duration: state.validationReducer.exam_duration,

    candidate_exam_start_time: state.validationReducer.candidate_exam_start_time,
    candidate_exam_duration: state.validationReducer.candidate_exam_duration,
    candidate_exam_buffer_time: state.validationReducer.candidate_exam_buffer_time,
    candidate_exam_time_limit: state.validationReducer.candidate_exam_time_limit,

    answerMap: state.testReducer.candidateAnswerContentMap,
    candidateAnswerContents: state.testReducer.candidateAnswerContents,
    answerContents: state.testReducer.answerContents,
    candidateAnswers: state.testReducer.candidateAnswers,
    candidate: state.testReducer.candidate,

    gradeError: state.gradeReducer.error,
    gradeLoading: state.gradeReducer.loading,

    report: state.examReducer.report,
  };
}

export default connect(mapStateToProps, mapStateToActions)(PaperQuestions);
