import i18n from "../assets/i18n/118n";

export const Nav = {
  ROOT: {
    Path: "/",
  },
  HOME: {
    Name: i18n.labels.home,
    Path: "/home",
  },
  DASHBOARD: {
    Name: i18n.labels.dashboard,
    Path: "/dashboard",
  },
  SITE_ADMINISTRATION: {
    Name: i18n.labels.site_administration,
    Path: "/siteAdministration",
  },
  PENDING_PAYMENTS: {
    Name: i18n.labels.pending_payments,
    Path: "/pendingPayments",
  },
  PENDING_REGISTRATIONS: {
    Name: i18n.labels.pending_registrations,
    Path: "/pendingRegistrations",
  },
  LOG_OUT: {
    Name: i18n.labels.log_out,
    Path: "/login",
  },
  USERS: {
    Name: i18n.labels.users,
    Path: "/siteAdministration/users/",
  },
  USERS_SEARCH: {
    Name: i18n.labels.user_search,
    Path: "/siteAdministration/users/search",
  },
  USERS_VIEW: {
    Name: i18n.labels.user_view,
    Path: "/siteAdministration/users/view",
  },
  USERS_EDIT: {
    Name: i18n.labels.user_edit,
    Path: "/siteAdministration/users/edit",
  },
  USERS_ADD: {
    Name: i18n.labels.user_add,
    Path: "/siteAdministration/users/add",
  },
  USERS_ROLES: {
    Name: i18n.labels.user_roles,
    Path: "/siteAdministration/user_role",
  },
  USERS_ROLES_SEARCH: {
    Name: i18n.labels.user_roles_search,
    Path: "/siteAdministration/user_role/search",
  },
  USERS_ROLES_VIEW: {
    Name: i18n.labels.user_roles_view,
    Path: "/siteAdministration/user_role/view",
  },
  USERS_ROLES_ADD: {
    Name: i18n.labels.user_roles_add,
    Path: "/siteAdministration/user_role/add",
  },
  ROLES: {
    Name: i18n.labels.roles,
    Path: "/siteAdministration/role",
  },
  ROLES_SEARCH: {
    Name: i18n.labels.role_search,
    Path: "/siteAdministration/role/search",
  },
  ROLES_VIEW: {
    Name: i18n.labels.role_view,
    Path: "/siteAdministration/role/view",
  },
  ROLES_ADD: {
    Name: i18n.labels.role_add,
    Path: "/siteAdministration/role/add",
  },
  ROLE_PRIVILEGES: {
    Name: i18n.labels.role_privileges,
    Path: "/siteAdministration/role_privilege",
  },
  ROLE_PRIVILEGES_SEARCH: {
    Name: i18n.labels.role_privileges_search,
    Path: "/siteAdministration/role_privilege/search",
  },
  ROLE_PRIVILEGES_VIEW: {
    Name: i18n.labels.role_privileges_view,
    Path: "/siteAdministration/role_privilege/view",
  },
  ROLE_PRIVILEGES_ADD: {
    Name: i18n.labels.role_privileges_add,
    Path: "/siteAdministration/role_privilege/add",
  },
  PRIVILEGES: {
    Name: i18n.labels.privilege,
    Path: "/siteAdministration/privilege",
  },
  PRIVILEGES_SEARCH: {
    Name: i18n.labels.privilege_search,
    Path: "/siteAdministration/privilege/search",
  },
  PRIVILEGES_VIEW: {
    Name: i18n.labels.privilege_view,
    Path: "/siteAdministration/privilege/view",
  },
  SYSTEM_SETTINGS: {
    Name: i18n.labels.system_settings,
    Path: "/siteAdministration/settings",
  },
  SYSTEM_SETTINGS_TIMEZONE: {
    Name: i18n.labels.settings_timezone,
    Path: "/siteAdministration/settings/timezone",
  },
};

export const TermsAgreementConstants = {
  AGREEMENT_REQUEST: "AGREEMENT_REQUEST",
  AGREEMENT_SUCCESS: "AGREEMENT_SUCCESS",
  AGREEMENT_FAILURE: "AGREEMENT_FAILURE",
};

export const roleConstants = {
  CREATE_REQUEST: "ROLE_CREATE_REQUEST",
  CREATE_SUCCESS: "ROLE_CREATE_SUCCESS",
  CREATE_FAILURE: "ROLE_CREATE_FAILURE",

  GET_ALL_REQUEST: "ROLE_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "ROLE_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "ROLE_GET_ALL_FAILURE",

  GET_PRIVILEGED_ROLES_REQUEST: "GET_PRIVILEGED_ROLES_REQUEST",
  GET_PRIVILEGED_ROLES_SUCCESS: "GET_PRIVILEGED_ROLES_SUCCESS",
  GET_PRIVILEGED_ROLES_FAILURE: "GET_PRIVILEGED_ROLES_FAILURE",

  UPDATE_REQUEST: "ROLE_UPDATE_REQUEST",
  UPDATE_SUCCESS: "ROLE_UPDATE_SUCCESS",
  UPDATE_FAILURE: "ROLE_UPDATE_FAILURE",

  DELETE_REQUEST: "ROLE_DELETE_REQUEST",
  DELETE_SUCCESS: "ROLE_DELETE_SUCCESS",
  DELETE_FAILURE: "ROLE_DELETE_FAILURE",

  SELECTED_ROLE: "ROLE_SELECTED",
};

export const privilegeConstants = {
  GET_ALL_REQUEST: "PRIVILEGE_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "PRIVILEGE_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "PRIVILEGE_GET_ALL_FAILURE",

  SELECTED_PRIVILEGES: "PRIVILEGE_SELECTED",
};

export const userRoleConstants = {
  CREATE_REQUEST: "USER_ROLE_CREATE_REQUEST",
  CREATE_SUCCESS: "USER_ROLE_CREATE_SUCCESS",
  CREATE_FAILURE: "USER_ROLE_CREATE_FAILURE",

  GET_ALL_REQUEST: "USER_ROLE_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "USER_ROLE_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "USER_ROLE_GET_ALL_FAILURE",

  UPDATE_REQUEST: "USER_ROLE_UPDATE_REQUEST",
  UPDATE_SUCCESS: "USER_ROLE_UPDATE_SUCCESS",
  UPDATE_FAILURE: "USER_ROLE_UPDATE_FAILURE",

  GET_PRIVILEGED_USER_ROLES_REQUEST: "GET_PRIVILEGED_USER_ROLES_REQUEST",
  GET_PRIVILEGED_USER_ROLES_SUCCESS: "GET_PRIVILEGED_USER_ROLES_SUCCESS",
  GET_PRIVILEGED_USER_ROLES_FAILURE: "GET_PRIVILEGED_USER_ROLES_FAILURE",

  DELETE_REQUEST: "USER_ROLE_DELETE_REQUEST",
  DELETE_SUCCESS: "USER_ROLE_DELETE_SUCCESS",
  DELETE_FAILURE: "USER_ROLE_DELETE_FAILURE",

  SELECTED_USER_ROLE: "USER_ROLE_SELECTED",
};

export const rolePrivilegeConstants = {
  CREATE_REQUEST: "ROLE_PRIVILEGE_CREATE_REQUEST",
  CREATE_SUCCESS: "ROLE_PRIVILEGE_CREATE_SUCCESS",
  CREATE_FAILURE: "ROLE_PRIVILEGE_CREATE_FAILURE",

  GET_ALL_REQUEST: "ROLE_PRIVILEGE_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "ROLE_PRIVILEGE_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "ROLE_PRIVILEGE_GET_ALL_FAILURE",

  UPDATE_REQUEST: "ROLE_PRIVILEGE_UPDATE_REQUEST",
  UPDATE_SUCCESS: "ROLE_PRIVILEGE_UPDATE_SUCCESS",
  UPDATE_FAILURE: "ROLE_PRIVILEGE_UPDATE_FAILURE",

  DELETE_REQUEST: "ROLE_PRIVILEGE_DELETE_REQUEST",
  DELETE_SUCCESS: "ROLE_PRIVILEGE_DELETE_SUCCESS",
  DELETE_FAILURE: "ROLE_PRIVILEGE_DELETE_FAILURE",

  SELECTED_ROLE_PRIVILEGE: "ROLE_PRIVILEGE_SELECTED",
};

export const assessmentConstants = {
  GET_ALL_REQUEST: "ASSESSMENT_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "ASSESSMENT_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "ASSESSMENT_GET_ALL_FAILURE",
};

export const loginLoaderConstants = {
  REQUEST: "LOADING_REQUEST",
  ERROR: "LOADING_ERROR",
  SUCCESS: "LOADING_SUCCESS",
};

export const userConstants = {
    GET_ME_REQUEST: 'GET_ME_REQUEST',
    GET_ME_SUCCESS: 'GET_ME_SUCCESS',
    GET_ME_FAILURE: 'GET_ME_FAILURE',

    GET_STUDENT_ME: 'GET_STUDENT_ME',
    GET_STUDENT_ME_REQUEST: 'GET_STUDENT_ME_REQUEST',
    GET_STUDENT_ME_SUCCESS: 'GET_STUDENT_ME_SUCCESS',
    GET_STUDENT_ME_FAILURE: 'GET_STUDENT_ME_FAILURE',

    GET_ALL_USERS: 'GET_ALL_USERS',
    GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_FAILURE: 'GET_ALL_USERS_FAILURE',

    GET_ALL_STUDENTUSERS: 'GET_ALL_STUDENTUSERS',
    GET_ALL_STUDENTUSERS_REQUEST: 'GET_ALL_STUDENTUSERS_REQUEST',
    GET_ALL_STUDENTUSERS_SUCCESS: 'GET_ALL_STUDENTUSERS_SUCCESS',
    GET_ALL_STUDENTUSERS_FAILURE: 'GET_ALL_STUDENTUSERS_FAILURE',

    GET_USERS: 'GET_USERS',
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',

    CREATE_USER_MAP: 'CREATE_USER_MAP',

    CREATE_USER_ROLES_MAP: 'CREATE_USER_ROLES_MAP',
    GET_USER_ROLES_REQUEST: 'GET_USER_ROLES_REQUEST',
    GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
    GET_USER_ROLES_FAILURE: 'GET_USER_ROLES_FAILURE',

    CREATE_STUDENTUSER_MAP: 'CREATE_STUDENTUSER_MAP',

    CREATE_STUDENT_USER_ROLES_MAP: 'CREATE_STUDENT_USER_ROLES_MAP',
    GET_ALL_STUDENT_USER_ROLES_REQUEST: 'GET_ALL_STUDENT_USER_ROLES_REQUEST',
    GET_ALL_STUDENT_USER_ROLES_SUCCESS: 'GET_ALL_STUDENT_USER_ROLES_SUCCESS',
    GET_ALL_STUDENT_USER_ROLES_FAILURE: 'GET_ALL_STUDENT_USER_ROLES_FAILURE',

    GET_ALL_ROLES_REQUEST: 'GET_ALL_ROLES_REQUEST',
    GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
    GET_ALL_ROLES_FAILURE: 'GET_ALL_ROLES_FAILURE'
};

export const authenticationConstants = {
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    LOGOUT: 'LOGOUT',

    TOKEN_REQUEST: 'ACCESS_TOKEN_REQUEST',
    TOKEN_SUCCESS: 'ACCESS_TOKEN_SUCCESS',
    TOKEN_FAILURE: 'ACCESS_TOKEN_FAILURE',
};

export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const federationConstants = {
    MICROSOFT: 'MicrosoftLogin',
    GOOGLE: 'GoogleLogin',
    FACEBOOK: 'FacebookLogin'
};

export const userTypes = {
  GET_ME_REQUEST: 'GET_ME_REQUEST',
  GET_ME_SUCCESS: 'GET_ME_SUCCESS',
  GET_ME_FAILURE: 'GET_ME_FAILURE',

  GET_ALL_USERS: 'GET_ALL_USERS',
  GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
  GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_FAILURE: 'GET_ALL_FAILURE',

  GET_ALL_STAFFUSERS: 'GET_ALL_STAFFUSERS',
  GET_ALL_STAFFUSERS_REQUEST: 'GET_ALL_STAFFUSERS_REQUEST',
  GET_ALL_STAFFUSERS_SUCCESS: 'GET_ALL_STAFFUSERS_SUCCESS',
  GET_ALL_STAFFUSERS_FAILURE: 'GET_ALL_STAFFUSERS_FAILURE',

  GET_ALL_STUDENTUSERS: 'GET_ALL_STUDENTUSERS',
  GET_ALL_STUDENTUSERS_REQUEST: 'GET_ALL_STUDENTUSERS_REQUEST',
  GET_ALL_STUDENTUSERS_SUCCESS: 'GET_ALL_STUDENTUSERS_SUCCESS',
  GET_ALL_STUDENTUSERS_FAILURE: 'GET_ALL_STUDENTUSERS_FAILURE',

  GET_TEACHER_ME: 'GET_TEACHER_ME',
  GET_TEACHER_ME_REQUEST: 'GET_TEACHER_ME_REQUEST',
  GET_TEACHER_ME_SUCCESS: 'GET_TEACHER_ME_SUCCESS',
  GET_TEACHER_ME_FAILURE: 'GET_TEACHER_ME_FAILURE',

  GET_TEACHER_DETAILS: 'GET_TEACHER_DETAILS',
  GET_TEACHER_DETAILS_REQUEST: 'GET_TEACHER_DETAILS_REQUEST',
  GET_TEACHER_DETAILS_SUCCESS: 'GET_TEACHER_DETAILS_SUCCESS',
  GET_TEACHER_DETAILS_FAILURE: 'GET_TEACHER_DETAILS_FAILURE',

  GET_ALL_TEACHERS: 'GET_ALL_TEACHERS',
  GET_ALL_TEACHERS_REQUEST: 'GET_ALL_TEACHERS_REQUEST',
  GET_ALL_TEACHERS_SUCCESS: 'GET_ALL_TEACHERS_SUCCESS',
  GET_ALL_TEACHERS_FAILURE: 'GET_ALL_TEACHERS_FAILURE',

  GET_STUDENT_DETAILS: 'GET_STUDENT_DETAILS',
  GET_STUDENT_DETAILS_REQUEST: 'GET_STUDENT_DETAILS_REQUEST',
  GET_STUDENT_DETAILS_SUCCESS: 'GET_STUDENT_DETAILS_SUCCESS',
  GET_STUDENT_DETAILS_FAILURE: 'GET_STUDENT_DETAILS_FAILURE',

  GET_USERS: 'GET_USERS',
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',

  CREATE_USER_MAP: 'CREATE_USER_MAP',
   
  CREATE_STAFFUSER_MAP: 'CREATE_STAFFUSER_MAP',

  CREATE_STUDENTUSER_MAP: 'CREATE_STUDENTUSER_MAP',

  CREATE_USER_ROLES_MAP: 'CREATE_USER_ROLES_MAP',
  GET_USER_ROLES_REQUEST: 'GET_USER_ROLES_REQUEST',
  GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
  GET_USER_ROLES_FAILURE: 'GET_USER_ROLES_FAILURE',

  CREATE_STAFF_USER_ROLES_MAP: 'CREATE_STAFF_USER_ROLES_MAP',
  GET_STAFF_USER_ROLES_REQUEST: 'GET_STAFF_USER_ROLES_REQUEST',
  GET_STAFF_USER_ROLES_SUCCESS: 'GET_STAFF_USER_ROLES_SUCCESS',
  GET_STAFF_USER_ROLES_FAILURE: 'GET_STAFF_USER_ROLES_FAILURE',

  CREATE_STUDENT_USER_ROLES_MAP: 'CREATE_STUDENT_USER_ROLES_MAP',
  GET_ALL_STUDENT_USER_ROLES_REQUEST: 'GET_ALL_STUDENT_USER_ROLES_REQUEST',
  GET_ALL_STUDENT_USER_ROLES_SUCCESS: 'GET_ALL_STUDENT_USER_ROLES_SUCCESS',
  GET_ALL_STUDENT_USER_ROLES_FAILURE: 'GET_ALL_STUDENT_USER_ROLES_FAILURE',

  GET_ALL_ROLES_REQUEST: 'GET_ALL_ROLES_REQUEST',
  GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
  GET_ALL_ROLES_FAILURE: 'GET_ALL_ROLES_FAILURE'
};
export const createTypes = {
  CREATE_PAGE_REFRESH: 'CREATE_PAGE_REFRESH',
  SELECT_QUESTION_TYPE: 'SELECT_QUESTION_TYPE',
  SELECT_QUESTION_CATEGORY: 'SELECT_QUESTION_CATEGORY',
  SELECT_QUESTION_DIFFICULTY: 'SELECT_QUESTION_DIFFICULTY',
  SELECT_QUESTION_LANGUAGE: 'SELECT_QUESTION_LANGUAGE',
  SELECT_QUESTION_SOURCE: 'SELECT_QUESTION_SOURCE',
  SELECT_QUESTION_SUBJECT: 'SELECT_QUESTION_SUBJECT',
  SELECT_QUESTION_AUTHOR: 'SELECT_QUESTION_AUTHOR',
  SELECT_QUESTION_YEAR: 'SELECT_QUESTION_YEAR',
  SELECT_QUESTION_TERM: 'SELECT_QUESTION_TERM',

  GET_QUESTION_TYPES: 'GET_QUESTION_TYPES',
  GET_QUESTION_TYPES_REQUEST: 'GET_QUESTION_TYPES_REQUEST',
  GET_QUESTION_TYPES_SUCCESS: 'GET_QUESTION_TYPES_SUCCESS',
  GET_QUESTION_TYPES_FAILURE: 'GET_QUESTION_TYPES_FAILURE',

  GET_QUESTION_DIFFICULTY: 'GET_QUESTION_DIFFICULTY',
  GET_QUESTION_DIFFICULTY_REQUEST: 'GET_QUESTION_DIFFICULTY_REQUEST',
  GET_QUESTION_DIFFICULTY_SUCCESS: 'GET_QUESTION_DIFFICULTY_SUCCESS',
  GET_QUESTION_DIFFICULTY_FAILURE: 'GET_QUESTION_DIFFICULTY_FAILURE',

  GET_QUESTION_LANGUAGE: 'GET_QUESTION_LANGUAGE',
  GET_QUESTION_LANGUAGE_REQUEST: 'GET_QUESTION_LANGUAGE_REQUEST',
  GET_QUESTION_LANGUAGE_SUCCESS: 'GET_QUESTION_LANGUAGE_SUCCESS',
  GET_QUESTION_LANGUAGE_FAILURE: 'GET_QUESTION_LANGUAGE_FAILURE',

  GET_QUESTION_GRADE: 'GET_QUESTION_GRADE',
  GET_QUESTION_GRADE_REQUEST: 'GET_QUESTION_GRADE_REQUEST',
  GET_QUESTION_GRADE_SUCCESS: 'GET_QUESTION_GRADE_SUCCESS',
  GET_QUESTION_GRADE_FAILURE: 'GET_QUESTION_GRADE_FAILURE',

  GET_QUESTION_CHAPTER: 'GET_QUESTION_CHAPTER',
  GET_QUESTION_CHAPTER_REQUEST: 'GET_QUESTION_CHAPTER_REQUEST',
  GET_QUESTION_CHAPTER_SUCCESS: 'GET_QUESTION_CHAPTER_SUCCESS',
  GET_QUESTION_CHAPTER_FAILURE: 'GET_QUESTION_CHAPTER_FAILURE',

  GET_QUESTION_SOURCE: 'GET_QUESTION_SOURCE',
  GET_QUESTION_SOURCE_REQUEST: 'GET_QUESTION_SOURCE_REQUEST',
  GET_QUESTION_SOURCE_SUCCESS: 'GET_QUESTION_SOURCE_SUCCESS',
  GET_QUESTION_SOURCE_FAILURE: 'GET_QUESTION_SOURCE_FAILURE',

  GET_QUESTION_SUBJECT: 'GET_QUESTION_SUBJECT',
  GET_QUESTION_SUBJECT_REQUEST: 'GET_QUESTION_SUBJECT_REQUEST',
  GET_QUESTION_SUBJECT_SUCCESS: 'GET_QUESTION_SUBJECT_SUCCESS',
  GET_QUESTION_SUBJECT_FAILURE: 'GET_QUESTION_SUBJECT_FAILURE',

  GET_QUESTION_AUTHOR: 'GET_QUESTION_AUTHOR',
  GET_QUESTION_AUTHOR_REQUEST: 'GET_QUESTION_AUTHOR_REQUEST',
  GET_QUESTION_AUTHOR_SUCCESS: 'GET_QUESTION_AUTHOR_SUCCESS',
  GET_QUESTION_AUTHOR_FAILURE: 'GET_QUESTION_AUTHOR_FAILURE',

  GET_QUESTION_YEAR: 'GET_QUESTION_YEAR',
  GET_QUESTION_YEAR_REQUEST: 'GET_QUESTION_YEAR_REQUEST',
  GET_QUESTION_YEAR_SUCCESS: 'GET_QUESTION_YEAR_SUCCESS',
  GET_QUESTION_YEAR_FAILURE: 'GET_QUESTION_YEAR_FAILURE',

  GET_QUESTION_TERM: 'GET_QUESTION_TERM',
  GET_QUESTION_TERM_REQUEST: 'GET_QUESTION_TERM_REQUEST',
  GET_QUESTION_TERM_SUCCESS: 'GET_QUESTION_TERM_SUCCESS',
  GET_QUESTION_TERM_FAILURE: 'GET_QUESTION_TERM_FAILURE',

  GET_QUESTION_CATEGORIES: 'GET_QUESTION_CATEGORIES',
  GET_QUESTION_CATEGORIES_REQUEST: 'GET_QUESTION_CATEGORIES_REQUEST',
  GET_QUESTION_CATEGORIES_SUCCESS: 'GET_QUESTION_CATEGORIES_SUCCESS',
  GET_QUESTION_CATEGORIES_FAILURE: 'GET_QUESTION_CATEGORIES_FAILURE',

  GET_ALL_QUESTION_CATEGORIES: 'GET_ALL_QUESTION_CATEGORIES',
  GET_ALL_QUESTION_CATEGORIES_REQUEST: 'GET_ALL_QUESTION_CATEGORIES_REQUEST',
  GET_ALL_QUESTION_CATEGORIES_SUCCESS: 'GET_ALL_QUESTION_CATEGORIES_SUCCESS',
  GET_ALL_QUESTION_CATEGORIES_FAILURE: 'GET_ALL_QUESTION_CATEGORIES_FAILURE',

  ADD_QUESTION_CATEGORY: 'ADD_QUESTION_CATEGORY',
  ADD_QUESTION_CATEGORY_REQUEST: 'ADD_QUESTION_CATEGORY_REQUEST',
  ADD_QUESTION_CATEGORY_SUCCESS: 'ADD_QUESTION_CATEGORY_SUCCESS',
  ADD_QUESTION_CATEGORY_FAILURE: 'ADD_QUESTION_CATEGORY_FAILURE',

  CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: 'CREATE_CATEGORY_FAILURE',
};

export const assessmentTypes = {
  CREATE_ASSESSMENT: 'CREATE_ASSESSMENT',
  CREATE_ASSESSMENT_REQUEST: 'CREATE_ASSESSMENT_REQUEST',
  CREATE_ASSESSMENT_SUCCESS: 'CREATE_ASSESSMENT_SUCCESS',
  CREATE_ASSESSMENT_FAILURE: 'CREATE_ASSESSMENT_FAILURE',
  CREATE_ASSESSMENT_REFRESH: 'CREATE_ASSESSMENT_REFRESH',

  EDIT_ASSESSMENT: 'EDIT_ASSESSMENT',
  EDIT_ASSESSMENT_REQUEST: 'EDIT_ASSESSMENT_REQUEST',
  EDIT_ASSESSMENT_SUCCESS: 'EDIT_ASSESSMENT_SUCCESS',
  EDIT_ASSESSMENT_FAILURE: 'EDIT_ASSESSMENT_FAILURE',

  DELETE_ASSESSMENT: 'DELETE_ASSESSMENT',
  DELETE_ASSESSMENT_REQUEST: 'DELETE_ASSESSMENT_REQUEST',
  DELETE_ASSESSMENT_SUCCESS: 'DELETE_ASSESSMENT_SUCCESS',
  DELETE_ASSESSMENT_FAILURE: 'DELETE_ASSESSMENT_FAILURE',

  GET_ASSESSMENT_LANGUAGE: 'GET_ASSESSMENT_LANGUAGE',
  GET_ASSESSMENT_LANGUAGE_REQUEST: 'GET_ASSESSMENT_LANGUAGE_REQUEST',
  GET_ASSESSMENT_LANGUAGE_SUCCESS: 'GET_ASSESSMENT_LANGUAGE_SUCCESS',
  GET_ASSESSMENT_LANGUAGE_FAILURE: 'GET_ASSESSMENT_LANGUAGE_FAILURE',

  
  GET_ASSESSMENT_DIFFICULTY: 'GET_ASSESSMENT_DIFFICULTY',
  GET_ASSESSMENT_DIFFICULTY_REQUEST: 'GET_ASSESSMENT_DIFFICULTY_REQUEST',
  GET_ASSESSMENT_DIFFICULTY_SUCCESS: 'GET_ASSESSMENT_DIFFICULTY_SUCCESS',
  GET_ASSESSMENT_DIFFICULTY_FAILURE: 'GET_ASSESSMENT_DIFFICULTY_FAILURE',

  
  GET_ASSESSMENT_CATEGORY: 'GET_ASSESSMENT_CATEGORY',
  GET_ASSESSMENT_CATEGORY_REQUEST: 'GET_ASSESSMENT_CATEGORY_REQUEST',
  GET_ASSESSMENT_CATEGORY_SUCCESS: 'GET_ASSESSMENT_CATEGORY_SUCCESS',
  GET_ASSESSMENT_CATEGORY_FAILURE: 'GET_ASSESSMENT_CATEGORY_FAILURE',

  GET_ASSESSMENT_ATTEMPT: 'GET_ASSESSMENT_ATTEMPT',
  GET_ASSESSMENT_ATTEMPT_REQUEST: 'GET_ASSESSMENT_ATTEMPT_REQUEST',
  GET_ASSESSMENT_ATTEMPT_SUCCESS: 'GET_ASSESSMENT_ATTEMPT_SUCCESS',
  GET_ASSESSMENT_ATTEMPT_FAILURE: 'GET_ASSESSMENT_ATTEMPT_FAILURE',

  GET_ASSESSMENT_GRADE: 'GET_ASSESSMENT_GRADE',
  GET_ASSESSMENT_GRADE_REQUEST: 'GET_ASSESSMENT_GRADE_REQUEST',
  GET_ASSESSMENT_GRADE_SUCCESS: 'GET_ASSESSMENT_GRADE_SUCCESS',
  GET_ASSESSMENT_GRADE_FAILURE: 'GET_ASSESSMENT_GRADE_FAILURE',

  GET_ASSESSMENT_SUBJECT: 'GET_ASSESSMENT_SUBJECT',
  GET_ASSESSMENT_SUBJECT_REQUEST: 'GET_ASSESSMENT_SUBJECT_REQUEST',
  GET_ASSESSMENT_SUBJECT_SUCCESS: 'GET_ASSESSMENT_SUBJECT_SUCCESS',
  GET_ASSESSMENT_SUBJECT_FAILURE: 'GET_ASSESSMENT_SUBJECT_FAILURE',

  GET_ASSESSMENT_AUTHOR: 'GET_ASSESSMENT_AUTHOR',
  GET_ASSESSMENT_AUTHOR_REQUEST: 'GET_ASSESSMENT_AUTHOR_REQUEST',
  GET_ASSESSMENT_AUTHOR_SUCCESS: 'GET_ASSESSMENT_AUTHOR_SUCCESS',
  GET_ASSESSMENT_AUTHOR_FAILURE: 'GET_ASSESSMENT_AUTHOR_FAILURE',

  GET_ASSESSMENT_YEAR: 'GET_ASSESSMENT_YEAR',
  GET_ASSESSMENT_YEAR_REQUEST: 'GET_ASSESSMENT_YEAR_REQUEST',
  GET_ASSESSMENT_YEAR_SUCCESS: 'GET_ASSESSMENT_YEAR_SUCCESS',
  GET_ASSESSMENT_YEAR_FAILURE: 'GET_ASSESSMENT_YEAR_FAILURE',

  GET_ASSESSMENT_TERM: 'GET_ASSESSMENT_TERM',
  GET_ASSESSMENT_TERM_REQUEST: 'GET_ASSESSMENT_TERM_REQUEST',
  GET_ASSESSMENT_TERM_SUCCESS: 'GET_ASSESSMENT_TERM_SUCCESS',
  GET_ASSESSMENT_TERM_FAILURE: 'GET_ASSESSMENT_TERM_FAILURE',

  GET_ASSESSMENT_CHAPTER: 'GET_ASSESSMENT_CHAPTER',
  GET_ASSESSMENT_CHAPTER_REQUEST: 'GET_ASSESSMENT_CHAPTER_REQUEST',
  GET_ASSESSMENT_CHAPTER_SUCCESS: 'GET_ASSESSMENT_CHAPTER_SUCCESS',
  GET_ASSESSMENT_CHAPTER_FAILURE: 'GET_ASSESSMENT_CHAPTER_FAILURE',

  SEARCH_ASSESSMENT: 'SEARCH_ASSESSMENT',
  FETCH_ASSESSMENT: 'FETCH_ASSESSMENT',
  ERROR_ASSESSMENT: 'ERROR_ASSESSMENT',

  VIEW_ASSESSMENT: 'VIEW_ASSESSMENT',
  VIEW_ASSESSMENT_REQUEST: 'VIEW_ASSESSMENT_REQUEST',
  VIEW_ASSESSMENT_SUCCESS: 'VIEW_ASSESSMENT_SUCCESS',
  VIEW_ASSESSMENT_FAILURE: 'VIEW_ASSESSMENT_FAILURE',
  VIEW_ASSESSMENT_REFRESH: 'VIEW_ASSESSMENT_REFRESH',

  EDIT_DETAILS: 'EDIT_DETAILS',
  EDIT_DETAILS_REQUEST: 'EDIT_DETAILS_REQUEST',
  EDIT_DETAILS_SUCCESS: 'EDIT_DETAILS_SUCCESS',
  EDIT_DETAILS_FAILURE: 'EDIT_DETAILS_FAILURE',

  SCHEDULE_ASSESSMENT: 'SCHEDULE_ASSESSMENT',
  SCHEDULE_ASSESSMENT_REQUEST: 'SCHEDULE_ASSESSMENT_REQUEST',
  SCHEDULE_ASSESSMENT_REFRESH: 'SCHEDULE_ASSESSMENT_REFRESH',
  SCHEDULE_ASSESSMENT_SUCCESS: 'SCHEDULE_ASSESSMENT_SUCCESS',
  SCHEDULE_ASSESSMENT_FAILURE: 'SCHEDULE_ASSESSMENT_FAILURE',

  GET_SCHEDULE: 'GET_SCHEDULE',
  GET_SCHEDULE_REQUEST: 'GET_SCHEDULE_REQUEST',
  GET_SCHEDULE_SUCCESS: 'GET_SCHEDULE_SUCCESS',
  GET_SCHEDULE_FAILURE: 'GET_SCHEDULE_FAILURE',

  GET_ASSESSOR_REMARKS_REQUEST: 'GET_ASSESSOR_REMARKS_REQUEST',
  GET_ASSESSOR_REMARKS_SUCCESS: 'GET_ASSESSOR_REMARKS_SUCCESS',
  GET_ASSESSOR_REMARKS_FAILURE: 'GET_ASSESSOR_REMARKS_FAILURE',

  EDIT_SCHEDULE: ' EDIT_SCHEDULE',
  EDIT_SCHEDULE_REQUEST: 'EDIT_SCHEDULE_REQUEST',
  EDIT_SCHEDULE_REFRESH: 'EDIT_SCHEDULE_REFRESH',
  EDIT_SCHEDULE_SUCCESS: 'EDIT_SCHEDULE_SUCCESS',
  EDIT_SCHEDULE_FAILURE: 'EDIT_SCHEDULE_FAILURE',

  EDIT_CANDIDATES_ASSESSMENT: 'EDIT_CANDIDATES_ASSESSMENT',
  EDIT_CANDIDATES_ASSESSMENT_REQUEST: 'EDIT_CANDIDATES_ASSESSMENT_REQUEST',
  EDIT_CANDIDATES_ASSESSMENT_REFRESH: 'EDIT_CANDIDATES_ASSESSMENT_REFRESH',
  EDIT_CANDIDATES_ASSESSMENT_SUCCESS: 'EDIT_CANDIDATES_ASSESSMENT_SUCCESS',
  EDIT_CANDIDATES_ASSESSMENT_FAILURE: 'EDIT_CANDIDATES_ASSESSMENT_FAILURE',

  ASSIGN_ASSESSORS_ASSESSMENT: 'ASSIGN_ASSESSORS_ASSESSMENT',
  ASSIGN_ASSESSORS_ASSESSMENT_REQUEST: 'ASSIGN_ASSESSORS_ASSESSMENT_REQUEST',
  ASSIGN_ASSESSORS_ASSESSMENT_REFRESH: 'ASSIGN_ASSESSORS_ASSESSMENT_REFRESH',
  ASSIGN_ASSESSORS_ASSESSMENT_SUCCESS: 'ASSIGN_ASSESSORS_ASSESSMENT_SUCCESS',
  ASSIGN_ASSESSORS_ASSESSMENT_FAILURE: 'ASSIGN_ASSESSORS_ASSESSMENT_FAILURE',

  GET_ASSESSMENT_ASSESSORS: 'GET_ASSESSMENT_ASSESSORS',
  GET_ASSESSMENT_ASSESSORS_REQUEST: 'GET_ASSESSMENT_ASSESSORS_REQUEST',
  GET_ASSESSMENT_ASSESSORS_SUCCESS: 'GET_ASSESSMENT_ASSESSORS_SUCCESS',
  GET_ASSESSMENT_ASSESSORS_FAILURE: 'GET_ASSESSMENT_ASSESSORS_FAILURE',

  EDIT_ASSESSORS_ASSESSMENT: 'EDIT_ASSESSORS_ASSESSMENT',
  EDIT_ASSESSORS_ASSESSMENT_REFRESH: 'EDIT_ASSESSORS_ASSESSMENT_REFRESH',
  EDIT_ASSESSORS_ASSESSMENT_REQUEST: 'EDIT_ASSESSORS_ASSESSMENT_REQUEST',
  EDIT_ASSESSORS_ASSESSMENT_SUCCESS: 'EDIT_ASSESSORS_ASSESSMENT_SUCCESS',
  EDIT_ASSESSORS_ASSESSMENT_FAILURE: 'EDIT_ASSESSORS_ASSESSMENT_FAILURE',

  ADD_CANDIDATES_ASSESSMENT: 'ADD_CANDIDATES_ASSESSMENT',
  ADD_CANDIDATES_ASSESSMENT_REQUEST: 'ADD_CANDIDATES_ASSESSMENT_REQUEST',
  ADD_CANDIDATES_ASSESSMENT_REFRESH: 'ADD_CANDIDATES_ASSESSMENT_REFRESH',
  ADD_CANDIDATES_ASSESSMENT_SUCCESS: 'ADD_CANDIDATES_ASSESSMENT_SUCCESS',
  ADD_CANDIDATES_ASSESSMENT_FAILURE: 'ADD_CANDIDATES_ASSESSMENT_FAILURE',

  GET_CANDIDATES: 'GET_CANDIDATES',
  GET_CANDIDATES_REQUEST: 'GET_CANDIDATES_REQUEST',
  GET_CANDIDATES_SUCCESS: 'GET_CANDIDATES_SUCCESS',
  GET_CANDIDATES_FAILURE: 'GET_CANDIDATES_FAILURE',

  EDIT_CANDIDATES: ' EDIT_SCHEDULE',
  EDIT_CANDIDATES_REQUEST: 'EDIT_SCHEDULE_REQUEST',
  EDIT_CANDIDATES_REFRESH: 'EDIT_SCHEDULE_REFRESH',
  EDIT_CANDIDATES_SUCCESS: 'EDIT_SCHEDULE_SUCCESS',
  EDIT_CANDIDATES_FAILURE: 'EDIT_SCHEDULE_FAILURE',

  ADD_SCHEDULE_ASSESSMENT: 'ADD_SCHEDULE_ASSESSMENT',
  ADD_SCHEDULE_ASSESSMENT_REQUEST: 'ADD_SCHEDULE_ASSESSMENT_REQUEST',
  ADD_SCHEDULE_ASSESSMENT_REFRESH: 'ADD_SCHEDULE_ASSESSMENT_REFRESH',
  ADD_SCHEDULE_ASSESSMENT_SUCCESS: 'ADD_SCHEDULE_ASSESSMENT_SUCCESS',
  ADD_SCHEDULE_ASSESSMENT_FAILURE: 'ADD_SCHEDULE_ASSESSMENT_FAILURE',

  GET_ALL_QUESTIONS: 'GET_ALL_QUESTIONS',
  GET_ALL_QUESTIONS_REQUEST: 'GET_ALL_QUESTIONS_REQUEST',
  GET_ALL_QUESTIONS_SUCCESS: 'GET_ALL_QUESTIONS_SUCCESS',
  GET_ALL_QUESTIONS_FAILURE: 'GET_ALL_QUESTIONS_FAILURE',

  GET_ALL_ADD_QUESTIONS: 'GET_ALL_ADD_QUESTIONS',
  GET_ALL_ADD_QUESTIONS_REQUEST: 'GET_ALL_ADD_QUESTIONS_REQUEST',
  GET_ALL_ADD_QUESTIONS_SUCCESS: 'GET_ALL_ADD_QUESTIONS_SUCCESS',
  GET_ALL_ADD_QUESTIONS_FAILURE: 'GET_ALL_ADD_QUESTIONS_FAILURE',

  GET_ALL_QUESTIONS_CONTENTS_BY_TYPE: 'GET_ALL_QUESTIONS_CONTENTS_BY_TYPE',
  GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_REQUEST: 'GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_REQUEST',
  GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_SUCCESS: 'GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_SUCCESS',
  GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_FAILURE: 'GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_FAILURE',

  

  GET_CANDIDATE_ANSWERS: 'GET_CANDIDATE_ANSWERS',
  GET_CANDIDATE_ANSWERS_REQUEST: 'GET_CANDIDATE_ANSWERS_REQUEST',
  GET_CANDIDATE_ANSWERS_SUCCESS: 'GET_CANDIDATE_ANSWERS_SUCCESS',
  GET_CANDIDATE_ANSWERS_FAILURE: 'GET_CANDIDATE_ANSWERS_FAILURE',

  GET_QUESTION_CONTENT: 'GET_QUESTION_CONTENT',
  GET_QUESTION_CONTENT_REQUEST: 'GET_QUESTION_CONTENT_REQUEST',
  GET_QUESTION_CONTENT_SUCCESS: 'GET_QUESTION_CONTENT_SUCCESS',
  GET_QUESTION_CONTENT_FAILURE: 'GET_QUESTION_CONTENT_FAILURE',

  GET_CANDIDATE_REPORTS: 'GET_CANDIDATE_REPORTS',
  GET_CANDIDATE_REPORTS_REQUEST: 'GET_CANDIDATE_REPORTS_REQUEST',
  GET_CANDIDATE_REPORTS_SUCCESS: 'GET_CANDIDATE_REPORTS_SUCCESS',
  GET_CANDIDATE_REPORTS_FAILURE: 'GET_CANDIDATE_REPORTS_FAILURE',

  ADD_QUESTIONS_ASSESSMENT: 'ADD_QUESTIONS_ASSESSMENT',
  ADD_QUESTIONS_PAGE_REFRESH: 'ADD_QUESTIONS_PAGE_REFRESH',
  ADD_QUESTIONS_ASSESSMENT_REQUEST: 'ADD_QUESTIONS_ASSESSMENT_REQUEST',
  ADD_QUESTIONS_ASSESSMENT_REFRESH: 'ADD_QUESTIONS_ASSESSMENT_REFRESH',
  ADD_QUESTIONS_ASSESSMENT_SUCCESS: 'ADD_QUESTIONS_ASSESSMENT_SUCCESS',
  ADD_QUESTIONS_ASSESSMENT_FAILURE: 'ADD_QUESTIONS_ASSESSMENT_FAILURE',

  GET_ASSESSMENT_QUESTIONS: 'GET_ASSESSMENT_QUESTIONS',
  GET_ASSESSMENT_QUESTIONS_REQUEST: 'GET_ASSESSMENT_QUESTIONS_REQUEST',
  GET_ASSESSMENT_QUESTIONS_SUCCESS: 'GET_ASSESSMENT_QUESTIONS_SUCCESS',
  GET_ASSESSMENT_QUESTIONS_FAILURE: 'GET_ASSESSMENT_QUESTIONS_FAILURE',

  GET_ALL_ASSESSMENT_QUESTIONS: 'GET_ALL_ASSESSMENT_QUESTIONS',
  GET_ALL_ASSESSMENT_QUESTIONS_REQUEST: 'GET_ALL_ASSESSMENT_QUESTIONS_REQUEST',
  GET_ALL_ASSESSMENT_QUESTIONS_SUCCESS: 'GET_ALL_ASSESSMENT_QUESTIONS_SUCCESS',
  GET_ALL_ASSESSMENT_QUESTIONS_FAILURE: 'GET_ALL_ASSESSMENT_QUESTIONS_FAILURE',

  GET_SET_QUESTION: 'GET_SET_QUESTION',
  GET_SET_QUESTION_REQUEST: 'GET_SET_QUESTION_REQUEST',
  GET_SET_QUESTION_SUCCESS: 'GET_SET_QUESTION_SUCCESS',
  GET_SET_QUESTION_FAILURE: 'GET_SET_QUESTION_FAILURE',

  GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME: 'GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME',
  GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_REQUEST: 'GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_REQUEST',
  GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_SUCCESS: 'GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_SUCCESS',
  GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_FAILURE: 'GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_FAILURE',

  REMOVE_QUESTIONS_ADDED_BY_ME: 'REMOVE_QUESTIONS_ADDED_BY_ME',
  REMOVE_QUESTIONS_ADDED_BY_ME_REQUEST: 'REMOVE_QUESTIONS_ADDED_BY_ME_REQUEST',
  REMOVE_QUESTIONS_ADDED_BY_ME_REFRESH: 'REMOVE_QUESTIONS_ADDED_BY_ME_REFRESH',
  REMOVE_QUESTIONS_ADDED_BY_ME_SUCCESS: 'REMOVE_QUESTIONS_ADDED_BY_ME_SUCCESS',
  REMOVE_QUESTIONS_ADDED_BY_ME_FAILURE: 'REMOVE_QUESTIONS_ADDED_BY_ME_FAILURE',

  SAVE_MARK_ALLOCATIONS: 'SAVE_MARK_ALLOCATIONS',
  SAVE_MARK_ALLOCATIONS_REQUEST: 'SAVE_MARK_ALLOCATIONS_REQUEST',
  SAVE_MARK_ALLOCATIONS_SUCCESS: 'SAVE_MARK_ALLOCATIONS_SUCCESS',
  SAVE_MARK_ALLOCATIONS_FAILURE: 'SAVE_MARK_ALLOCATIONS_FAILURE',

  SAVE_MARKS_REQUEST: 'SAVE_MARKS_REQUEST',
  SAVE_MARKS_SUCCESS: 'SAVE_MARKS_SUCCESS',
  SAVE_MARKS_FAILURE: 'SAVE_MARKS_FAILURE',

  FINALIZE_ALL: 'FINALIZE_ALL',
  FINALIZE_ALL_REQUEST: 'FINALIZE_ALL_REQUEST',
  FINALIZE_ALL_SUCCESS: 'FINALIZE_ALL_SUCCESS',
  FINALIZE_ALL_FAILURE: 'FINALIZE_ALL_FAILURE',

  FINALIZE_GRADE: 'FINALIZE_GRADE',
  FINALIZE_GRADE_REQUEST: 'FINALIZE_GRADE_REQUEST',
  FINALIZE_GRADE_SUCCESS: 'FINALIZE_GRADE_SUCCESS',
  FINALIZE_GRADE_FAILURE: 'FINALIZE_GRADE_FAILURE',

  GET_ALL_QUESTION_CONTENTS: 'GET_ALL_QUESTION_CONTENTS',
  GET_ALL_QUESTION_CONTENTS_REQUEST: 'GET_ALL_QUESTION_CONTENTS_REQUEST',
  GET_ALL_QUESTION_CONTENTS_SUCCESS: 'GET_ALL_QUESTION_CONTENTS_SUCCESS',
  GET_ALL_QUESTION_CONTENTS_FAILURE: 'GET_ALL_QUESTION_CONTENTS_FAILURE',

  GET_ALL_CANDIDATE_ANSWER_CONTENTS: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS',
  GET_ALL_CANDIDATE_ANSWER_CONTENTS_REQUEST: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS_REQUEST',
  GET_ALL_CANDIDATE_ANSWER_CONTENTS_SUCCESS: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS_SUCCESS',
  GET_ALL_CANDIDATE_ANSWER_CONTENTS_FAILURE: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS_FAILURE',

  GET_ALL_ANSWER_CONTENTS: 'GET_ALL_ANSWER_CONTENTS',
  GET_ALL_ANSWER_CONTENTS_REQUEST: 'GET_ALL_ANSWER_CONTENTS_REQUEST',
  GET_ALL_ANSWER_CONTENTS_SUCCESS: 'GET_ALL_ANSWER_CONTENTS_SUCCESS',
  GET_ALL_ANSWER_CONTENTS_FAILURE: 'GET_ALL_ANSWER_CONTENTS_FAILURE',

  DELETE_CANDIDATE: 'DELETE_CANDIDATE',
  DELETE_CANDIDATE_REQUEST: 'DELETE_CANDIDATE_REQUEST',
  DELETE_CANDIDATE_SUCCESS: 'DELETE_CANDIDATE_SUCCESS',
  DELETE_CANDIDATE_FAILURE: 'DELETE_CANDIDATE_FAILURE',

  FINALIZE_ASSESSMENT: 'FINALIZE_ASSESSMENT',
  FINALIZE_ASSESSMENT_REQUEST: 'FINALIZE_ASSESSMENT_REQUEST',
  FINALIZE_ASSESSMENT_REFRESH: 'FINALIZE_ASSESSMENT_REFRESH',
  FINALIZE_ASSESSMENT_SUCCESS: 'FINALIZE_ASSESSMENT_SUCCESS',
  FINALIZE_ASSESSMENT_FAILURE: 'FINALIZE_ASSESSMENT_FAILURE',

  REORDER_QUESTIONS: 'REORDER_QUESTIONS',

  SET_MARKS: 'SET_MARKS'
}

export const quesGradeTypes = {
  LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

  DELETE_GRADE_REQUEST: 'DELETE_GRADE_REQUEST',
  DELETE_GRADE_SUCCESS: 'DELETE_GRADE_SUCCESS',
  DELETE_GRADE_FAILURE: 'DELETE_GRADE_FAILURE',

  UPDATE_GRADE_REQUEST: 'UPDATE_GRADE_REQUEST',
  UPDATE_GRADE_SUCCESS: 'UPDATE_GRADE_SUCCESS',
  UPDATE_GRADE_FAILURE: 'UPDATE_GRADE_FAILURE',

  CREATE_GRADE_REQUEST: 'CREATE_GRADE_REQUEST',
  CREATE_GRADE_SUCCESS: 'CREATE_GRADE_SUCCESS',
  CREATE_GRADE_FAILURE: 'CREATE_GRADE_FAILURE',

  GET_ALL_QUESTION_GRADES: 'GET_ALL_QUESTION_GRADES',
  GET_ALL_QUESTION_GRADES_REQUEST: 'GET_ALL_QUESTION_GRADES_REQUEST',
  GET_ALL_QUESTION_GRADES_SUCCESS: 'GET_ALL_QUESTION_GRADES_SUCCESS',
  GET_ALL_QUESTION_GRADES_FAILURE: 'GET_ALL_QUESTION_GRADES_FAILURE',
};


export const testConstants = {

    VALIDATE_CANDIDATE: 'VALIDATE_CANDIDATE',
    VALIDATE_CANDIDATE_REQUEST: 'VALIDATE_CANDIDATE_REQUEST',
    VALIDATE_CANDIDATE_SUCCESS: 'VALIDATE_CANDIDATE_SUCCESS',
    VALIDATE_CANDIDATE_FAILURE: 'VALIDATE_CANDIDATE_FAILURE',
    VALIDATE_CANDIDATE_REFRESH: 'VALIDATE_CANDIDATE_REFRESH',

    VIEW_TEST: 'VIEW_TEST',
    VIEW_TEST_REQUEST: 'VIEW_TEST_REQUEST',
    VIEW_TEST_SUCCESS: 'VIEW_TEST_SUCCESS',
    VIEW_TEST_FAILURE: 'VIEW_TEST_FAILURE',
    VIEW_TEST_REFRESH: 'VIEW_TEST_REFRESH',

    LOAD_EXAM_QUESTIONS: 'LOAD_EXAM_QUESTIONS',
    LOAD_EXAM_QUESTIONS_REQUEST: 'LOAD_EXAM_QUESTIONS_REQUEST',
    LOAD_EXAM_QUESTIONS_SUCCESS: 'LOAD_EXAM_QUESTIONS_SUCCESS',
    LOAD_EXAM_QUESTIONS_FAILURE: 'LOAD_EXAM_QUESTIONS_FAILURE',
    LOAD_EXAM_QUESTIONS_REFRESH: 'LOAD_EXAM_QUESTIONS_REFRESH',

    LOAD_ASSESSMENT_QUESTIONS: 'LOAD_ASSESSMENT_QUESTIONS',
    LOAD_ASSESSMENT_QUESTIONS_REQUEST: 'LOAD_ASSESSMENT_QUESTIONS_REQUEST',
    LOAD_ASSESSMENT_QUESTIONS_SUCCESS: 'LOAD_ASSESSMENT_QUESTIONS_SUCCESS',
    LOAD_ASSESSMENT_QUESTIONS_FAILURE: 'LOAD_ASSESSMENT_QUESTIONS_FAILURE',
    LOAD_ASSESSMENT_QUESTIONS_REFRESH: 'LOAD_ASSESSMENT_QUESTIONS_REFRESH',

    LOAD_TEST_PAGE: 'LOAD_TEST_PAGE',
    LOAD_TEST_PAGE_REQUEST: 'LOAD_TEST_PAGE_REQUEST',
    LOAD_TEST_PAGE_SUCCESS: 'LOAD_TEST_PAGE_SUCCESS',
    LOAD_TEST_PAGE_FAILURE: 'LOAD_TEST_PAGE_FAILURE',
    LOAD_TEST_PAGE_REFRESH: 'LOAD_TEST_PAGE_REFRESH',

    GET_TEST_QUESTION_CONTENT: 'GET_TEST_QUESTION_CONTENT',
    GET_TEST_QUESTION_CONTENT_REQUEST: 'GET_TEST_QUESTION_CONTENT_REQUEST',
    GET_TEST_QUESTION_CONTENT_SUCCESS: 'GET_TEST_QUESTION_CONTENT_SUCCESS',
    GET_TEST_QUESTION_CONTENT_FAILURE: 'GET_TEST_QUESTION_CONTENT_FAILURE',

    GET_TEST_QUESTION_ANSWER_CONTENTS: 'GET_TEST_QUESTION_ANSWER_CONTENTS',
    GET_TEST_QUESTION_ANSWER_CONTENTS_REQUEST: 'GET_TEST_QUESTION_ANSWER_CONTENTS_REQUEST',
    GET_TEST_QUESTION_ANSWER_CONTENTS_SUCCESS: 'GET_TEST_QUESTION_ANSWER_CONTENTS_SUCCESS',
    GET_TEST_QUESTION_ANSWER_CONTENTS_FAILURE: 'GET_TEST_QUESTION_ANSWER_CONTENTS_FAILURE',

    GET_PAGE_QUESTION_CONTENTS: 'GET_PAGE_QUESTION_CONTENTS',
    GET_PAGE_QUESTION_CONTENTS_REQUEST: 'GET_PAGE_QUESTION_CONTENTS_REQUEST',
    GET_PAGE_QUESTION_CONTENTS_SUCCESS: 'GET_PAGE_QUESTION_CONTENTS_SUCCESS',
    GET_PAGE_QUESTION_CONTENTS_FAILURE: 'GET_PAGE_QUESTION_CONTENTS_FAILURE',

    START_EXAM: 'START_EXAM',
    START_EXAM_REQUEST: 'START_EXAM_REQUEST',
    START_EXAM_SUCCESS: 'START_EXAM_SUCCESS',
    START_EXAM_FAILURE: 'START_EXAM_FAILURE',

    UPDATE_CANDIDATE_STATUS: 'UPDATE_CANDIDATE_STATUS',
    UPDATE_CANDIDATE_STATUS_REQUEST: 'UPDATE_CANDIDATE_STATUS_REQUEST',
    UPDATE_CANDIDATE_STATUS_SUCCESS: 'UPDATE_CANDIDATE_STATUS_SUCCESS',
    UPDATE_CANDIDATE_STATUS_FAILURE: 'UPDATE_CANDIDATE_STATUS_FAILURE',

    SUBMIT_ANSWER: 'SUBMIT_ANSWER',
    SUBMIT_ANSWER_REQUEST: 'SUBMIT_ANSWER_REQUEST',
    SUBMIT_ANSWER_SUCCESS: 'SUBMIT_ANSWER_SUCCESS',
    SUBMIT_ANSWER_FAILURE: 'SUBMIT_ANSWER_FAILURE',
    SUBMIT_ANSWER_REFRESH: 'SUBMIT_ANSWER_REFRESH',

    SUBMIT_ANSWERS_FOR_PAGE: 'SUBMIT_ANSWERS_FOR_PAGE',
    SUBMIT_ANSWERS_FOR_PAGE_REQUEST: 'SUBMIT_ANSWERS_FOR_PAGE_REQUEST',
    SUBMIT_ANSWERS_FOR_PAGE_SUCCESS: 'SUBMIT_ANSWERS_FOR_PAGE_SUCCESS',
    SUBMIT_ANSWERS_FOR_PAGE_FAILURE: 'SUBMIT_ANSWERS_FOR_PAGE_FAILURE',

    GET_CANDIDATE_ANSWERS: 'GET_CANDIDATE_ANSWERS',
    GET_CANDIDATE_ANSWERS_REQUEST: 'GET_CANDIDATE_ANSWERS_REQUEST',
    GET_CANDIDATE_ANSWERS_SUCCESS: 'GET_CANDIDATE_ANSWERS_SUCCESS',
    GET_CANDIDATE_ANSWERS_FAILURE: 'GET_CANDIDATE_ANSWERS_FAILURE',
    CLEAR_CANDIDATE_ANSWERS: 'CLEAR_CANDIDATE_ANSWERS',

    SET_MARKS: 'SET_MARKS',

    SET_SELF_MARKS: 'SET_SELF_MARKS',

    SAVE_MARKS_REQUEST: 'SAVE_MARKS_REQUEST',
    SAVE_MARKS_SUCCESS: 'SAVE_MARKS_SUCCESS',
    SAVE_MARKS_FAILURE: 'SAVE_MARKS_FAILURE',

    FINALIZE_GRADE: 'FINALIZE_GRADE',
    FINALIZE_GRADE_REQUEST: 'FINALIZE_GRADE_REQUEST',
    FINALIZE_GRADE_SUCCESS: 'FINALIZE_GRADE_SUCCESS',
    FINALIZE_GRADE_FAILURE: 'FINALIZE_GRADE_FAILURE',


    SAVE_SELF_MARKS_REQUEST: 'SAVE_SELF_MARKS_REQUEST',
    SAVE_SELF_MARKS_SUCCESS: 'SAVE_SELF_MARKS_SUCCESS',
    SAVE_SELF_MARKS_FAILURE: 'SAVE_SELF_MARKS_FAILURE',

    FINALIZE_SELF_GRADE: 'FINALIZE_SELF_GRADE',
    FINALIZE_SELF_GRADE_REQUEST: 'FINALIZE_SELF_GRADE_REQUEST',
    FINALIZE_SELF_GRADE_SUCCESS: 'FINALIZE_SELF_GRADE_SUCCESS',
    FINALIZE_SELF_FAILURE: 'FINALIZE_SELF_FAILURE',
   
    GET_AUTO_SHUFFLE_REQUEST: 'GET_AUTO_SHUFFLE_REQUEST',
    GET_AUTO_SHUFFLE_SUCCESS: 'GET_AUTO_SHUFFLE_SUCCESS',
    GET_AUTO_SHUFFLE_FAILURE: 'GET_AUTO_SHUFFLE_FAILURE',

    GET_ALL_CANDIDATE_ANSWER_CONTENTS: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS',
    GET_ALL_CANDIDATE_ANSWER_CONTENTS_REQUEST: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS_REQUEST',
    GET_ALL_CANDIDATE_ANSWER_CONTENTS_SUCCESS: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS_SUCCESS',
    GET_ALL_CANDIDATE_ANSWER_CONTENTS_FAILURE: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS_FAILURE',

    FINISH_TEST: 'FINISH_TEST',
    FINISH_TEST_REQUEST: 'FINISH_TEST_REQUEST',
    FINISH_TEST_SUCCESS: 'FINISH_TEST_SUCCESS',
    FINISH_TEST_FAILURE: 'FINISH_TEST_FAILURE',

    GET_SAS_TOKEN_REQUEST: 'GET_SAS_TOKEN_REQUEST',
    GET_SAS_TOKEN_SUCCESS: 'GET_SAS_TOKEN_SUCCESS',
    GET_MEDIA_REQUEST: 'GET_MEDIA_REQUEST',
    GET_MEDIA_SUCCESS: 'GET_MEDIA_SUCCESS',
    GET_MEDIA_FAILURE: 'GET_MEDIA_FAILURE',

    GET_SAS_TOKEN_REQUEST_MANAGE: 'GET_SAS_TOKEN_REQUEST_MANAGE',
    GET_SAS_TOKEN_SUCCESS_MANAGE: 'GET_SAS_TOKEN_SUCCESS_MANAGE',
    GET_MEDIA_REQUEST_MANAGE: 'GET_MEDIA_REQUEST_MANAGE',
    GET_MEDIA_SUCCESS_MANAGE: 'GET_MEDIA_SUCCESS_MANAGE',
    GET_MEDIA_FAILURE_MANAGE: 'GET_MEDIA_FAILURE_MANAGE'
};

export const libraryConstants = {

    LIST_ASSESSMENTS: 'LIST_ASSESSMENTS',
    LIST_ASSESSMENTS_REQUEST: 'LIST_ASSESSMENTS_REQUEST',
    LIST_ASSESSMENTS_SUCCESS: 'LIST_ASSESSMENTS_SUCCESS',
    LIST_ASSESSMENTS_FAILURE: 'LIST_ASSESSMENTS_FAILURE',
    LIST_ASSESSMENTS_REFRESH: 'LIST_ASSESSMENTS_REFRESH',

    LIST_CHILD_ASSESSMENTS: 'LIST_CHILD_ASSESSMENTS',
    LIST_CHILD_ASSESSMENTS_REQUEST: 'LIST_CHILD_ASSESSMENTS_REQUEST',
    LIST_CHILD_ASSESSMENTS_SUCCESS: 'LIST_CHILD_ASSESSMENTS_SUCCESS',
    LIST_CHILD_ASSESSMENTS_FAILURE: 'LIST_CHILD_ASSESSMENTS_FAILURE',
    LIST_CHILD_ASSESSMENTS_REFRESH: 'LIST_CHILD_ASSESSMENTS_REFRESH',

    LIST_NODE_ASSESSMENTS: 'LIST_NODE_ASSESSMENTS',
    LIST_NODE_ASSESSMENTS_REQUEST: 'LIST_NODE_ASSESSMENTS_REQUEST',
    LIST_NODE_ASSESSMENTS_SUCCESS: 'LIST_NODE_ASSESSMENTS_SUCCESS',
    LIST_NODE_ASSESSMENTS_FAILURE: 'LIST_NODE_ASSESSMENTS_FAILURE',

    GET_ENROLLED_NODE_EXAMS: 'GET_ENROLLED_NODE_EXAMS',
    GET_ENROLLED_NODE_EXAMS_REQUEST: 'GET_ENROLLED_NODE_EXAMS_REQUEST',
    GET_ENROLLED_NODE_EXAMS_SUCCESS: 'GET_ENROLLED_NODE_EXAMS_SUCCESS',
    GET_ENROLLED_NODE_EXAMS_FAILURE: 'GET_ENROLLED_NODE_EXAMS_FAILURE',

    GET_CHILD_ENROLLED_NODE_EXAMS: 'GET_CHILD_ENROLLED_NODE_EXAMS',
    GET_CHILD_ENROLLED_NODE_EXAMS_REQUEST: 'GET_CHILD_ENROLLED_NODE_EXAMS_REQUEST',
    GET_CHILD_ENROLLED_NODE_EXAMS_SUCCESS: 'GET_CHILD_ENROLLED_NODE_EXAMS_SUCCESS',
    GET_CHILD_ENROLLED_NODE_EXAMS_FAILURE: 'GET_CHILD_ENROLLED_NODE_EXAMS_FAILURE',

    GET_ENROLLED_NODE_EXAMS_FOR_USER: 'GET_ENROLLED_NODE_EXAMS_FOR_USER',
    GET_ENROLLED_NODE_EXAMS_REQUEST_FOR_USER: 'GET_ENROLLED_NODE_EXAMS_REQUEST_FOR_USER',
    GET_ENROLLED_NODE_EXAMS_SUCCESS_FOR_USER: 'GET_ENROLLED_NODE_EXAMS_SUCCESS_FOR_USER',
    GET_ENROLLED_NODE_EXAMS_FAILURE_FOR_USER: 'GET_ENROLLED_NODE_EXAMS_FAILURE_FOR_USER',

    GET_ASSESSMENT: 'GET_ASSESSMENT',
    GET_ASSESSMENT_REQUEST: 'GET_ASSESSMENT_REQUEST',
    GET_ASSESSMENT_SUCCESS: 'GET_ASSESSMENT_SUCCESS',
    GET_ASSESSMENT_FAILURE: 'GET_ASSESSMENT_FAILURE',

    OPEN_ASSESSMENT: 'OPEN_ASSESSMENT',

    GET_REPORT: 'GET_REPORT',
    GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
    GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
    GET_REPORT_FAILURE: 'GET_REPORT_FAILURE'
};

export const mediaConstants = {
    START_VIDEO: 'START_VIDEO',
    STOP_VIDEO: 'STOP_VIDEO',

    SAVE_VIDEO_REQUEST: 'SAVE_VIDEO_REQUEST',
    SAVE_VIDEO_SUCCESS: 'SAVE_VIDEO_SUCCESS',
    SAVE_VIDEO_FAILURE: 'SAVE_VIDEO_FAILURE',
}

export const proctorConstants = {
    INITIATE_LOGIN_REQUEST: 'INITIATE_LOGIN_REQUEST',
    INITIATE_LOGIN_SUCCESS: 'INITIATE_LOGIN_SUCCESS',
    INITIATE_LOGIN_FAILURE: 'INITIATE_LOGIN_FAILURE'
}

export const ASSESSMENT_FILTERS = [
  "Category",
  "Difficulty",
  "Language",
  "Subject",
  "Author",
  "Year",
  "Term",
  "Chapter",
  "Grade",
]

export const CANDIDATE_FILTERS = [
  "Category",
  "Difficulty",
  "Language",
  "Subject",
  "Author",
  "Year",
  "Term",
  "Chapter",
  "Grade",
  "Status"
]

export const PAPER_FILTERS = [
  "Category",
  "Difficulty",
  "Language",
  "Subject",
  "Author",
  "Year",
  "Term",
  "Chapter",
  "Grade",
  "Status"
]

export const ASSESSMENT_LIBRARY_FILTERS = [
  "Category",
  "Difficulty",
  "Language",
  "Subject",
  "Author",
  "Year",
  "Term",
  "Chapter",
  "Grade",
  "Status"
]

export const assessmentFilters = {
    ALL: 'ALL',
    UP_COMING: 'UP_COMING',
    ON_PROGRESS: 'ON_PROGRESS',
    EXPIRED: 'EXPIRED',
    COMPLETED: 'COMPLETED'
}

export const assessmentStatus = {
    ASSIGNED: 'Assigned',
    ON_PROGRESS: 'Onprogress',
    UPCOMING: 'Upcoming',
    FINISHED: 'Finished'
}

export const assessmentState = {
    INITIAL: 'Initial',
    READY: 'Ready'
}

export const candidateStatus = {
    STARTED: 'Started',
    NOT_STARTED: 'Not Started',
    COMPLETED: 'Completed'
}

export const wizardSteps = {
    PROCTORED: 3,
    NON_PROCTORED: 2
}

// export const questionTypeConstants = {
//     MCQ: 'MCQ',
//     DESCRIPTIVE: 'Descriptive',
//     SUBMISSION: 'Submission'
// }

export const questionTypeConstants = {
    MCQ: {
        id: 1,
        name: 'MCQ'
    },
    DESCRIPTIVE: {
        id: 2,
        name: 'Descriptive'
    },
    SUBMISSION: {
        id: 3,
        name: 'Submission'
    },
    SUBQUESTION: {
        id: 5,
        name: "Sub Question"
    }
}

export const unitConstants = {
  MINUTES: {
      id: 1,
      name: 'minutes'
  },
  HOURS: {
      id: 2,
      name: 'hours'
  },
  DAYS: {
      id: 3,
      name: 'days'
  },
  MONTHS: {
      id: 4,
      name: "months"
  }
}

export const QUESTION_FILTERS = [
    "Type",
    "Category",
    "Difficulty",
    "Language",
    "Source",
    "Subject",
    "Author",
    "Year",
    "Term",
    "Chapter",
    "Grade",
]

export const questionDifficultyConstants = {
    EASY: {
        id: 1,
        name: 'Easy'
    },
    MEDIUM: {
        id: 2,
        name: 'Medium'
    },
    HARD: {
        id: 3,
        name: 'Hard'
    }
}

export const exportImportFormatConstants = {
    AIKEN: 'AIKEN',
    GIFT: 'GIFT',
    PDF: 'PDF'
}

export const autoSubmissionInterval = 300000

export const customerConstants = {
    EXO_CUSTOMER1: 'EdSider',
    EXO_CUSTOMER2: 'CMC',
    EDF_CUSTOMER1: 'EdFoci',
    EDF_CUSTOMER2: 'RA',
    EDF_CUSTOMER3: 'CGC'
}

export const fn_form_attachment_addalter_delete_outputs = [
  {code:1, errorMessage:'Form detached successfully',title:'Success !', iconInfo:'success'},
  {code:2, errorMessage:'Form note updated successfully',title:'Success !', iconInfo:'success'},
  {code:3, errorMessage:'Form attached successfully',title:'Success !', iconInfo:'success'},
  {code:-1, errorMessage:'No form exist for deletion', title:'Failed !', iconInfo:'error'},
  {code:-2, errorMessage:'User unavailable !', title:'Failed !', iconInfo:'error'},
  {code:-3, errorMessage:'Form unavailable !', title:'Failed !', iconInfo:'error'},
  {code:-4, errorMessage:'Cannot delete Form-Manage', title:'Failed !', iconInfo:'error'},
  {code:-5, errorMessage:'Form is already in use', title:'Failed !', iconInfo:'error'},
]
