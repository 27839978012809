import React from "react";
import { connect } from "react-redux";
import ContentWrapper from "../../components/common/ContentWrapper.js";
import Card from "../../components/common/Card.js";
import CardHeader from "../../components/common/CardHeader.js";
import CardBody from "../../components/common/CardBody.js";
import Wizard from "../../components/common/wizard/Wizard.js";
import WizardSteps from "../../components/common/wizard/WizardSteps.js";
import WizardForm from "../../components/common/wizard/WizardForm.js";
import StepContent from "../../components/common/wizard/StepContent.js";
import WizardActions2 from "../../components/common/wizard/WizardActions2.js";
import WizardStepsItems from "../../components/common/wizard/WizardStepsItems.js";
import Spinner from "../../components/Spinner3.js";
import { Button } from "@mui/material";
import SwitchCheck from "../../components/common/form/SwitchCheck.js";
import { assessmentStatus, questionTypeConstants, wizardSteps } from "../../helper/constants.js";
import { history } from "../../helper/history.js";
import {
  refreshAssessmentStartPage,
  validateCandidate,
  startTimer,
  loadQuestions,
  refreshForExamView,
  getCandidateAnswers,
  getAllCandidateAnswerContents,
  finishTest,
  loadAssessmentQuestions,
  onSetMarks,
} from "../../actions/candidate/testActions.js";
import {
  saveMarks,
  finalizeGrade
} from "../../actions/candidate/gradeActions.js";
import { getReport } from '../../actions/candidate/examActions.js';
import { namespace } from "../../helper/namespace.js";
import AssessmentTerms from "./AssessmentTerms.js";
import PaperQuestions  from "./PaperQuestions.js"
import config from '../../helper/config.js';
import { customerConstants } from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, disabledBtnStyle, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnStyle = {
      color: "var(--maincolor)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
      textTransform: "unset"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "unset"
    }
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
      textTransform: "unset"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
      textTransform: "unset"
    }
    break
  default:
    closeBtnStyle = {
      color: "var(--maincolor)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
      textTransform: "unset"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "unset"
    }
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class EnrolledPaperDetailsNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agree: false,
      approval: false,
      start_exam: false,
      resume_exam: false,
      currentIndex: 0,
      maxCount: null,
      successAction: "",
      isAllMCQs: true,
      examViewLoading: false
    };
    this.onClickStart = this.onClickStart.bind(this);
    this.onClickResume = this.onClickResume.bind(this);
  }

  onUnload = (e) => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = "";
  };

  componentDidMount() {
    if (window.KTWizard6) window.KTWizard6.init();
    this.props.refreshAssessmentStartPage();
    this.setState({ start_exam: false });
    // this.setState({ resume_exam: false });
    if (
      this.props.assessment.candidate_status === assessmentStatus.ON_PROGRESS
    ) {
      this.setState({
        resume_exam: true,
      });
    }
    if (this.props.assessment.proctor_requirement) {
      this.setState({
        maxCount: wizardSteps.PROCTORED,
      });
    } else {
      this.setState({
        maxCount: wizardSteps.NON_PROCTORED,
      });
    }

    window.onpopstate = function (event) {
      // this.props.history.push({
      //   pathname: "/enrolled-paper-list",
      //   state: {
      //     mid: sessionStorage.getItem("enrolled_paper_list_mid"),
      //     lg_user_id: sessionStorage.getItem("lg_user_id"),
      //     lg_user_table_id: sessionStorage.getItem("lg_user_table_id"),
      //     flg_list_active: sessionStorage.getItem("flg_list_active"),
      //   },
      // });
      this.props.offStartExam()
    };
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  findMaxCount() {
    if (this.props.assessment.proctor_requirement) {
      this.setState({
        maxCount: wizardSteps.PROCTORED,
      });
    } else {
      this.setState({
        maxCount: wizardSteps.NON_PROCTORED,
      });
    }
  }

  findResumeOrStart() {
    if (
      this.props.assessment.candidate_status === assessmentStatus.ON_PROGRESS
    ) {
      this.setState({
        resume_exam: true,
      });
    }
  }

  handleToggle = (e) => {
    let agree = !this.state.agree;
    this.setState({ agree: agree });
  };
  onClickStart(e) {
    e.preventDefault();
    window.removeEventListener("beforeunload", this.onUnload);
    if (
      this.state.maxCount === wizardSteps.PROCTORED &&
      this.state.currentIndex === 2
    ) {
      this.setState({ start_exam: true });
    }
    if (
      this.state.maxCount === wizardSteps.NON_PROCTORED &&
      this.state.currentIndex === 1
    ) {
      this.setState({ start_exam: true });
    }

    window.Swal.fire({
      title: namespace.START_MESSAGE,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: namespace.YES,
      denyButtonText: namespace.NO,
      customClass: {
        confirmButton: `btn font-weight-bold ${confirmClass}`,
        cancelButton: `btn font-weight-bold ${cancelClass}`,
      },
      onOpen: (modalElement) => {
        if (customer === customerConstants.EXO_CUSTOMER2) {
          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
        }
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.setState({ examViewLoading: true});
        window.addEventListener("beforeunload", this.onUnload);
        await this.props.validateCandidate(this.props.assessment.candidate_id);
        this.setState({ examViewLoading: false});
        if (this.props.text === "candidate validated successfully") {
          this.setState({ examViewLoading: true});
          await this.props.startTimer({
            candidate_id: this.props.assessment.candidate_id,
            assessment_id: this.props.assessment.assessment_id,
            attempt_no: this.props.assessment.attempt_no,
          });
          await this.props.loadQuestions(this.props.assessment.assessment_id);
          this.props.refreshForExamView();
          this.setState({ examViewLoading: false});
          this.setState({
            successAction: "proceed",
          });
        } else {
          window.Swal.fire({
            title: namespace.FAILED,
            text: this.props.error,
            icon:  "error",
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
            }
          });
        }
      }
    });
  }

  onClickResume() {
    if (
      this.state.maxCount === wizardSteps.PROCTORED &&
      this.state.currentIndex === 2
    ) {
      this.setState({ resume_exam: true });
    }
    if (
      this.state.maxCount === wizardSteps.NON_PROCTORED &&
      this.state.currentIndex === 1
    ) {
      this.setState({ resume_exam: true });
    }
    if (this.state.resume_exam) {
      window.Swal.fire({
        title: namespace.RESUME_MESSAGE,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: namespace.YES,
        denyButtonText: namespace.NO,
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
          cancelButton: `btn font-weight-bold ${cancelClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
          }
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.setState({ examViewLoading: true});
          window.removeEventListener("beforeunload", this.onUnload);
          await this.props.validateCandidate(
            this.props.assessment.candidate_id
          );
          this.setState({ examViewLoading: false});
          if (this.props.text === "candidate validated successfully") {
            this.setState({ examViewLoading: true});
            await this.props.startTimer({
              candidate_id: this.props.assessment.candidate_id,
              assessment_id: this.props.assessment.assessment_id,
              attempt_no: this.props.assessment.attempt_no,
            });
            await this.props.loadQuestions(this.props.assessment.assessment_id);
            await this.props.getCandidateAnswers(this.props.assessment);
            await this.props.getAllCandidateAnswerContents({
              answers: this.props.candidateAnswers,
            });
            this.props.refreshForExamView();
            this.setState({ examViewLoading: false});
            this.setState({
              successAction: "proceed",
            });
          } else {

            if(this.props.error.error && this.props.error.error === "Time's up! You have exceeded the allocated paper duration. Please contact the Administrator!"){
              await this.props.loadAssessmentQuestions(this.props.assessment.assessment_id);

              await this.checkingForAllMcqs();
              if (this.state.isAllMCQs) {
                await this.autoGradeMCQ();
              }
              let candidate_id = this.props.assessment.candidate_id;
              let is_all_mcq = this.state.isAllMCQs;
              this.props.finishTest({ candidate_id, is_all_mcq }).then(() => {
                this.setState({ finishState: true }, () => {
                  if (!this.props.submission_uploading && this.props.submission_error) {
                    window.Swal.fire({
                      title: "Exam Finishing Failed",
                      text: this.props.error?.error,
                      icon: "error",
                      customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                      },
                      onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                      }
                    });
                    this.setState({ finishState: false, timeUp: false, submit: false, renderGradeReport: null });
                  } else if (
                    !this.props.finish_loading && this.props.finish_success
                  ) {
                    localStorage.removeItem(this.props.candidate_id);
                    window.Swal.fire({
                      title: "Time's up!",
                      text: " Time's up! You have exceeded the allocated paper duration, Exam Finished",
                      icon: "info",
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                      },
                      onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                      }
                    });
                    this.props.offStartExam()

                  }
                });
              });
            }
            else{
              window.Swal.fire({
                title: namespace.FAILED,
                text: this.props.error?.error,
                icon:  "error",
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              });
              this.props.offStartExam()
            }
            
          }
        }
      });
    }
  }

  // checking whether all questions are MCQS or not
  async checkingForAllMcqs() {
    var question_list = this.props.assessmentQuestions,
      i;
    for (i = 0; i < this.props?.assessmentQuestions.length; i++) {
      if (question_list[i]["question_type"] !== questionTypeConstants.MCQ.name) {
        this.setState({ isAllMCQs: false });
      }
    }
    return;
  }

  async autoGradeMCQ() {
    var i;
    for (i = 0; i < this.props?.assessmentQuestions.length; i++) {
      if (this.props.assessmentQuestions[i]?.question_type == questionTypeConstants.MCQ.name) {
        var candidate_answer =
          this.props?.answerMap[
          this.props.assessmentQuestions[i]?.question_id
          ];
        if (candidate_answer) {
          candidate_answer = JSON.parse(candidate_answer);
        }
        var res = this.props.answerContents[i]?.toString().split(",");
        let numOfCandidateAnswers = candidate_answer?.length;
        let numOfAnswers = 0;
        for (var m in res) {
          if (res[m] != "Nan" && res[m] != 0) {
            numOfAnswers = numOfAnswers + 1;
          }
        }
        if (numOfCandidateAnswers <= numOfAnswers) {
          var marks = 0, j, ans_index;
          for (j in candidate_answer) {
            ans_index = candidate_answer[j];
            marks = marks + (res[ans_index] *
              this.props.assessmentQuestions[i]?.mark_allocation) /
              100;
          }
          const assessor_id = "root";
          var id = this.props.questions[i]?.question_id;
          await this.props?.onSetMarks({ id, marks, assessor_id });
        }
        else {
          var marks = 0;
          const assessor_id = "root";
          var id = this.props?.questions[i]?.question_id;
          await this.props?.onSetMarks({ id, marks, assessor_id });
        }
      }
    }

    var total = 0;
    for (i = 0; i < this.props?.assessmentQuestions?.length; i++) {
      var candidate_answer =
        this.props?.answerMap[
        this.props?.assessmentQuestions[i]?.question_id
        ];
      if (candidate_answer?.mark_obtained) {
        total = total + candidate_answer?.mark_obtained;
      }
    }

    const assessment_id = this.props?.assessment?.assessment_id;
    const user_role_id = "CASS" + this.props?.assessment?.created_by;
    const questions = this.props?.candidateAnswers;
    const candidate_id = this.props?.assessment.candidate_id;
    const attempt_no = this.props?.assessment.attempt_no;
    sessionStorage.setItem("candidate_id", candidate_id);
    sessionStorage.setItem("attempt_no", attempt_no);
    const final_grade = this.props?.candidate.final_grade;
    const assessor_remarks = this.props?.candidate_id;
    window.Swal.showLoading();

    await this.props?.saveMarks({
      assessment_id,
      questions,
      user_role_id,
      assessor_remarks,
      candidate_id,
      attempt_no
    });

    await this.props?.finalizeGrade({
      candidate_id,
      final_grade,
      user_role_id,
      attempt_no
    });

    await this.props.getReport({ candidate_id, attempt_no });
    window.Swal.close();

    if (!this.props.gradeLoading && this.props.gradeError) {
      window.Swal.fire({
        title: "Failed!",
        text: "Failed to autograde!",
        icon: "error",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
    // else {
    //   window.Swal.fire("Success!", "Autograded successfully!", "success");
    // }

  }

  getTermsAndConditions(language_id){
      if(language_id === 1){
        namespace.AGREEMENT_CONFIRMATION = 'I acknowledge that I have read and fully grasped the above online paper terms & conditions before starting the paper.';
      }
      else if(language_id === 2){
        namespace.AGREEMENT_CONFIRMATION = 'මම පත්‍රය ආරම්භ කිරීමට පෙර ඉහත මාර්ගගත කඩදාසි නියමයන් සහ කොන්දේසි කියවා සම්පූර්ණයෙන් ග්‍රහණය කර ගත් බව මම පිළිගනිමි.';
      }
      else if(language_id === 3){
        namespace.AGREEMENT_CONFIRMATION = 'தேர்வு தாளைத் தொடங்கும் முன் மேலே உள்ள ஆன்லைன் தேர்வு விதிமுறைகள் & நிபந்தனைகளைப் படித்து முழுமையாகப் புரிந்துகொண்டேன் என்பதை ஒப்புக்கொள்கிறேன்.';
      }
      else{
        namespace.AGREEMENT_CONFIRMATION = 'I acknowledge that I have read and fully grasped the above online paper terms & conditions before starting the paper.';
      }
    return namespace.AGREEMENT_CONFIRMATION;
  }

  changeRoute() {
    // this.props.history.push({
    //   pathname: "/enrolled-paper-list",
    //   state: {
    //     mid: sessionStorage.getItem("enrolled_paper_list_mid"),
    //     lg_user_id: sessionStorage.getItem("lg_user_id"),
    //     lg_user_table_id: sessionStorage.getItem("lg_user_table_id"),
    //     flg_list_active: sessionStorage.getItem("flg_list_active"),
    //   },
    // });
    this.props.offStartExam()    
  }

  render() {
    if (this.state.successAction === "proceed") {
      //this.props.history.push("/view-question-paper");
      return <PaperQuestions 
        state={this.props.state}
        offStartExam={() => this.props.offStartExam()}
        offViewReport={() => this.props.offViewReport()}
        allUnits={this.props.allUnits}
      />
    }

    // this.findMaxCount();
    // this.findResumeOrStart();

    return (
      <>
        <ContentWrapper>
          {this.props.loading && <Spinner id="show" text="Please wait..." />}
          {this.state.examViewLoading && <Spinner id="show" text="Loading questions paper..." />}
          {/* begin::Card */}

          <Card>
            <Wizard>
              {this.props.assessment.start_time && (
                <CardHeader>
                  {this.state.maxCount === wizardSteps.NON_PROCTORED && (
                  <div className="row w-100 d-flex px-0">
                      <div className="col-md-6 col-6 px-0">
                        <WizardSteps>
                          <WizardStepsItems
                            wizardTitle="Paper Details"
                            wizardDesc="Pre paper instructions"
                            className="svg-icon d-none"
                            wizardNumber="2"
                            dataWizardState={
                              this.state.currentIndex > -1 ? "current" : ""
                            }
                          />
                        </WizardSteps>
                      </div>
                    <div className="col-md-6 col-6 px-0 d-flex justify-content-end align-items-center">
                      <Button
                        variant="contained"
                        style={addBtnStyle}
                        onClick={() => {
                          // this.props.history.push({
                          //   pathname: "/enrolled-paper-list",
                          //   state: {
                          //     mid: sessionStorage.getItem(
                          //       "enrolled_paper_list_mid"
                          //     ),
                          //     lg_user_id: sessionStorage.getItem("lg_user_id"),
                          //     lg_user_table_id:
                          //       sessionStorage.getItem("lg_user_table_id"),
                          //     flg_list_active:
                          //       sessionStorage.getItem("flg_list_active"),
                          //   },
                          // });
                          this.props.offStartExam()
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </div>
                  )}
                </CardHeader>
              )}

              <CardBody>
                <WizardForm id="kt_form">
                  {/* begin: Wizard Step 1 */}
                  {this.state.currentIndex === 0 &&
                    this.props.assessment.start_time && (
                      <StepContent dataWizardState="current">
                        <ul className="navi px-10 pb-10">
                          <AssessmentTerms
                            terms_content={this.props.assessment.terms_content}
                          />
                          <li className="navi-item mt-10">
                            <span className="navi-text font-weight-bold font-size-h5 d-flex">
                              <span className="symbol mr-3 float-left">
                                <SwitchCheck
                                  name="agree"
                                  checked={this.state.agree}
                                  onChange={this.handleToggle}
                                  id="agree"
                                  type="checkbox"
                                />
                              </span>
                              <span className="pt-1 float-left">
                                {this.getTermsAndConditions(parseInt(this.props.assessment?.language_id))}
                              </span>
                            </span>
                          </li>
                        </ul>
                      </StepContent>
                    )}
                  {/* end: Wizard Step 1 */}

                  {/* begin: Wizard Step 2 */}

                  {this.props.assessment.start_time === undefined &&
                    // this.props.history.push({
                    //   pathname: "/enrolled-paper-list",
                    //   state: {
                    //     mid: sessionStorage.getItem("enrolled_paper_list_mid"),
                    //     lg_user_id: sessionStorage.getItem("lg_user_id"),
                    //     lg_user_table_id:
                    //       sessionStorage.getItem("lg_user_table_id"),
                    //     flg_list_active:
                    //       sessionStorage.getItem("flg_list_active"),
                    //   },
                    // })
                    
                    this.props.offStartExam()
                  }

                  {/* end: Wizard Step 2 */}

                  {/* begin: Wizard Step 3 */}

                  {/* end: Wizard Step 3 */}
                  {this.props.assessment.start_time && (
                    <WizardActions2
                      onClickStart={this.onClickStart}
                      onClickResume={this.onClickResume}
                      maxCount={this.state.maxCount}
                      currentIndex={this.state.currentIndex}
                      agree={this.state.agree}
                      resume_exam={this.state.resume_exam}
                    />
                  )}
                </WizardForm>
              </CardBody>
            </Wizard>
          </Card>
          {/* end::Card */}
        </ContentWrapper>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.validationReducer.loading,
    error: state.validationReducer.error,
    success: state.validationReducer.success,
    candidate_id: state.validationReducer.candidate_id,
    text: state.validationReducer.text,
    assessment: state.examReducer.assessment,
    loading_test: state.testReducer.loading,
    candidateAnswers: state.testReducer.candidateAnswers,

    submission_uploading: state.submissionReducer.loading,
    finish_loading: state.submissionReducer.finish_loading,
    submission_error: state.submissionReducer.error,
    submission_success: state.submissionReducer.success,
    finish_success: state.submissionReducer.finish_success,
    finish_error: state.submissionReducer.finish_error,

    assessmentQuestions: state.testReducer.assessmentQuestions,

    loading: state.testReducer.loading,
    answerMap: state.testReducer.candidateAnswerContentMap,
    candidateAnswerContents: state.testReducer.candidateAnswerContents,
    answerContents: state.testReducer.answerContents,
    candidate: state.testReducer.candidate,

    loading: state.gradeReducer.loading,
    gradeError: state.gradeReducer.error,
    gradeLoading: state.gradeReducer.loading,

    loading: state.examReducer.loading,
    report: state.examReducer.report,
  };
}

const mapStateToActions = {
  refreshAssessmentStartPage: refreshAssessmentStartPage,
  validateCandidate: validateCandidate,
  loadQuestions: loadQuestions,
  refreshForExamView: refreshForExamView,
  startTimer: startTimer,
  getCandidateAnswers: getCandidateAnswers,
  getAllCandidateAnswerContents: getAllCandidateAnswerContents,
  finishTest:finishTest,
  loadAssessmentQuestions:loadAssessmentQuestions,
  onSetMarks: onSetMarks,
  saveMarks: saveMarks,
  finalizeGrade: finalizeGrade,
  getReport: getReport,
  history: history,
};

export default connect(
  mapStateToProps,
  mapStateToActions
)(EnrolledPaperDetailsNew);
