import { AzureStorage } from "../../helper/azureStorage";
import { questionTypeConstants } from "../../helper/constants";
import DataHandler from "../../handlers/DataHandler";
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const libraryService = {
    getAllQuestions,
    getAllMyQuestions,
    getAllQuestionsCount,
    getQuestionContent,
    getQuestionGuidanceContent,
    getAnswerContent,
    getSharedQuestions,
    getAllSharedQuestions,
    getAllLibraryQuestions,
    updateQuestion,
    updateAllQuestions,
    deleteQuestion,
    deleteCategory,
    updateCategory,
    getAllPublicQuestions,
    getAllCount
};

async function getAllCount() {
    try {
        const requestOptions = {
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            method: 'GET'
        };

        const response = await fetch(`${config.oat_api_url}/questions/getAllCount/`, requestOptions);
        const data = await response.json();

        return data;
    } catch (error) {
        return { error: error };
    }
}

async function getQuestionContent(obj) {
    try {
        if(obj.is_content_blob === "true"){
            const user_id = `${obj.created_by}`;
            const questionContainerName= config.storageBlobConfigs.containerName;
            const questionBlobName = "".concat(user_id, "-q-", obj.content);
            const questionContent = await AzureStorage.getBlobContent(questionContainerName, questionBlobName);
            return questionContent;
        }
        else{
            return obj.content;
        }
    } catch (error) {
        return { error: error };
    }
}

async function getQuestionGuidanceContent(obj) {
  return await obj.guidence;
}

async function getAnswerContent(obj) {
    try {
        if(obj.is_answer_blob === "true"){
            const user_id = `${obj.created_by}`;
            const answerContainerName= config.storageBlobConfigs.containerName;
            const answerBlobName = "".concat(user_id, "-a-", obj.answer);
            const answerContent = await AzureStorage.getBlobContent(answerContainerName, answerBlobName);
            return answerContent;
        }
        else{
            return obj.answer;
        }
    } catch (error) {
        return { error: error };
    }
}

async function getAllQuestions() {
    try {
        var user_id = DataHandler.getFromSession("user_id")
        const requestOptions = {
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            method: 'GET'
        };

        return await fetch(`${config.oat_api_url}/questions/getAll/${user_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function getAllQuestionsCount() {
    try {
        var user_id = DataHandler.getFromSession("user_id")
        const requestOptions = {
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            method: 'GET'
        };

        return await fetch(`${config.oat_api_url}/questions/getAllQuestionsCount/${user_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function getAllMyQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectedCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources) {
    try {
        var user_id = DataHandler.getFromSession("user_id")
        const requestOptions = {
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            method: 'GET'
        };

        return await fetch(`${config.oat_api_url}/questions/getAllQuestionsWithFilters/${user_id}?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&category_id=${category_id}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTypes=${selectedTypes}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedSources=${selectedSources}`,
            requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function getAllPublicQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectedCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources) {
    try {
        const requestOptions = {
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            method: 'GET'
        };

        return await fetch(`${config.oat_api_url}/questions/getAllPublicWithFilters?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&category_id=${category_id}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTypes=${selectedTypes}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedSources=${selectedSources}`,
            requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function getAllLibraryQuestions(limit, offset, sort_column, sort_direction, search_term, selectedCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources) {
    try {
        const requestOptions = {
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            method: 'GET'
        };

        return await fetch(`${config.oat_api_url}/questions/getAllLibraryQuestionsWithFilters?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTypes=${selectedTypes}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedSources=${selectedSources}`,
            requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function getSharedQuestions() {
    try {
        var user_id = DataHandler.getFromSession("user_id")
        const requestOptions = {
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            method: 'GET'
        };

        return await fetch(`${config.oat_api_url}/question/shares/sharedWith/${user_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function getAllSharedQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectedCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources) {
    try {
        var user_id = DataHandler.getFromSession("user_id")
        const requestOptions = {
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            method: 'GET'
        };

        return await fetch(`${config.oat_api_url}/questions/getAllSharedQuestionsWithFilters/sharedWith/${user_id}?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&category_id=${category_id}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTypes=${selectedTypes}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedSources=${selectedSources}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function updateQuestion(obj) {
    try {
        const user_id = DataHandler.getFromSession("user_id");
        const questionBlobName = "".concat(user_id, "-q-", obj.question_key);
        const answerBlobName = "".concat(user_id, "-a-", obj.answer_key);
        let newQuestionContent = JSON.stringify(obj.question_content);
        let newAnswerContent = JSON.stringify(obj.answer_content);
        const newGuidanceContent = JSON.stringify(obj.guidence);

        let requestBody = {
            title: obj.title,
            subject_id: obj.subject_id,
            source_id: obj.source_id,
            author_id: obj.author_id,
            year_id: obj.year_id,
            term_id: obj.term_id,
            tags: obj.tags,
            content: newQuestionContent,
            qtype: obj.qtype,
            category_id: obj.category_id,
            chapter_id: obj.chapter_id,
            grade_id: obj.grade_id,
            guidence: newGuidanceContent,
            difficulty_id: obj.difficulty_id,
            privilege_id: obj.privilege_id,
            language_id: obj.language_id,
            created_by: obj.created_by,
            answer: newAnswerContent,
            is_content_blob: obj.is_content_blob,
            is_answer_blob: obj.is_answer_blob
        }

        if(obj.is_content_blob === "true"){
            // Update question content in Azure Blob Storage
            const questionContainerName= config.storageBlobConfigs.containerName;
            await AzureStorage.updateBlobContent(questionContainerName, questionBlobName, newQuestionContent, newQuestionContent.length);
            newQuestionContent = obj.question_key;
            requestBody.content = newQuestionContent;
        }

        if (obj.qtype === questionTypeConstants.MCQ.id) {
            requestBody.answer = obj.answer;
        }

        if (obj.is_answer_blob === "true") {
            // Update answer content in Azure Blob Storage
            const answerContainerName= config.storageBlobConfigs.containerName;
            await AzureStorage.updateBlobContent(answerContainerName, answerBlobName, newAnswerContent, newAnswerContent.length);
            newAnswerContent = obj.answer_key;
            requestBody.answer = newAnswerContent;
        }

        const requestOptions = {
            method: 'POST',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(requestBody)
        };

        let data = await fetch(`${config.oat_api_url}/questions/update/${obj.question_id}`, requestOptions);

        return APIHandler.handleResponse(data);
    } catch (error) {
        return { error: error };
    }
}

async function updateAllQuestions(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };
    return await fetch(`${config.oat_api_url}/questions/updateAllQuestions/`, requestOptions).then(APIHandler.handleResponse);
}

async function deleteQuestion(obj) {
    try {
        const user_id = DataHandler.getFromSession("user_id");
        const questionBlobName = "".concat(user_id, "-q-", obj.content);
        const answerBlobName = "".concat(user_id, "-a-", obj.answer);

        const requestOptions = {
            method: 'POST',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify({
                title: obj.title,
                content: obj.content,
                qtype: obj.qtype,
                category_id: obj.category_id,
                created_by: obj.created_by,
                answer: obj.answer
            })
        };

        if(obj.is_content_blob === "true"){
            // Delete question content from Azure Blob Storage
            const questionContainerName= config.storageBlobConfigs.containerName;
            await AzureStorage.removeBlobContent(questionContainerName, questionBlobName);
        }

        // Delete answer content from Azure Blob Storage
        if (obj.qtype === questionTypeConstants.DESCRIPTIVE.id) {
            if(obj.is_answer_blob === "true"){
                const answerContainerName= config.storageBlobConfigs.containerName;
                await AzureStorage.removeBlobContent(answerContainerName, answerBlobName);
            }
        }

        return await fetch(`${config.oat_api_url}/questions/delete/${obj.question_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function deleteCategory(obj) {
    try {
        const requestOptions = {
            method: 'POST',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(obj)
        };
        return await fetch(`${config.oat_api_url}/question/categories/delete/${obj.category_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function updateCategory(obj) {
    try {
        let requestBody = {
            name: obj.name.toString()
        }
        const requestOptions = {
            method: 'POST',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(requestBody)
        };

        let data = await fetch(`${config.oat_api_url}/question/categories/update/${obj.category_id}`, requestOptions);

        return APIHandler.handleResponse(data);
    } catch (error) {
        return { error: error };
    }
}

