import {assessmentTypes} from '../../reducers/types';
import {createTypes} from '../../reducers/types';

import {questionService} from "../../service/assessment/questionService";

export const questionActions = {
    getAllQuestions,
    getAllAddQuestions,
    getQuestionContent,
    getQuestionGuidanceContent,
    getAllQuestionContents,
    getAllQuestionContentsByType,
    getQuestionsAddedByMe,
    getAssessmentQuestions,
    getAllAssessmentQuestions,
    getSetQuestion,
    removeMyQuestions,
    applyPositionChangeLocal,
    addQuestions,    
    saveUpdates,
    viewAddQuestions,
    refreshforAddingQuestions,
    refreshforRemovingQuestions,
    refreshPage,
    getAllAnswerContents,
    
    getQuestionTypes,
    getQuestionCategories,
    getQuestionDifficulty
};

function getAllQuestions() {
    return async dispatch => {
        dispatch(get_all_questions());
        dispatch(get_all_questions_request());

        await questionService.getQuestions().then(
            data => {
                dispatch(get_all_questions_success(data.data))
            },
            error => {
                dispatch(get_all_questions_failure(error))
            }
        )
    }

    function get_all_questions() {
        return {type: assessmentTypes.GET_ALL_QUESTIONS}
    }

    function get_all_questions_request() {
        return {type: assessmentTypes.GET_ALL_QUESTIONS_REQUEST}
    }

    function get_all_questions_success(data) {
        return {type: assessmentTypes.GET_ALL_QUESTIONS_SUCCESS, data}
    }

    function get_all_questions_failure(error) {
        return {type: assessmentTypes.GET_ALL_QUESTIONS_FAILURE, error}
    }
}

function getAllAddQuestions(limit, offset, sort_column, sort_direction, search_term, category_id) {
    return async dispatch => {
        dispatch(get_all_add_questions());
        dispatch(get_all_add_questions_request());

        await questionService.getAllAddQuestions(limit, offset, sort_column, sort_direction, search_term, category_id).then(
            data => {
                dispatch(get_all_add_questions_success(data.data))
            },
            error => {
                dispatch(get_all_add_questions_failure(error))
            }
        )
    }

    function get_all_add_questions() {
        return {type: assessmentTypes.GET_ALL_ADD_QUESTIONS}
    }

    function get_all_add_questions_request() {
        return {type: assessmentTypes.GET_ALL_ADD_QUESTIONS_REQUEST}
    }

    function get_all_add_questions_success(data) {
        return {type: assessmentTypes.GET_ALL_ADD_QUESTIONS_SUCCESS, data}
    }

    function get_all_add_questions_failure(error) {
        return {type: assessmentTypes.GET_ALL_ADD_QUESTIONS_FAILURE, error}
    }
}

//function to get getAllQuestionContentsByType
function getAllQuestionContentsByType(obj) {
    return async dispatch => {
        dispatch(get_all_questions_Contents_By_Type());
        dispatch(get_all_questions_Contents_By_Type_request());

        try{
            let questionContents = await questionService.getAllQuestionContentsByType(obj);
            dispatch(get_all_questions_Contents_By_Type_success(questionContents));
        } catch (error) {
            dispatch(get_all_questions_Contents_By_Type_failure(error.toString()));
        }
    }

    function get_all_questions_Contents_By_Type() {
        return {type: assessmentTypes.GET_ALL_QUESTIONS_CONTENTS_BY_TYPE}
    }

    function get_all_questions_Contents_By_Type_request() {
        return {type: assessmentTypes.GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_REQUEST}
    }

    function get_all_questions_Contents_By_Type_success(data) {
        return {type: assessmentTypes.GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_SUCCESS, data}
    }

    function get_all_questions_Contents_By_Type_failure(error) {
        return {type: assessmentTypes.GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_FAILURE, error}
    }
}


function getQuestionContent(key) {
    return async dispatch => {
        dispatch(get_question_content_request(key));
        try{
            let content = await questionService.getQuestionContent(key);
            dispatch(get_question_content_success(content))
        }catch(error){
            dispatch(get_question_content_failure(error.toString()))
        }
        
    }

    function get_question_content_request(question) {
        return {type: assessmentTypes.GET_QUESTION_CONTENT_REQUEST,question}
    }

    function get_question_content_success(question) {
        return {type: assessmentTypes.GET_QUESTION_CONTENT_SUCCESS, question}
    }

    function get_question_content_failure(error) {
        return {type: assessmentTypes.GET_QUESTION_CONTENT_FAILURE, error}
    }
}

function getQuestionGuidanceContent(key) {
    return async dispatch => {
        dispatch(get_question_guidance_content_request(key));
        try{
            let guidance = await questionService.getQuestionGuidanceContent(key);
            dispatch(get_question_guidance_content_success(guidance))
        }catch(error){
            dispatch(get_question_guidance_content_failure(error.toString()))
        }
        
    }

    function get_question_guidance_content_request(guidance) {
        return {type: assessmentTypes.GET_QUESTION_GUIDANCE_CONTENT_REQUEST, guidance}
    }

    function get_question_guidance_content_success(guidance) {
        return {type: assessmentTypes.GET_QUESTION_GUIDANCE_CONTENT_SUCCESS, guidance}
    }

    function get_question_guidance_content_failure(error) {
        return {type: assessmentTypes.GET_QUESTION_GUIDANCE_CONTENT_FAILURE, error}
    }
}

function getAllQuestionContents (obj) {
    return async dispatch => {
        dispatch(get_all_question_contents());
        dispatch(get_all_question_contents_request());

        try{
            let questions = await questionService.getAllQuestionContents(obj);
            dispatch(get_all_question_contents_success(questions));
        } catch (error) {
            dispatch(get_all_question_contents_failure(error.toString()));
        }

    }

    function get_all_question_contents() {
        return {type: assessmentTypes.GET_ALL_QUESTION_CONTENTS}
    }

    function get_all_question_contents_request() {
        return {type: assessmentTypes.GET_ALL_QUESTION_CONTENTS_REQUEST}
    }

    function get_all_question_contents_success(questions) {
        return {type: assessmentTypes.GET_ALL_QUESTION_CONTENTS_SUCCESS, questions}
    }

    function get_all_question_contents_failure(error) {
        return {type: assessmentTypes.GET_ALL_QUESTION_CONTENTS_FAILURE, error}
    }
}

function getAllAnswerContents (obj) {
    return async dispatch => {
        dispatch(get_all_answer_contents());
        dispatch(get_all_answer_contents_request());

        try{
            let answer = await questionService.getAllAnswerContents(obj);
            dispatch(get_all_answer_contents_success(answer));
        } catch (error) {
            dispatch(get_all_answer_contents_failure(error.toString()));
        }

    }

    function get_all_answer_contents() {
        return {type: assessmentTypes.GET_ALL_ANSWER_CONTENTS}
    }

    function get_all_answer_contents_request() {
        return {type: assessmentTypes.GET_ALL_ANSWER_CONTENTS_REQUEST}
    }

    function get_all_answer_contents_success(answers) {
        return {type: assessmentTypes.GET_ALL_ANSWER_CONTENTS_SUCCESS, answers}
    }

    function get_all_answer_contents_failure(error) {
        return {type: assessmentTypes.GET_ALL_ANSWER_CONTENTS_FAILURE, error}
    }
}

function getQuestionsAddedByMe(assessment) {
    return dispatch => {
        dispatch(get_assessment_questions_added_by_me());
        dispatch(get_assessment_questions_added_by_me_request());

        questionService.getQuestionsAddedByMe(assessment).then(
            data => {
                dispatch(get_assessment_questions_added_by_me_success(data.data))
            },
            error => {
                dispatch(get_assessment_questions_added_by_me_failure(error))
            }
        )

    }

    function get_assessment_questions_added_by_me() {
        return {type: assessmentTypes.GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME}
    }

    function get_assessment_questions_added_by_me_request() {
        return {type: assessmentTypes.GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_REQUEST}
    }

    function get_assessment_questions_added_by_me_success(data) {
        return {type: assessmentTypes.GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_SUCCESS, data}
    }

    function get_assessment_questions_added_by_me_failure(error) {
        return {type: assessmentTypes.GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_FAILURE, error}
    }
}

function getSetQuestion(question_id) {
    return async dispatch => {
        try {
            dispatch(get_set_question());
            dispatch(get_set_question_request());

            var questions = await questionService.getSetQuestion(question_id)
            dispatch(get_set_question_success(questions.data))
        } catch (error) {
            dispatch(get_set_question_failure(error))

        }
    }

    function get_set_question() {
        return {type: assessmentTypes.GET_SET_QUESTION}
    }

    function get_set_question_request() {
        return {type: assessmentTypes.GET_SET_QUESTION_REQUEST}
    }

    function get_set_question_success(data) {
        return {type: assessmentTypes.GET_SET_QUESTION_SUCCESS, data}
    }

    function get_set_question_failure(error) {
        return {type: assessmentTypes.GET_SET_QUESTION_FAILURE, error}
    }
}

function getAssessmentQuestions(assessment_id) {
    return async dispatch => {
        try {
            dispatch(get_assessment_questions());
            dispatch(get_assessment_questions_request());

            var questions = await questionService.getAssessmentQuestions(assessment_id)
            dispatch(get_assessment_questions_success(questions.data))
        } catch (error) {
            dispatch(get_assessment_questions_failure(error))

        }
    }

    function get_assessment_questions() {
        return {type: assessmentTypes.GET_ASSESSMENT_QUESTIONS}
    }

    function get_assessment_questions_request() {
        return {type: assessmentTypes.GET_ASSESSMENT_QUESTIONS_REQUEST}
    }

    function get_assessment_questions_success(data) {
        return {type: assessmentTypes.GET_ASSESSMENT_QUESTIONS_SUCCESS, data}
    }

    function get_assessment_questions_failure(error) {
        return {type: assessmentTypes.GET_ASSESSMENT_QUESTIONS_FAILURE, error}
    }
}

function getAllAssessmentQuestions(assessment_id, limit, offset, sort_column, sort_direction, search_term, selectCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources) {
    return async dispatch => {
        try {
            dispatch(get_all_assessment_questions());
            dispatch(get_all_assessment_questions_request());

            var questions = await questionService.getAllAssessmentQuestions(assessment_id, limit, offset, sort_column, sort_direction, search_term, selectCategories, selectedAuthors, selectedChapters,
                selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources)
            dispatch(get_all_assessment_questions_success(questions.data))
        } catch (error) {
            dispatch(get_all_assessment_questions_failure(error))

        }
    }

    function get_all_assessment_questions() {
        return {type: assessmentTypes.GET_ALL_ASSESSMENT_QUESTIONS}
    }

    function get_all_assessment_questions_request() {
        return {type: assessmentTypes.GET_ALL_ASSESSMENT_QUESTIONS_REQUEST}
    }

    function get_all_assessment_questions_success(data) {
        return {type: assessmentTypes.GET_ALL_ASSESSMENT_QUESTIONS_SUCCESS, data}
    }

    function get_all_assessment_questions_failure(error) {
        return {type: assessmentTypes.GET_ALL_ASSESSMENT_QUESTIONS_FAILURE, error}
    }
}

function removeMyQuestions(obj) {
    return async dispatch => {
        dispatch(remove_my_questions());
        dispatch(remove_my_questions_request());

        try {
            var data = await questionService.removeQuestions(obj)
            dispatch(remove_my_questions_success(data.data))
        } catch (error) {
            dispatch(remove_my_questions_failure(error))
        }
    }

    function remove_my_questions() {
        return {type: assessmentTypes.REMOVE_QUESTIONS_ADDED_BY_ME}
    }

    function remove_my_questions_request() {
        return {type: assessmentTypes.REMOVE_QUESTIONS_ADDED_BY_ME_REQUEST}
    }

    function remove_my_questions_success(data) {
        return {type: assessmentTypes.REMOVE_QUESTIONS_ADDED_BY_ME_SUCCESS, data}
    }

    function remove_my_questions_failure(error) {
        return {type: assessmentTypes.REMOVE_QUESTIONS_ADDED_BY_ME_FAILURE, error}
    }
}

function applyPositionChangeLocal (obj) {

    return dispatch => {
        dispatch(reorder_questions(obj));        
    }

    function reorder_questions(data) {
        return {type: assessmentTypes.REORDER_QUESTIONS , data}
    }

}

function addQuestions (data) {

    var questions = []
    var question, content;

    for (question of data.questions) {
        content = {
            'assessment_id': data.assessment_id,
            'question_id': parseInt(question),
            'added_by': data.assessor_id
        }

        questions.push(content)
    }

    return async dispatch => {
        dispatch(add_assessment_questions_request());

        try{
            let data = await questionService.addQuestions(questions);
            dispatch(add_assessment_questions_success(data.data))
        } catch (error) {
            dispatch(add_assessment_questions_failure(error))
        }
    }

    function add_assessment_questions_request() {
        return {type: assessmentTypes.ADD_QUESTIONS_ASSESSMENT_REQUEST}
    }

    function add_assessment_questions_success(data) {
        return {type: assessmentTypes.ADD_QUESTIONS_ASSESSMENT_SUCCESS, data}
    }

    function add_assessment_questions_failure(error) {
        return {type: assessmentTypes.ADD_QUESTIONS_ASSESSMENT_FAILURE, error}
    }

}

function saveUpdates(data) {
    return async dispatch => {
        dispatch(save_mark_allocations_request());

        try {
            var assessments = await questionService.saveUpdates(data)
            dispatch(save_mark_allocations_success(assessments.message))
        } catch (error) {
            dispatch(save_mark_allocations_failure(error))
        }

    }

    function save_mark_allocations_request() {
        return {type: assessmentTypes.SAVE_MARK_ALLOCATIONS_REQUEST}
    }

    function save_mark_allocations_success(data) {
        return {type: assessmentTypes.SAVE_MARK_ALLOCATIONS_SUCCESS, data}
    }

    function save_mark_allocations_failure(error) {
        return {type: assessmentTypes.SAVE_MARK_ALLOCATIONS_FAILURE, error}
    }
}

function viewAddQuestions(assessment) {
    return dispatch => {
        dispatch(start_add_questions(assessment));
    }

    function start_add_questions(assessment) {
        return {type: assessmentTypes.ADD_QUESTIONS_ASSESSMENT, assessment}
    }
}

function refreshforAddingQuestions() {

    return dispatch => {
        dispatch(refresh_add_questions());
    }

    function refresh_add_questions() {
        return {type: assessmentTypes.ADD_QUESTIONS_ASSESSMENT_REFRESH}
    }
}

function refreshforRemovingQuestions() {

    return dispatch => {
        dispatch(refresh_remove_questions());
    }

    function refresh_remove_questions() {
        return {type: assessmentTypes.REMOVE_QUESTIONS_ADDED_BY_ME_REFRESH}
    }
}

function refreshPage() {

    return dispatch => {
        dispatch(refresh_question_page());
    }

    function refresh_question_page() {
        return {type: assessmentTypes.ADD_QUESTIONS_PAGE_REFRESH}
    }
}


function getQuestionDifficulty(){
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let difficulty = await questionService.getQuestionDifficulty();
            dispatch(success(difficulty.data));
        }catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return {type: createTypes.GET_QUESTION_DIFFICULTY}
    }

    function request() {
        return {type: createTypes.GET_QUESTION_DIFFICULTY_REQUEST}
    }

    function success(types) {
        return {type: createTypes.GET_QUESTION_DIFFICULTY_SUCCESS, types}
    }

    function failure(error) {
        return {type: createTypes.GET_QUESTION_DIFFICULTY_FAILURE, error}
    }
}

function getQuestionTypes() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let qTypes = await questionService.getQuestionTypes();
            dispatch(success(qTypes.data));
        }catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return {type: createTypes.GET_QUESTION_TYPES}
    }

    function request() {
        return {type: createTypes.GET_QUESTION_TYPES_REQUEST}
    }

    function success(types) {
        return {type: createTypes.GET_QUESTION_TYPES_SUCCESS, types}
    }

    function failure(error) {
        return {type: createTypes.GET_QUESTION_TYPES_FAILURE, error}
    }
}

function getQuestionCategories() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let categories = await questionService.getQuestionCategories();
            dispatch(success(categories.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };

    function clearExisting() {
        return {type: createTypes.GET_QUESTION_CATEGORIES}
    }

    function request() {
        return {type: createTypes.GET_QUESTION_CATEGORIES_REQUEST}
    }

    function success(categories) {
        return {type: createTypes.GET_QUESTION_CATEGORIES_SUCCESS, categories}
    }

    function failure(error) {
        return {type: createTypes.GET_QUESTION_CATEGORIES_FAILURE, error}
    }
}
