import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../../components/admin/css/common.css";
import {sweetStatusAlert} from "../../../components/common/SweetAlert.js";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Autocomplete,
  TextField
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import debounce from "lodash.debounce";
import SimpleReactValidator from "simple-react-validator";
import { candidateService } from "../../../service/settings/candidate/candidateService.js";
import { assessmentService } from "../../../service/assessment/assessmentService";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import config from '../../../helper/config.js';
import {customerConstants} from "../../../helper/constants.js";
import moment from "moment";
import ReactSelect from "react-select";
import { CANDIDATE_FILTERS } from "../../../helper/constants.js";
import ModalBody from "../../../components/common/modal/ModalBody";
import ModalHeader from "../../../components/common/modal/ModalHeader";
import ViewForm from "../../../components/common/form/Form";
import { unitConstants } from "../../../helper/constants.js";
import DataHandler from "../../../handlers/DataHandler.js";
import { assessmentStatus, candidateStatus } from "../../../helper/constants.js";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class extends Component {
  constructor(props) {
    super(props);
    this.createValidationsInstance();
    this.state = {
      candidate_id: "",
      exam_duration: null,
      max_exam_duration: "",
      start_time: "",
      buffer_time: null,
      max_buffer_time: "",
      schedules: [],
      papers: [],
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      sort_column: "enrolled_time",
      sort_direction: "DESC",
      selectedAssessment: null,
      selectedExam: null,
      selectedStudent: null,
      selectedCandidate: null,
      selectedAttempt: null,
      selectedStudentExamAttempt: null,
      selectedCategories: null,
      selectedAuthors: null,
      selectedChapters: null,
      selectedGrades: null,
      selectedDifficulties: null,
      selectedLanguages: null,
      selectedTerms: null,
      selectedYears: null,
      selectedsubjects: null,
      selectedCandidateStatuses: 'Assigned,Onprogress,Finished',
      totalCount: 10,
      isTableDataLoading: true,
      currentPage: 0,
      isLoading: false,
      allCategories: [],
      allLanguages: [],
      allSubjects: [],
      allAuthors: [],
      allYears: [],
      allDifficulties: [],
      allGrades: [],
      allTerms: [],
      allChapters: [],
      allUnits: [],
      allCandidateStatuses: [],
      studentlist: [],
      attemptList: [],
      selectSearchTerm: "",
      selectedCandidates: [],
      maxTimeDurationList: [],
      maxBufferTimeList: [],
      selectAll: false,
      filterValues: [],
      filters: [],
      selectedFilters: [],
      editTestDurationValue: "",
      editBufferDurationValue: "",
      editTimeUnit: 1,
      editBufferTimeUnit: 1,
      currentTimeUnit: "",
      currentBufferTimeUnit: "",
      isSetTimeLimit: false
    };

    // Debounced version of handleSearch function
    this.debouncedHandleSearch = debounce(this.fetchAllCandidateSchedulesData, 3000);
    this.debouncedHandleStudentSearch = debounce(this.fetchAllStudentsForExam, 1000);
    this.debouncedHandlePaperSearch = debounce(this.fetchAllCandidateSchedulesData, 1000);
    this.debouncedHandleAttemptSearch = debounce(this.fetchAllAttemptsForStudentExam, 1000);
  }

  // Reset the form fields to initail values on update, insert, edit modal close and add modal close
  resetFormFields = () => {
    this.setState({
      exam_duration: null,
      buffer_time: null,
      start_time: "",
      editTestDurationValue: "",
      editBufferDurationValue: "",
      editTimeUnit: 1,
      editBufferTimeUnit: 1,
      currentTimeUnit: "",
      currentBufferTimeUnit: "",
      isSetTimeLimit: false
    });
  };

  componentDidMount() {
    this.fetchAllAssessmentMetaData();
    this.fetchAllCandidateSchedulesData();
  }

  // Creating validator instance
  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        customMin: {
          message: "The :attribute value must be greater than :min.",
          rule: (val, params) => {
            return parseInt(val, 10) > params[0];
          },
          messageReplace: (message, params) => message.replace(':min', params[0]),
          required: true
        }
      }
    });
  }

  fetchAllStudentsForExam = async () => {
    const { selectedAssessment , selectedCandidateStatuses} = this.state;

    this.setState({ isTableDataLoading: true });

    await candidateService.getAllStudentsForExam(selectedAssessment, selectedCandidateStatuses)
      .then((response) => {
        if (response.success) {
          this.setState({ studentlist: response.data });
          this.setState({ isTableDataLoading: false });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching students for exam. Please refresh the screen!",
            "error"
          );
          this.setState({ isTableDataLoading: false });
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching students for exam. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  fetchAllAttemptsForStudentExam = async () => {
    const { selectedStudent, selectedAssessment, selectedCandidateStatuses } = this.state;

    this.setState({ isTableDataLoading: true });

    await candidateService.getAllAttemptsForStudentExam(
        selectedStudent, selectedAssessment, selectedCandidateStatuses
    )
      .then((response) => {
        if (response.success) {
          this.setState({ attemptList: response.data });
          this.setState({ isTableDataLoading: false });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching attempts for exam for student. Please refresh the screen!",
            "error"
          );
          this.setState({ isTableDataLoading: false });
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching attempts for exam for student. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  fetchAllAssessmentMetaData  = async () => {
    this.setState({
      isTableDataLoading: true,
    });

    const fetchAssessmentMetaData =
      await assessmentService.getAllAssessmentMetaData();

    await Promise.all([fetchAssessmentMetaData])
      .then((response) => {
        const [assessmentMetaDataResponse] = response;

        this.setState({
          allCategories: assessmentMetaDataResponse.data.find(
            (item) => "categories" in item
          ).categories,
          allLanguages: assessmentMetaDataResponse.data.find(
            (item) => "languages" in item
          ).languages,
          allSubjects: assessmentMetaDataResponse.data.find(
            (item) => "subjects" in item
          ).subjects,
          allAuthors: assessmentMetaDataResponse.data.find(
            (item) => "authors" in item
          ).authors,
          allYears: assessmentMetaDataResponse.data.find(
            (item) => "years" in item
          ).years,
          allDifficulties: assessmentMetaDataResponse.data.find(
            (item) => "difficulty_types" in item
          ).difficulty_types,
          allGrades: assessmentMetaDataResponse.data.find(
            (item) => "grades" in item
          ).grades,
          allTerms: assessmentMetaDataResponse.data.find(
            (item) => "terms" in item
          ).terms,
          allChapters: assessmentMetaDataResponse.data.find(
            (item) => "chapters" in item
          ).chapters,
          allUnits: assessmentMetaDataResponse.data.find(item => 'units' in item).units,
          allCandidateStatuses: [{ id: assessmentStatus.ASSIGNED, status: candidateStatus.NOT_STARTED }, { id: assessmentStatus.ON_PROGRESS, status: candidateStatus.STARTED }, { id: assessmentStatus.FINISHED, status: candidateStatus.COMPLETED }],
          isTableDataLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getPaperCategory(category_id) {
    if (category_id !== null) {
        const category = this.state.allCategories?.find(
            (category) => category.category_id == category_id
        );
        return category?.name;
    }
    else{
        return "N/A";
    }
  }

  getPaperLanguage(language_id) {
    if (language_id !== null) {
        const language = this.state.allLanguages?.find(
            (language) => language.language_id == language_id
        );
        return language?.language;
    }
    else{
        return "N/A";
    }
  }

  getPaperSubject(subject_id) {
    if (subject_id !== null) {
        const subject = this.state.allSubjects?.find(
            (subject) => subject.subject_id == subject_id
        );
        return subject?.subject;
    }
    else{
        return "N/A";
    }
  }

  getPaperAuthor(author_id) {
    if (author_id !== null) {
        const author = this.state.allAuthors?.find(
            (author) => author.author_id == author_id
        );
        return author?.author;
    }
    else{
        return "N/A";
    }
  }

  getPaperYear(year_id) {
    if (year_id !== null) {
        const year = this.state.allYears?.find(
            (year) => year.year_id == year_id
        );
        return year?.year;
    }
    else{
        return "N/A";
    }
  }

  getPaperDifficulty(difficulty_id) {
    if (difficulty_id !== null) {
        const difficulty = this.state.allDifficulties?.find(
            (difficulty) => difficulty.difficulty_id == difficulty_id
        );
        return difficulty?.difficulty_type;
    }
    else{
        return "N/A";
    }
  }

  getPaperGrade(grade_id) {
    if (grade_id !== null) {
        const grade = this.state.allGrades?.find(
            (grade) => grade.grade_id == grade_id
        );
        return grade?.grade;
    }
    else{
        return "N/A";
    }
  }

  getPaperTerm(term_id) {
    if (term_id !== null) {
        const term = this.state.allTerms?.find(
            (term) => term.term_id == term_id
        );
        return term?.term;
    }
    else{
        return "N/A";
    }
  }

  getPaperChapter(chapter_id) {
    if (chapter_id !== null) {
        const chapter = this.state.allChapters?.find(
            (chapter) => chapter.chapter_id == chapter_id
        );
        return chapter?.chapter;
    }
    else{
        return "N/A";
    }
  }

  getScheduleUnit(unit_id, value) {
    if(value !== null){
      if (unit_id !== null) {
        const unit = this.state.allUnits?.find(
          (unit) => unit.unit_id == parseInt(unit_id, 10)
        );
        return unit?.unit;
      }
      else{
        return "N/A";
      }
    }
    else{
      return "";
    }
  }

  convertScheduleInSelectedUnit(value, unit) {
    if(value !== null){
      let valueInSelectedUnit;
      let selectedUnit = this.getScheduleUnit(unit, value);

      switch (selectedUnit) {
        case unitConstants.MONTHS.name:
          valueInSelectedUnit = value / 43200;
          break;
        case unitConstants.HOURS.name:
          valueInSelectedUnit = value / 60;
          break;
        case unitConstants.DAYS.name:
          valueInSelectedUnit = value / 1440;
          break;
        case unitConstants.MINUTES.name:
        default:
          valueInSelectedUnit = value;
      }
      
      return valueInSelectedUnit;
    }
    else{
      return 'Unlimited';
    }
  }

  isDecreasingUnitAllowed = (currentUnit, newUnit) => {
    const unitHierarchy = {
      minutes: 1,
      hours: 2,
      days: 3,
      months: 4
    };

    const currentUnitValue = unitHierarchy[currentUnit];
    const newUnitValue = unitHierarchy[newUnit];
    
    if (newUnitValue > currentUnitValue) {
      return true;
    }
    else{
      return false;
    }
    
  };

  getPaperStartTimeLocal(time) {
    if(time != null){
        let timeStamp = new Date(time.concat("Z"))
        let d = new Date(timeStamp)
        // let startDate = d.toDateString();
        // let startTime = d.toLocaleTimeString('en-SL');
        let  local = moment(timeStamp).local().format('YYYY-MM-DDTHH:mm')
        return local;
    }
    else{
        return "N/A";
    }
  }

  getMinDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  fetchAllCandidateSchedulesData = async () => {
    const { 
      limit, 
      offset, 
      sort_column, 
      sort_direction, 
      search_term, 
      selectedAssessment, 
      selectedStudent, 
      selectedAttempt,
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedGrades,
      selectedDifficulties,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects,
      selectedCandidateStatuses
    } = this.state;
    this.setState({ isTableDataLoading: true })
    await candidateService.getAllCandidateSchedules(
      limit, 
      offset, 
      sort_column, 
      sort_direction, 
      search_term, 
      selectedAssessment, 
      selectedStudent, 
      selectedAttempt,
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedGrades,
      selectedDifficulties,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects,
      selectedCandidateStatuses
    ).then((response) => {
        if (response.success) {
          this.setState({
            schedules: response.data.schedules,
            totalCount: parseInt(response.data.total_count),
            papers: response.data.papers
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching candidate schedules. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        sweetStatusAlert(
          "Failed!",
          "Error fetching candidate schedules. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false }); // Set isTableDataLoading to false after data is fetched
      });
  };

  handleSortChange = (sort_column) => {
    // If the same column is clicked, toggle the sort direction
    const { sort_column: currentSortColumn, sort_direction } = this.state;
    const newSortDirection =
      currentSortColumn === sort_column && sort_direction === "ASC"
        ? "DESC"
        : "ASC";

    this.setState(
      {
        sort_column,
        sort_direction: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
       this.fetchAllCandidateSchedulesData();
      }
    );
  };

  // Handle the data fetching as per the offset(page no) changes
  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllCandidateSchedulesData();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.fetchAllCandidateSchedulesData();
      }
    );
  };

  // handle the data fetching as per the data per page limit gets changess
  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllCandidateSchedulesData();
      }
    );
  };

  // handle search text as per the regex validation and search_term , and fetch data as per the debounce
  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  onFilterInput = (v, r) => {
    let categoryOptions;
    let languageOptions;
    let subjectOptions;
    let authorOptions;
    let gradeOptions;
    let yearOptions;
    let termOptions;
    let chapterOptions;
    let candidateStatusOptions;
    let difficultyOptions;
    let selectedFilters = [];
    this.setState({
      filters: v,
      filterValues: [],
    });

    if (r === "removeOption") {
      this.setState(
        {
          selectedAuthors: null,
          selectedsubjects: null,
          selectedChapters: null,
          selectedTerms: null,
          selectedCategories: null,
          selectedYears: null,
          selectedLanguages: null,
          selectedDifficulties: null,
          selectedGrades: null,
          selectedCandidateStatuses: 'Assigned,Onprogress,Finished',
        },
        () => {
          this.fetchAllCandidateSchedulesData();
        }
      );
    }
    if (v.includes("Category")) {
      categoryOptions = this.state.allCategories?.map((val) => {
        return {
          filter: "Category",
          name: val.name,
          category_id: val.category_id,
        };
      });
      selectedFilters = [...selectedFilters, ...categoryOptions];
    }
    if (v.includes("Language")) {
      languageOptions = this.state.allLanguages?.map((val) => {
        return {
          filter: "Language",
          name: val.language,
          language_id: val.language_id,
        };
      });
      selectedFilters = [...selectedFilters, ...languageOptions];
    }
    if (v.includes("Subject")) {
      subjectOptions = this.state.allSubjects?.map((val) => {
        return {
          filter: "Subject",
          name: val.subject,
          subject_id: val.subject_id,
        };
      });
      selectedFilters = [...selectedFilters, ...subjectOptions];
    }
    if (v.includes("Author")) {
      authorOptions = this.state.allAuthors?.map((val) => {
        return {
          filter: "Author",
          name: val.author,
          author_id: val.author_id,
        };
      });
      selectedFilters = [...selectedFilters, ...authorOptions];
    }
    if (v.includes("Year")) {
      yearOptions = this.state.allYears?.map((val) => {
        return {
          filter: "Year",
          name: val.year,
          year_id: val.year_id,
        };
      });
      selectedFilters = [...selectedFilters, ...yearOptions];
    }
    if (v.includes("Difficulty")) {
      difficultyOptions = this.state.allDifficulties?.map((val) => {
        return {
          filter: "Difficulty",
          name: val.difficulty_type,
          difficulty_id: val.difficulty_id,
        };
      });
      selectedFilters = [...selectedFilters, ...difficultyOptions];
    }
    if (v.includes("Grade")) {
      gradeOptions = this.state.allGrades?.map((val) => {
        return {
          filter: "Grade",
          name: val.grade,
          grade_id: val.grade_id,
        };
      });
      selectedFilters = [...selectedFilters, ...gradeOptions];
    }
    if (v.includes("Term")) {
      termOptions = this.state.allTerms?.map((val) => {
        return {
          filter: "Term",
          name: val.term,
          term_id: val.term_id,
        };
      });
      selectedFilters = [...selectedFilters, ...termOptions];
    }
    if (v.includes("Chapter")) {
      chapterOptions = this.state.allChapters?.map((val) => {
        return {
          filter: "Chapter",
          name: val.chapter,
          chapter_id: val.chapter_id,
        };
      });
      selectedFilters = [...selectedFilters, ...chapterOptions];
    }
    if (v.includes("Status")) {
      candidateStatusOptions = this.state.allCandidateStatuses?.map((val) => {
        return {
          filter: "Status",
          name: val.status,
          id: val.id
        };
      });
      selectedFilters = [...selectedFilters, ...candidateStatusOptions];
    }
    this.setState({
      selectedFilters: selectedFilters,
    });
  };

  onSelectFilter = (e) => {
    let selectedGrades = [];
    let selectedChapters = [];
    let selectedsubjects = [];
    let selectedAuthors = [];
    let selectedYears = [];
    let selectedCategories = [];
    let selectedTerms = [];
    let selectedDifficulties = [];
    let selectedLanguages = [];
    let selectedCandidateStatuses = [];

    e.map((val) => {
      if (val.filter === "Category") {
        selectedCategories.push(val?.category_id);
      }
      if (val.filter === "Difficulty") {
        selectedDifficulties.push(val?.difficulty_id);
      }
      if (val.filter === "Language") {
        selectedLanguages.push(val?.language_id);
      }
      if (val.filter === "Subject") {
        selectedsubjects.push(val?.subject_id);
      }
      if (val.filter === "Author") {
        selectedAuthors.push(val?.author_id);
      }
      if (val.filter === "Year") {
        selectedYears.push(val?.year_id);
      }
      if (val.filter === "Term") {
        selectedTerms.push(val?.term_id);
      }
      if (val.filter === "Chapter") {
        selectedChapters.push(val?.chapter_id);
      }
      if (val.filter === "Grade") {
        selectedGrades.push(val?.grade_id);
      }
      if (val.filter === "Status") {
        selectedCandidateStatuses.push(val?.id);
      }
    });

    this.setState(
      {
        filterValues: e,
        selectedAuthors: selectedAuthors.length > 0 ? selectedAuthors : null,
        selectedsubjects: selectedsubjects.length > 0 ? selectedsubjects : null,
        selectedChapters: selectedChapters.length > 0 ? selectedChapters : null,
        selectedTerms: selectedTerms.length > 0 ? selectedTerms : null,
        selectedCategories:
          selectedCategories.length > 0 ? selectedCategories : null,
        selectedYears: selectedYears.length > 0 ? selectedYears : null,
        selectedLanguages:
          selectedLanguages.length > 0 ? selectedLanguages : null,
        selectedDifficulties:
          selectedDifficulties.length > 0 ? selectedDifficulties : null,
        selectedGrades: selectedGrades.length > 0 ? selectedGrades : null,
        selectedCandidateStatuses: selectedCandidateStatuses.length > 0 ? selectedCandidateStatuses : 'Assigned,Onprogress,Finished',
      },
      () => {
        this.fetchAllCandidateSchedulesData();
      }
    );
  };

  handleClose = () => {
    this.setState({
      showUpdateDetailModal: false
    });
    this.validator.hideMessages();
    this.validator.purgeFields();
    this.resetFormFields();
  }

  handleBulkClose = () => {
    this.setState({
      showUpdateBulkDetailModal: false
    });
    this.validator.hideMessages();
    this.validator.purgeFields();
    this.resetFormFields();
  }

  // Toggle out the state of update form modal
  toggleUpdateCandidateScheduleModal = (candidate_id, start_time, exam_duration, buffer_time, exam_time_interval_unit_id, buffer_time_unit_id, is_set_time_limit) => {
    this.setState((prevState) => ({
      showUpdateDetailModal: !prevState.showUpdateDetailModal,
      candidate_id: candidate_id,
      exam_duration: exam_duration,
      start_time: this.getPaperStartTimeLocal(start_time),
      editTestDurationValue: this.convertScheduleInSelectedUnit(exam_duration, exam_time_interval_unit_id),
      buffer_time: buffer_time,
      editBufferDurationValue: this.convertScheduleInSelectedUnit(buffer_time, buffer_time_unit_id),
      editTimeUnit: exam_time_interval_unit_id,
      currentTimeUnit: exam_time_interval_unit_id,
      editBufferTimeUnit: buffer_time_unit_id,
      currentBufferTimeUnit: buffer_time_unit_id,
      isSetTimeLimit: is_set_time_limit
    }));
  };

  // Toggle out the state of update form modal
  toggleUpdateBulkCandidateScheduleModal = () => {
    this.setState((prevState) => ({
      showUpdateBulkDetailModal: !prevState.showUpdateBulkDetailModal,
    }));
  };

  handleViewClick = (candidateScheduleItem) => {
    this.setState({
      selectedSchedule: candidateScheduleItem,  // Store the selected schedule in state
      showDetailModal: true,  // Open the modal
    });
  };
  
  toggleDetailModal = () => {
    this.setState((prevState) => ({
      showDetailModal: !prevState.showDetailModal,
    }));
  };

  handleChangeExamDuration = (event) => {
    event.preventDefault();
    const editTestDurationValue = parseInt(event.target.value, 10) || 0;
    this.calculateEditDurationInMinutes(editTestDurationValue, this.state.editTimeUnit);
  };

  handleChangeTimeUnit = (e) => {
    e.preventDefault();
    const editTimeUnit = e.target.value;
    this.setState({ editTimeUnit }, () => {
      this.calculateEditDurationInMinutes(this.state.editTestDurationValue, editTimeUnit);;
    });
  };
  
  calculateEditDurationInMinutes = (value, unit) => {
    let editTestDurationInMinutes;
    let timeUnit = this.getScheduleUnit(unit, value);
  
    switch (timeUnit) {
      case unitConstants.MONTHS.name:
        editTestDurationInMinutes = value * 30 * 24 * 60;
        break;
      case unitConstants.HOURS.name:
        editTestDurationInMinutes = value * 60;
        break;
      case unitConstants.DAYS.name:
        editTestDurationInMinutes = value * 24 * 60;
        break;
      case unitConstants.MINUTES.name:
      default:
        editTestDurationInMinutes = value;
    }
  
    this.setState({
      exam_duration: editTestDurationInMinutes,
      editTestDurationValue: value
    });
  };

  handleChangeTime = (event) => {
    event.preventDefault();
    this.setState({ start_time: event.target.value });
  };

  handleChangeBufferTime = (event) => {
    event.preventDefault();
    const editBufferDurationValue = parseInt(event.target.value, 10);
    this.calculateEditBufferDurationInMinutes(editBufferDurationValue, this.state.editBufferTimeUnit);
  };

  handleChangeBufferTimeUnit = (e) => {
    e.preventDefault();
    const editBufferTimeUnit = e.target.value;
    this.setState({ editBufferTimeUnit }, () => {
      this.calculateEditBufferDurationInMinutes(this.state.editBufferDurationValue, editBufferTimeUnit);
    });
  };
  
  calculateEditBufferDurationInMinutes = (value, unit) => {
    let editBufferDurationInMinutes;
    let bufferUnit = this.getScheduleUnit(unit, value);
    switch (bufferUnit) {
      case unitConstants.HOURS.name:
        editBufferDurationInMinutes = value * 60;
        break;
      case unitConstants.DAYS.name:
        editBufferDurationInMinutes = value * 24 * 60;
        break;
      case unitConstants.MONTHS.name:
        editBufferDurationInMinutes = value * 30 * 24 * 60;
        break;
      case unitConstants.MINUTES.name:
      default:
        editBufferDurationInMinutes = value;
    }
  
    this.setState({
      buffer_time: editBufferDurationInMinutes,
      editBufferDurationValue: value,
    });
  };

  handleCheckTimeLimit = () => {
    this.setState(
    (prevState) => ({
      isSetTimeLimit: !prevState.isSetTimeLimit,
    }), () => {
      if(this.state.isSetTimeLimit === false){
        this.setState({ exam_duration: null, buffer_time: null, editTimeUnit: 1, editBufferTimeUnit: 1});
      }
    });
  }

  handleChangeSelectedAssessment = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    this.setState({ selectedAssessment: value, selectedExam: selectedOption }, async () => {
        if (selectedOption) {
          await this.fetchAllStudentsForExam();
          await this.fetchAllCandidateSchedulesData();
        }
        else{
          this.setState({selectedAssessment: null});
          await this.fetchAllCandidateSchedulesData();
        }
    });
  };

  handleChangeSelectedStudent = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    this.setState({ selectedStudent: value, selectedCandidate: selectedOption }, async () => {
        if (selectedOption) {
          await this.fetchAllAttemptsForStudentExam();
          await this.fetchAllCandidateSchedulesData();
        }
        else{
          this.setState({selectedStudent: null}); 
          await this.fetchAllCandidateSchedulesData();
        }
    });
  };

  handleChangeSelectedAttempt = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    this.setState({ selectedAttempt: value, selectedStudentExamAttempt: selectedOption }, async () => {
        if (selectedOption) {
            await this.fetchAllCandidateSchedulesData();
        }
        else{
            this.setState({selectedAttempt: null}); 
            await this.fetchAllCandidateSchedulesData();
        }
    });
  };

  handleSelectStudentSearch = (event) => {
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;
    if (event === "" || regex.test(event)) {
      let num = event
      this.setState(
        {
          selectSearchTerm: num,
          searchError: false,
        },
        () => {
          this.debouncedHandleStudentSearch();
        }
      );
    } else {
      this.setState({ selectSearchTerm: event, searchError: true });
    }
  };

  handleSelectPaperSearch = (event) => {
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;
    if (event === "" || regex.test(event)) {
      let num = event
      this.setState(
        {
          selectSearchTerm: num,
          searchError: false,
        },
        () => {
          this.debouncedHandlePaperSearch();
        }
      );
    } else {
      this.setState({ selectSearchTerm: event, searchError: true });
    }
  };

  handleSelectAttemptSearch = (event) => {
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;
    if (event === "" || regex.test(event)) {
      let num = event
      this.setState(
        {
          selectSearchTerm: num,
          searchError: false,
        },
        () => {
          this.debouncedHandleAttemptSearch();
        }
      );
    } else {
      this.setState({ selectSearchTerm: event, searchError: true });
    }
  };

  // Make put request based on the fields validity and updation of necessary states
  handleUpdateSubmit = async (event) => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });

      let start_time = moment.utc(
        this.state.start_time.concat(
          DataHandler.getFromSession(
            DataHandler.SESSION_DATA.TimeZoneOffset
          )
        )
      );

      // start_time = moment.utc(start_time).format("YYYY-MM-DDTHH:mm:ss");

      start_time = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

      const candidateScheduleItem = {
        candidate_id: this.state.candidate_id,
        start_time: start_time,
        exam_time_interval: this.state.exam_duration,
        buffer_time: this.state.buffer_time,
        exam_time_interval_unit_id: parseInt(this.state.editTimeUnit, 10),
        buffer_time_unit_id: parseInt(this.state.editBufferTimeUnit, 10),
        is_set_time_limit: this.state.isSetTimeLimit
      };

      await candidateService.updateCandidateSchedule(candidateScheduleItem)
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.success) {
            this.setState({ showUpdateDetailModal: false });
            sweetStatusAlert(
              "Success!",
              "Schedule updated successfully!",
              "success"
            );
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
            this.fetchAllCandidateSchedulesData();
          } else {
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert(
            "Failed !",
            "Unable to update the schedule!",
            "error"
          );
        });
    }
  };

  handleUpdateBulkSubmit = async (event) => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });

      let start_time = moment.utc(
        this.state.start_time.concat(
          DataHandler.getFromSession(
            DataHandler.SESSION_DATA.TimeZoneOffset
          )
        )
      );

      // start_time = moment.utc(start_time).format("YYYY-MM-DDTHH:mm:ss");

      start_time = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

      const candidateScheduleItem = {
        candidate_list: this.state.selectedCandidates,
        start_time: start_time,
        exam_time_interval: this.state.exam_duration,
        buffer_time: this.state.buffer_time,
        exam_time_interval_unit_id: this.state.editTimeUnit === null ? 1 : parseInt(this.state.editTimeUnit, 10),
        buffer_time_unit_id: this.state.editBufferTimeUnit === null ? 1 : parseInt(this.state.editBufferTimeUnit, 10),
        is_set_time_limit: this.state.isSetTimeLimit
      };

      await candidateService.updateBulkCandidateSchedule(candidateScheduleItem)
        .then((response) => {
          this.setState({ isLoading: false, selectedCandidates: [], selectAll: false });
          if (response.success) {
            this.setState({ showUpdateDetailModal: false });
            sweetStatusAlert(
              "Success!",
              "Selected schedule/s updated successfully!",
              "success"
            );
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
            this.fetchAllCandidateSchedulesData();
          } else {
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert(
            "Failed !",
            "Unable to update the selected schedule/s!",
            "error"
          );
        });
    }
  };

  getColor(state) {
    switch (state) {
      case assessmentStatus.ASSIGNED:
        return "label label-lg label-light-primary label-inline";
      case assessmentStatus.ON_PROGRESS:
        return "label label-lg label-light-warning label-inline";
      case assessmentStatus.FINISHED:
        return "label label-lg label-success label-inline";
      default:
        return "label label-lg label-light-info label-inline";
    }
  }

  getPaperStatus(state) {
    switch (state) {
      case assessmentStatus.ASSIGNED:
        return candidateStatus.NOT_STARTED;
      case assessmentStatus.FINISHED:
        return candidateStatus.COMPLETED;
      case assessmentStatus.ON_PROGRESS:
        return candidateStatus.STARTED;
      default:
        return state;
    }
  }

  getMaxTimeDuration = () => {
    const { maxTimeDurationList } = this.state;
    const maxNo =  Math.max(...maxTimeDurationList);
    this.setState({max_exam_duration: maxNo})
  };

  getMaxBufferDuration = () => {
    const { maxBufferTimeList } = this.state;
    const maxNo =  Math.max(...maxBufferTimeList);
    this.setState({max_buffer_time: maxNo})
  };
  
  handleSelect = (candidate_id, exam_time_interval, bufffer_time, is_set_time_limit) => {
    this.setState((prevState) => {
      const { selectedCandidates, maxTimeDurationList, maxBufferTimeList } = prevState;
      if (selectedCandidates.includes(candidate_id)) {
        return {
          selectedCandidates: selectedCandidates.filter((id) => (id !== candidate_id && is_set_time_limit)),
          maxTimeDurationList: maxTimeDurationList.filter((time) => (time !== exam_time_interval && is_set_time_limit)),
          maxBufferTimeList: maxBufferTimeList.filter((buffer) => (buffer !== bufffer_time && is_set_time_limit)),
        };
      } else {
        return {
          selectedCandidates: [...selectedCandidates, candidate_id],
          maxTimeDurationList: [...maxTimeDurationList, exam_time_interval],
          maxBufferTimeList: [...maxBufferTimeList, bufffer_time],
        };
      }
    }, 
    () =>{
      this.getMaxTimeDuration();
      this.getMaxBufferDuration();
    });
  };

  handleSelectAll = () => {
    const { selectAll, schedules } = this.state;
    if (selectAll) {
      this.setState({ selectedCandidates: [], selectAll: false, maxTimeDurationList: [], maxBufferTimeList: [] });
    } else {
      const allIds = schedules.filter((item) => (item.candidate_status === assessmentStatus.ASSIGNED)).map((item) => item.candidate_id);
      const allTimes = schedules.filter((item) => (item.candidate_status === assessmentStatus.ASSIGNED)).map((item) => item.exam_time_interval);
      const allBuffers = schedules.filter((item) => (item.candidate_status === assessmentStatus.ASSIGNED)).map((item) => item.buffer_time);
      this.setState({ selectedCandidates: allIds, selectAll: true, maxTimeDurationList: allTimes, maxBufferTimeList: allBuffers }, 
      () => {
        this.getMaxTimeDuration();
        this.getMaxBufferDuration();
      });
    }
  };

  //Rendering the necessary components based on the logic
  render() {
    const {
      limit,
      schedules,
      totalCount,
      isTableDataLoading,
      currentPage,
      isLoading,
      selectedCandidates,
      selectAll
    } = this.state;
    
    this.validator.purgeFields();

    return (
      <>
        <ContentWrapper>
          <div className="row justify-content-between align-items-center py-5 mb-3">
            <div className="col-md-6 col-6">
              <h3 className="text-nowrap">Manage Student Paper Schedules</h3>
            </div>
          </div>
          <div>
          <div className="pr-3 mb-2" style={{ display: "flex", gap: "10px" }}>
            <div className="col-md-9 col-12 px-0">
              <div className="row w-100">
                <Autocomplete
                  multiple
                  size="small"
                  id="checkboxes-filter"
                  options={CANDIDATE_FILTERS}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (
                    <li
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        listStyle: "none",
                      }}
                    >
                      {option}
                    </li>
                  )}
                  onChange={(e, v, r) => this.onFilterInput(v, r)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ fontFamily: "Poppins" }}>
                      <Checkbox
                        style={{ marginRight: 8, color: "#5458AF" }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  className="col-lg-6 col-md-6 col-12 px-0 py-2"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Paper"
                      className="col-md-12"
                    />
                  )}
                />
                {this.state.filters.length > 0 && (
                  <Autocomplete
                    multiple
                    size="small"
                    id="grouped-demo"
                    className="col-lg-6 col-md-6 col-12 px-md-1 px-sm-0 py-2"
                    options={this.state.selectedFilters}
                    groupBy={(option) => option.filter}
                    onChange={(e, v) => this.onSelectFilter(v)}
                    getOptionLabel={(option) => (
                      <li
                        style={{
                          fontFamily: "Poppins, sans-serif",
                          listStyle: "none",
                        }}
                      >
                        {option.name}
                      </li>
                    )}
                    sx={{
                      width: 300,
                      fontFamily: "Poppins, sans-serif",
                    }}
                    value={this.state.filterValues}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Filters"
                        className="col-md-12"
                      />
                    )}
                  />
                )}
              </div>
            </div>
            {this.state.selectedCandidates.length > 0 && 
              <Button
                onClick={() => this.toggleUpdateBulkCandidateScheduleModal()}
                className="btn btn-primary btn-sm m-2" 
                title="Select an/all schedule/s to update selected schedule/s"
                disabled={this.state.selectedCandidates.length === 0 ? true : false} 
              >
                Update Selected Schedule/s
              </Button>
            }
            </div>
            <div className="row">
              <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-start', gap: "10px" }}>
                {this.state.papers.length > 0 && 
                  <ReactSelect
                    isClearable={true}
                    placeholder="Select Paper"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={this.state.papers.map((paper) => ({
                        value: paper.assessment_id,
                        label: `${paper.title}`,
                    }))}
                    value={this.state.selectedExam}
                    isLoading={this.state.isTableDataLoading}
                    onChange={this.handleChangeSelectedAssessment}
                    onInputChange={(inputValue) => {
                        if (inputValue) {
                            this.handleSelectPaperSearch(inputValue);
                        }
                    }}
                  />
                }
                { this.state.papers.length > 0 && 
                  this.state.studentlist.length > 0 && 
                  <ReactSelect
                    isClearable={true}
                    placeholder="Select Student"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={this.state.studentlist.map((student) => ({
                        value: parseInt(student.student_id, 10),
                        label: `${student.first_name + ' ' + student.last_name}`,
                    }))}
                    value={this.state.selectedCandidate}
                    isLoading={this.state.isTableDataLoading}
                    onChange={this.handleChangeSelectedStudent}
                    onInputChange={(inputValue) => {
                        if (inputValue) {
                            this.handleSelectStudentSearch(inputValue);
                        }
                    }}
                  />
                }
                { this.state.papers.length > 0 && 
                  this.state.studentlist.length > 0 && 
                  this.state.attemptList.length > 0 && 
                  <ReactSelect
                    isClearable={true}
                    placeholder="Select Attempt"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={this.state.attemptList.map((attempt) => ({
                        value: attempt.attempt_no,
                        label: `Attempt No ${attempt.attempt_no}`,
                    }))}
                    value={this.state.selectedStudentExamAttempt}
                    isLoading={this.state.isTableDataLoading}
                    onChange={this.handleChangeSelectedAttempt}
                    onInputChange={(inputValue) => {
                        if (inputValue) {
                            this.handleSelectAttemptSearch(inputValue);
                        }
                    }}
                  />
                } 
              </div>
              <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                <Form.Group controlId="exampleForm.ControlInput1" style={{ width: "300px" }}>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    style={{ width: "100%" }}
                    className="col-md-12 col-12"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>
            </div>
            {isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            {isTableDataLoading ? (
              <div
                className="loading-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "75vw",
                  height: "90vh",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : schedules.length > 0 ? (
            <div className="row">
              <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {this.state.selectedCandidateStatuses == assessmentStatus.ASSIGNED && 
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                              checked={selectAll}
                              onChange={this.handleSelectAll}
                              color="primary"
                            />
                            <span>Select All</span>
                          </div>
                        }
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("last_name")}
                      >
                        Student Name{" "}
                        {this.state.sort_column === "last_name" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("exam_time_interval")}
                      >
                        Paper Time Duration{" "}
                        {this.state.sort_column === "exam_time_interval" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("buffer_time")}
                      >
                        Paper Access Buffer Duration{" "}
                        {this.state.sort_column === "buffer_time" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("attempt_no")}
                      >
                        Paper Current Attempt No{" "}
                        {this.state.sort_column === "attempt_no" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("candidate_status")}
                      >
                        Paper Status{" "}
                        {this.state.sort_column === "candidate_status" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                      </TableCell>
                      {/* <TableCell className="thead">Student Name</TableCell> */}
                      {/* <TableCell className="thead">Paper Time Duration</TableCell> */}
                      {/* <TableCell className="thead">Paper Access Buffer Duration</TableCell> */}
                      {/* <TableCell className="thead">Paper Current Attempt No</TableCell> */}
                      {/* <TableCell className="thead">Paper Status</TableCell> */}
                      <TableCell className="thead">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {schedules.map((candidateScheduleItem) => (
                      <TableRow key={candidateScheduleItem.candidate_id}>
                        <TableCell>
                          {this.state.selectedCandidateStatuses == assessmentStatus.ASSIGNED && 
                            <Checkbox
                              checked={selectedCandidates.includes(candidateScheduleItem.candidate_id)}
                              onChange={() => this.handleSelect(candidateScheduleItem.candidate_id, candidateScheduleItem.exam_time_interval, candidateScheduleItem.buffer_time, candidateScheduleItem.is_set_time_limit)}
                              color="primary"
                            />
                          }
                        </TableCell>
                        <TableCell className="tData">
                          {candidateScheduleItem.first_name + ' ' + candidateScheduleItem.last_name} 
                        </TableCell>
                        <TableCell className="tData">
                          {this.convertScheduleInSelectedUnit(candidateScheduleItem.exam_time_interval, candidateScheduleItem.exam_time_interval_unit_id) + ' ' + this.getScheduleUnit(candidateScheduleItem.exam_time_interval_unit_id, candidateScheduleItem.exam_time_interval)} 
                        </TableCell>
                        <TableCell className="tData">
                          {this.convertScheduleInSelectedUnit(candidateScheduleItem.buffer_time, candidateScheduleItem.buffer_time_unit_id) + ' ' + this.getScheduleUnit(candidateScheduleItem.buffer_time_unit_id, candidateScheduleItem.buffer_time)}
                        </TableCell>
                        <TableCell className="tData">
                          {candidateScheduleItem.attempt_no + ' out of ' + candidateScheduleItem.no_of_attempts}
                        </TableCell>
                        <TableCell className="tData">
                          <div className={this.getColor(candidateScheduleItem.candidate_status)}>
                            {this.getPaperStatus(candidateScheduleItem.candidate_status)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="btn-group">
                            <Button
                              onClick={() => this.handleViewClick(candidateScheduleItem)}
                              className="btn btn-icon btn-primary btn-sm m-1 view-assement-btn flaticon-eye-icon"
                              title="View More Details"
                            />
                            {candidateScheduleItem.candidate_status === assessmentStatus.ASSIGNED && 
                              <Button
                                onClick={() =>
                                  this.toggleUpdateCandidateScheduleModal(
                                    candidateScheduleItem.candidate_id,
                                    candidateScheduleItem.start_time,
                                    candidateScheduleItem.exam_time_interval,
                                    candidateScheduleItem.buffer_time,
                                    candidateScheduleItem.exam_time_interval_unit_id,
                                    candidateScheduleItem.buffer_time_unit_id,
                                    candidateScheduleItem.is_set_time_limit
                                  )}
                                className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                title="Edit Schedule Details"
                              />
                            }
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <MaterialCustomTablePagination
                  totalCount = {totalCount}
                  currentPage = {currentPage}
                  limit = {limit}
                  handlePageChange = {this.handlePageChange}
                  handleRowsPerPageChange = {this.handleRowsPerPageChange}
                  handlePageOffsetChange = {this.handlePageOffsetChange}
                  offset = {this.state.offset}
                  rowsPerPageOptions={[5, 10, 20]}
                />
              </div>
            </div>
            ) : (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="thead">Student Name</TableCell>
                      <TableCell className="thead">Paper Time Duration</TableCell>
                      <TableCell className="thead">Paper Access Buffer Duration</TableCell>
                      <TableCell className="thead">Paper Current Attempt No</TableCell>
                      <TableCell className="thead">Paper Status</TableCell>
                      <TableCell className="thead">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <div className="text-center mb-5 my-10">
                  <label>No papers are available!</label>
                </div>
              </div>
            )}
          </div>
        </ContentWrapper>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showUpdateDetailModal}
          onHide={this.toggleUpdateCandidateScheduleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Schedule Details</Modal.Title>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "25px", marginTop: "20px" }}>
              <Form.Label style={{ marginRight: "10px", marginBottom: "0px" }}>Set Time Limit</Form.Label>
              <Form.Label className="mb-0 mr-2" style={{ fontWeight: "500", marginRight: "10px" }}>
                Yes
              </Form.Label>
              <Checkbox
                onChange={this.handleCheckTimeLimit}
                checked={this.state.isSetTimeLimit}
                style={{ marginRight: "10px" }}
              />
              <Form.Label className="mb-0 mr-2" style={{ fontWeight: "500", marginRight: "10px" }}>
                No
              </Form.Label>
              <Checkbox
                onChange={this.handleCheckTimeLimit}
                checked={!this.state.isSetTimeLimit}
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  {!this.state.isSetTimeLimit && 
                    <div className="text-primary">Time limit is not set. Please enable it.</div>
                  }
                  {this.state.isSetTimeLimit && 
                    <>
                      <Form.Label>Time Duration</Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Control
                          type="number"
                          placeholder="Enter new value in selected unit"
                          name="duration"
                          value={this.state.editTestDurationValue || ''}
                          onChange={this.handleChangeExamDuration}
                          style={{ width: '70%', marginRight: '5px' }}
                        />
                        <Form.Select
                          id="edit-assessment-schedule-time-units"
                          value={this.state.editTimeUnit}
                          onChange={this.handleChangeTimeUnit}
                          className="form-control"
                          style={{ width: '30%' }}
                        >
                          {this.state.allUnits.map((option, index) => (
                            <option key={index} value={option.unit_id}>
                              {option.unit}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      {this.validator.message(
                        "duration",
                        this.state.editTestDurationValue || '',
                        `required|integer|customMin:${this.convertScheduleInSelectedUnit(this.state.editTestDurationValue, 1)}`,
                        {
                          className: "text-danger",
                          messages: {
                            required: "The paper duration field is required.",
                            integer: "The paper duration must be a valid integer.",
                          },
                        }
                      )}
                    </>
                  }
                </Form.Group>
                <Form.Group 
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <div></div>
                  {/* <Form.Label>Start Date/Time</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    placeholder="Select the new start date/time"
                    name="start_time"
                    value={this.state.start_time || ''}
                    min={this.getMinDateTime()}
                    onChange={this.handleChangeTime}
                    style={{ marginBottom: '16px' }}
                  />
                  {this.validator.message(
                    "start_time",
                    this.state.start_time || '',
                    `required`,
                    {
                      className: "text-danger",
                      messages: {
                        required: "The start date/time field is required."
                      },
                    }
                  )} */}
                  {this.state.isSetTimeLimit && 
                    <>
                      <Form.Label>Access Buffer Duration</Form.Label>
                        <div className="d-flex align-items-center">
                          <Form.Control
                            type="number"
                            placeholder="Enter new value in selected unit"
                            name="buffer"
                            value={this.state.editBufferDurationValue || ''}
                            onChange={this.handleChangeBufferTime}
                            style={{ width: '70%', marginRight: '5px' }}
                          />
                          <Form.Select
                            id="edit-assessment-schedule-time-units"
                            value={this.state.editBufferTimeUnit}
                            onChange={this.handleChangeBufferTimeUnit}
                            className="form-control"
                            style={{ width: '30%' }}
                          >
                            {this.state.allUnits.map((option, index) => (
                              <option key={index} value={option.unit_id}>
                                {option.unit}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        {this.validator.message(
                          "buffer",
                          this.state.editBufferDurationValue || '',
                          `required|integer|customMin:${this.convertScheduleInSelectedUnit(this.state.editBufferDurationValue, 1)}`,
                          {
                            className: "text-danger",
                            messages: {
                              required: "The paper buffer time field is required.",
                              integer: "The paper buffer time must be a valid integer.",
                            },
                          }
                        )}  
                    </>
                  }
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="modal-btn"
              variant="primary"
              style={addBtnStyle}
              onClick={this.handleUpdateSubmit}
            >
              Save changes
            </Button>
            <Button
              className="modal-btn"
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={this.handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showUpdateBulkDetailModal}
          onHide={this.toggleUpdateBulkCandidateScheduleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Selected Schedule/s</Modal.Title>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "25px", marginTop: "20px" }}>
              <Form.Label style={{ marginRight: "10px", marginBottom: "0px" }}>Set Time Limit</Form.Label>
              <Form.Label className="mb-0 mr-2" style={{ fontWeight: "500", marginRight: "10px" }}>
                Yes
              </Form.Label>
              <Checkbox
                onChange={this.handleCheckTimeLimit}
                checked={this.state.isSetTimeLimit}
                style={{ marginRight: "10px" }}
              />
              <Form.Label className="mb-0 mr-2" style={{ fontWeight: "500", marginRight: "10px" }}>
                No
              </Form.Label>
              <Checkbox
                onChange={this.handleCheckTimeLimit}
                checked={!this.state.isSetTimeLimit}
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  {!this.state.isSetTimeLimit && 
                    <div className="text-muted">Time limit is not set. Please enable it.</div>
                  }
                  {this.state.isSetTimeLimit && 
                    <>
                      <Form.Label>Time Duration</Form.Label>
                        <div className="d-flex align-items-center">
                          <Form.Control
                            type="number"
                            placeholder="Enter new value in selected unit"
                            name="duration"
                            value={this.state.editTestDurationValue || ''}
                            onChange={this.handleChangeExamDuration}
                            className="mr-2" 
                            style={{ width: '70%', marginRight: '5px' }}
                          />
                          <Form.Select
                            id="edit-assessment-schedule-time-units"
                            value={this.state.editTimeUnit}
                            onChange={this.handleChangeTimeUnit}
                            className="form-control"
                            style={{ width: '30%' }}
                          >
                            {this.state.allUnits.map((option, index) => (
                              <option key={index} value={option.unit_id}>
                                {option.unit}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        {this.validator.message(
                          "duration",
                          this.state.editTestDurationValue || '',
                          `required|integer|customMin:${this.state.max_exam_duration}`,
                          {
                            className: "text-danger",
                            messages: {
                              required: "The paper duration field is required.",
                              customMin: `The paper duration should not be decreased than the current maximum value ${this.state.max_exam_duration} minutes of all selected schedules.`,
                              integer: "The paper duration must be a valid integer.",
                            },
                          }
                        )}
                    </>
                  }
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <div></div>
                  {/* <Form.Label>Start Date/Time</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    placeholder="Select the new start date/time"
                    name="start_time"
                    value={this.state.start_time || ''}
                    min={this.getMinDateTime()}
                    onChange={this.handleChangeTime}
                    style={{ marginBottom: '16px' }}
                  />
                  {this.validator.message(
                    "start_time",
                    this.state.start_time || '',
                    `required`,
                    {
                      className: "text-danger",
                      messages: {
                        required: "The start date/time field is required."
                      },
                    }
                  )} */}
                  {this.state.isSetTimeLimit &&
                    <>
                      <Form.Label>Access Buffer Duration</Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Control
                          type="number"
                          placeholder="Enter new value in selected unit"
                          name="buffer"
                          value={this.state.editBufferDurationValue || ''}
                          onChange={this.handleChangeBufferTime}
                          style={{ width: '70%', marginRight: '5px' }}
                        />
                        <Form.Select
                          id="edit-assessment-schedule-time-units"
                          value={this.state.editBufferTimeUnit}
                          onChange={this.handleChangeBufferTimeUnit}
                          className="form-control"
                          style={{ width: '30%' }}
                        >
                          {this.state.allUnits.map((option, index) => (
                            <option key={index} value={option.unit_id}>
                              {option.unit}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      {this.validator.message(
                        "buffer",
                        this.state.editBufferDurationValue || '',
                        `required|integer|customMin:${this.state.max_buffer_time}`,
                        {
                          className: "text-danger",
                          messages: {
                            required: "The paper buffer time field is required.",
                            customMin: `The paper buffer time should not be decreased than the current maximum value ${this.state.max_buffer_time} minutes of all selected schedules.`,
                            max: "The paper buffer time should not exceed 365 days.",
                          },
                        }
                      )}
                    </>
                  }
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="modal-btn"
              variant="primary"
              style={addBtnStyle}
              onClick={this.handleUpdateBulkSubmit}
            >
              Save changes
            </Button>
            <Button
              className="modal-btn"
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={this.handleBulkClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          centered
          show={this.state.showDetailModal}
          onHide={this.toggleDetailModal}
        >
          <ModalHeader>
            <h5 className="modal-title">{this.state.selectedSchedule?.title}</h5>
            <Button variant="secondary" onClick={this.toggleDetailModal}>
              Close
            </Button>
          </ModalHeader>
          {this.state.selectedSchedule && (
          <ModalBody>
            <ViewForm className="form" id="kt_form">
              <div className="row">
                <h6 className="font-weight-bolder mb-3 col-md-4">
                  Student Code :
                </h6>
                <div className="text-dark-50 line-height-lg col-md-5">
                  <div className="inline-flex full-width align-center">
                    {this.state.selectedSchedule?.user_code}
                  </div>
                </div>
              </div>
              {/* <div className="separator separator-dashed my-5"></div>
              <div className="row">
                <h6 className="font-weight-bolder mb-3 col-md-4">
                  Student Name :
                </h6>
                <div className="text-dark-50 line-height-lg col-md-5">
                  <div className="inline-flex full-width align-center">
                    {this.state.selectedSchedule?.first_name + ' ' + this.state.selectedSchedule?.last_name}
                  </div>
                </div>
              </div> */}
              <div className="separator separator-dashed my-5"></div>
              <div className="row">
                <h6 className="font-weight-bolder mb-3 col-md-4">
                  Total Allowed Attempts :
                </h6>
                <div className="text-dark-50 line-height-lg col-md-5">
                  <div className="inline-flex full-width align-center">
                    {this.state.selectedSchedule?.no_of_attempts}
                  </div>
                </div>
              </div> 
              <div className="separator separator-dashed my-5"></div>
              <div className="row">
                <h6 className="font-weight-bolder mb-3 col-md-4">
                  Current Attempt No :
                </h6>
                <div className="text-dark-50 line-height-lg col-md-5">
                  <div className="inline-flex full-width align-center">
                    {this.state.selectedSchedule?.attempt_no}
                  </div>
                </div>
              </div>
              {/* <div className="separator separator-dashed my-5"></div>
              <div className="row">
                <h6 className="font-weight-bolder mb-3 col-md-4">
                  Start Time :
                </h6>
                <div className="text-dark-50 line-height-lg col-md-5">
                  <div className="inline-flex full-width align-center">
                    {this.getPaperStartTimeLocal(this.state.selectedSchedule?.start_time)}
                  </div>
                </div>
              </div> */}
              {this.state.selectedSchedule?.attempt_start_time !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Attempt Start Time :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperStartTimeLocal(this.state.selectedSchedule?.attempt_start_time)}
                      </div>
                    </div>
                  </div>
                </>
              }     
              {this.state.selectedSchedule?.attempt_end_time !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Attempt End Time :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperStartTimeLocal(this.state.selectedSchedule?.attempt_end_time)}
                      </div>
                    </div>
                  </div>
                </>
              }     
              {/* <div className="separator separator-dashed my-5"></div>
              <div className="row">
                <h6 className="font-weight-bolder mb-3 col-md-4">
                  Time Duration :
                </h6>
                <div className="text-dark-50 line-height-lg col-md-5">
                  <div className="inline-flex full-width align-center">
                    {this.convertScheduleInSelectedUnit(this.state.selectedSchedule?.exam_time_interval, this.state.selectedSchedule?.exam_time_interval_unit_id) + ' ' + this.getScheduleUnit(this.state.selectedSchedule?.exam_time_interval_unit_id, this.state.selectedSchedule?.exam_time_interval)} 
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-5"></div>
              <div className="row">
                <h6 className="font-weight-bolder mb-3 col-md-4">
                  Access Buffer Duration :
                </h6>
                <div className="text-dark-50 line-height-lg col-md-5">
                  <div className="inline-flex full-width align-center">
                    {this.convertScheduleInSelectedUnit(this.state.selectedSchedule?.buffer_time, this.state.selectedSchedule?.buffer_time_unit_id) + ' ' + this.getScheduleUnit(this.state.selectedSchedule?.buffer_time_unit_id, this.state.selectedSchedule?.buffer_time)} 
                  </div>
                  </div>
              </div> */}
              {/* <div className="separator separator-dashed my-5"></div>
              <div className="row">
                <h6 className="font-weight-bolder mb-3 col-md-4">
                  Status :
                </h6>
                <div className="text-dark-50 line-height-lg col-md-5">
                  <div className="inline-flex full-width align-center">
                    <div className={this.getColor(this.state.selectedSchedule.candidate_status)}>
                      {this.getPaperStatus(this.state.selectedSchedule.candidate_status)}
                    </div>
                  </div>
                </div>
              </div> */}
              {this.state.selectedSchedule?.grade_id !== null && 
                <>          
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Grade :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperGrade(this.state.selectedSchedule?.grade_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedSchedule?.category_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Category :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperCategory(this.state.selectedSchedule?.category_id)}
                      </div>
                    </div>
                  </div>
                </>
              }     
              {this.state.selectedSchedule?.language_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Language/Medium :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperLanguage(this.state.selectedSchedule?.language_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedSchedule?.difficulty_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Difficulty :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperDifficulty(this.state.selectedSchedule?.difficulty_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedSchedule?.subject_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Subject :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperSubject(this.state.selectedSchedule?.subject_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedSchedule?.chapter_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Chapter/Module/Unit :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperChapter(this.state.selectedSchedule?.chapter_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedSchedule?.author_id !== null &&
                <>
                  <div className="separator separator-dashed my-5"></div> 
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Author :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperAuthor(this.state.selectedSchedule?.author_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedSchedule?.year_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Year :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperYear(this.state.selectedSchedule?.year_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
              {this.state.selectedSchedule?.term_id !== null && 
                <>
                  <div className="separator separator-dashed my-5"></div>
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Term/Semester :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      <div className="inline-flex full-width align-center">
                        {this.getPaperTerm(this.state.selectedSchedule?.term_id)}
                      </div>
                    </div>
                  </div>
                </>
              }
            </ViewForm>
          </ModalBody>
          )}
        </Modal>
      </>
    );
  }
}
