import {testConstants} from '../../helper/constants';
import { gradeService} from '../../service/candidate/gradeService';

export function saveMarks(data) {
  return async (dispatch) => {
    dispatch(save_marks_request());

    try {
      var arr = [];
      for (const [, value] of Object.entries(data.questions)) {
        arr.push(value);
      }
      data.questions = arr;
      var answers = await gradeService.saveMarks(data);
      dispatch(save_marks_success(answers.data));
    } catch (error) {
      dispatch(save_marks_failure(error));
    }
  };

  function save_marks_request() {
    return { type: testConstants.SAVE_MARKS_REQUEST };
  }

  function save_marks_success(data) {
    return { type: testConstants.SAVE_MARKS_SUCCESS, data };
  }

  function save_marks_failure(error) {
    return { type: testConstants.SAVE_MARKS_FAILURE, error };
  }
}

export function finalizeGrade(assessment) {
  return async (dispatch) => {
    dispatch(finalize_grade());

    var data = new Object();
    Object.assign(data, assessment);

    let content = {
      candidate_id: data.candidate_id,
      user_role_id: data.user_role_id,
      final_grade: "",
      attempt_no: data.attempt_no,
      total_mark_allocation: data.total_mark_allocation,
      grade: data.grade
    };

    if (data.final_grade == null) {
      content["final_grade"] = "grade";
      data["final_grade"] = "0";
    }

    dispatch(request_finalize());

    try {
      var message = await gradeService.finalizeGrade(content);
      var messageData = message.data
      if(messageData["final_grade"] === null){
        messageData["final_grade"] = 0
      }
      dispatch(success_finalize({ data, messageData }));
    } catch (error) {
      dispatch(failure_finalize(error));
    }
  };

  function finalize_grade() {
    return { type: testConstants.FINALIZE_GRADE };
  }

  function request_finalize() {
    return { type: testConstants.FINALIZE_GRADE_REQUEST };
  }

  function success_finalize(candidate) {
    return { type: testConstants.FINALIZE_GRADE_SUCCESS, candidate };
  }

  function failure_finalize(error) {
    return { type: testConstants.FINALIZE_GRADE_FAILURE, error };
  }
}

export function saveSelfMarks(data) {
  return async (dispatch) => {
    dispatch(save_self_marks_request());

    try {
      var arr = [];
      for (const [, value] of Object.entries(data.questions)) {
        arr.push(value);
      }
      data.questions = arr;
      var answers = await gradeService.saveSelfMarks(data);
      dispatch(save_self_marks_success(answers));
    } catch (error) {
      dispatch(save_self_marks_failure(error));
    }
  };

  function save_self_marks_request() {
    return { type: testConstants.SAVE_SELF_MARKS_REQUEST };
  }

  function save_self_marks_success(data) {
    return { type: testConstants.SAVE_SELF_MARKS_SUCCESS, data };
  }

  function save_self_marks_failure(error) {
    return { type: testConstants.SAVE_SELF_MARKS_FAILURE, error };
  }
}

export function finalizeSelfGrade(assessment) {
  return async (dispatch) => {
    dispatch(finalize_self_grade());

    var data = new Object();
    Object.assign(data, assessment);

    let content = {
      candidate_id: data.candidate_id,
      user_role_id: data.user_role_id,
      final_grade: "",
      attempt_no: data.attempt_no,
      total_mark_allocation: data.total_mark_allocation,
      grade: data.grade
    };

    if (data.final_grade == null) {
      content["final_grade"] = "grade";
      data["final_grade"] = null;
    }

    dispatch(request_finalize());

    try {
      var message = await gradeService.selfFinalizeGrade(content);
      var messageData = message.data
      dispatch(success_finalize({ data, messageData }));
    } catch (error) {
      dispatch(failure_finalize(error));
    }
  };

  function finalize_self_grade() {
    return { type: testConstants.FINALIZE_SELF_GRADE };
  }

  function request_finalize() {
    return { type: testConstants.FINALIZE_SELF_GRADE_REQUEST };
  }

  function success_finalize(candidate) {
    return { type: testConstants.FINALIZE_SELF_GRADE_SUCCESS, candidate };
  }

  function failure_finalize(error) {
    return { type: testConstants.FINALIZE_SELF_GRADE_FAILURE, error };
  }
} 