export const authenticationTypes = {
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    TOKEN_REQUEST: 'ACCESS_TOKEN_REQUEST',
    TOKEN_SUCCESS: 'ACCESS_TOKEN_SUCCESS',
    TOKEN_FAILURE: 'ACCESS_TOKEN_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
};

export const alertTypes = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const userTypes = {
    GET_ME_REQUEST: 'GET_ME_REQUEST',
    GET_ME_SUCCESS: 'GET_ME_SUCCESS',
    GET_ME_FAILURE: 'GET_ME_FAILURE',

    GET_ALL_USERS: 'GET_ALL_USERS',
    GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_FAILURE: 'GET_ALL_FAILURE',

    GET_ALL_STAFFUSERS: 'GET_ALL_STAFFUSERS',
    GET_ALL_STAFFUSERS_REQUEST: 'GET_ALL_STAFFUSERS_REQUEST',
    GET_ALL_STAFFUSERS_SUCCESS: 'GET_ALL_STAFFUSERS_SUCCESS',
    GET_ALL_STAFFUSERS_FAILURE: 'GET_ALL_STAFFUSERS_FAILURE',

    GET_ALL_STUDENTUSERS: 'GET_ALL_STUDENTUSERS',
    GET_ALL_STUDENTUSERS_REQUEST: 'GET_ALL_STUDENTUSERS_REQUEST',
    GET_ALL_STUDENTUSERS_SUCCESS: 'GET_ALL_STUDENTUSERS_SUCCESS',
    GET_ALL_STUDENTUSERS_FAILURE: 'GET_ALL_STUDENTUSERS_FAILURE',

    GET_TEACHER_ME: 'GET_TEACHER_ME',
    GET_TEACHER_ME_REQUEST: 'GET_TEACHER_ME_REQUEST',
    GET_TEACHER_ME_SUCCESS: 'GET_TEACHER_ME_SUCCESS',
    GET_TEACHER_ME_FAILURE: 'GET_TEACHER_ME_FAILURE',

    GET_TEACHER_DETAILS: 'GET_TEACHER_DETAILS',
    GET_TEACHER_DETAILS_REQUEST: 'GET_TEACHER_DETAILS_REQUEST',
    GET_TEACHER_DETAILS_SUCCESS: 'GET_TEACHER_DETAILS_SUCCESS',
    GET_TEACHER_DETAILS_FAILURE: 'GET_TEACHER_DETAILS_FAILURE',

    GET_TEACHER_DETAILS_FOR_STUDENT: 'GET_TEACHER_DETAILS_FOR_STUDENT',
    GET_TEACHER_DETAILS_FOR_STUDENT_REQUEST: 'GET_TEACHER_DETAILS_FOR_STUDENT_REQUEST',
    GET_TEACHER_DETAILS_FOR_STUDENT_SUCCESS: 'GET_TEACHER_DETAILS_FOR_STUDENT_SUCCESS',
    GET_TEACHER_DETAILS_FOR_STUDENT_FAILURE: 'GET_TEACHER_DETAILS_FOR_STUDENT_FAILURE',

    GET_ALL_TEACHERS: 'GET_ALL_TEACHERS',
    GET_ALL_TEACHERS_REQUEST: 'GET_ALL_TEACHERS_REQUEST',
    GET_ALL_TEACHERS_SUCCESS: 'GET_ALL_TEACHERS_SUCCESS',
    GET_ALL_TEACHERS_FAILURE: 'GET_ALL_TEACHERS_FAILURE',

    GET_ALL_STUDENTS: 'GET_ALL_STUDENTS',
    GET_ALL_STUDENTS_REQUEST: 'GET_ALL_STUDENTS_REQUEST',
    GET_ALL_STUDENTS_SUCCESS: 'GET_ALL_STUDENTS_SUCCESS',
    GET_ALL_STUDENTS_FAILURE: 'GET_ALL_STUDENTS_FAILURE',

    GET_STUDENT_DETAILS: 'GET_STUDENT_DETAILS',
    GET_STUDENT_DETAILS_REQUEST: 'GET_STUDENT_DETAILS_REQUEST',
    GET_STUDENT_DETAILS_SUCCESS: 'GET_STUDENT_DETAILS_SUCCESS',
    GET_STUDENT_DETAILS_FAILURE: 'GET_STUDENT_DETAILS_FAILURE',

    GET_USERS: 'GET_USERS',
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',

    CREATE_USER_MAP: 'CREATE_USER_MAP',
     
    CREATE_STAFFUSER_MAP: 'CREATE_STAFFUSER_MAP',

    CREATE_STUDENTUSER_MAP: 'CREATE_STUDENTUSER_MAP',

    CREATE_USER_ROLES_MAP: 'CREATE_USER_ROLES_MAP',
    GET_USER_ROLES_REQUEST: 'GET_USER_ROLES_REQUEST',
    GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
    GET_USER_ROLES_FAILURE: 'GET_USER_ROLES_FAILURE',

    CREATE_STAFF_USER_ROLES_MAP: 'CREATE_STAFF_USER_ROLES_MAP',
    GET_STAFF_USER_ROLES_REQUEST: 'GET_STAFF_USER_ROLES_REQUEST',
    GET_STAFF_USER_ROLES_SUCCESS: 'GET_STAFF_USER_ROLES_SUCCESS',
    GET_STAFF_USER_ROLES_FAILURE: 'GET_STAFF_USER_ROLES_FAILURE',

    CREATE_STUDENT_USER_ROLES_MAP: 'CREATE_STUDENT_USER_ROLES_MAP',
    GET_ALL_STUDENT_USER_ROLES_REQUEST: 'GET_ALL_STUDENT_USER_ROLES_REQUEST',
    GET_ALL_STUDENT_USER_ROLES_SUCCESS: 'GET_ALL_STUDENT_USER_ROLES_SUCCESS',
    GET_ALL_STUDENT_USER_ROLES_FAILURE: 'GET_ALL_STUDENT_USER_ROLES_FAILURE',

    GET_ALL_ROLES_REQUEST: 'GET_ALL_ROLES_REQUEST',
    GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
    GET_ALL_ROLES_FAILURE: 'GET_ALL_ROLES_FAILURE'
};

export const assessmentTypes = {
    CREATE_ASSESSMENT: 'CREATE_ASSESSMENT',
    CREATE_ASSESSMENT_REQUEST: 'CREATE_ASSESSMENT_REQUEST',
    CREATE_ASSESSMENT_SUCCESS: 'CREATE_ASSESSMENT_SUCCESS',
    CREATE_ASSESSMENT_FAILURE: 'CREATE_ASSESSMENT_FAILURE',
    CREATE_ASSESSMENT_REFRESH: 'CREATE_ASSESSMENT_REFRESH',

    EDIT_ASSESSMENT: 'EDIT_ASSESSMENT',
    EDIT_ASSESSMENT_REQUEST: 'EDIT_ASSESSMENT_REQUEST',
    EDIT_ASSESSMENT_SUCCESS: 'EDIT_ASSESSMENT_SUCCESS',
    EDIT_ASSESSMENT_FAILURE: 'EDIT_ASSESSMENT_FAILURE',

    DELETE_ASSESSMENT: 'DELETE_ASSESSMENT',
    DELETE_ASSESSMENT_REQUEST: 'DELETE_ASSESSMENT_REQUEST',
    DELETE_ASSESSMENT_SUCCESS: 'DELETE_ASSESSMENT_SUCCESS',
    DELETE_ASSESSMENT_FAILURE: 'DELETE_ASSESSMENT_FAILURE',

    GET_ASSESSMENT_LANGUAGE: 'GET_ASSESSMENT_LANGUAGE',
    GET_ASSESSMENT_LANGUAGE_REQUEST: 'GET_ASSESSMENT_LANGUAGE_REQUEST',
    GET_ASSESSMENT_LANGUAGE_SUCCESS: 'GET_ASSESSMENT_LANGUAGE_SUCCESS',
    GET_ASSESSMENT_LANGUAGE_FAILURE: 'GET_ASSESSMENT_LANGUAGE_FAILURE',
    
    GET_ASSESSMENT_DIFFICULTY: 'GET_ASSESSMENT_DIFFICULTY',
    GET_ASSESSMENT_DIFFICULTY_REQUEST: 'GET_ASSESSMENT_DIFFICULTY_REQUEST',
    GET_ASSESSMENT_DIFFICULTY_SUCCESS: 'GET_ASSESSMENT_DIFFICULTY_SUCCESS',
    GET_ASSESSMENT_DIFFICULTY_FAILURE: 'GET_ASSESSMENT_DIFFICULTY_FAILURE',

    GET_ASSESSMENT_CATEGORY: 'GET_ASSESSMENT_CATEGORY',
    GET_ASSESSMENT_CATEGORY_REQUEST: 'GET_ASSESSMENT_CATEGORY_REQUEST',
    GET_ASSESSMENT_CATEGORY_SUCCESS: 'GET_ASSESSMENT_CATEGORY_SUCCESS',
    GET_ASSESSMENT_CATEGORY_FAILURE: 'GET_ASSESSMENT_CATEGORY_FAILURE',

    GET_ASSESSMENT_PRIVILEGE: 'GET_ASSESSMENT_PRIVILEGE',
    GET_ASSESSMENT_PRIVILEGE_REQUEST: 'GET_ASSESSMENT_PRIVILEGE_REQUEST',
    GET_ASSESSMENT_PRIVILEGE_SUCCESS: 'GET_ASSESSMENT_PRIVILEGE_SUCCESS',
    GET_ASSESSMENT_PRIVILEGE_FAILURE: 'GET_ASSESSMENT_PRIVILEGE_FAILURE',

    GET_ASSESSMENT_ATTEMPT: 'GET_ASSESSMENT_ATTEMPT',
    GET_ASSESSMENT_ATTEMPT_REQUEST: 'GET_ASSESSMENT_ATTEMPT_REQUEST',
    GET_ASSESSMENT_ATTEMPT_SUCCESS: 'GET_ASSESSMENT_ATTEMPT_SUCCESS',
    GET_ASSESSMENT_ATTEMPT_FAILURE: 'GET_ASSESSMENT_ATTEMPT_FAILURE',

    GET_ASSESSMENT_GRADE: 'GET_ASSESSMENT_GRADE',
    GET_ASSESSMENT_GRADE_REQUEST: 'GET_ASSESSMENT_GRADE_REQUEST',
    GET_ASSESSMENT_GRADE_SUCCESS: 'GET_ASSESSMENT_GRADE_SUCCESS',
    GET_ASSESSMENT_GRADE_FAILURE: 'GET_ASSESSMENT_GRADE_FAILURE',

    GET_ASSESSMENT_SUBJECT: 'GET_ASSESSMENT_SUBJECT',
    GET_ASSESSMENT_SUBJECT_REQUEST: 'GET_ASSESSMENT_SUBJECT_REQUEST',
    GET_ASSESSMENT_SUBJECT_SUCCESS: 'GET_ASSESSMENT_SUBJECT_SUCCESS',
    GET_ASSESSMENT_SUBJECT_FAILURE: 'GET_ASSESSMENT_SUBJECT_FAILURE',

    GET_ASSESSMENT_AUTHOR: 'GET_ASSESSMENT_AUTHOR',
    GET_ASSESSMENT_AUTHOR_REQUEST: 'GET_ASSESSMENT_AUTHOR_REQUEST',
    GET_ASSESSMENT_AUTHOR_SUCCESS: 'GET_ASSESSMENT_AUTHOR_SUCCESS',
    GET_ASSESSMENT_AUTHOR_FAILURE: 'GET_ASSESSMENT_AUTHOR_FAILURE',

    GET_ASSESSMENT_YEAR: 'GET_ASSESSMENT_YEAR',
    GET_ASSESSMENT_YEAR_REQUEST: 'GET_ASSESSMENT_YEAR_REQUEST',
    GET_ASSESSMENT_YEAR_SUCCESS: 'GET_ASSESSMENT_YEAR_SUCCESS',
    GET_ASSESSMENT_YEAR_FAILURE: 'GET_ASSESSMENT_YEAR_FAILURE',

    GET_ASSESSMENT_TERM: 'GET_ASSESSMENT_TERM',
    GET_ASSESSMENT_TERM_REQUEST: 'GET_ASSESSMENT_TERM_REQUEST',
    GET_ASSESSMENT_TERM_SUCCESS: 'GET_ASSESSMENT_TERM_SUCCESS',
    GET_ASSESSMENT_TERM_FAILURE: 'GET_ASSESSMENT_TERM_FAILURE',

    GET_ASSESSMENT_CHAPTER: 'GET_ASSESSMENT_CHAPTER',
    GET_ASSESSMENT_CHAPTER_REQUEST: 'GET_ASSESSMENT_CHAPTER_REQUEST',
    GET_ASSESSMENT_CHAPTER_SUCCESS: 'GET_ASSESSMENT_CHAPTER_SUCCESS',
    GET_ASSESSMENT_CHAPTER_FAILURE: 'GET_ASSESSMENT_CHAPTER_FAILURE',

    GET_ASSESSMENT_META_DATA: 'GET_ASSESSMENT_META_DATA',
    GET_ASSESSMENT_META_DATA_REQUEST: 'GET_ASSESSMENT_META_DATA_REQUEST',
    GET_ASSESSMENT_META_DATA_SUCCESS: 'GET_ASSESSMENT_META_DATA_SUCCESS',
    GET_ASSESSMENT_META_DATA_FAILURE: 'GET_ASSESSMENT_META_DATA_FAILURE',

    SEARCH_ASSESSMENT: 'SEARCH_ASSESSMENT',
    FETCH_ASSESSMENT: 'FETCH_ASSESSMENT',
    ERROR_ASSESSMENT: 'ERROR_ASSESSMENT',

    VIEW_ASSESSMENT: 'VIEW_ASSESSMENT',
    VIEW_ASSESSMENT_REQUEST: 'VIEW_ASSESSMENT_REQUEST',
    VIEW_ASSESSMENT_SUCCESS: 'VIEW_ASSESSMENT_SUCCESS',
    VIEW_ASSESSMENT_FAILURE: 'VIEW_ASSESSMENT_FAILURE',
    VIEW_ASSESSMENT_REFRESH: 'VIEW_ASSESSMENT_REFRESH',

    EDIT_DETAILS: 'EDIT_DETAILS',
    EDIT_DETAILS_REQUEST: 'EDIT_DETAILS_REQUEST',
    EDIT_DETAILS_SUCCESS: 'EDIT_DETAILS_SUCCESS',
    EDIT_DETAILS_FAILURE: 'EDIT_DETAILS_FAILURE',

    SCHEDULE_ASSESSMENT: 'SCHEDULE_ASSESSMENT',
    SCHEDULE_ASSESSMENT_REQUEST: 'SCHEDULE_ASSESSMENT_REQUEST',
    SCHEDULE_ASSESSMENT_REFRESH: 'SCHEDULE_ASSESSMENT_REFRESH',
    SCHEDULE_ASSESSMENT_SUCCESS: 'SCHEDULE_ASSESSMENT_SUCCESS',
    SCHEDULE_ASSESSMENT_FAILURE: 'SCHEDULE_ASSESSMENT_FAILURE',

    GET_SCHEDULE: 'GET_SCHEDULE',
    GET_SCHEDULE_REQUEST: 'GET_SCHEDULE_REQUEST',
    GET_SCHEDULE_SUCCESS: 'GET_SCHEDULE_SUCCESS',
    GET_SCHEDULE_FAILURE: 'GET_SCHEDULE_FAILURE',

    GET_ASSESSOR_REMARKS_REQUEST: 'GET_ASSESSOR_REMARKS_REQUEST',
    GET_ASSESSOR_REMARKS_SUCCESS: 'GET_ASSESSOR_REMARKS_SUCCESS',
    GET_ASSESSOR_REMARKS_FAILURE: 'GET_ASSESSOR_REMARKS_FAILURE',

    EDIT_SCHEDULE: ' EDIT_SCHEDULE',
    EDIT_SCHEDULE_REQUEST: 'EDIT_SCHEDULE_REQUEST',
    EDIT_SCHEDULE_REFRESH: 'EDIT_SCHEDULE_REFRESH',
    EDIT_SCHEDULE_SUCCESS: 'EDIT_SCHEDULE_SUCCESS',
    EDIT_SCHEDULE_FAILURE: 'EDIT_SCHEDULE_FAILURE',

    EDIT_CANDIDATES_ASSESSMENT: 'EDIT_CANDIDATES_ASSESSMENT',
    EDIT_CANDIDATES_ASSESSMENT_REQUEST: 'EDIT_CANDIDATES_ASSESSMENT_REQUEST',
    EDIT_CANDIDATES_ASSESSMENT_REFRESH: 'EDIT_CANDIDATES_ASSESSMENT_REFRESH',
    EDIT_CANDIDATES_ASSESSMENT_SUCCESS: 'EDIT_CANDIDATES_ASSESSMENT_SUCCESS',
    EDIT_CANDIDATES_ASSESSMENT_FAILURE: 'EDIT_CANDIDATES_ASSESSMENT_FAILURE',

    ASSIGN_ASSESSORS_ASSESSMENT: 'ASSIGN_ASSESSORS_ASSESSMENT',
    ASSIGN_ASSESSORS_ASSESSMENT_REQUEST: 'ASSIGN_ASSESSORS_ASSESSMENT_REQUEST',
    ASSIGN_ASSESSORS_ASSESSMENT_REFRESH: 'ASSIGN_ASSESSORS_ASSESSMENT_REFRESH',
    ASSIGN_ASSESSORS_ASSESSMENT_SUCCESS: 'ASSIGN_ASSESSORS_ASSESSMENT_SUCCESS',
    ASSIGN_ASSESSORS_ASSESSMENT_FAILURE: 'ASSIGN_ASSESSORS_ASSESSMENT_FAILURE',

    GET_ASSESSMENT_ASSESSORS: 'GET_ASSESSMENT_ASSESSORS',
    GET_ASSESSMENT_ASSESSORS_REQUEST: 'GET_ASSESSMENT_ASSESSORS_REQUEST',
    GET_ASSESSMENT_ASSESSORS_SUCCESS: 'GET_ASSESSMENT_ASSESSORS_SUCCESS',
    GET_ASSESSMENT_ASSESSORS_FAILURE: 'GET_ASSESSMENT_ASSESSORS_FAILURE',

    EDIT_ASSESSORS_ASSESSMENT: 'EDIT_ASSESSORS_ASSESSMENT',
    EDIT_ASSESSORS_ASSESSMENT_REFRESH: 'EDIT_ASSESSORS_ASSESSMENT_REFRESH',
    EDIT_ASSESSORS_ASSESSMENT_REQUEST: 'EDIT_ASSESSORS_ASSESSMENT_REQUEST',
    EDIT_ASSESSORS_ASSESSMENT_SUCCESS: 'EDIT_ASSESSORS_ASSESSMENT_SUCCESS',
    EDIT_ASSESSORS_ASSESSMENT_FAILURE: 'EDIT_ASSESSORS_ASSESSMENT_FAILURE',

    ADD_CANDIDATES_ASSESSMENT: 'ADD_CANDIDATES_ASSESSMENT',
    ADD_CANDIDATES_ASSESSMENT_REQUEST: 'ADD_CANDIDATES_ASSESSMENT_REQUEST',
    ADD_CANDIDATES_ASSESSMENT_REFRESH: 'ADD_CANDIDATES_ASSESSMENT_REFRESH',
    ADD_CANDIDATES_ASSESSMENT_SUCCESS: 'ADD_CANDIDATES_ASSESSMENT_SUCCESS',
    ADD_CANDIDATES_ASSESSMENT_FAILURE: 'ADD_CANDIDATES_ASSESSMENT_FAILURE',

    GET_CANDIDATES: 'GET_CANDIDATES',
    GET_CANDIDATES_REQUEST: 'GET_CANDIDATES_REQUEST',
    GET_CANDIDATES_SUCCESS: 'GET_CANDIDATES_SUCCESS',
    GET_CANDIDATES_FAILURE: 'GET_CANDIDATES_FAILURE',

    GET_CANDIDATE: 'GET_CANDIDATE',
    GET_CANDIDATE_REQUEST: 'GET_CANDIDATE_REQUEST',
    GET_CANDIDATE_SUCCESS: 'GET_CANDIDATE_SUCCESS',
    GET_CANDIDATE_FAILURE: 'GET_CANDIDATE_FAILURE',

    EDIT_CANDIDATES: ' EDIT_SCHEDULE',
    EDIT_CANDIDATES_REQUEST: 'EDIT_SCHEDULE_REQUEST',
    EDIT_CANDIDATES_REFRESH: 'EDIT_SCHEDULE_REFRESH',
    EDIT_CANDIDATES_SUCCESS: 'EDIT_SCHEDULE_SUCCESS',
    EDIT_CANDIDATES_FAILURE: 'EDIT_SCHEDULE_FAILURE',

    ADD_SCHEDULE_ASSESSMENT: 'ADD_SCHEDULE_ASSESSMENT',
    ADD_SCHEDULE_ASSESSMENT_REQUEST: 'ADD_SCHEDULE_ASSESSMENT_REQUEST',
    ADD_SCHEDULE_ASSESSMENT_REFRESH: 'ADD_SCHEDULE_ASSESSMENT_REFRESH',
    ADD_SCHEDULE_ASSESSMENT_SUCCESS: 'ADD_SCHEDULE_ASSESSMENT_SUCCESS',
    ADD_SCHEDULE_ASSESSMENT_FAILURE: 'ADD_SCHEDULE_ASSESSMENT_FAILURE',

    GET_ALL_QUESTIONS: 'GET_ALL_QUESTIONS',
    GET_ALL_QUESTIONS_REQUEST: 'GET_ALL_QUESTIONS_REQUEST',
    GET_ALL_QUESTIONS_SUCCESS: 'GET_ALL_QUESTIONS_SUCCESS',
    GET_ALL_QUESTIONS_FAILURE: 'GET_ALL_QUESTIONS_FAILURE',

    GET_ALL_ADD_QUESTIONS: 'GET_ALL_ADD_QUESTIONS',
    GET_ALL_ADD_QUESTIONS_REQUEST: 'GET_ALL_ADD_QUESTIONS_REQUEST',
    GET_ALL_ADD_QUESTIONS_SUCCESS: 'GET_ALL_ADD_QUESTIONS_SUCCESS',
    GET_ALL_ADD_QUESTIONS_FAILURE: 'GET_ALL_ADD_QUESTIONS_FAILURE',

    GET_ALL_QUESTIONS_CONTENTS_BY_TYPE: 'GET_ALL_QUESTIONS_CONTENTS_BY_TYPE',
    GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_REQUEST: 'GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_REQUEST',
    GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_SUCCESS: 'GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_SUCCESS',
    GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_FAILURE: 'GET_ALL_QUESTIONS_CONTENTS_BY_TYPE_FAILURE',

    

    GET_CANDIDATE_ANSWERS: 'GET_CANDIDATE_ANSWERS',
    GET_CANDIDATE_ANSWERS_REQUEST: 'GET_CANDIDATE_ANSWERS_REQUEST',
    GET_CANDIDATE_ANSWERS_SUCCESS: 'GET_CANDIDATE_ANSWERS_SUCCESS',
    GET_CANDIDATE_ANSWERS_FAILURE: 'GET_CANDIDATE_ANSWERS_FAILURE',

    GET_QUESTION_CONTENT: 'GET_QUESTION_CONTENT',
    GET_QUESTION_CONTENT_REQUEST: 'GET_QUESTION_CONTENT_REQUEST',
    GET_QUESTION_CONTENT_SUCCESS: 'GET_QUESTION_CONTENT_SUCCESS',
    GET_QUESTION_CONTENT_FAILURE: 'GET_QUESTION_CONTENT_FAILURE',

    GET_QUESTION_GUIDANCE_CONTENT: 'GET_QUESTION_GUIDANCE_CONTENT',
    GET_QUESTION_GUIDANCE_CONTENT_REQUEST: 'GET_QUESTION_GUIDANCE_CONTENT_REQUEST',
    GET_QUESTION_GUIDANCE_CONTENT_SUCCESS: 'GET_QUESTION_GUIDANCE_CONTENT_SUCCESS',
    GET_QUESTION_GUIDANCE_CONTENT_FAILURE: 'GET_QUESTION_GUIDANCE_CONTENT_FAILURE',

    GET_CANDIDATE_REPORTS: 'GET_CANDIDATE_REPORTS',
    GET_CANDIDATE_REPORTS_REQUEST: 'GET_CANDIDATE_REPORTS_REQUEST',
    GET_CANDIDATE_REPORTS_SUCCESS: 'GET_CANDIDATE_REPORTS_SUCCESS',
    GET_CANDIDATE_REPORTS_FAILURE: 'GET_CANDIDATE_REPORTS_FAILURE',

    ADD_QUESTIONS_ASSESSMENT: 'ADD_QUESTIONS_ASSESSMENT',
    ADD_QUESTIONS_PAGE_REFRESH: 'ADD_QUESTIONS_PAGE_REFRESH',
    ADD_QUESTIONS_ASSESSMENT_REQUEST: 'ADD_QUESTIONS_ASSESSMENT_REQUEST',
    ADD_QUESTIONS_ASSESSMENT_REFRESH: 'ADD_QUESTIONS_ASSESSMENT_REFRESH',
    ADD_QUESTIONS_ASSESSMENT_SUCCESS: 'ADD_QUESTIONS_ASSESSMENT_SUCCESS',
    ADD_QUESTIONS_ASSESSMENT_FAILURE: 'ADD_QUESTIONS_ASSESSMENT_FAILURE',

    GET_ASSESSMENT_QUESTIONS: 'GET_ASSESSMENT_QUESTIONS',
    GET_ASSESSMENT_QUESTIONS_REQUEST: 'GET_ASSESSMENT_QUESTIONS_REQUEST',
    GET_ASSESSMENT_QUESTIONS_SUCCESS: 'GET_ASSESSMENT_QUESTIONS_SUCCESS',
    GET_ASSESSMENT_QUESTIONS_FAILURE: 'GET_ASSESSMENT_QUESTIONS_FAILURE',

    GET_ALL_ASSESSMENT_QUESTIONS: 'GET_ALL_ASSESSMENT_QUESTIONS',
    GET_ALL_ASSESSMENT_QUESTIONS_REQUEST: 'GET_ALL_ASSESSMENT_QUESTIONS_REQUEST',
    GET_ALL_ASSESSMENT_QUESTIONS_SUCCESS: 'GET_ALL_ASSESSMENT_QUESTIONS_SUCCESS',
    GET_ALL_ASSESSMENT_QUESTIONS_FAILURE: 'GET_ALL_ASSESSMENT_QUESTIONS_FAILURE',

    GET_SET_QUESTION: 'GET_SET_QUESTION',
    GET_SET_QUESTION_REQUEST: 'GET_SET_QUESTION_REQUEST',
    GET_SET_QUESTION_SUCCESS: 'GET_SET_QUESTION_SUCCESS',
    GET_SET_QUESTION_FAILURE: 'GET_SET_QUESTION_FAILURE',

    GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME: 'GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME',
    GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_REQUEST: 'GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_REQUEST',
    GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_SUCCESS: 'GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_SUCCESS',
    GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_FAILURE: 'GET_ASSESSMENT_QUESTIONS_ADDED_BY_ME_FAILURE',

    REMOVE_QUESTIONS_ADDED_BY_ME: 'REMOVE_QUESTIONS_ADDED_BY_ME',
    REMOVE_QUESTIONS_ADDED_BY_ME_REQUEST: 'REMOVE_QUESTIONS_ADDED_BY_ME_REQUEST',
    REMOVE_QUESTIONS_ADDED_BY_ME_REFRESH: 'REMOVE_QUESTIONS_ADDED_BY_ME_REFRESH',
    REMOVE_QUESTIONS_ADDED_BY_ME_SUCCESS: 'REMOVE_QUESTIONS_ADDED_BY_ME_SUCCESS',
    REMOVE_QUESTIONS_ADDED_BY_ME_FAILURE: 'REMOVE_QUESTIONS_ADDED_BY_ME_FAILURE',

    SAVE_MARK_ALLOCATIONS: 'SAVE_MARK_ALLOCATIONS',
    SAVE_MARK_ALLOCATIONS_REQUEST: 'SAVE_MARK_ALLOCATIONS_REQUEST',
    SAVE_MARK_ALLOCATIONS_SUCCESS: 'SAVE_MARK_ALLOCATIONS_SUCCESS',
    SAVE_MARK_ALLOCATIONS_FAILURE: 'SAVE_MARK_ALLOCATIONS_FAILURE',

    SAVE_MARKS_REQUEST: 'SAVE_MARKS_REQUEST',
    SAVE_MARKS_SUCCESS: 'SAVE_MARKS_SUCCESS',
    SAVE_MARKS_FAILURE: 'SAVE_MARKS_FAILURE',

    FINALIZE_ALL: 'FINALIZE_ALL',
    FINALIZE_ALL_REQUEST: 'FINALIZE_ALL_REQUEST',
    FINALIZE_ALL_SUCCESS: 'FINALIZE_ALL_SUCCESS',
    FINALIZE_ALL_FAILURE: 'FINALIZE_ALL_FAILURE',

    FINALIZE_GRADE: 'FINALIZE_GRADE',
    FINALIZE_GRADE_REQUEST: 'FINALIZE_GRADE_REQUEST',
    FINALIZE_GRADE_SUCCESS: 'FINALIZE_GRADE_SUCCESS',
    FINALIZE_GRADE_FAILURE: 'FINALIZE_GRADE_FAILURE',

    GET_ALL_QUESTION_CONTENTS: 'GET_ALL_QUESTION_CONTENTS',
    GET_ALL_QUESTION_CONTENTS_REQUEST: 'GET_ALL_QUESTION_CONTENTS_REQUEST',
    GET_ALL_QUESTION_CONTENTS_SUCCESS: 'GET_ALL_QUESTION_CONTENTS_SUCCESS',
    GET_ALL_QUESTION_CONTENTS_FAILURE: 'GET_ALL_QUESTION_CONTENTS_FAILURE',

    GET_ALL_CANDIDATE_ANSWER_CONTENTS: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS',
    GET_ALL_CANDIDATE_ANSWER_CONTENTS_REQUEST: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS_REQUEST',
    GET_ALL_CANDIDATE_ANSWER_CONTENTS_SUCCESS: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS_SUCCESS',
    GET_ALL_CANDIDATE_ANSWER_CONTENTS_FAILURE: 'GET_ALL_CANDIDATE_ANSWER_CONTENTS_FAILURE',

    GET_ALL_ANSWER_CONTENTS: 'GET_ALL_ANSWER_CONTENTS',
    GET_ALL_ANSWER_CONTENTS_REQUEST: 'GET_ALL_ANSWER_CONTENTS_REQUEST',
    GET_ALL_ANSWER_CONTENTS_SUCCESS: 'GET_ALL_ANSWER_CONTENTS_SUCCESS',
    GET_ALL_ANSWER_CONTENTS_FAILURE: 'GET_ALL_ANSWER_CONTENTS_FAILURE',

    DELETE_CANDIDATE: 'DELETE_CANDIDATE',
    DELETE_CANDIDATE_REQUEST: 'DELETE_CANDIDATE_REQUEST',
    DELETE_CANDIDATE_SUCCESS: 'DELETE_CANDIDATE_SUCCESS',
    DELETE_CANDIDATE_FAILURE: 'DELETE_CANDIDATE_FAILURE',

    FINALIZE_ASSESSMENT: 'FINALIZE_ASSESSMENT',
    FINALIZE_ASSESSMENT_REQUEST: 'FINALIZE_ASSESSMENT_REQUEST',
    FINALIZE_ASSESSMENT_REFRESH: 'FINALIZE_ASSESSMENT_REFRESH',
    FINALIZE_ASSESSMENT_SUCCESS: 'FINALIZE_ASSESSMENT_SUCCESS',
    FINALIZE_ASSESSMENT_FAILURE: 'FINALIZE_ASSESSMENT_FAILURE',

    REORDER_QUESTIONS: 'REORDER_QUESTIONS',

    SET_MARKS: 'SET_MARKS'
}

export const testTypes = {
    VIEW_TEST: 'VIEW_TEST',
    VIEW_TEST_REQUEST: 'VIEW_TEST_REQUEST',
    VIEW_TEST_SUCCESS: 'VIEW_TEST_SUCCESS',
    VIEW_TEST_FAILURE: 'VIEW_TEST_FAILURE',
    VIEW_TEST_REFRESH: 'VIEW_TEST_REFRESH',

    SUBMIT_ANSWER: 'SUBMIT_ANSWER',
    SUBMIT_ANSWER_REQUEST: 'SUBMIT_ANSWER_REQUEST',
    SUBMIT_ANSWER_SUCCESS: 'SUBMIT_ANSWER_SUCCESS',
    SUBMIT_ANSWER_FAILURE: 'SUBMIT_ANSWER_FAILURE',
    SUBMIT_ANSWER_REFRESH: 'SUBMIT_ANSWER_REFRESH',

    GET_TEST_QUESTION_CONTENT: 'GET_TEST_QUESTION_CONTENT',
    GET_TEST_QUESTION_CONTENT_REQUEST: 'GET_TEST_QUESTION_CONTENT_REQUEST',
    GET_TEST_QUESTION_CONTENT_SUCCESS: 'GET_TEST_QUESTION_CONTENT_SUCCESS',
    GET_TEST_QUESTION_CONTENT_FAILURE: 'GET_TEST_QUESTION_CONTENT_FAILURE',

    VALIDATE_CANDIDATE: 'VALIDATE_CANDIDATE',
    VALIDATE_CANDIDATE_REQUEST: 'VALIDATE_CANDIDATE_REQUEST',
    VALIDATE_CANDIDATE_SUCCESS: 'VALIDATE_CANDIDATE_SUCCESS',
    VALIDATE_CANDIDATE_FAILURE: 'VALIDATE_CANDIDATE_FAILURE',
    VALIDATE_CANDIDATE_REFRESH: 'VALIDATE_CANDIDATE_REFRESH',

    LOAD_EXAM_QUESTIONS: 'LOAD_EXAM_QUESTIONS',
    LOAD_EXAM_QUESTIONS_REQUEST: 'LOAD_EXAM_QUESTIONS_REQUEST',
    LOAD_EXAM_QUESTIONS_SUCCESS: 'LOAD_EXAM_QUESTIONS_SUCCESS',
    LOAD_EXAM_QUESTIONS_FAILURE: 'LOAD_EXAM_QUESTIONS_FAILURE',
    LOAD_EXAM_QUESTIONS_REFRESH: 'LOAD_EXAM_QUESTIONS_REFRESH'
}

export const createTypes = {
    CREATE_PAGE_REFRESH: 'CREATE_PAGE_REFRESH',
    SELECT_QUESTION_TYPE: 'SELECT_QUESTION_TYPE',
    SELECT_QUESTION_CATEGORY: 'SELECT_QUESTION_CATEGORY',
    SELECT_QUESTION_DIFFICULTY: 'SELECT_QUESTION_DIFFICULTY',
    SELECT_QUESTION_LANGUAGE: 'SELECT_QUESTION_LANGUAGE',
    SELECT_QUESTION_SOURCE: 'SELECT_QUESTION_SOURCE',
    SELECT_QUESTION_SUBJECT: 'SELECT_QUESTION_SUBJECT',
    SELECT_QUESTION_AUTHOR: 'SELECT_QUESTION_AUTHOR',
    SELECT_QUESTION_YEAR: 'SELECT_QUESTION_YEAR',
    SELECT_QUESTION_TERM: 'SELECT_QUESTION_TERM',

    GET_QUESTION_TYPES: 'GET_QUESTION_TYPES',
    GET_QUESTION_TYPES_REQUEST: 'GET_QUESTION_TYPES_REQUEST',
    GET_QUESTION_TYPES_SUCCESS: 'GET_QUESTION_TYPES_SUCCESS',
    GET_QUESTION_TYPES_FAILURE: 'GET_QUESTION_TYPES_FAILURE',

    GET_QUESTION_DIFFICULTY: 'GET_QUESTION_DIFFICULTY',
    GET_QUESTION_DIFFICULTY_REQUEST: 'GET_QUESTION_DIFFICULTY_REQUEST',
    GET_QUESTION_DIFFICULTY_SUCCESS: 'GET_QUESTION_DIFFICULTY_SUCCESS',
    GET_QUESTION_DIFFICULTY_FAILURE: 'GET_QUESTION_DIFFICULTY_FAILURE',

    GET_QUESTION_PRIVILEGE: 'GET_QUESTION_PRIVILEGE',
    GET_QUESTION_PRIVILEGE_REQUEST: 'GET_QUESTION_PRIVILEGE_REQUEST',
    GET_QUESTION_PRIVILEGE_SUCCESS: 'GET_QUESTION_PRIVILEGE_SUCCESS',
    GET_QUESTION_PRIVILEGE_FAILURE: 'GET_QUESTION_PRIVILEGE_FAILURE',

    GET_QUESTION_LANGUAGE: 'GET_QUESTION_LANGUAGE',
    GET_QUESTION_LANGUAGE_REQUEST: 'GET_QUESTION_LANGUAGE_REQUEST',
    GET_QUESTION_LANGUAGE_SUCCESS: 'GET_QUESTION_LANGUAGE_SUCCESS',
    GET_QUESTION_LANGUAGE_FAILURE: 'GET_QUESTION_LANGUAGE_FAILURE',

    GET_QUESTION_GRADE: 'GET_QUESTION_GRADE',
    GET_QUESTION_GRADE_REQUEST: 'GET_QUESTION_GRADE_REQUEST',
    GET_QUESTION_GRADE_SUCCESS: 'GET_QUESTION_GRADE_SUCCESS',
    GET_QUESTION_GRADE_FAILURE: 'GET_QUESTION_GRADE_FAILURE',

    GET_QUESTION_CHAPTER: 'GET_QUESTION_CHAPTER',
    GET_QUESTION_CHAPTER_REQUEST: 'GET_QUESTION_CHAPTER_REQUEST',
    GET_QUESTION_CHAPTER_SUCCESS: 'GET_QUESTION_CHAPTER_SUCCESS',
    GET_QUESTION_CHAPTER_FAILURE: 'GET_QUESTION_CHAPTER_FAILURE',

    GET_QUESTION_SOURCE: 'GET_QUESTION_SOURCE',
    GET_QUESTION_SOURCE_REQUEST: 'GET_QUESTION_SOURCE_REQUEST',
    GET_QUESTION_SOURCE_SUCCESS: 'GET_QUESTION_SOURCE_SUCCESS',
    GET_QUESTION_SOURCE_FAILURE: 'GET_QUESTION_SOURCE_FAILURE',

    GET_QUESTION_SUBJECT: 'GET_QUESTION_SUBJECT',
    GET_QUESTION_SUBJECT_REQUEST: 'GET_QUESTION_SUBJECT_REQUEST',
    GET_QUESTION_SUBJECT_SUCCESS: 'GET_QUESTION_SUBJECT_SUCCESS',
    GET_QUESTION_SUBJECT_FAILURE: 'GET_QUESTION_SUBJECT_FAILURE',

    GET_QUESTION_AUTHOR: 'GET_QUESTION_AUTHOR',
    GET_QUESTION_AUTHOR_REQUEST: 'GET_QUESTION_AUTHOR_REQUEST',
    GET_QUESTION_AUTHOR_SUCCESS: 'GET_QUESTION_AUTHOR_SUCCESS',
    GET_QUESTION_AUTHOR_FAILURE: 'GET_QUESTION_AUTHOR_FAILURE',

    GET_QUESTION_YEAR: 'GET_QUESTION_YEAR',
    GET_QUESTION_YEAR_REQUEST: 'GET_QUESTION_YEAR_REQUEST',
    GET_QUESTION_YEAR_SUCCESS: 'GET_QUESTION_YEAR_SUCCESS',
    GET_QUESTION_YEAR_FAILURE: 'GET_QUESTION_YEAR_FAILURE',

    GET_QUESTION_TERM: 'GET_QUESTION_TERM',
    GET_QUESTION_TERM_REQUEST: 'GET_QUESTION_TERM_REQUEST',
    GET_QUESTION_TERM_SUCCESS: 'GET_QUESTION_TERM_SUCCESS',
    GET_QUESTION_TERM_FAILURE: 'GET_QUESTION_TERM_FAILURE',

    GET_QUESTION_CATEGORIES: 'GET_QUESTION_CATEGORIES',
    GET_QUESTION_CATEGORIES_REQUEST: 'GET_QUESTION_CATEGORIES_REQUEST',
    GET_QUESTION_CATEGORIES_SUCCESS: 'GET_QUESTION_CATEGORIES_SUCCESS',
    GET_QUESTION_CATEGORIES_FAILURE: 'GET_QUESTION_CATEGORIES_FAILURE',

    GET_ALL_QUESTION_CATEGORIES: 'GET_ALL_QUESTION_CATEGORIES',
    GET_ALL_QUESTION_CATEGORIES_REQUEST: 'GET_ALL_QUESTION_CATEGORIES_REQUEST',
    GET_ALL_QUESTION_CATEGORIES_SUCCESS: 'GET_ALL_QUESTION_CATEGORIES_SUCCESS',
    GET_ALL_QUESTION_CATEGORIES_FAILURE: 'GET_ALL_QUESTION_CATEGORIES_FAILURE',

    ADD_QUESTION_CATEGORY: 'ADD_QUESTION_CATEGORY',
    ADD_QUESTION_CATEGORY_REQUEST: 'ADD_QUESTION_CATEGORY_REQUEST',
    ADD_QUESTION_CATEGORY_SUCCESS: 'ADD_QUESTION_CATEGORY_SUCCESS',
    ADD_QUESTION_CATEGORY_FAILURE: 'ADD_QUESTION_CATEGORY_FAILURE',

    GET_QUESTION_BY_ID: 'GET_QUESTION_BY_ID_REQUEST',
    GET_QUESTION_BY_ID_SUCCESS: 'GET_QUESTION_BY_ID_SUCCESS',
    GET_QUESTION_BY_ID_FAILURE: 'GET_QUESTION_BY_ID_FAILURE',

    CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
    CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
    CREATE_CATEGORY_FAILURE: 'CREATE_CATEGORY_FAILURE',

    GET_QUESTION_META_DATA: 'GET_QUESTION_META_DATA',
    GET_QUESTION_META_DATA_REQUEST: 'GET_QUESTION_META_DATA_REQUEST',
    GET_QUESTION_META_DATA_SUCCESS: 'GET_QUESTION_META_DATA_SUCCESS',
    GET_QUESTION_META_DATA_FAILURE: 'GET_QUESTION_META_DATA_FAILURE',
};

export const submissionTypes = {
    SUBMISSION_PAGE_REFRESH: 'SUBMISSION_PAGE_REFRESH',

    GET_SAS_TOKEN_REQUEST: 'GET_SAS_TOKEN_REQUEST',
    GET_SAS_TOKEN_SUCCESS: 'GET_SAS_TOKEN_SUCCESS',
    GET_SAS_TOKEN_FAILURE: 'GET_SAS_TOKEN_FAILURE',

    GET_MEDIA_REQUEST: 'GET_MEDIA_REQUEST',
    GET_MEDIA_SUCCESS: 'GET_MEDIA_SUCCESS',
    GET_MEDIA_FAILURE: 'GET_MEDIA_FAILURE',

    CREATE_SUBMISSION_QUESTION: 'CREATE_SUBMISSION_QUESTION',
    CREATE_SUBMISSION_QUESTION_REQUEST: 'CREATE_SUBMISSION_QUESTION_REQUEST',
    CREATE_SUBMISSION_QUESTION_SUCCESS: 'CREATE_SUBMISSION_QUESTION_SUCCESS',
    CREATE_SUBMISSION_QUESTION_FAILURE: 'CREATE_SUBMISSION_QUESTION_FAILURE',

    SAVE_SUBMISSION_QUESTION_CONTENT: 'SAVE_SUBMISSION_QUESTION_CONTENT',
    SAVE_SUBMISSION_QUESTION_CONTENT_REQUEST: 'SAVE_SUBMISSION_QUESTION_CONTENT_REQUEST',
    SAVE_SUBMISSION_QUESTION_CONTENT_SUCCESS: 'SAVE_SUBMISSION_QUESTION_CONTENT_SUCCESS',
    SAVE_SUBMISSION_QUESTION_CONTENT_FAILURE: 'SAVE_SUBMISSION_QUESTION_CONTENT_FAILURE',

    ADD_ANSWER: 'ADD_ANSWER',
    ADD_ANSWER_REQUEST: 'ADD_ANSWER_REQUEST',
    ADD_ANSWER_SUCCESS: 'ADD_ANSWER_SUCCESS',
    ADD_ANSWER_FAILURE: 'ADD_ANSWER_FAILURE',

    SAVE_SUBMISSION_ANSWER_CONTENT: 'SAVE_SUBMISSION_ANSWER_CONTENT',
    SAVE_SUBMISSION_ANSWER_CONTENT_REQUEST: 'SAVE_SUBMISSION_ANSWER_CONTENT_REQUEST',
    SAVE_SUBMISSION_ANSWER_CONTENT_SUCCESS: 'SAVE_SUBMISSION_ANSWER_CONTENT_SUCCESS',
    SAVE_SUBMISSION_ANSWER_CONTENT_FAILURE: 'SAVE_SUBMISSION_ANSWER_CONTENT_FAILURE'
};

export const descriptiveTypes = {
    DESCRIPTIVE_PAGE_REFRESH: 'DESCRIPTIVE_PAGE_REFRESH',

    CREATE_DESCRIPTIVE_QUESTION: 'CREATE_DESCRIPTIVE_QUESTION',
    CREATE_DESCRIPTIVE_QUESTION_REQUEST: 'CREATE_DESCRIPTIVE_QUESTION_REQUEST',
    CREATE_DESCRIPTIVE_QUESTION_SUCCESS: 'CREATE_DESCRIPTIVE_QUESTION_SUCCESS',
    CREATE_DESCRIPTIVE_QUESTION_FAILURE: 'CREATE_DESCRIPTIVE_QUESTION_FAILURE',

    SAVE_DESCRIPTIVE_QUESTION_CONTENT: 'SAVE_DESCRIPTIVE_QUESTION_CONTENT',
    SAVE_DESCRIPTIVE_QUESTION_CONTENT_REQUEST: 'SAVE_DESCRIPTIVE_QUESTION_CONTENT_REQUEST',
    SAVE_DESCRIPTIVE_QUESTION_CONTENT_SUCCESS: 'SAVE_DESCRIPTIVE_QUESTION_CONTENT_SUCCESS',
    SAVE_DESCRIPTIVE_QUESTION_CONTENT_FAILURE: 'SAVE_DESCRIPTIVE_QUESTION_CONTENT_FAILURE',

    ADD_ANSWER: 'ADD_ANSWER',
    ADD_ANSWER_REQUEST: 'ADD_ANSWER_REQUEST',
    ADD_ANSWER_SUCCESS: 'ADD_ANSWER_SUCCESS',
    ADD_ANSWER_FAILURE: 'ADD_ANSWER_FAILURE',

    SAVE_DESCRIPTIVE_ANSWER_CONTENT: 'SAVE_DESCRIPTIVE_ANSWER_CONTENT',
    SAVE_DESCRIPTIVE_ANSWER_CONTENT_REQUEST: 'SAVE_DESCRIPTIVE_ANSWER_CONTENT_REQUEST',
    SAVE_DESCRIPTIVE_ANSWER_CONTENT_SUCCESS: 'SAVE_DESCRIPTIVE_ANSWER_CONTENT_SUCCESS',
    SAVE_DESCRIPTIVE_ANSWER_CONTENT_FAILURE: 'SAVE_DESCRIPTIVE_ANSWER_CONTENT_FAILURE'
};

export const mcqTypes = {
    MCQ_PAGE_REFRESH: 'MCQ_PAGE_REFRESH',

    CREATE_MCQ_QUESTION: 'CREATE_MCQ_QUESTION',
    CREATE_MCQ_QUESTION_REQUEST: 'CREATE_MCQ_QUESTION_REQUEST',
    CREATE_MCQ_QUESTION_SUCCESS: 'CREATE_MCQ_QUESTION_SUCCESS',
    CREATE_MCQ_QUESTION_FAILURE: 'CREATE_MCQ_QUESTION_FAILURE',

    UPDATE_MCQ_QUESTION: 'UPDATE_MCQ_QUESTION',
    UPDATE_MCQ_QUESTION_REQUEST: 'UPDATE_MCQ_QUESTION_REQUEST',
    UPDATE_MCQ_QUESTION_SUCCESS: 'UPDATE_MCQ_QUESTION_SUCCESS',
    UPDATE_MCQ_QUESTION_FAILURE: 'UPDATE_MCQ_QUESTION_FAILURE',

    SAVE_MCQ_QUESTION_CONTENT: 'SAVE_MCQ_QUESTION_CONTENT',
    SAVE_MCQ_QUESTION_CONTENT_REQUEST: 'SAVE_MCQ_QUESTION_CONTENT_REQUEST',
    SAVE_MCQ_QUESTION_CONTENT_SUCCESS: 'SAVE_MCQ_QUESTION_CONTENT_SUCCESS',
    SAVE_MCQ_QUESTION_CONTENT_FAILURE: 'SAVE_MCQ_QUESTION_CONTENT_FAILURE'
};


export const libraryTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    SELECT_QUESTION: 'SELECT_QUESTION',

    UPDATE_QUESTION_REQUEST: 'UPDATE_QUESTION_REQUEST',
    UPDATE_QUESTION_SUCCESS: 'UPDATE_QUESTION_SUCCESS',
    UPDATE_QUESTION_FAILURE: 'UPDATE_QUESTION_FAILURE',

    GET_MY_QUESTIONS: 'GET_MY_QUESTIONS',
    GET_MY_QUESTIONS_REQUEST: 'GET_MY_QUESTIONS_REQUEST',
    GET_MY_QUESTIONS_SUCCESS: 'GET_MY_QUESTIONS_SUCCESS',
    GET_MY_QUESTIONS_FAILURE: 'GET_MY_QUESTIONS_FAILURE',

    GET_ALL_MY_QUESTIONS: 'GET_ALL_MY_QUESTIONS',
    GET_ALL_MY_QUESTIONS_REQUEST: 'GET_ALL_MY_QUESTIONS_REQUEST',
    GET_ALL_MY_QUESTIONS_SUCCESS: 'GET_ALL_MY_QUESTIONS_SUCCESS',
    GET_ALL_MY_QUESTIONS_FAILURE: 'GET_ALL_MY_QUESTIONS_FAILURE',

    GET_ALL_PUBLIC_QUESTIONS: 'GET_ALL_PUBLIC_QUESTIONS',
    GET_ALL_PUBLIC_QUESTIONS_REQUEST: 'GET_ALL_PUBLIC_QUESTIONS_REQUEST',
    GET_ALL_PUBLIC_QUESTIONS_SUCCESS: 'GET_ALL_PUBLIC_QUESTIONS_SUCCESS',
    GET_ALL_PUBLIC_QUESTIONS_FAILURE: 'GET_ALL_PUBLIC_QUESTIONS_FAILURE',

    GET_QUESTION_CONTENT: 'GET_QUESTION_CONTENT',
    GET_QUESTION_CONTENT_REQUEST: 'GET_QUESTION_CONTENT_REQUEST',
    GET_QUESTION_CONTENT_SUCCESS: 'GET_QUESTION_CONTENT_SUCCESS',
    GET_QUESTION_CONTENT_FAILURE: 'GET_QUESTION_CONTENT_FAILURE',
    CLEAR_QUESTION_CONTENT: 'CLEAR_QUESTION_CONTENT',

    GET_QUESTION_GUIDANCE_CONTENT: 'GET_QUESTION_GUIDANCE_CONTENT',
    GET_QUESTION_GUIDANCE_CONTENT_REQUEST: 'GET_QUESTION_GUIDANCE_CONTENT_REQUEST',
    GET_QUESTION_GUIDANCE_CONTENT_SUCCESS: 'GET_QUESTION_GUIDANCE_CONTENT_SUCCESS',
    GET_QUESTION_GUIDANCE_CONTENT_FAILURE: 'GET_QUESTION_GUIDANCE_CONTENT_FAILURE',

    GET_ANSWER_CONTENT_REQUEST: 'GET_ANSWER_CONTENT_REQUEST',
    GET_ANSWER_CONTENT_SUCCESS: 'GET_ANSWER_CONTENT_SUCCESS',
    GET_ANSWER_CONTENT_FAILURE: 'GET_ANSWER_CONTENT_FAILURE',

    GET_QUESTIONS_SHARED_WITH_ME: 'GET_QUESTIONS_SHARED_WITH_ME',
    GET_QUESTIONS_SHARED_WITH_ME_REQUEST: 'GET_QUESTIONS_SHARED_WITH_ME_REQUEST',
    GET_QUESTIONS_SHARED_WITH_ME_SUCCESS: 'GET_QUESTIONS_SHARED_WITH_ME_SUCCESS',
    GET_QUESTIONS_SHARED_WITH_ME_FAILURE: 'GET_QUESTIONS_SHARED_WITH_ME_FAILURE',

    GET_ALL_QUESTIONS_SHARED_WITH_ME: 'GET_ALL_QUESTIONS_SHARED_WITH_ME',
    GET_ALL_QUESTIONS_SHARED_WITH_ME_REQUEST: 'GET_ALL_QUESTIONS_SHARED_WITH_ME_REQUEST',
    GET_ALL_QUESTIONS_SHARED_WITH_ME_SUCCESS: 'GET_ALL_QUESTIONS_SHARED_WITH_ME_SUCCESS',
    GET_ALL_QUESTIONS_SHARED_WITH_ME_FAILURE: 'GET_ALL_QUESTIONS_SHARED_WITH_ME_FAILURE',

    DELETE_QUESTION_REQUEST: 'DELETE_QUESTION_REQUEST',
    DELETE_QUESTION_SUCCESS: 'DELETE_QUESTION_SUCCESS',
    DELETE_QUESTION_FAILURE: 'DELETE_QUESTION_FAILURE',

    DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    LIST_MANAGED_ASSESSMENT: 'LIST_MANAGED_ASSESSMENT',
    LIST_MANAGED_ASSESSMENT_REQUEST: 'LIST_MANAGED_ASSESSMENT_REQUEST',
    LIST_MANAGED_ASSESSMENT_SUCCESS: 'LIST_MANAGED_ASSESSMENT_SUCCESS',
    LIST_MANAGED_ASSESSMENT_FAILURE: 'LIST_MANAGED_ASSESSMENT_FAILURE',

    GET_ALL_MY_ASSESSMENTS: 'GET_ALL_MY_ASSESSMENTS',
    GET_ALL_MY_ASSESSMENTS_REQUEST: 'GET_ALL_MY_ASSESSMENTS_REQUEST',
    GET_ALL_MY_ASSESSMENTS_SUCCESS: 'GET_ALL_MY_ASSESSMENTS_SUCCESS',
    GET_ALL_MY_ASSESSMENTS_FAILURE: 'GET_ALL_MY_ASSESSMENTS_FAILURE',

    LIST_ADMIN_ASSESSMENT: 'LIST_ADMIN_ASSESSMENT',
    LIST_ADMIN_ASSESSMENT_REQUEST: 'LIST_ADMIN_ASSESSMENT_REQUEST',
    LIST_ADMIN_ASSESSMENT_SUCCESS: 'LIST_ADMIN_ASSESSMENT_SUCCESS',
    LIST_ADMIN_ASSESSMENT_FAILURE: 'LIST_ADMIN_ASSESSMENT_FAILURE',

    LIST_ASSESSED_ASSESSMENT: 'LIST_ASSESSED_ASSESSMENT',
    LIST_ASSESSED_ASSESSMENT_REQUEST: 'LIST_ASSESSED_ASSESSMENT_REQUEST',
    LIST_ASSESSED_ASSESSMENT_SUCCESS: 'LIST_ASSESSED_ASSESSMENT_SUCCESS',
    LIST_ASSESSED_ASSESSMENT_FAILURE: 'LIST_ASSESSED_ASSESSMENT_FAILURE',

    GET_ALL_SHARED_ASSESSMENTS: 'GET_ALL_SHARED_ASSESSMENTS',
    GET_ALL_SHARED_ASSESSMENTS_REQUEST: 'GET_ALL_SHARED_ASSESSMENTS_REQUEST',
    GET_ALL_SHARED_ASSESSMENTS_SUCCESS: 'GET_ALL_SHARED_ASSESSMENTS_SUCCESS',
    GET_ALL_SHARED_ASSESSMENTS_FAILURE: 'GET_ALL_SHARED_ASSESSMENTS_FAILURE',

    GET_ALL_PUBLIC_ASSESSMENTS: 'GET_ALL_PUBLIC_ASSESSMENTS',
    GET_ALL_PUBLIC_ASSESSMENTS_REQUEST: 'GET_ALL_PUBLIC_ASSESSMENTS_REQUEST',
    GET_ALL_PUBLIC_ASSESSMENTS_SUCCESS: 'GET_ALL_PUBLIC_ASSESSMENTS_SUCCESS',
    GET_ALL_PUBLIC_ASSESSMENTS_FAILURE: 'GET_ALL_PUBLIC_ASSESSMENTS_FAILURE',

    VIEW_ASSESSMENT: 'VIEW_ASSESSMENT',
    VIEW_ASSESSMENT_REQUEST: 'VIEW_ASSESSMENT_REQUEST',
    VIEW_ASSESSMENT_SUCCESS: 'VIEW_ASSESSMENT_SUCCESS',
    VIEW_ASSESSMENT_FAILURE: 'VIEW_ASSESSMENT_FAILURE',
    VIEW_ASSESSMENT_REFRESH: 'VIEW_ASSESSMENT_REFRESH',

    VIEW_ASSESSMENT_DATA: 'VIEW_ASSESSMENT_DATA',
    VIEW_ASSESSMENT_DATA_REQUEST: 'VIEW_ASSESSMENT_DATA_REQUEST',
    VIEW_ASSESSMENT_DATA_SUCCESS: 'VIEW_ASSESSMENT_DATA_SUCCESS',
    VIEW_ASSESSMENT_DATA_FAILURE: 'VIEW_ASSESSMENT_DATA_FAILURE',

    GET_USERS: 'GET_USERS',
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',
    GET_USERS_REFRESH: 'GET_USERS_REFRESH',
};

export const shareTypes = {
    SHARE_PAGE_REFRESH: 'SHARE_PAGE_REFRESH',

    SHARE_QUESTION: 'SHARE_QUESTION',
    SHARE_QUESTION_REQUEST: 'SHARE_QUESTION_REQUEST',
    SHARE_QUESTION_SUCCESS: 'SHARE_QUESTION_SUCCESS',
    SHARE_QUESTION_FAILURE: 'SHARE_QUESTION_FAILURE',

    GET_SHARED_USERS: 'GET_SHARED_USERS',
    GET_SHARED_USERS_REQUEST: 'GET_SHARED_USERS_REQUEST',
    GET_SHARED_USERS_SUCCESS: 'GET_SHARED_USERS_SUCCESS',
    GET_SHARED_USERS_FAILURE: 'GET_SHARED_USERS_FAILURE',

    STOP_SHARING_QUESTION_REQUEST: 'STOP_SHARING_QUESTION_REQUEST',
    STOP_SHARING_QUESTION_SUCCESS: 'STOP_SHARING_QUESTION_SUCCESS',
    STOP_SHARING_QUESTION_FAILURE: 'STOP_SHARING_QUESTION_FAILURE',

    UNSHARE_FROM_ME_REQUEST: 'UNSHARE_FROM_ME_REQUEST',
    UNSHARE_FROM_ME_SUCCESS: 'UNSHARE_FROM_ME_SUCCESS',
    UNSHARE_FROM_ME_FAILURE: 'UNSHARE_FROM_ME_FAILURE',
};

export const userRoles = {
    ASSR: 'ASSR',
    CASS: 'CASS',
    ASAD: 'ASAD'
};

export const messageTypes = {
    ASSESSMENT_CREATE_SUCCESS: 'Assessment Created Successfully!',
    CREATE_FAIL: 'Creation Failed',
    DELETE_FAIL: 'Deletion Failed',
    CREATE_SUCCESS: 'Added Successfully',
    ASSESSMENT_EDIT_SUCCESS: 'Assessment Updated Successfully!',
    REPORT_SUCCESS_MESSAGE: 'Grade Report Loaded!',
    FAILED_MESSAGE: 'Sorry, Something Went Wrong',
    SUCCESS_WARNING: 'Success!',
    FAILED_WARNING: 'Failed!',
    WARNING: 'Warning!',
    SUCCESS: 'success',
    WARN: 'warning',
    ERROR: 'error',
    INFO: 'information'
};
