import React, { Component } from "react"
import Nav from "react-bootstrap/Nav"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import edfocilogo from "../../../assets/images/logo.png"
import edsiderlogo from "../../../assets/images/edsiderlogo.png"
import cmclogo from "../../../assets/images/mid.png";
import socialf from "../assets/images/socialf.svg"
import socialt from "../assets/images/socialt.svg"
import sociali from "../assets/images/sociali.svg"
import sociall from "../assets/images/sociall.svg"
import socialy from "../assets/images/socialy.svg"
import socialtk from "../assets/images/socialtk.svg"
import config from '../../../helper/config';
import { customerConstants } from "../../../helper/constants.js";
import { routes } from "../../../helper/routes.js"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};
class Footer extends Component {
  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role)
    this.props.history.push(routes.auth_response)
  }

  handleSignUp = () => {
    this.props.history.push(routes.registration_form_view)
  }

  render() {
    const currentYear = new Date().getFullYear()
    const customer = getCustomerFeatureFlag();
    let logo, footerText, copyRightText;
    switch (customer) {
      case customerConstants.EXO_CUSTOMER1:
        logo = edsiderlogo;
        copyRightText = "© 2024 EdSider";
        footerText = "All Right Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
        break;
      case customerConstants.EXO_CUSTOMER2:
        logo = cmclogo;
        copyRightText = "© 2024"
        footerText = "All Rights Reserved. Powered by EdSider (Pvt) Ltd. Supported by Infinity Innovators (Pvt) Ltd"
        break;
      default:
        logo = edfocilogo;
        copyRightText = "© 2024 EdFoci"
        footerText = "All Rights Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd."
        break;
    }
    return (
      <>
        <div id="tabs" className="mt-4 container footer-container">
          <div className="row mb-5">
            <div className="col-md-4 col-sm-12 col-xs-12">
              <img
                src={logo}
                alt="edsider-logo"
                style={{ height: "5rem" }}
              />
              <p className="footer-text">
                Comprehensive learning materials to <br /> succeed in your exams
              </p>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-4 mb-2">
              <p className="follow-text">Follow Us</p>
              <div className="row social-icons">
                <a href="https://www.facebook.com/profile.php?id=100092749131453">
                  <img src={socialf}
                    alt="facebook-icon"
                    className="fb-icon"
                  />
                </a>
                <a href="https://twitter.com/Edsider_Exams">
                  <img
                    src={socialt}
                    alt="twitter-icon"
                    className="twitter-icon"
                  />
                </a>
                {/* <a href="https://www.instagram.com/edsider_examonline/">
                  <img
                    src={sociali}
                    alt="instagram-icon"
                    className="insta-icon"
                  />
                </a> */}
                <a href="https://www.linkedin.com/company/edsider/">
                  <img
                    src={sociall}
                    alt="linkedin-icon"
                    className="mail-icon"
                  />
                </a>
                <a href="https://www.youtube.com/channel/UCDJjKUotWHQ3uhNX_GMTldw">
                  <img
                    src={socialy}
                    alt="youtube-icon"
                    className="mail-icon"
                  />
                </a>
                {/* <a href="https://www.tiktok.com/@edsider_examonline?lang=en">
                  <img
                    src={socialtk}
                    alt="tiktok-icon"
                    className="mail-icon"
                  />
                </a> */}
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-4 px-0">
              <h3 className="footer-title general-title">General</h3>
              <div className="row footer-content">
                <div className="col-md-12">
                  <Nav.Link className="footer-links general-links" href="#home">
                    Home
                  </Nav.Link>
                  <Nav.Link
                    className="footer-links general-links"
                    href="#support"
                  >
                    Support
                  </Nav.Link>
                  <Nav.Link
                    className="footer-links general-links"
                    href="#about"
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    className="footer-links general-links"
                    href="#edsider"
                  >
                    Why EdSider
                  </Nav.Link>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-4">
              <h3 className="footer-title">Contact Us</h3>
              <div className="footer-content">
                <Nav.Link
                  className="footer-links f-action-links"
                >
                  Phone: +94 11 255 0173
                </Nav.Link>
                <Nav.Link
                  className="footer-links f-action-links"
                  href="mailto:info@edsider.com"
                >
                  Email: info@edsider.com
                </Nav.Link>
                <Nav.Link
                  className="footer-links f-action-links"
                >
                  Address: No. 28, 1/1, Temple Lane, Colombo 03, Sri Lanka
                </Nav.Link>
                {/* <Nav.Link className="footer-links f-action-links" href='#'>Telephone: +94 XX XXX XXXX</Nav.Link> */}
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-between copyright-container">
            <div className="col-md-2 col-sm-3 copyright-items">
              {copyRightText}
            </div>
            <div className="col-md-6 col-sm-3 copyright-items">
              All Rights Reserved
            </div>
            <div className="col-md-4 col-sm-3 d-flex justify-content-end policy-content">
              <span className="text-dark-75">
                <a
                  href={routes.terms_and_conditions_view}
                  target="_blank"
                  className="text-dark-75 text-hover-primary"
                >
                  Privacy Policy and Terms & Conditions
                </a>
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Footer)
