import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { routes } from "../../helper/routes";
import { history } from "../../helper/history";
import _ from "lodash";
import { questionTypeConstants } from "../../helper/constants";
import FileUpload from "../../components/common/form/FileUpload";
import ContentWrapper from "../../components/common/AssessmentContentWrapper";
import AdminTemplate from '../../components/admin/layouts/Template';
import Spinner from "../../components/common/Spinner";
import Card from "../../components/common/Card";
import CardHeader from "../../components/common/CardHeader";
import CardBody from "../../components/common/CardBody";
import CardTitle from "../../components/common/AssessmentCardTitle";
import Breadcrumb from "../../components/common/Breadcrumb";
import BreadcrumbItem from "../../components/common/BreadcrumbItem";
import CheckBox from "../../components/common/form/CheckBox";
import CheckBoxInline from "../../components/common/form/CheckBoxInline";
import Button from "../../components/common/form/Button";
import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ViewEditor from "../../components/common/editor/ViewEditor";
import { questionActions } from "../../actions/assessment/questionActions";
import { userActions } from "../../actions/user_management/userActions";


class StudentView extends React.Component {
  constructor(props) {
    super(props);

    this.ref_tagify = React.createRef();
    this.state = {
      assessment_title: "",
      assessmentQuestionsData: [],
      questions_contents_by_type: {},
      backToFinalize: false,
      questions: [],
      subQuestions: [],
      subQuestionsIndexes: []
    };
  }

  async componentDidMount() {
    if (window.KTSelect2) window.KTSelect2.init();
    if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
    if (window.KTIONRangeSlider) window.KTIONRangeSlider.init();
    if (window.KTApp.initComponents) window.KTApp.initComponents();
    if (window.$) window.$(".selectpicker").selectpicker();
    if (typeof window.KTLayoutStickyCard !== "undefined") {
      window.KTLayoutStickyCard.update();
    } else {
      if (window.KTLayoutStickyCard)
        window.KTLayoutStickyCard.init("kt_page_sticky_card");
    }

    await this.props.getUsers();
    var user_id = sessionStorage.getItem("userID");
    if (_.isEqual({}, this.props.userMap)) {
      this.props.createUserMap();
    }

    let questions = this.props.assessmentFilteredQuestions?.filter(item => item.is_subquestion !== "true");
    this.setState({
      questions: questions
    })

    let subQuestionsObject = {};
    this.props.assessmentFilteredQuestions.forEach((item, index) => {
      if (item.is_subquestion === "true") {
        if (!subQuestionsObject[index]) {
          subQuestionsObject[index] = item;
        }
      }
    });
    this.setState({
      subQuestions: subQuestionsObject
    })

    let subQuestionsIndexes = this.props.assessmentFilteredQuestions.map((item, index) => item.is_subquestion === "true" ? index : null)
      ?.filter(index => index !== null);
    this.setState({
      subQuestionsIndexes: subQuestionsIndexes
    })


    await this.props.assessment;
    this.setState({ assessment_title: this.props.assessment.title });

    window.onpopstate = function (event) {
      history.push(routes.view_assessment);
      window.location.reload();
    };
  }

  async componentDidUpdate() {
    if (
      this.props.assessmentFilteredQuestions &&
      this.props.assessmentFilteredQuestions.length &&
      !this.state.assessmentQuestionsData.length
    ) {
      var question_list = this.state.questions,
        i;

      for (i = 0; i < question_list.length; i++) {
        question_list[i]["question_number"] = i + 1; //adding "question_number" as prperty for each questions
      }
      this.setState({ assessmentQuestionsData: question_list });
    }
  }

  changeRoute() {
    window.location = routes.view_assessment;
  }

  onClickBackbtn = () => {
    this.setState({
      backToFinalize: true,
    })
  }

  render() {
    let question_content;
    let questionsByType = this.state.questions_contents_by_type;

    if (this.state.backToFinalize) {
      return <Redirect to={routes.setup_assessment} />;
    }

    if (this.state.assessmentQuestionsData.length) {
      let indexes = this.state.subQuestionsIndexes
      let subQuestions = {};
      indexes.forEach(index => {
        if (this.props.questionContents.hasOwnProperty(index)) {
          subQuestions[index] = this.props.questionContents[index];
        }
      });
      let filteredQuestions = this.props?.questionContents?.filter((item, index) => !indexes.includes(index));
      question_content = filteredQuestions.map((question, i) => {

        let states = JSON.parse(question)
        let tempAnswerStates = []
        let tempQuestionState = EditorState.createWithContent(convertFromRaw(states[0]))
        if (this.state.questions[i]?.question_type === questionTypeConstants.DESCRIPTIVE.name) {
          return <div className="col-md-12">
            <h3 className="row align-items-center mb-5 overflow-hidden all-questions-title">
              <div className="col-md-1 col-1 number-wrap">
                {i + 1}.
              </div>
              <div className="col-md-11 col-11 rdw-editor-question">
                <ViewEditor
                  editorState={tempQuestionState}
                />
              </div>
            </h3>

            <div className="RichEditor-root-descriptive-answer" key={i} id="editAnswer">
              <Editor
                editorStyle={{ height: '200px' }}
                editorState={""}
                key={""}
                onEditorStateChange={''}
                toolbarHidden={true}
                readOnly={true}
              />
            </div>
            <div className="separator separator-dashed separator-border-2 mt-5 mb-5" />
          </div>

        } else if (this.state.questions[i]?.question_type === questionTypeConstants.SUBQUESTION.name) {
          let subs = this.state.subQuestions
          let arr = []
          for (let val in subs) {
            if (subs[val].title === this.state.questions[i].title) {
              arr.push(val)
            }
          }
          let sub_ques = {}
          arr.forEach(index => {
            if (subQuestions.hasOwnProperty(index)) {
              sub_ques[index] = subQuestions[index];
            }
          });
          return <div className="col-md-12">
            <h3 className="row align-items-center mb-5 overflow-hidden all-questions-title">
              <div className="col-md-1 col-1 number-wrap">
                {i + 1}.
              </div>
              <div className="col-md-11 col-11 rdw-editor-question">
                <ViewEditor
                  editorState={tempQuestionState}
                />
              </div>
            </h3>
            {Object.entries(sub_ques).map(([key, val], j) => {
              let state = JSON.parse(val);
              let tempQuestion = EditorState.createWithContent(
                convertFromRaw(state[0])
              );
              return (
                <div className="col-md-12">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-1 col-1 number-wrap" style={{ fontWeight: "600" }}>{j + 1}.</div>
                    <div className="col-md-11 col-11 rdw-editor-question" style={{ fontWeight: "500" }}>
                      <ViewEditor
                        editorState={tempQuestion}
                      />
                    </div>
                  </div>

                  <div
                    className="RichEditor-root-descriptive-answer"
                    key={j}
                    id="editAnswer"
                  >
                    <Editor
                      editorStyle={{ maxHeight: "200px", overflow: "auto" }}
                      key={i}
                      toolbarClassName="vertical-toolbar"
                    />
                  </div>
                  <div className="separator separator-dashed separator-border-2 mt-5 mb-5" />
                </div>
              )
            })}
            <div className="separator separator-dashed separator-border-2 mt-5 mb-5" />
          </div>

        }
        else if (this.state.questions[i]?.question_type === questionTypeConstants.MCQ.name) {
          states.shift();
          states.forEach(state => {
            tempAnswerStates.push(EditorState.createWithContent(convertFromRaw(state)))
          });
          return (
            <div className="col-md-12">
              <h3 className="row d-flex align-items-center mb-5 overflow-hidden all-questions-title">
                <div className="col-md-1 col-1 number-wrap">{i + 1}.</div>
                <div className="col-md-11 col-11 rdw-editor-question">
                  <ViewEditor
                    editorState={tempQuestionState}
                  />
                </div>
              </h3>
              <div>
                <div
                  className={`RichEditor-root parent-class ${tempAnswerStates.some(
                    (answer) =>
                      answer.getCurrentContent().getPlainText().length > 100
                  )
                    ? "single-column"
                    : ""
                    }`}
                >
                  {tempAnswerStates.map((answer, j) => {
                    return (
                      <div className="row d-flex align-items-center child-class">
                        <div className="col-md-2 col-2">
                          <CheckBoxInline className="col-md-1">
                            <CheckBox
                              key={j}
                              onClick={""}
                              checked={""}
                              style ={{
                                color: "#5458AF",
                              }}
                            />
                          </CheckBoxInline>
                        </div>
                        <div className="col-md-10 col-10 px-0">
                          <Editor
                            editorState={answer}
                            toolbarClassName="hide-toolbar"
                            readOnly={true}
                            toolbar={{}}
                            toolbarHidden={true}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="separator separator-dashed separator-border-2 mt-5 mb-5" />
            </div>
          )

        }
        else if (this.state.questions[i]?.question_type === questionTypeConstants.SUBMISSION.name) {
          var reload = false
          return <div className="col-md-12">
            <h3 className="row d-flex align-items-center mb-5 overflow-hidden all-questions-title">
              <div className="col-md-1 col-1 number-wrap">
                {i + 1}.
              </div>
              <div className="col-md-11 col-11">
                <ViewEditor
                  editorState={tempQuestionState}
                />
              </div>
            </h3>
            <div className="col-md-10">

            </div>
            <FileUpload className="col-md-10 col-form-label"
              key={i}
              id={""}
              title={""}
              defaultFile={""}
              callBack={""}

              reload={reload}
              questionIndex={""}
            />

            <div className="separator separator-dashed separator-border-2 mt-5 mb-5" />
          </div>
        }

      })
    }


    return (
      <AdminTemplate>
        <ContentWrapper>
          {this.props.loading && (
            <Spinner id="show" text="Loading, Please wait!" />
          )}
          {/* begin::Card */}
          {this.state.assessment_title != undefined ? (
            <div style={{ height: "90vh", overflow: "auto" }}>
            <Card>
              <CardHeader>
                <CardTitle cardLabel={this.props.assessment.title}>
                  <Breadcrumb>
                    <BreadcrumbItem
                      to={routes.view_assessment}
                      breadcrumbItemName="Go Back to Assessments Library"
                    />
                    {/* <BreadcrumbItem
                      to={routes.setup_assessment}
                      active="active"
                      breadcrumbItemName="Finalize"
                    /> */}
                  </Breadcrumb>
                </CardTitle>
                <Button
                  className="btn btn-primary mr-2"
                  btnName="Back"
                  onClick={this.onClickBackbtn}
                  type="button"
                />
              </CardHeader>
              <CardBody>
                <div>
                  {question_content}
                </div>
              </CardBody>
            </Card>
            </div>
          ) : (
            <div>
              {" "}
              <Redirect to={routes.view_assessment} />
            </div>
          )}

        </ContentWrapper>

      </AdminTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    userMap: state.userReducer.userMap,
    assessment: state.questionReducer.assessment,
    question: state.questionReducer.question,
    questionContents: state.questionReducer.questionContents,
    questions: state.questionReducer.questions,
    assessmentQuestions: state.questionReducer.assessmentQuestions,
    assessmentFilteredQuestions: state.questionReducer.assessmentFilteredQuestions,
    loading: state.questionReducer.loading,
  };
}

const mapActionsToProps = {
  createUserMap: userActions.createUserMap,
  refreshPage: questionActions.refreshPage,
  getUsers: userActions.getAllUsers,
  getAssessmentQuestions: questionActions.getAssessmentQuestions,
  getAllAssessmentQuestions: questionActions.getAllAssessmentQuestions,
  getAllQuestions: questionActions.getAllQuestions,
  getQuestionContent: questionActions.getQuestionContent,
  getAllQuestionContentsByType: questionActions.getAllQuestionContentsByType,
  getAllQuestionContents: questionActions.getAllQuestionContents,
  getQuestionTypes: questionActions.getQuestionTypes,
};

export default connect(mapStateToProps, mapActionsToProps)(StudentView);
