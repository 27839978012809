import React from "react"
import CheckBox from "../../components/common/form/CheckBox.js"
import CheckBoxInline from "../../components/common/form/CheckBoxInline.js"
import { Editor } from "react-draft-wysiwyg"
import ViewEditor from "../../components/common/ViewEditor.js"
import { connect } from "react-redux"
import { convertFromRaw, EditorState } from "draft-js"
import {
  getAllQuestionContents,
  getAllAnswerContents,
  getAllCandidateAnswerContents,
  getTestQuestionContent,
  loadAssessmentQuestions,
  loadQuestions,
  getAutoShuffleFlag,
  getCandidateAnswers,
  clearCandidateAnswers,
} from "../../actions/candidate/testActions.js"
import { AzureStorage } from "../../helper/azureStorage.js"
import Spinner from "../../components/Spinner3.js";
import {
  openAssessment
} from "../../actions/candidate/examActions.js"
import ContentWrapper from "../../components/common/ContentWrapper.js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "./Questions.css"
import Card from "../../components/common/Card.js"
import CardHeader from "../../components/common/CardHeader.js"
import CardTitle from "../../components/common/CardTitle.js"
import { Button } from "@mui/material"
import AttachmentButton from "../../components/common/AttachmentButton.js"
import { questionTypeConstants } from "../../helper/constants.js"
import config from "../../helper/config.js"
import { customerConstants } from "../../helper/constants.js"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let addBtnStyle, closeBtnStyle, textColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    textColor = "var(--maincolor)"
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "Unset",
      fontFamily: "Poppins",
    }
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
      boxShadow: "none",
    }
    textColor = "var(--maincolor-cmc)"
    break
  default:
    textColor = "var(--maincolor)"
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "Unset",
      fontFamily: "Poppins",
    }
    break
}

class PaperReview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      contentMap: {},
      questions: "",
      questionContents: null,
      assessmentQuestions: "",
      answerContents: null,
      marksSheetMap: {},
      correctAnswer: 0.0,
      paperViewLoading: false
    }

    this.getQuestionanswer = this.getQuestionanswer.bind(this)
    this.getSumOfMarkAllocation = this.getSumOfMarkAllocation.bind(this)
    this.getSumOfMarkObtained = this.getSumOfMarkObtained.bind(this)
    this.onDownloadAttachment = this.onDownloadAttachment.bind(this)
  }

  async componentDidMount() {
    if (window.KTApp) window.KTApp.initComponents()
    this.setState({ paperViewLoading: true });
    await this.props.loadAssessmentQuestions(this.props.candidate?.assessment_id)
    await this.props.getAllQuestionContents({
      questions: this.props.assessmentQuestions,
    })
    await this.props.getAllAnswerContents({
      questions: this.props.assessmentQuestions,
    })
    await this.props.getCandidateAnswers(this.props.candidate)
    await this.props.getAllCandidateAnswerContents({
      answers: this.props.candidateAnswers,
    })
    this.setState({ questions: this.props.assessmentQuestions })
    this.setState({ questionContents: this.props.questionContents })
    this.setState({ answerContents: this.props.answerContents })
    await this.props.assessmentQuestions?.map(async (question, j) => {
      let map = this.state.marksSheetMap
      map[question["question_number"]] = question
      if (question.question_type === questionTypeConstants.MCQ.name) {
        let answerContent = question["answer"].toString().split(",")
        let val = "0",
          correctAnswer = this.state.correctAnswer
        if (parseFloat(answerContent[j]) > 0) {
          val = "1"
          this.setState({ correctAnswer: answerContent[j] }, () => {
            map[question["question_number"]]["correctAnswer"] =
              this.state.correctAnswer
            this.setState({ marksSheetMap: map })
          })
        } else if (parseFloat(answerContent[j]) < 0) {
          val = "-1"
        }
      } else if (
        question.question_type === questionTypeConstants.DESCRIPTIVE.name ||
        question.question_type === questionTypeConstants.SUBMISSION.name ||
        question.question_type === questionTypeConstants.SUBQUESTION.name
      ) {
        let answerContent = question["answer"]
        this.setState({ correctAnswer: answerContent }, () => {
          map[question["question_number"]]["correctAnswer"] =
            this.state.correctAnswer
          this.setState({ marksSheetMap: map })
        })
      }
      this.setState({ marksSheetMap: map })
    })
    this.setState({ paperViewLoading: false });
  }

  componentWillUnmount() {
    this.props.clearCandidateAnswers()
  }

  async onDownloadAttachment(e, container, attach) {
    e.preventDefault()
    let fileReader
    async function handleFileRead() {
      const content = fileReader.result
    }

    fileReader = new FileReader()
    fileReader.onloadend = handleFileRead
    let content = await AzureStorage.downloadBlob(container, attach)
    fileReader.readAsArrayBuffer(content)
  }

  getColor(val) {
    switch (val) {
      case "-1":
        return "label label-lg label-light-danger label-inline"
      case "1":
        return "label label-lg label-light-success label-inline"
      default:
        return ""
    }
  }

  getContentInEditor(el, i) {
    var question_type = this.props.assessmentQuestions[i].question_type
    var is_sub = this.props.assessmentQuestions[i].is_subquestion
    let states = JSON.parse(this.props.questionContents[i])
    let tempAnswerStates = []
    let mark_allocation = this.state.marksSheetMap[i + 1]?.mark_allocation
    let tempQuestionState = EditorState.createWithContent(
      convertFromRaw(states[0])
    )
    if (question_type == questionTypeConstants.DESCRIPTIVE.name) {
      return (
        <div>
          <div className="row mb-5">
            <div className="col-md-10 col-10">
              <h5 className="m-0">
                {el.mainAnsCount}. {this.props.assessmentQuestions[i]?.title}
              </h5>
            </div>
            <div className="col-md-2 col-2 d-flex align-items-center justify-content-end">
              <p className="stu-variant-class label label-lg label-success label-inline">
                {/* Marks:{" "} */}
                {
                  this.props.candidateAnswerMap[
                    this.props.assessmentQuestions[i]?.question_id
                  ]?.mark_obtained
                }
                /{mark_allocation}
              </p>
            </div>
          </div>
          <h3 className="m-0 overflow-hidden">
            <p className="col-md-12 col-12 answer-sheet-text">
              <ViewEditor editorState={tempQuestionState} />
            </p>
          </h3>
          {/* <div class="separator separator-dashed separator-border-2 my-5"></div> */}
        </div>
      )
    } else if (
      question_type == questionTypeConstants.SUBQUESTION.name &&
      is_sub !== "true"
    ) {
      let subs = this.props.assessmentQuestions[i]?.sub_questions
      let sub_ques = subs.split(",")
      let sub_questions = {}
      this.props.assessmentQuestions.forEach((item, index) => {
        if (sub_ques.includes(item.question_id.toString())) {
          if (!sub_questions[index]) {
            sub_questions[index] = item
          }
        }
      })
      let sub_marks = 0
      let total_sub_marks = 0
      for (let mark in sub_questions) {
        sub_marks =
          sub_marks +
          this.props.candidateAnswerMap[
            this.props.assessmentQuestions[mark]?.question_id
          ]?.mark_obtained
        total_sub_marks =
          total_sub_marks +
          this.props.assessmentQuestions[mark]?.mark_allocation
      }

      return (
        <div>
          {/* <h5 className="m-0 px-4 mb-2">Main Question</h5> */}
          <div className="row mb-5">
            <div className="col-md-9 col-9">
              <h5 className="m-0">
                {el.mainAnsCount}. {this.props.assessmentQuestions[i]?.title}
              </h5>
            </div>
            <div className="col-md-3 col-3 d-flex align-items-center justify-content-end">
              <p className="stu-variant-class label label-lg label-light-success label-inline">
                {/* Marks:{" "} */}
                {sub_marks}/{total_sub_marks}
              </p>
            </div>
          </div>
          <h3 className="m-0 mb-5 overflow-hidden">
            <p className="col-md-12 col-12 answer-sheet-text">
              <ViewEditor editorState={tempQuestionState} />
            </p>
          </h3>
          {/* <div class="separator separator-dashed separator-border-2 my-5"></div> */}
          {/* <h5 className="m-0 px-4 mb-2">Sub Questions</h5> */}
          {Object.entries(sub_questions).map(([key, val], j) => {
            let states = JSON.parse(this.props.questionContents[key])
            let marks =
              this.state.marksSheetMap[parseInt(key) + 1]?.mark_allocation
            var answerMap = JSON.parse(
              this.props.contentMap[
                this.props.assessmentQuestions[key].question_id
              ]
            )
            var answer = JSON.parse(this.props.answerContents[key])
            let tempQuestion = EditorState.createWithContent(
              convertFromRaw(states[0])
            )
            return (
              <div className="ml-5">
                <div className="row mb-5">
                  <div className="col-md-11 col-11">
                    <h4 className="m-0 all-questions-title">
                      <h5 className="number-wrap m-0 answer-sheet-text">
                        {j + 1}.
                      </h5>
                      <h5 className="rdw-editor-question m-0 answer-sheet-text sub-view-editor">
                        <ViewEditor className='test' editorState={tempQuestion} />
                      </h5>
                    </h4>
                  </div>
                  <div className="col-md-1 col-1 d-flex align-items-center justify-content-end">
                    <h5 className="m-0 stu-variant-class label label-lg label-success label-inline">
                      {/* Marks:{" "} */}
                      {
                        this.props.candidateAnswerMap[
                          this.props.assessmentQuestions[key]?.question_id
                        ]?.mark_obtained
                      }
                      /{marks}
                    </h5>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row mt-3">
                    <div className="col-md-2 col-12">
                      <h6 className="model-guidance-text mt-4">Your Answer:</h6>
                    </div>
                    <div className="col-md-10 col-12 d-flex align-items-center">
                      {answerMap?.blocks?.length > 0 ? (
                        <p className="m-0 overflow-hidden answer-sheet-text">
                          <Editor
                            editorState={EditorState.createWithContent(
                              convertFromRaw(answerMap)
                            )}
                            toolbarClassName="hide-toolbar"
                            toolbarHidden={true}
                            readOnly={true}
                          />
                        </p>
                      ) : (
                        <p className="stu-variant-class label label-lg label-danger label-inline answer-sheet-text">
                          Not Answered
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2 col-12">
                      <h6 className="model-guidance-text mt-4">
                        Model Answer:
                      </h6>
                    </div>
                    <div className="col-md-10 col-12">
                      <p className="answer-sheet-text">
                        <Editor
                          editorState={EditorState.createWithContent(
                            convertFromRaw(answer[0])
                          )}
                          toolbarClassName="hide-toolbar"
                          toolbarHidden={true}
                          readOnly={true}
                        />
                      </p>
                    </div>
                  </div>
                  {/* <div className="separator separator-dashed separator-border-2 mt-5 mb-5" /> */}
                </div>
              </div>
            )
          })}
        </div>
      )
    } else if (question_type == questionTypeConstants.MCQ.name) {
      states.shift()
      states.forEach((state) => {
        tempAnswerStates.push(
          EditorState.createWithContent(convertFromRaw(state))
        )
      })
      return (
        <div>
          <div className="row">
            <div className="col-md-9 col-9">
              <h5 className="m-0">
                {el.mainAnsCount}. {this.props.assessmentQuestions[i]?.title}
              </h5>
            </div>
            <div className="col-md-3 col-3 d-flex align-items-center justify-content-end">
              <p className="stu-variant-class label label-lg label-success label-inline">
                {/* Marks:{" "} */}
                {
                  this.props.candidateAnswerMap[
                    this.props.assessmentQuestions[i]?.question_id
                  ]?.mark_obtained
                }
                /{mark_allocation}
              </p>
            </div>
            <h3 className="m-0 overflow-hidden">
              <p className="col-md-12 col-12 answer-sheet-text">
                <ViewEditor editorState={tempQuestionState} />
              </p>
            </h3>
          </div>
          {/* <div class="separator separator-dashed separator-border-2 my-5"></div> */}
        </div>
      )
    } else if (question_type == questionTypeConstants.SUBMISSION.name) {
      return (
        <div>
          <div className="row mb-5">
            <div className="col-md-9 col-9">
              <h5 className="m-0">
                {el.mainAnsCount}. {this.props.assessmentQuestions[i]?.title}
              </h5>
            </div>
            <div className="col-md-3 col-3 d-flex align-items-center justify-content-end">
              <p className="stu-variant-class label label-lg label-success label-inline">
                {/* Marks:{" "} */}
                {
                  this.props.candidateAnswerMap[
                    this.props.assessmentQuestions[i]?.question_id
                  ]?.mark_obtained
                }
                /{mark_allocation}
              </p>
            </div>
          </div>
          <h3 className="m-0 overflow-hidden">
            <p className="col-md-12 col-12 answer-sheet-text">
              <ViewEditor editorState={tempQuestionState} />
            </p>
          </h3>
          {/* <div class="separator separator-dashed separator-border-2 my-5"></div> */}
        </div>
      )
    }
  }

  getAssessorAnswerInEditor(question, i) {
    var question_type = this.props.assessmentQuestions[i].question_type
    var answer =
      this.props.contentMap[this.props.assessmentQuestions[i].question_id]
    let states = JSON.parse(this.props.questionContents[i])
    var no_ans = states.length - 1
    var tempAnswerStates = []
    // let container = "".concat(
    //   this.props.assessmentQuestions[i].created_by,
    //   "-sa-",
    //   this.props.assessmentQuestions[i].answer
    // )
    let container= config.storageBlobConfigs.containerName;
    if (question_type == questionTypeConstants.MCQ.name) {
      var res = this.props.answerContents[i]?.split(",")
      var anyBoxesChecked = [],
          ans
      if (answer) {
        answer = JSON.parse(answer)
        
        for (var n = 0; n < no_ans; n++) {
          anyBoxesChecked.push(false)
        }
        for (ans in answer) {
          anyBoxesChecked[answer[ans]] = true
        }
      }
      states.shift()
      states.forEach((state) => {
        tempAnswerStates.push(
          EditorState.createWithContent(convertFromRaw(state))
        )
      })
      return (
        <div>
          <div className="RichEditor-root py-0">
            {tempAnswerStates?.map((answer, j) => {
              let val = "0"
              if (parseFloat(res[j]) > 0) {
                val = "1"
              } else if (parseFloat(res[j]) < 0) {
                val = "-1"
              }
              return (
                <div
                  className={`row d-flex align-items-center ${
                    parseFloat(res[j])
                      ? "answersheet-correct"
                      : anyBoxesChecked[j]
                      ? "answersheet-wrong"
                      : ""
                  }`}
                >
                  <div className="col-md-1 col-1">
                    <CheckBoxInline className="col-md-1">
                      {/* <CheckBox key={j} checked={anyBoxesChecked[j]} className="correct-color" />
                      <CheckBox key={j} checked={parseFloat(res[j]) > 0} /> */}
                      <CheckBox
                        key={j}
                        checked={anyBoxesChecked[j]}
                        style={{
                          color: "#5458AF",
                        }}
                      />
                    </CheckBoxInline>
                  </div>
                  <div className="col-md-11 col-11">
                    <div className="row d-flex align-items-center">
                      <div className="col-md-11 col-11 answer-sheet-text">
                        <Editor
                          editorState={answer}
                          toolbarHidden={true}
                          toolbarClassName="hide-toolbar"
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-1 col-1 d-flex justify-content-end">
                        <div className={this.getColor(val)}>
                          {parseFloat(res[j]) > 0 ? "✔" : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div class="separator my-5"></div>
        </div>
      )
    } else if (question_type == questionTypeConstants.DESCRIPTIVE.name) {
      var answer = JSON.parse(this.props.answerContents[i])
      return (
        <div>
          <h3 className="m-0 overflow-hidden">
            <div className="row mt-3">
              <div className="col-md-2 col-12">
                <h6 className="model-guidance-text mt-4">Model Answer:</h6>
              </div>
              <div className="col-md-10 col-12">
                <p className="answer-sheet-text">
                  <Editor
                    editorState={EditorState.createWithContent(
                      convertFromRaw(answer[0])
                    )}
                    toolbarClassName="hide-toolbar"
                    toolbarHidden={true}
                    readOnly={true}
                  />
                </p>
              </div>
            </div>
          </h3>
          {/* <div class="separator separator-dashed separator-border-2 my-5"></div> */}
        </div>
      )
    } else if (question_type == questionTypeConstants.SUBMISSION.name) {
      let attachmentName = this.props.answerContents[i]
        .replace(/^\[(.+)\]$/, "$1")
        .replace(/"\"$/, "$1")
        .split(",")
      if (attachmentName.length > 0) {
        let itemList = []
        {
          attachmentName.map((attach) => {
            if (attach.replace(/"/g, "") != "[]") {
              itemList.push(
                <div>
                  <div className="row">
                    <div className="col-md-3 col-3 px-0">
                      <AttachmentButton
                        className="btn mr-2 submission-document"
                        btnIconStyle="flaticon-interface-3"
                        // inlineStyle={{ innerWidth: "400px" }}
                        btnName={attach.replace(/"/g, "")}
                        onClick={(e) => {
                          this.onDownloadAttachment(
                            e,
                            container,
                            attach.replace(/"/g, "")
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          })
        }
        return (
          <div>
            <div className="row mt-3">
              <div className="col-md-2 col-12">
                <h6 className="model-guidance-text mt-4">Model Answer:</h6>
              </div>
              <div className="RichEditor-root col-md-10 p-0">{itemList}</div>
            </div>
            {/* <div class="separator separator-dashed separator-border-2 my-5"></div> */}
          </div>
        )
      } else {
        return (
          <div>
            <div className="row mt-3">
              <div className="col-md-2 col-12">
                <h6 className="model-guidance-text mt-4">Model Answer:</h6>
              </div>
              <div className="RichEditor-root col-md-10">
                No Answer Uploaded!
              </div>
            </div>
            {/* <div class="separator separator-dashed separator-border-2 my-5"></div> */}
          </div>
        )
      }
    }
  }

  getAssessorAnswerGuidanceInEditor(question, i) {
    var guidance = this.props.assessmentQuestions[i].guidence

    var guidanceContent = JSON.parse(guidance);

    let guidanceEditorContent = EditorState.createWithContent(
      convertFromRaw(guidanceContent[0])
    )

    var question_type = this.props.assessmentQuestions[i].question_type

    if (question_type === questionTypeConstants.MCQ.name && guidance) {
      return (
        <div>
          <div className="row">
            <div className="col-md-2 col-12 d-flex align-items-center">
              <h6 className="model-guidance-text">Marking Guidance:</h6>
            </div>
            <div className="col-md-10 col-12">
              <div className="card p-3">
                <p className="answer-sheet-text">
                  {guidance ? (
                    <ViewEditor editorState={guidanceEditorContent} />
                  ) : (
                    <div>
                      <p className="answer-sheet-text">
                        No marking guidance given for this question
                      </p>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="separator mt-4 mb-4"></div>
        </div>
      )
    } else if (
      question_type == questionTypeConstants.DESCRIPTIVE.name &&
      guidance
    ) {
      return (
        <div>
          <div className="row">
            <div className="col-md-2 col-12 d-flex align-items-center">
              <h6 className="model-guidance-text">Marking Guidance:</h6>
            </div>
            <div className="col-md-10 col-12">
              <div className="card p-3">
                <p className="answer-sheet-text">
                  {guidance ? (
                    <ViewEditor editorState={guidanceEditorContent} />
                  ) : (
                    <div>
                      <p className="answer-sheet-text">
                        No marking guidance given for this question
                      </p>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="separator mt-4 mb-4"></div>
        </div>
      )
    } else if (
      question_type == questionTypeConstants.SUBMISSION.name &&
      guidance
    ) {
      return (
        <div>
          <div className="row">
            <div className="col-md-2 col-12 d-flex align-items-center">
              <h6 className="model-guidance-text">Marking Guidance:</h6>
            </div>
            <div className="col-md-10 col-12">
              <div className="card p-3">
                <p className="answer-sheet-text">
                  {guidance ? (
                    <ViewEditor editorState={guidanceEditorContent} />
                  ) : (
                    <div>
                      <p className="answer-sheet-text">
                        No marking guidance given for this question
                      </p>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="separator mt-4 mb-4"></div>
        </div>
      )
    }
  }

  getAnswerInEditor(question, i) {
    var question_type = this.props.assessmentQuestions[i].question_type
    var answer =
      this.props.contentMap[this.props.assessmentQuestions[i].question_id]
    let states = JSON.parse(this.props.questionContents[i])
    var no_ans = states.length - 1
    var tempAnswerStates = []
    if (question_type == questionTypeConstants.DESCRIPTIVE.name) {
      if (answer) {
        var answerState = JSON.parse(answer)
        return (
          <div>
            <div className="row">
              <div className="col-md-2 col-12">
                <h6 className="model-guidance-text mt-4">Your Answer:</h6>
              </div>
              <div className="col-md-10 col-12 d-flex align-items-center">
                {answerState?.blocks?.length > 0 ? (
                  <p className="m-0 overflow-hidden answer-sheet-text">
                    <Editor
                      editorState={EditorState.createWithContent(
                        convertFromRaw(answerState)
                      )}
                      toolbarClassName="hide-toolbar"
                      toolbarHidden={true}
                      readOnly={true}
                    />
                  </p>
                ) : (
                  <p className="stu-variant-class label label-lg label-danger label-inline answer-sheet-text">
                    Not Answered
                  </p>
                )}
              </div>
            </div>
            <div class="separator my-5"></div>
          </div>
        )
      }
    } else if (question_type == questionTypeConstants.SUBMISSION.name) {
      let attachmentName = answer
        .replace(/^\[(.+)\]$/, "$1")
        .replace(/"\"$/, "$1")
        .split(",")
      const container = "".concat(
        this.props.candidate.candidate_id,
        "-",
        this.props.assessmentQuestions[i].question_id
      )
      const container_name = config.storageBlobConfigs.containerName;
      
      if (attachmentName[0] !== "[]") {
        let itemList = []
        {
          attachmentName.map((attach) => {
            if (attach.replace(/"/g, "") != "[]") {
              itemList.push(
                <div className="row">
                  <AttachmentButton
                    className="btn mr-2 submission-document"
                    btnIconStyle="flaticon-interface-3"
                    btnName={attach.replace(/"/g, "")}
                    onClick={(e) => {
                      this.onDownloadAttachment(
                        e,
                        container_name,
                        container + "-" + attach.replace(/"/g, "")
                      )
                    }}
                  />
                </div>
              )
            }
          })
        }
        return (
          <div>
            <div className="row">
              <div className="col-md-2 col-12">
                <h6 className="model-guidance-text mt-4">Your Answer:</h6>
              </div>
              <div className="RichEditor-root col-md-10 p-0">{itemList}</div>
            </div>
            <div class="separator my-5"></div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="row">
              <div className="col-md-2 col-12">
                <h6 className="model-guidance-text mt-4">Your Answer:</h6>
              </div>
              <p className="RichEditor-root d-flex align-items-center stu-variant-class label label-lg label-danger label-inline mx-4 my-2 answer-sheet-text">
                No Answer Uploaded!
              </p>
            </div>
            <div class="separator my-5"></div>
          </div>
        )
      }
    }
  }

  getQuestionanswer(question_number) {
    let answer = this.state.marksSheetMap[question_number].correctAnswer
    return answer
  }

  getSumOfMarkAllocation() {
    let totalSum = 0
    Object.keys(this.state.marksSheetMap).forEach((questionNumber) => {
      const marksData = this.state.marksSheetMap[questionNumber]
      const correctAnswerMarkAllocation = marksData.mark_allocation || 0
      totalSum += correctAnswerMarkAllocation
    })
    return totalSum
  }

  getSumOfMarkObtained() {
    let totalMarksObtained = 0
    for (let i = 0; i < this.props.assessmentQuestions?.length; i++) {
      const question = this.props.assessmentQuestions[i]
      const questionId = question?.question_id
      const markObtained =
        this.props.candidateAnswerMap?.[questionId]?.mark_obtained || 0
      totalMarksObtained += markObtained
    }
    return totalMarksObtained
  }

  render() {
    let mainAnsCount = 0
    return (
      <>
        <div style={{ overflowY: "auto", height: "90vh" }}>
          <ContentWrapper>
            {this.props.answerContents.length &&
            this.props.questionContents.length &&
            !this.state.paperViewLoading ? (
            <Card>
              <CardHeader>
                <CardTitle
                  cardLabel={this.props.assessment?.title + " - Answer Sheet"}
                />
                <div className="d-flex justify-content-end">
                  <h4 className="stu-variant-class label label-lg label-light-success label-inline border border-primary p-3 m-0 mr-2">
                    Total Marks: {this.getSumOfMarkObtained()}/
                    {this.getSumOfMarkAllocation()}
                  </h4>
                  {/* <div className="d-flex align-items-center justify-content-end">
                    <h4 className="text-white font-weight-bold p-3 mb-3" style={addBtnStyle}>
                      Total Marks: {this.getSumOfMarkObtained()}/
                      {this.getSumOfMarkAllocation()}
                    </h4>
                  </div> */}
                  <Button
                    variant="contained"
                    style={addBtnStyle}
                    onClick={() => {
                      // this.props.history.push({
                      //   pathname: "/paper-grade-report",
                      //   state: {
                      //     mid: sessionStorage.getItem("enrolled_paper_list_mid"),
                      //     pid: sessionStorage.getItem("pid"),
                      //     lg_user_id: sessionStorage.getItem("lg_user_id"),
                      //     lg_user_table_id:
                      //       sessionStorage.getItem("lg_user_table_id"),
                      //     flg_list_active:
                      //       sessionStorage.getItem("flg_list_active"),
                      //     form_display_title:
                      //       sessionStorage.getItem("form_display_title"),
                      //   },
                      // });
                      this.props.hidePaperReview()
                    }}
                  >
                    Back
                  </Button>
                </div>
              </CardHeader>
                <div className="mt-3">
                  {this.props.assessmentQuestions.map((question, i) => {                    
                    if(question.is_subquestion !== "true"){
                      mainAnsCount = mainAnsCount + 1
                    }
                    let altQuestion = {...question, mainAnsCount:mainAnsCount }
                    return (
                    <div
                      className={
                        this.props.assessmentQuestions[i]?.is_subquestion
                          ? ""
                          : "question-container"
                      }
                      key={i}
                    >
                      {this.getContentInEditor(altQuestion, i)}
                      {this.getAnswerInEditor(altQuestion, i)}
                      {this.getAssessorAnswerInEditor(altQuestion, i)}
                      {this.getAssessorAnswerGuidanceInEditor(altQuestion, i)}
                    </div>
                  )})}
                </div>
            </Card>
            ) : (
              <Spinner id="show" text="Loading Answer Sheet..." />
            )}
          </ContentWrapper>
        </div>
      </>
    )
  }
}

const mapStateToActions = {
  getTestQuestionContent: getTestQuestionContent,
  getAllQuestionContents: getAllQuestionContents,
  getAllAnswerContents: getAllAnswerContents,
  getAllCandidateAnswerContents: getAllCandidateAnswerContents,
  getCandidateAnswers: getCandidateAnswers,
  getAutoShuffleFlag: getAutoShuffleFlag,
  loadQuestions: loadQuestions,
  loadAssessmentQuestions: loadAssessmentQuestions,
  openAssessment: openAssessment,
  clearCandidateAnswers: clearCandidateAnswers,
}

function mapStateToProps(state) {
  return {
    test_loading: state.testReducer.loading,
    test_error: state.testReducer.error,
    questions: state.testReducer.questions,
    assessmentQuestions: state.testReducer.assessmentQuestions,
    auto_shuffle: state.testReducer.auto_shuffle,

    content_error: state.testPageReducer.error,
    content_success: state.testPageReducer.success,
    content_loading: state.testPageReducer.loading,
    questionContents: state.testPageReducer.questionContents,

    submission_uploading: state.submissionReducer.loading,
    submission_error: state.submissionReducer.error,
    submission_success: state.submissionReducer.success,

    candidate_id: state.validationReducer.candidate_id,
    start_time: state.validationReducer.start_time,
    exam_duration: state.validationReducer.exam_duration,

    contentMap: state.testReducer.candidateAnswerContentMap,
    candidateAnswerContents: state.testReducer.candidateAnswerContents,
    answerContents: state.testReducer.answerContents,
    candidateAnswers: state.testReducer.candidateAnswers,
    candidateAnswerMap: state.testReducer.candidateAnswerMap,
    candidate: state.testReducer.candidate,

    gradeError: state.gradeReducer.error,
    gradeLoading: state.gradeReducer.loading,
  }
}

export default connect(mapStateToProps, mapStateToActions)(PaperReview)
